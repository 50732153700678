import { AxiosError } from 'axios';
// import { AlertDTO, AlertsQuery, AlertsResponse } from 'dtos/alert';
// import { useQuery, UseQueryOptions } from 'react-query';
import { AgentDTO } from 'dtos/agent';
import { AlertDTO, AlertsQuery, AlertsResponse, EditAlertPreferenceDTO } from 'dtos/alert';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { UseMutationOptions } from 'react-query/types/react/types';
import { AlertsService } from 'services/Alert';

export const useAlerts = (query: AlertsQuery) => {
  return useQuery<AlertsResponse>(['alerts', query], async () => {
    return await AlertsService.getAllAlerts(query);
  });
};

export const useAlert = (id: string, options?: UseQueryOptions<AlertDTO, AxiosError>) =>
  useQuery<AlertDTO, AxiosError>(['alert-data', id], () => AlertsService.getAlert(id), options);

export const useEditAlertPreferences = (options?: UseMutationOptions<AgentDTO, AxiosError, EditAlertPreferenceDTO>) => {
  const queryClient = useQueryClient();

  return useMutation(
    'edit-alert-preferences',
    (data: EditAlertPreferenceDTO) => AlertsService.updateAlertPreferences(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['current-agent']);
        await queryClient.refetchQueries(['agency-users']);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

// export const useEditAlert = (id: string, options?: UseMutationOptions<AlertDTO, AxiosError, EditAlertDTO>) => {
//   const queryClient = useQueryClient();

//   return useMutation('edit-alert', (data: EditAlertDTO) => AlertsService.updateAlert(id, data), {
//     ...options,
//     onSuccess: async (data, variables, context) => {
//       await queryClient.refetchQueries(['alerts']);
//       await queryClient.refetchQueries(['alert-data', id]);

//       if (options && options.onSuccess) {
//         options.onSuccess(data, variables, context);
//       }
//     },
//   });
// };
