import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useToasters } from 'hooks/useToasters';
import { useMarkApplicationRequestForConfiguration } from 'queries/useApplications';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  applicationId: string;
  className?: string;
  isRequestedForConfiguration?: boolean;
  requestPermitted?: boolean;
}

export const RequestForConfigurationButton: React.FC<Props> = ({
  applicationId,
  className,
  isRequestedForConfiguration,
  requestPermitted,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useMarkApplicationRequestForConfiguration(applicationId, {
    onSuccess: () => {
      showToaster(
        `Requested configuration successfully. We will notify you via email once the configuration is ready.`,
      );
      setIsModalOpen(false);
    },
    onError: (e) => {
      toasterErrorHandler(e);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const MODAL_TITLE = requestPermitted ? 'Confirm Request Configuration' : 'Unable to Request Configuration';
  const MODAL_TEXT = requestPermitted
    ? 'Please note that Mac functionality is limited and has a few manual steps needed as will be described in the configuration page. Another limitation for Macs is that we cannot provide application history. Do you want to proceed?'
    : 'Configuration can be requested only if not already requested';

  return (
    <>
      <Tooltip title='Request Configuration for Mac Devices'>
        <Button
          className={className}
          onClick={handleModalOpen}
          variant={isRequestedForConfiguration ? 'text' : 'outlined'}
          color='primary'
          disabled={isRequestedForConfiguration}
        >
          {isRequestedForConfiguration ? 'Configuration Requested' : 'Request Configuration'}
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            {MODAL_TITLE}
          </Typography>
          <Typography variant='body1'>{MODAL_TEXT}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>{requestPermitted ? 'Cancel' : 'Close'}</Button>
          {requestPermitted && (
            <Button color='primary' variant='contained' disabled={isLoading} onClick={() => mutate()}>
              {isLoading ? (isRequestedForConfiguration ? 'Saving...' : 'Submitting...') : 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
