import { ApplicationDTO, ApplicationStatus } from 'dtos/application';
import React from 'react';

import { PolicyEffectiveDate } from './PolicyEffectiveDate';

interface Props {
  application: ApplicationDTO;
  table?: boolean;
}

export const EffectiveDate = ({ application: { applicationResponse, id, applicationStatus }, table }: Props) =>
  applicationResponse.target_effective_date && applicationStatus !== ApplicationStatus.BOUND ? (
    applicationResponse.target_effective_date
  ) : table ? (
    <>{'-'}</>
  ) : (
    <PolicyEffectiveDate applicationId={id} applicationStatus={applicationStatus} />
  );
