/* eslint-disable indent */
import { PieCustomLayerProps, ResponsivePie } from '@nivo/pie';
import React from 'react';

const SEGMENTS = [
  {
    id: 'low',
    label: 'Low',
    value: 40,
    color: '#E9EDFC',
  },
  {
    id: 'fair',
    label: 'Fair',
    value: 10,
    color: '#cfd7fa',
  },
  {
    id: 'good',
    label: 'Good',
    value: 20,
    color: '#b4c1fa',
  },
  {
    id: 'high',
    label: 'High',
    value: 30,
    color: '#516AE6',
  },
];

export const mapSecurityScoreLabel = (percent?: number) => {
  if (!percent && percent !== 0) return 'N/A';
  if (percent < 40) {
    return 'Low';
  }

  if (percent < 50) {
    return 'Fair';
  }

  if (percent < 70) {
    return 'Good';
  }

  return 'High';
};

interface CustomLayerProps {
  value: number;
  markers?: Array<{ label?: string; value?: number; type?: MarkerType; showLabel?: boolean }>;
}

export enum MarkerType {
  COLOR = 'color',
  BLACK = 'black',
}

const Marker =
  ({ markers }: CustomLayerProps) =>
  ({ centerX, centerY }: PieCustomLayerProps<any>) => {
    const MARKER_WRAPPER_WIDTH = 124;
    const MARKER_WRAPPER_HEIGHT = 14;
    const MARKER_WIDTH = 50;
    const MARKER_HEIGHT = 6;

    return (
      <g>
        {markers?.map((m, idx) => (
          <g transform={`translate(${centerX - MARKER_WRAPPER_WIDTH}, ${centerY})`} key={idx}>
            <g
              transform={`rotate(${(m?.value || 0) * 1.8})`}
              style={{
                width: MARKER_WRAPPER_WIDTH,
                height: MARKER_WRAPPER_HEIGHT,
                transformOrigin: `${MARKER_WRAPPER_WIDTH}px 2px`,
              }}
            >
              {m?.type !== MarkerType.BLACK && (
                <rect style={{ width: MARKER_WIDTH, height: MARKER_WRAPPER_HEIGHT, fill: '#fff' }} rx={4}></rect>
              )}
              <rect
                style={{
                  width: MARKER_WIDTH,
                  height: m?.type === MarkerType.BLACK ? 2 : MARKER_HEIGHT,
                  fill: m?.type === MarkerType.BLACK ? '#000' : '#516AE6',
                  transform: `translateY(${(MARKER_WRAPPER_HEIGHT - MARKER_HEIGHT) / 2}px)`,
                }}
                rx={4}
              ></rect>
            </g>
          </g>
        ))}
        {markers?.map((m, idx) => {
          if (m?.showLabel) {
            let translateX = -48;
            let translateY = -128;
            let internalShowLabel: boolean = m?.showLabel;
            if (m?.value && m?.showLabel) {
              if (m?.value < 30 || m?.value > 70) {
                internalShowLabel = false;
              } else {
                internalShowLabel = m?.showLabel;
              }
              if (m?.value < 35) {
                translateX -= 46;
                translateY += 12;
              } else if (m?.value < 40) {
                translateX -= 40;
                translateY += 8;
              } else if (m?.value < 45) {
                translateX -= 32;
                translateY += 4;
              } else if (m?.value > 65) {
                translateX += 48;
                translateY += 12;
              } else if (m?.value > 60) {
                translateX += 40;
                translateY += 8;
              } else if (m?.value > 55) {
                translateX += 32;
                translateY += 4;
              }
            }
            if (m?.value && internalShowLabel) {
              return (
                <text
                  transform={`translate(${centerX + translateX}, ${centerY + translateY})`}
                  fill='black'
                  fontSize='13px'
                  fontWeight='500'
                  key={idx}
                >
                  {m?.label}
                </text>
              );
            }
            return null;
          }
          return null;
        })}
      </g>
    );
  };

const Needle =
  ({ value }: CustomLayerProps) =>
  ({ centerX, centerY }: PieCustomLayerProps<any>) => {
    return (
      <g transform={`translate(${centerX - 28}, ${centerY - 18}) `}>
        <path
          transform={`rotate(${value * 1.8})`}
          style={{
            transformOrigin: '27px 15px',
          }}
          fill='#516AE6'
          d='M22.93.57c-1.026.285-2.01.674-2.937 1.155l-1.36.794-16.82 9.824c-2.417 1.412-2.417 4.903 0 6.314l16.82 9.823 1.36.795A15.532 15.532 0 0 0 27.225 31c8.418-.062 15.31-6.922 15.397-15.333C42.712 7.029 35.732 0 27.11 0c-1.449 0-2.85.199-4.18.57Zm9.717 15.322c-.19 2.753-2.434 4.975-5.19 5.142a5.54 5.54 0 0 1-4.87-2.322 5.513 5.513 0 0 1-1.026-3.212c0-1.197.417-2.41 1.118-3.338a5.54 5.54 0 0 1 4.431-2.207 5.547 5.547 0 0 1 5.537 5.937Z'
        />
        <circle cx='27' cy='15' r='6' fill='#fff' />
      </g>
    );
  };

const CustomLabels = ({ centerX, centerY }: PieCustomLayerProps<any>) => {
  return (
    <g>
      <text transform={`translate(${centerX - 114}, ${centerY + 24})`} fill='#B9C3F5' fontSize='12px' fontWeight='700'>
        Low
      </text>
      <text transform={`translate(${centerX + 86}, ${centerY + 24})`} fill='#516AE6' fontSize='12px' fontWeight='500'>
        High
      </text>
    </g>
  );
};

type Props = Partial<CustomLayerProps>;

const MARGIN = {
  top: 40,
  right: 0,
  bottom: 40,
  left: 0,
};

export const GaugeChart = ({ value = 0, markers }: Props) => {
  return (
    <ResponsivePie
      data={SEGMENTS}
      // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      margin={MARGIN}
      innerRadius={0.65}
      padAngle={1}
      cornerRadius={4}
      startAngle={-90}
      endAngle={90}
      activeOuterRadiusOffset={8}
      colors={{ datum: 'data.color' }}
      enableArcLabels={false}
      isInteractive={false}
      layers={[
        'arcs',
        'arcLinkLabels',
        'legends',
        'arcLabels',
        Marker({ value, markers }),
        Needle({ value, markers }),
        CustomLabels,
      ]}
      // change below
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor='#333333'
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
    />
  );
};
