import { makeStyles } from '@material-ui/core/styles';
import { DetailsList, Props as ListProps } from 'components/DetailsList';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {},

  // Header
  header: {
    ...TYPOGRAPHY.TITLE_3,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(5),
    paddingRight: theme.spacing(3),
    gap: theme.spacing(1.5),
  },

  list: {
    '& + $list': {
      marginTop: theme.spacing(5),
    },
  },
}));

interface Props {
  header: React.ReactNode;
  lists: ListProps[];
}

export const Sidebar = ({ header, lists }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>{header}</div>

      {lists.map((l, idx) => (
        <DetailsList key={idx} {...l} className={classes.list} />
      ))}
    </div>
  );
};
