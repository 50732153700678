import { AxiosError } from 'axios';
import { PolicyDTO } from 'dtos/policy';
import { ProposalWithApplicationDTO } from 'dtos/proposal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { ApplicationService } from 'services/Application';
import { ProposalService } from 'services/Proposal';

export const useProposal = (id: string, options?: UseQueryOptions<ProposalWithApplicationDTO, AxiosError>) =>
  useQuery<ProposalWithApplicationDTO, AxiosError>(
    ['proposal-by-id', id],
    () => ProposalService.getProposalById(id),
    options,
  );

export const useLatestProposalForApplication = (
  applicationId: string,
  options?: UseQueryOptions<ProposalWithApplicationDTO, AxiosError>,
) =>
  useQuery<ProposalWithApplicationDTO, AxiosError>(
    ['proposal-by-application-id', applicationId],
    () => ApplicationService.getLatestActiveProposal(applicationId),
    options,
  );

export const useChooseProposalQuote = (id: string, options?: UseMutationOptions<PolicyDTO, AxiosError, string>) => {
  return useMutation((quoteId: string) => ProposalService.chooseProposalQuote(id, quoteId), options);
};

export const useDeleteProposal = (
  applicationId: string,
  id: string,
  options?: UseMutationOptions<void, AxiosError, void>,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => ProposalService.removeProposalById(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application-proposals', applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
