import { LinearProgress, withStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { variantColorsMap } from 'components/Badge';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

interface StylesProps {
  customVariant: ProgressBarVariant;
}

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(1.25),
    // width: 175,
    width: 60,
    // https://github.com/mui/material-ui/issues/23204
    zIndex: 0,
  },
  colorPrimary: (props: StylesProps) => ({
    backgroundColor: variantColorsMap[props?.customVariant].bg,
    color: variantColorsMap[props?.customVariant].text,
  }),
  bar: (props: StylesProps) => ({
    borderRadius: theme.spacing(1.25),
    backgroundColor: variantColorsMap[props?.customVariant].text,
  }),
}))(LinearProgress);

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  progressTitle: (props) => ({
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: variantColorsMap[props?.customVariant].text,
    marginRight: theme.spacing(1),
  }),
}));

export type ProgressBarVariant = 'green' | 'red' | 'yellow' | 'grey' | 'blue';

interface Props extends StylesProps {
  label: string;
  progress?: number;
}

export const ProgressBar = ({ label, progress, customVariant = 'grey' }: Props) => {
  const classes = useStyles({ customVariant });
  return (
    <div className={classes.progressWrapper}>
      <span className={classes.progressTitle}>{label}</span>
      <CustomLinearProgress variant='determinate' value={progress || 100} customVariant={customVariant} />
    </div>
  );
};
