import { SingleFilter } from 'components/SingleFilter';
import { ApplicationWithAgentDTO, MonitoringFrequency } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import moment from 'moment';
import { useUpdateApplicationSettings } from 'queries/useApplications';
import React, { useEffect, useMemo, useState } from 'react';

interface Props {
  application: ApplicationWithAgentDTO;
  className?: string;
}

export const MonitoringFrequencyModal = ({ application }: Props) => {
  const [selectedFrequency, setSelectedFrequency] = useState<MonitoringFrequency | null>(null);
  const { showToaster, toasterErrorHandler } = useToasters();

  useEffect(() => {
    if (application.monitoringFrequency) {
      setSelectedFrequency(application.monitoringFrequency);
    }
  }, [application]);

  const { mutate, isLoading } = useUpdateApplicationSettings(application.id, {
    onSuccess: () => {
      showToaster('Frequency Updated');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const nextScan = useMemo(() => {
    switch (application.monitoringFrequency) {
      case MonitoringFrequency.WEEKLY:
        return moment().add(1, 'weeks').startOf('isoWeek').format('Do MMMM YYYY');
      case MonitoringFrequency.MONTHLY:
        return moment().add(1, 'months').startOf('month').format('Do MMMM YYYY');
      case MonitoringFrequency.QUARTERLY:
        return moment().add(1, 'quarters').startOf('quarter').format('Do MMMM YYYY');
      default:
        return null;
    }
  }, [application.monitoringFrequency]);

  return (
    <div style={{ textAlign: 'right' }}>
      <SingleFilter
        type='radio'
        buttonText={`Frequency: ${selectedFrequency ? `${selectedFrequency}` : 'None'}`}
        defaultSelectedOptions={[selectedFrequency]}
        popperText='Select Frequency:'
        showDropdownIcon
        options={Object.values(MonitoringFrequency)}
        setSelectedOptions={(d) => d[0] && mutate({ monitoringFrequency: d[0] })}
        disabled={isLoading}
        startIcon={<></>}
      />
      {nextScan && (
        <div style={{ fontSize: 15, paddingTop: '5px', fontStyle: 'italic' }}>Next scan scheduled for {nextScan}</div>
      )}
    </div>
  );
};
