import { GeneratedUploadUrlResponseDTO, UpdateDocumentDTO } from 'dtos/_common';
import {
  CreatePolicyDocumentDTO,
  CreatePolicyDTO,
  PolicyDocumentDTO,
  PolicyDocumentType,
  PolicyDTO,
  UpdatePolicyDTO,
} from 'dtos/policy';
import fileDownload from 'js-file-download';

import RequestService from './Request';

class _PolicyService {
  async apiBindQuote(policyId: string) {
    return await RequestService.client.post<void>(`/policies/${policyId}/api-bind`).then((response) => response.data);
  }

  async createPolicyDocument(policyId: string, data: CreatePolicyDocumentDTO) {
    return await RequestService.client
      .post<PolicyDocumentDTO>(`/policies/${policyId}/documents`, data)
      .then((response) => response.data);
  }

  async deletePolicyDocument(policyId: string, documentId: string) {
    return await RequestService.client
      .delete<void>(`/policies/${policyId}/documents/${documentId}`)
      .then((response) => response.data);
  }

  async uploadPolicyDocument(policyId: string, type: PolicyDocumentType, file: File) {
    const data = await RequestService.client
      .post<GeneratedUploadUrlResponseDTO>(`/policies/${policyId}/upload-document`, { fileType: type })
      .then((response) => response.data);

    await RequestService.uploadFile(file, data);

    return data;
  }

  async downloadPolicyDocument(policyId: string, policyDocument: PolicyDocumentDTO) {
    const signedDownloadUrl = await RequestService.client
      .get<string>(`/policies/${policyId}/documents/${policyDocument.id}`)
      .then((response) => response.data);

    const response = await RequestService.client.get<Blob>(signedDownloadUrl, {
      responseType: 'blob',
    });

    fileDownload(response.data, policyDocument.fileName);
  }

  async getPolicyDocuments(policyId: string) {
    return await RequestService.client
      .get<PolicyDocumentDTO[]>(`/policies/${policyId}/documents`)
      .then((response) => response.data);
  }

  async updatePolicyDocument(policyId: string, documentId: string, data: UpdateDocumentDTO) {
    return await RequestService.client
      .patch<PolicyDocumentDTO>(`/policies/${policyId}/documents/${documentId}`, data)
      .then((response) => response.data);
  }

  async createPolicy(data: CreatePolicyDTO) {
    return await RequestService.client.post<PolicyDTO>(`/policies`, data).then((response) => response.data);
  }

  async cancelPolicy(id: string) {
    return await RequestService.client.patch<PolicyDTO>(`/policies/${id}/cancel`).then((response) => response.data);
  }

  async updatePolicy(id: string, data: UpdatePolicyDTO) {
    return await RequestService.client.patch<PolicyDTO>(`/policies/${id}`, data).then((response) => response.data);
  }

  async getSigningUrl(policyId: string) {
    return await RequestService.client.post<string>(`/policies/${policyId}/sign`).then((response) => {
      location.href = response.data;
    });
  }

  async getPolicyDocumentsToSign(policyId: string) {
    return await RequestService.client
      .get<PolicyDocumentDTO[]>(`/policies/${policyId}/documents-to-sign`)
      .then((response) => response.data);
  }

  async checkPolicyEnvelopeStatus(policyId: string) {
    return await RequestService.client
      .get<PolicyDTO>(`/policies/${policyId}/check-signing-status`)
      .then((response) => response.data);
  }

  async downloadCombinedSignedPolicyDocuments(policyId: string, data: PolicyDTO) {
    const signedDownloadUrl = await RequestService.client
      .get<string>(`/policies/${policyId}/signed-documents`)
      .then((response) => response.data);

    const response = await RequestService.client.get<Blob>(signedDownloadUrl, {
      responseType: 'blob',
    });

    fileDownload(
      response.data,
      `signed-policy-documents-${data.application.applicationResponse.organization_name}.pdf`,
    );
  }

  async getBillingUrl(policyId: string) {
    return await RequestService.client.post<string>(`/policies/${policyId}/checkout`).then((response) => {
      location.href = response.data;
    });
  }

  async checkBillingStatus(policyId: string) {
    return await RequestService.client
      .get<PolicyDTO>(`/policies/${policyId}/checkout`)
      .then((response) => response.data);
  }
}

const PolicyService = new _PolicyService();

export { PolicyService };
