import { Button, makeStyles } from '@material-ui/core';
import { AxiosError } from 'axios';
import { FilesDnD } from 'components/FilesDnD';
import { DeepScanDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { usePhishingCsv, useUpdateDeepScan } from 'queries/useDeepScan';
import React, { useMemo } from 'react';

import { CONFIGURATION_STEP } from './configuration';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 16,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
  },
  boxSubTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  boxButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  boxButtonMac: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  deepScan: DeepScanDTO;
  onVerify?: () => void;
}

export const UploadEmployeeListSection = ({ deepScan, onVerify }: Props) => {
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate } = useUpdateDeepScan(deepScan.id, undefined, {
    onSuccess: () => {
      showToaster(`Successfully verified employee list!`);
    },
  });

  const {
    mutate: uploadDomainCsv,
    isLoading: isUploading,
    reset: resetUploadeDomainCsv,
  } = usePhishingCsv(deepScan.id, {
    onSuccess: () => {
      showToaster('Successfully uploaded file');
      mutate({
        uploadedEmployeeList: true,
      });
    },
    onError: (error: AxiosError | unknown) => {
      resetUploadeDomainCsv();
      toasterErrorHandler(error);
    },
  });

  const handleDrop = (files: File[]) => {
    uploadDomainCsv(files[0]);
  };

  const buttonText = useMemo(() => {
    if (isUploading) {
      return 'Uploading...';
    }

    if (deepScan.employeeListFileName) {
      return `Uploaded ${deepScan.employeeListFileName}`;
    }

    return 'Upload CSV file here';
  }, [isUploading, deepScan.employeeListFileName]);

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      hideVerify={true}
      type={CONFIGURATION_STEP.EMPLOYEES_LIST}
      title={deepScan?.isLightScan ? 'Dark Web Email List (Optional)' : 'Upload Employee List'}
      button={{
        text: !deepScan.employeeListFileName ? 'Upload list to verify' : undefined,
        props: {
          disabled: !deepScan.employeeListFileName || deepScan.uploadedEmployeeList,
        },
      }}
      description={
        deepScan?.isLightScan
          ? `
          We automatically scan employee emails from M365 (if connected). If you want additional emails (like
          contractors, personal emails of employee etc) you can upload them here.
        `
          : `
          Upload information about your client’s employees. We use this for our phishing simulation, monitoring
          application activity and enhancing assessment reporting.
        `
      }
    >
      <div className={classes.boxesContainer}>
        <div className={classes.box}>
          <div className={classes.boxTitle}>Bulk Upload</div>
          <FilesDnD multiple={false} accept='.csv' addAnother={false} buttonLabel={buttonText} onDrop={handleDrop} />

          <br />

          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              window.open('/email-list-template.csv', '_blank');
            }}
          >
            Download CSV Template
          </Button>
        </div>
      </div>
    </SectionTemplate>
  );
};
