import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { ReactComponent as UploadIcon } from 'images/upload-cloud.svg';
import React, { useCallback, useRef } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  button: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    width: '100%',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    background: COLORS.WHITE,
    borderRadius: theme.spacing(1),
    color: COLORS.GREY_3,
    border: `1px solid ${COLORS.GREY_4}`,
    display: 'flex',
    alignItems: 'center',

    '&:not(:disabled)': {
      cursor: 'pointer',
    },

    '&:focused:not(:disabled), &:active:not(:disabled)': {
      borderColor: COLORS.BLUE_1,
      boxShadow: `0 0 0 5px ${COLORS.BLUE_2}`,
    },

    '&:hover:not(:disabled)': {
      borderColor: COLORS.BLUE_1,
    },
  },
  fileSelected: {
    color: COLORS.GREY_1,
    border: `1px solid ${COLORS.GREY_4}`,
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

interface Props {
  className?: string;
  isLoading?: boolean;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type'>;
  onChange: (file: File) => void;
  onReset?: () => void;
  selectedFile?: File | null;
  label: string;
  disabled?: boolean;
}

export const FilePicker = ({
  onChange,
  inputProps,
  selectedFile,
  label,
  className,
  onReset,
  isLoading,
  disabled,
}: Props) => {
  const classes = useStyles();
  const fileInput = useRef<null | HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        onChange(file);
      }
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }

    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const buttonClasses = cx(classes.button, {
    [classes.fileSelected]: selectedFile,
  });

  return (
    <div className={cx(classes.root, className)}>
      <input ref={fileInput} style={{ display: 'none' }} type='file' onChange={handleFileChange} {...inputProps} />
      <button
        className={buttonClasses}
        disabled={Boolean(disabled)}
        onClick={() => {
          if (fileInput.current) {
            fileInput.current.click();
          }
        }}
      >
        {!selectedFile && <UploadIcon className={classes.icon} />}
        {selectedFile ? selectedFile.name : label}
        {isLoading ? ' – Uploading...' : ''}
      </button>
      {onReset && selectedFile && (
        <IconButton className={classes.deleteButton} onClick={handleReset}>
          <TrashIcon />
        </IconButton>
      )}
    </div>
  );
};
