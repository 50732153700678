import { ControlProps, Layout } from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, uniq } from 'lodash';
import isString from 'lodash/isString';
import React from 'react';

import { _CheckboxWithDescription } from '../CheckboxWithDescription';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },

  elementsWrapper: {
    paddingLeft: theme.spacing(4),

    '& .MuiPaper-elevation1': {
      boxShadow: 'none',

      '& .MuiCardHeader-root': {
        padding: 0,
      },
    },

    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
}));

const _NestedCheckbox: React.FC<ControlProps> = ({
  data,
  uischema,
  schema,
  rootSchema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  description,
  id,
  errors,
  label,
  handleChange,
}) => {
  const classes = useStyles();

  if (isEmpty((uischema as unknown as Layout).elements) || !visible) {
    return null;
  }

  const elementsState = uniq(Object.values(data));
  const handleChangeAll = () => {
    for (const key in data) {
      handleChange(`${path}.${key}`, !elementsState[0]);
    }
  };

  return (
    <Grid container direction='column' spacing={0} className={classes.root}>
      <_CheckboxWithDescription
        data={!!elementsState[0]}
        indeterminate={elementsState.length > 1}
        visible={visible}
        description={description}
        enabled={enabled}
        handleChange={handleChangeAll}
        id={id}
        label={isString(label) ? label : label.default}
        errors={errors}
        path={path}
        uischema={uischema}
        schema={schema}
        rootSchema={rootSchema}
      />
      {(uischema as unknown as Layout).elements.map((child, index) => (
        <Grid item key={`${path}-${index}`} xs className={classes.elementsWrapper}>
          <ResolvedJsonFormsDispatch
            uischema={child}
            schema={schema}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const NestedCheckbox = withJsonFormsControlProps(_NestedCheckbox);
