import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from 'components/CustomTextField';
// import { Modal } from 'components/Modal';
import { RadioSelect } from 'components/RadioSelect';
import { AgentDTO, AgentRole, CreateEditAgentInAgencyDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useInviteEditUser } from 'queries/useAgency';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    display: 'block',
    minWidth: 200,
    marginLeft: 'auto',
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '12px',
      color: '#232830',
      fontWeight: 500,
    },
  },
  edit: {
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
  invite: {},
}));

interface Props {
  agent?: AgentDTO;
  agencyId: string | null;
  open: boolean;
  handleClose: () => void;
}

export const CreateEditAgent = ({ agent, agencyId, open, handleClose }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useInviteEditUser({
    onSuccess: () => {
      showToaster(`Successfully ${agent ? 'updated' : 'created'} user`);
      if (agencyId) queryClient.refetchQueries(['agency-users', agencyId]);
      if (handleClose) handleClose();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const { handleSubmit, control, setValue } = useForm<CreateEditAgentInAgencyDTO>({
    defaultValues: {
      firstName: agent?.firstName || '',
      lastName: agent?.lastName || '',
      email: agent?.email || '',
      password: '',
      role: agent?.role || AgentRole.REGULAR,
    },
  });

  useEffect(() => {
    if (agent) {
      setValue('firstName', agent.firstName || '');
      setValue('lastName', agent.lastName || '');
      setValue('email', agent.email || '');
      setValue('role', agent.role || AgentRole.REGULAR);
    } else if (open) {
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('email', '');
      setValue('password', '');
      setValue('role', AgentRole.REGULAR);
    }
  }, [agent, setValue, open]);

  const onSubmit = (data: CreateEditAgentInAgencyDTO) => {
    mutate({
      firstName: data.firstName,
      lastName: data.lastName,
      email: agent?.email || data.email,
      password: data.password,
      role: data.role,
      agencyId: agencyId,
    });
  };

  return (
    <Dialog
      open={open || !!agent}
      maxWidth='lg'
      onClose={() => {
        if (handleClose) handleClose();
      }}
    >
      <DialogTitle>{agent ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='firstName'
              control={control}
              defaultValue=''
              render={({ field }) => <CustomTextField {...field} label='First Name' required />}
            />
            <Controller
              name='lastName'
              control={control}
              defaultValue=''
              render={({ field }) => <CustomTextField {...field} label='Last Name' required />}
            />
            <Controller
              name='email'
              control={control}
              defaultValue=''
              disabled={!!agent}
              render={({ field }) => <CustomTextField {...field} label='Email' required />}
            />
            {!agent && (
              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({ field }) => <CustomTextField {...field} label='Password' type='password' required />}
              />
            )}
            <Controller
              name='role'
              control={control}
              defaultValue={AgentRole.REGULAR}
              render={({ field }) => (
                <div className={classes.fieldRow}>
                  <p>Role *</p>
                  <RadioSelect<AgentRole>
                    className={classes.radioSelect}
                    selectedValue={field.value}
                    onOptionSelect={(opt) => field.onChange(opt.value)}
                    selectedFn={(optionValue, selectedValue) => optionValue === selectedValue}
                    options={[
                      { label: 'Own Assessments', value: AgentRole.REGULAR },
                      { label: 'All Assessments', value: AgentRole.AGENCY_ADMIN },
                    ]}
                  />
                </div>
              )}
            />
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} className={classes.button}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
