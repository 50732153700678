import { UISchemaElement } from '@jsonforms/core';
import { Button, makeStyles } from '@material-ui/core';
import { JsonForms } from 'components/JsonForms';
import { ApplicationDTO, ApplicationWithAgentDTO } from 'dtos/application';
import { useClientEditApplication, useUserSignupApplicationVersion } from 'queries/useApplications';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BottomActionsNavigation } from '../../components/BottomActionsNavigation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      minWidth: 400,
      width: 'auto',
      maxWidth: '100%',
    },
  },
  description: {
    maxWidth: 390,
    textAlign: 'center',
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  select: {
    marginBottom: theme.spacing(),
  },
  buttonWrapper: {
    justifyContent: 'center',
    display: 'flex',
    gap: theme.spacing(),
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  submit: {
    minWidth: 200,
  },

  bottomNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
}));

interface Props {
  application?: ApplicationWithAgentDTO;
  onSave: (application: ApplicationDTO) => void;
}

export const SecurityUserSignupOptionalForm = ({ application, onSave }: Props) => {
  const classes = useStyles();
  const { data: applicationVersion, isLoading } = useUserSignupApplicationVersion();

  const [applicationEditedData, setApplicationEditedData] = useState<any>({});
  const [selectedSection, setSelectedSection] = useState<string>('');

  useEffect(() => {
    // We want to invoke this code only once on initial load
    if (application?.applicationResponse) {
      setApplicationEditedData(application?.applicationResponse);
      setSelectedSection(applicationVersion?.uiSchema?.sections?.[0]?.pageSlug || '');
    }
  }, [application, applicationVersion?.uiSchema?.sections]);

  const currentPageIdx = useMemo(
    () => (applicationVersion?.uiSchema?.sections || [])?.findIndex((el) => el.pageSlug === selectedSection),
    [applicationVersion?.uiSchema?.sections, selectedSection],
  );

  const currentPageData = currentPageIdx != null && applicationVersion?.uiSchema?.sections?.[currentPageIdx];

  const { mutate, isLoading: isSaving } = useClientEditApplication(application?.id || '', {
    onSuccess: (data) => {
      setApplicationEditedData(data.applicationResponse);
      onSave(data);
    },
  });

  const handleUpdateData = useCallback(
    (updatedData: any) => {
      setApplicationEditedData(updatedData);
    },
    [setApplicationEditedData],
  );

  const handleSave = useCallback(() => {
    mutate({
      applicationResponse: applicationEditedData,
    });
  }, [applicationEditedData, mutate]);

  return (
    <div className={classes.root}>
      <p className={classes.description}>Tell us a little bit about your business</p>
      {currentPageData ? (
        <JsonForms
          schema={applicationVersion?.dataSchema}
          uischema={currentPageData?.uiSchema as UISchemaElement}
          data={applicationEditedData}
          onChange={({ data }) => handleUpdateData(data)}
          validationMode='ValidateAndHide'
          applicationSource='agent'
        />
      ) : null}
      {isLoading ? 'Loading...' : null}

      <div className={classes.buttonWrapper}></div>

      <BottomActionsNavigation roundedButton className={classes.bottomNavigation}>
        <Button onClick={handleSave} variant='outlined' disabled={isSaving}>
          {isSaving ? 'Loading...' : 'Skip'}
        </Button>
        <Button
          className={classes.submit}
          disabled={application?.applicationResponse === applicationEditedData || isSaving}
          onClick={handleSave}
          variant='contained'
          color='primary'
        >
          {isSaving ? 'Loading...' : 'Continue'}
        </Button>
      </BottomActionsNavigation>
    </div>
  );
};
