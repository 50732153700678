import { BadgeVariant } from 'components/Badge';
import { FindingSeverity } from 'dtos/application';
import { SecuritySeverity } from 'dtos/security-finding';

export const getSeverityBadgeVariant = (severity?: SecuritySeverity | FindingSeverity): BadgeVariant => {
  switch (severity) {
    case SecuritySeverity.HIGH:
    case FindingSeverity.High:
      return 'red';
    case SecuritySeverity.MEDIUM:
    case FindingSeverity.Medium:
      return 'yellow';
    case SecuritySeverity.LOW:
    case FindingSeverity.Low:
      return 'blue';
    default:
      return 'grey';
  }
};
