import { AxiosResponse } from 'axios';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import {
  CreateQuoteDocumentDTO,
  QuoteDocumentDTO,
  QuoteDocumentType,
  QuoteDTO,
  QuoteInReviewDTO,
  QuoteLetterCheckResultDTO,
  ScheduleApiQuotingDTO,
  UpdateManualQuoteDTO,
} from 'dtos/quotes';
import fileDownload from 'js-file-download';

import RequestService from './Request';

class _QuoteService {
  async createQuoteDocument(quoteId: string, data: CreateQuoteDocumentDTO) {
    return await RequestService.client
      .post<QuoteDocumentDTO>(`/quotes/${quoteId}/documents`, data)
      .then((response) => response.data);
  }

  async checkQuoteLetter(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await RequestService.client
      .post<QuoteLetterCheckResultDTO[]>(`/quotes/quote-data`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);
  }

  async getAllQuotesInReview(applicationId: string) {
    return await RequestService.client
      .get<QuoteInReviewDTO[]>(`/quotes-in-review/${applicationId}`)
      .then((response) => response.data);
  }

  async updateQuote(quoteId: string, data: UpdateManualQuoteDTO): Promise<QuoteDTO> {
    return await RequestService.client
      .put<UpdateManualQuoteDTO, AxiosResponse<QuoteDTO>>(`/quotes/${quoteId}`, data)
      .then((response) => response.data);
  }

  async deleteQuoteDocument(quoteId: string, documentId: string) {
    return await RequestService.client
      .delete<void>(`/quotes/${quoteId}/documents/${documentId}`)
      .then((response) => response.data);
  }

  async deleteQuote(quoteId: string) {
    return await RequestService.client.delete<void>(`/quotes/${quoteId}`).then((response) => response.data);
  }

  async scheduleApiQuoting(applicationId: string, data: ScheduleApiQuotingDTO) {
    return await RequestService.client
      .post<void>(`/quotes/quote/${applicationId}`, data)
      .then((response) => response.data);
  }

  async uploadQuoteDocument(quoteId: string, type: QuoteDocumentType, file: File) {
    const data = await RequestService.client
      .post<GeneratedUploadUrlResponseDTO>(`/quotes/${quoteId}/upload-document`, { fileType: type })
      .then((response) => response.data);

    await RequestService.uploadFile(file, data);

    return data;
  }

  async downloadQuoteDocument(quoteId: string, quoteDocument: QuoteDocumentDTO) {
    const signedDownloadUrl = await RequestService.client
      .get<string>(`/quotes/${quoteId}/documents/${quoteDocument.id}`)
      .then((response) => response.data);

    const response = await RequestService.client.get<Blob>(signedDownloadUrl, {
      responseType: 'blob',
    });

    fileDownload(response.data, quoteDocument.fileName);
  }
}

const QuoteService = new _QuoteService();

export { QuoteService };
