import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    ...TYPOGRAPHY.MONO_MEDIUM_SMALL,
  },
  row: {
    '& + $row': {
      marginTop: theme.spacing(1.5),
    },
  },
  noto: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
  },
}));

interface Props {
  rows: Array<string | React.ReactNode>;
  noto?: boolean;
}

export const MultiRow = ({ rows, noto }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {rows.map((r, idx) => (
        <div className={cx(classes.row, noto && classes.noto)} key={idx}>
          {r}
        </div>
      ))}
    </div>
  );
};
