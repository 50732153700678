import { AxiosError } from 'axios';
import {
  AgencyDTO,
  AgencyStatsDTO,
  AgentDTO,
  CreateEditAgentInAgencyDTO,
  CreateUpdateAgencyDTO,
  DeleteAgentInAgencyDTO,
} from 'dtos/agent';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';

import { AgencyService } from '../services/Agency';

export const useCurrentAgencyUsers = (options?: UseQueryOptions<AgentDTO[], AxiosError>) =>
  useQuery<AgentDTO[], AxiosError>('agency-users', () => AgencyService.getCurrentAgencyUsers(), options);

export const useGetAgencyUsers = (id: string, options?: UseQueryOptions<AgentDTO[], AxiosError>) =>
  useQuery<AgentDTO[], AxiosError>(['agency-users', id], () => AgencyService.getAgencyUsers(id), options);

export const useCurrentAgencyStats = (options?: UseQueryOptions<AgencyStatsDTO, AxiosError>) =>
  useQuery<AgencyStatsDTO, AxiosError>('agency-stats', () => AgencyService.getCurrentAgencyStats(), options);

export const useCurrentSubAgenciesStats = (options?: UseQueryOptions<AgencyStatsDTO[], AxiosError>) =>
  useQuery<AgencyStatsDTO[], AxiosError>(
    'sub-agencies-stats',
    () => AgencyService.getCurrentSubAgenciesStats(),
    options,
  );

export const useInviteEditUser = (options?: UseMutationOptions<AgentDTO, AxiosError, CreateEditAgentInAgencyDTO>) => {
  // const queryClient = useQueryClient();

  return useMutation(
    'invite-edit-user-for-agency',
    (data: CreateEditAgentInAgencyDTO) => AgencyService.createUpdateAgentInAgency(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        // await queryClient.refetchQueries(['current-agent']);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeleteUser = (
  id: string,
  options?: UseMutationOptions<AgentDTO, AxiosError, DeleteAgentInAgencyDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'delete-user-for-agency',
    (data: DeleteAgentInAgencyDTO) => AgencyService.deleteAgentInAgency(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['agency-users']);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useUpdateLogo = (options?: UseMutationOptions<string, AxiosError, File>) => {
  const queryClient = useQueryClient();

  return useMutation('upload-phishing-csv', (data: File) => AgencyService.uploadLogo(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['current-agent']);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useCreateEditAgency = (
  id?: string,
  options?: UseMutationOptions<AgencyDTO, AxiosError, CreateUpdateAgencyDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'create-edit-agency',
    (data: CreateUpdateAgencyDTO) => (id ? AgencyService.editAgency(id, data) : AgencyService.createAgency(data)),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['current-agent']);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }

        return data;
      },
    },
  );
};
