import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EmptyState } from 'components/EmptyState';
import React from 'react';
import { Link } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
  },
}));

interface Props {
  applicationId: string;
}

export const NoActivePolicies = (props: Props) => {
  const classes = useStyles();

  return (
    <EmptyState
      title='No Active Policy'
      text={"Looks like you haven't created a Policy yet. Select a Quote to start create a policy."}
      actions={
        <Link to={insertIdToRoute(ROUTES.agent.application.QUOTES, props.applicationId)} className={classes.link}>
          <Button color='primary' variant='contained'>
            View Quotes & Create Policy
          </Button>
        </Link>
      }
    />
  );
};
