import { TextField, withStyles } from '@material-ui/core';
import { COLORS } from 'telivy-theme';

export const CustomTextFieldShrink = withStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiInputLabel': {
      '&-root': {
        '&:not(.Mui-error):not(.Mui-focused)': {
          color: COLORS.GREY_3,
        },
      },
    },

    '& .MuiInput': {
      '&-input::placeholder': {
        color: COLORS.GREY_3,
      },
      '&-underline': {
        '&:after': {
          display: 'none',
        },
      },
      '&-formControl': {
        borderRadius: theme.spacing(),
        minHeight: 'auto',

        '&.Mui-focused': {
          border: `1px solid ${COLORS.BLUE_1}`,
        },
        '&.Mui-error': {
          border: `1px solid ${COLORS.RED_1}`,
        },
      },
    },
  },
}))(TextField);
