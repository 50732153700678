import { Button, makeStyles } from '@material-ui/core';
import { Column, Table } from 'components/Table';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { CLIENT_CATEGORIES, CompanyDTO } from 'dtos/company';
import queryString from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ClientStatusLabel } from 'views/agent/views/crm/ClientStatusLabel';
import { EditCompany } from 'views/agent/views/crm/EditCompany';

import { ApplicationViewContainer } from '../ApplicationViewContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.LARGE,
    color: COLORS.GREY_1,
    // marginBottom: theme.spacing(1),
    alignSelf: 'center',
  },

  section: {
    marginBottom: theme.spacing(3),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  bordered: {
    border: 'solid 1px #ededed',
  },
}));

// const tableData = (company: CompanyDTO): TableRow[] => {
//   return []
//   const domainItem = {
//     field: 'Company Domain',
//     value: company.domain,
//   };

//   return [
//     {
//       field: 'Company Name',
//       value: company.orgName,
//     },
//     ...(company.domain !== 'nodomain.local' ? [domainItem] : []),
//     {
//       field: 'Status',
//       value: company?.clientStatus && <ClientStatusLabel clientStatus={company.clientStatus} />,
//     },
//     {
//       field: 'Category',
//       value: company.clientCategory && CLIENT_CATEGORIES[company.clientCategory],
//     },
//     {
//       field: 'Anti Virus',
//       value: company.antiVirus,
//     },
//   ];
// };

interface Props {
  application: ApplicationWithAgentDTO;
}

export const CompanyDetailsView = ({ application }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState<CompanyDTO | null>(null);

  const handleViewAssessments = useCallback(
    (company: CompanyDTO) => {
      const urlWithParams = queryString.stringify({
        q: company.orgName,
      });
      navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.DASHBOARD}?${urlWithParams}`);
    },
    [navigate],
  );

  const onClose = () => {
    queryClient.refetchQueries(['application', application.id]);
    setSelectedCompany(null);
  };

  const columns: Column<CompanyDTO, keyof CompanyDTO>[] = useMemo(() => {
    return [
      {
        title: 'Company Name',
        render: (row) => row.orgName,
      },
      {
        title: 'Company Domain',
        render: (row) => row.domain !== 'nodomain.local' && row.domain,
      },
      {
        title: 'Status',
        render: (row) => row?.clientStatus && <ClientStatusLabel clientStatus={row.clientStatus} />,
      },
      {
        title: 'Category',
        render: (row) => row.clientCategory && CLIENT_CATEGORIES[row.clientCategory],
      },
      {
        title: 'Anti Virus',
        render: (row) => row.antiVirus,
      },
    ];
  }, []);

  return (
    <ApplicationViewContainer>
      <div className={classes.root}>
        <Helmet>
          <title>Company - {application?.company?.orgName}</title>
        </Helmet>

        <div className={classes.section}>
          <div className={classes.actionsContainer}>
            <div className={classes.title}>Company Details</div>
            <div>
              <Button
                variant='outlined'
                color='default'
                onClick={() => application?.company && setSelectedCompany(application?.company)}
                className={classes.actionButton}
              >
                Edit Company
              </Button>

              <Button
                variant='outlined'
                color='default'
                onClick={() => application?.company && handleViewAssessments(application?.company)}
                className={classes.actionButton}
              >
                View All Assessments
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.section}>
          {application?.company && (
            <div className={classes.bordered}>
              <Table<CompanyDTO>
                // bordered
                hideHeadersWhenEmpty
                columns={columns}
                data={application?.company ? [application?.company] : []}
                rowKey={(el) => el.id}
              />
            </div>
          )}
        </div>

        {selectedCompany && <EditCompany company={selectedCompany} onClose={onClose} />}
      </div>
    </ApplicationViewContainer>
  );
};
