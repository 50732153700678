import { makeStyles } from '@material-ui/core/styles';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanM365Users, RiskAnalysisItem } from 'dtos/deep-scan';
import { useDataRiskByDarkWebSecurityChart } from 'hooks/charts/useDataRiskByDarkWebSecurityChart';
import { useDataRiskByPasswordSecurityChart } from 'hooks/charts/useDataRiskByPasswordSecurityChart';
import { useDataRiskByVulnerabilityChart } from 'hooks/charts/useDataRiskByVulnerabilityChart';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import { useDeepScan, useDeepScanM365SecurityData, useDeepScanRiskAnalysis } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { LinkedUsersModal } from 'templates/SecurityAssessments/components/LinkedUsersModal';

// import { MouseOverPopover } from '../../components/MouseOverPopover';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  charts: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  chartContainer: {
    flex: 1,
    borderRadius: theme.spacing(1),
    border: `1px solid ${COLORS.GREY_1}`,
    padding: theme.spacing(3),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
}

const PER_PAGE = 10;
export const SecurityRiskAnalysis = ({ application }: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const deepScanId = application.deepScanId || '';
  const { data, isLoading } = useDeepScanRiskAnalysis(deepScanId, { enabled: Boolean(deepScanId) });
  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);
  const [sorter, setSorter] = useState<Sorter<keyof RiskAnalysisItem> | undefined>({
    key: 'dataRisk',
    order: SortOrder.DESC,
  });

  const { data: usersRaw, isLoading: isLoadingUsers } = useDeepScanM365SecurityData<DeepScanM365Users>(
    deepScanId,
    'users',
    { enabled: Boolean(deepScanId) },
  );

  const allUsers = useMemo(
    () =>
      (usersRaw?.value || []).map((u) => {
        return {
          id: u.id,
          mail: u.mail,
          displayName: u.displayName,
        };
      }),
    [usersRaw],
  );

  const { chart: riskByVulChart, dataRiskByVulnerability } = useDataRiskByVulnerabilityChart(enableCharts, data);
  const { chart: riskByPassChart, riskByPasswordSecurity } = useDataRiskByPasswordSecurityChart(enableCharts, data);
  const { chart: riskByDarkChart, riskByDarkWebSecurity } = useDataRiskByDarkWebSecurityChart(enableCharts, data);

  const columns = useMemo((): Column<RiskAnalysisItem, keyof RiskAnalysisItem>[] => {
    return [
      {
        title: 'Host',
        sortKey: 'hostname',
        render: (row) => row.hostname,
      },
      {
        title: 'Users',
        sortKey: 'users',
        width: '200',
        render: (row) => (
          <div>
            {/*row.users === undefined || row.users.length === 0 ? 'N/A' : ''*/}

            {/*row.users.length > 1 ? (
              <MouseOverPopover
                label={`${row.users.slice(0, 1).join(', ')} and ${row.users.length - 1} more`}
                content={row.users.join(', ')}
              />
            ) : (
              row.users.join(', ')
            )*/}

            {row.id && <LinkedUsersModal deepScanTarget={row} deepScanId={deepScanId} allUsers={allUsers} />}
          </div>
        ),
      },
      {
        title: 'Vulnerability Risk',
        sortKey: 'vulnerabilityScore',
        render: (row) =>
          row.vulnerabilityScore ? <ScoreBox ranking={capitalize(row.vulnerabilityScore) as ScoreRanking} /> : 'N/A',
      },
      {
        title: 'Data Risk',
        sortKey: 'dataRisk',
        render: (row) =>
          row.dataRisk !== undefined ? `${deepScan?.piiConfig?.currency}${row.dataRisk.toLocaleString()}` : 'N/A',
      },
      {
        title: 'Password Risk',
        sortKey: 'passwordSecurity',
        render: (row) =>
          row.passwordSecurity ? <ScoreBox ranking={capitalize(row.passwordSecurity) as ScoreRanking} /> : 'N/A',
      },
      {
        title: 'Dark Web Risk',
        sortKey: 'darkWebSecurity',
        render: (row) =>
          row.darkWebSecurity ? <ScoreBox ranking={capitalize(row.darkWebSecurity) as ScoreRanking} /> : 'N/A',
      },
    ];
  }, [deepScan, deepScanId, allUsers]);

  const sortedData = useMemo(() => {
    if (sorter) {
      const sortedData = sortBy(
        sorter.key === 'dataRisk' ? (data || []).map((row) => ({ ...row, dataRisk: row.dataRisk || 0 })) : data,
        sorter.key,
      );

      if (sorter.order === SortOrder.DESC) {
        return sortedData.reverse();
      }

      return sortedData;
    }

    return data;
  }, [data, sorter]);

  const pageData = useMemo(() => {
    return (sortedData || []).slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE);
  }, [page, sortedData]);

  const hasAnyCharts = dataRiskByVulnerability.length || riskByPasswordSecurity.length || riskByDarkWebSecurity.length;

  if (isLoading || isLoadingUsers) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.section}>
      <div className={classes.title}>
        Human Risk
        <span className={classes.findings}> ({(data || []).length} Devices)</span>
      </div>
      {hasAnyCharts ? (
        <div className={classes.charts}>
          {dataRiskByVulnerability.length ? riskByVulChart : null}
          {riskByPasswordSecurity.length ? riskByPassChart : null}
          {riskByDarkWebSecurity.length > 0 ? riskByDarkChart : null}
        </div>
      ) : null}
      <div className={classes.boxBorder}>
        <Table<RiskAnalysisItem, keyof RiskAnalysisItem>
          rowContentCentered
          sorter={sorter}
          pagination={isLoading ? undefined : { page, perPage: PER_PAGE, total: data?.length || 0 }}
          columns={columns}
          onChange={(pagination, sorting) => {
            if (pagination?.page !== undefined) {
              setPage(pagination?.page);
            }

            const isSorterChanging = sorter?.key !== sorting?.key || sorter?.order !== sorting?.order;
            setSorter(sorting);

            if (isSorterChanging && pagination?.page !== 0) {
              setPage(0);
            }
          }}
          className={classes.table}
          rowKey={(row) => row.hostname}
          data={pageData}
          loading={isLoading}
          noOverflow
        />
      </div>
    </div>
  );
};
