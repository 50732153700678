import { Button, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ApplicationDocumentDTO, ApplicationDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { ReactComponent as DownloadIcon } from 'images/download.svg';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { useCurrentAgent } from 'queries/useAgent';
import {
  useApplicationDocumentsGroups,
  useAssignDocumentToGroup,
  useDeleteApplicationDocument,
  useDownloadApplicationDocument,
} from 'queries/useApplications';
import React, { useCallback } from 'react';
import { isAgentAnAdmin } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  button: {
    '& svg path': {
      fill: COLORS.RED_1,
    },
  },
  buttonIcon: {
    width: 16,
    height: 16,
  },
  select: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: `1px solid ${COLORS.GREY_5}`,
    borderRadius: 4,
  },
}));

interface Props {
  document: ApplicationDocumentDTO;
  application: ApplicationDTO;
}

export const DocumentActions = ({ document, application }: Props) => {
  const classes = useStyles();
  const { showToaster } = useToasters();
  const { data: agent } = useCurrentAgent();
  const { mutate } = useDownloadApplicationDocument(application.id);
  const { mutate: deleteDocument } = useDeleteApplicationDocument(application.id);
  const { mutate: assignDocument } = useAssignDocumentToGroup(application.id, document.id, {
    onSuccess: () => {
      showToaster({ message: 'Document assigned to group successfully' });
    },
  });
  const { data: groups } = useApplicationDocumentsGroups(application.id);

  const handleClick = useCallback(() => mutate(document), [mutate, document]);

  const handleDeleteClick = useCallback(() => {
    deleteDocument(document.id);
  }, [deleteDocument, document]);

  return (
    <div className={classes.row}>
      <Button startIcon={<DownloadIcon className={classes.buttonIcon} />} onClick={handleClick}>
        Download
      </Button>
      {isAgentAnAdmin(agent) && (
        <>
          <Button startIcon={<TrashIcon className={classes.buttonIcon} />} onClick={handleDeleteClick}>
            Delete
          </Button>
          <Select
            className={classes.select}
            value={document.groupId || 'unassigned'}
            onChange={(el) => {
              if (el.target.value === 'unassigned') {
                assignDocument({ groupId: null });
                return;
              } else if (el.target.value) {
                assignDocument({ groupId: el.target.value as string });
              }
            }}
          >
            <MenuItem value={'unassigned'}>Unassigned</MenuItem>
            {(groups || []).map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </div>
  );
};
