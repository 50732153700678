import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.GREY_2,
  },
  noBorder: {
    '& $element + $element': {
      borderTop: 0,
    },
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'right',
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),

    '& + $element': {
      borderTop: `1px solid ${COLORS.GREY_5}`,
    },
  },
  noSpaceBetween: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  value: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    maxWidth: '40%',
    color: COLORS.GREY_1,
  },
  title: {
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60%',
  },
}));

export interface Props {
  className?: string;
  title?: string;
  borderLess?: boolean;
  noSpaceBetween?: boolean;
  rows: Array<{
    title: string;
    value: string | React.ReactNode;
  }>;
}

export const DetailsList = ({ title, noSpaceBetween, className, rows, borderLess }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {title ? <h3 className={classes.heading}>{title}</h3> : null}
      <ul className={cx(classes.list, borderLess && classes.noBorder)}>
        {rows.map((r, idx) => (
          <li className={cx(classes.element, noSpaceBetween && classes.noSpaceBetween)} key={idx}>
            <div className={classes.title}>{r.title}</div>
            <div className={classes.value}>{r.value}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};
