import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Column, Table } from 'components/Table';
import { DeepScanM365SubscribedSku } from 'dtos/deep-scan';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  input: {
    flex: 1,
  },

  scoreBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  red: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED_1,
  },
  yellow: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.YELLOW_1,
  },
  green: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_1,
  },
}));

interface Props {
  subscribedSku?: DeepScanM365SubscribedSku;
  isOpen: boolean;
  handleClose: () => void;
}

interface ServicePlan {
  appliesTo: string;
  servicePlanId: string;
  servicePlanName: string;
  provisioningStatus: string;
}

export const SubscribedSkuDetailsModal: React.FC<Props> = ({ subscribedSku, isOpen, handleClose }) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const columns = useMemo((): Column<ServicePlan, keyof ServicePlan>[] => {
    return [
      {
        title: 'Plan Name',
        // sortKey: 'servicePlanName',
        render: (row) => row.servicePlanName,
      },
      {
        title: 'Provisioning Status',
        // sortKey: 'provisioningStatus',
        render: (row) => row.provisioningStatus,
      },
    ];
  }, []);

  return (
    <Dialog fullWidth={true} open={isOpen} maxWidth='lg' onClose={handleClose}>
      <DialogContent>
        <Table<ServicePlan, keyof ServicePlan>
          rowKey={(row) => `${row.servicePlanName}`}
          data={subscribedSku?.servicePlans || []}
          // onRowClick={(row) => setSelectedPiiFile(row)}
          className={classes.table}
          loading={!subscribedSku}
          rowContentCentered
          columns={columns}
        />
      </DialogContent>
    </Dialog>
  );
};
