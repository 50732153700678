import { withJsonFormsControlProps } from '@jsonforms/react';
import { CustomControlProps } from 'components/JsonForms/customProps';
import { _RadioGroup } from 'components/JsonForms/RadioGroup';
import { _SelectInput } from 'components/JsonForms/SelectInput';
import { useWindowSize } from 'hooks/useWindowSize';
import React from 'react';

type InputType = 'radio' | 'select';

export const _RadioOrSelect = (props: CustomControlProps) => {
  const options = props.schema.enum as string[];
  const { width } = useWindowSize();
  const type: InputType = (width < 1280 && options.length > 2) || options.length >= 4 ? 'select' : 'radio';

  if (!props.visible) {
    return null;
  }

  if (type === 'select') {
    return <_SelectInput {...props} />;
  }

  return <_RadioGroup {...props} />;
};

export const RadioOrSelect = withJsonFormsControlProps(_RadioOrSelect);
