import { Button } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import { useToasters } from 'hooks/useToasters';
import { useDeepScanGoogleWsAuthLink } from 'queries/useDeepScan';
import React, { useCallback } from 'react';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

interface Props {
  deepScan: DeepScanDTO;
  application: ApplicationWithAgentDTO;
}

export const ConfigureGoogleWS = ({ deepScan, application }: Props) => {
  const { data: gwsAuthLink } = useDeepScanGoogleWsAuthLink(deepScan.id);
  const chartsEnabled = useChartsEnabled(deepScan, application);
  const { showToaster } = useToasters();

  const handleCopyLink = useCallback(() => {
    if (gwsAuthLink) {
      navigator.clipboard.writeText(gwsAuthLink);
      showToaster('Google Workspace connect link is copied to clipboard');
    }
  }, [gwsAuthLink, showToaster]);

  return (
    <div>
      <div>
        {deepScan.gwsConnected
          ? 'You have already connected Google Workspace'
          : 'Complete the following steps to configure Google Workspace.'}
        <br />
        <br />
      </div>
      {!chartsEnabled && (
        <SecurityCard.Badge variant='locked'>
          Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
        </SecurityCard.Badge>
      )}
      {chartsEnabled && gwsAuthLink && (
        <>
          <Button variant='contained' color='primary' onClick={() => window.open(gwsAuthLink, '_blank')?.focus()}>
            Click here to {deepScan.gwsConnected ? 'reconnect' : 'connect'} Google Workspace account
          </Button>
          <Button
            variant='text'
            style={{ marginLeft: '10px' }}
            onClick={handleCopyLink}
            startIcon={<FileCopyRoundedIcon />}
          >
            Copy link
          </Button>
        </>
      )}
    </div>
  );
};
