import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { RedirectLinkSwitch } from './RedirectLinkSwitch';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(5),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

interface Props {
  agent: AgentDTO;
}

export const WidgetDocumentation = ({ agent }: Props) => {
  const classes = useStyles();
  const leadMagnetEnabled = agent.agency?.enableLeadMagnet;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h1 className={classes.title}>Lead Magnet Documentation</h1>
      </div>
      <div>
        <p>There are 2 ways to implement a Telivy lead magnet.</p>
        <h3 className={classes.subtitle}>Easy-Go Link</h3>
        <p>
          An easy go link is a ready to use, unique link that can be embedded in a webpage button, email etc. without
          any coding or development needed.
        </p>
        <p>The form can accessed at:</p>
        {!leadMagnetEnabled && (
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        )}
        {leadMagnetEnabled && (
          <strong>
            <a href={`${window.location.origin}/start/${agent.id}`} target='_blank' rel='noreferrer'>
              {`${window.location.origin}/start/${agent.id}`}
            </a>
          </strong>
        )}
        <p> </p>

        <h3 className={classes.subtitle}>API POST Call</h3>
        <p>You can create an assessment using the following API POST call:</p>

        {!leadMagnetEnabled && (
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        )}
        {leadMagnetEnabled && (
          <>
            <p>
              <strong>URL: </strong>
              https://api-v1.telivy.com/api/v1/applications/public
            </p>
            <p>
              <strong>Payload:</strong>
            </p>
            <p>
              <img src='https://install.telivy.com/payload-2024-01-24.png' alt='payload' width='600' />
            </p>
            <p>
              <strong>Your Agent ID: </strong>
              {agent.id}
            </p>
          </>
        )}

        <h3 className={classes.subtitle}>Customizations</h3>
        {!leadMagnetEnabled && (
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        )}
        {leadMagnetEnabled && (
          <div>
            <RedirectLinkSwitch agent={agent} />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};
