import { makeStyles, Theme } from '@material-ui/core/styles';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Searchbar } from 'components/SearchBar';
import { Column, Table } from 'components/Table';
import { ApplicationBreachDataDTO } from 'dtos/application';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useApplicationBreachData } from 'queries/useApplications';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { DarkWebFullPasswordSwitch } from 'views/agent/views/application-details/views/form/DarkWebFullPasswordSwitch';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {},

  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    justifyContent: 'space-between',
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  switchForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },
}));

const columns: Column<ApplicationBreachDataDTO, keyof ApplicationBreachDataDTO>[] = [
  {
    title: 'Breach Name',
    render: (record) => (record?.lastBreach ? `${record?.breachName} (${record?.lastBreach})` : record?.breachName),
  },
  {
    title: 'Username',
    render: (record) => record?.userName || record?.email,
  },
  {
    title: 'Password',
    width: '10%',
    render: (record) => record?.password,
  },
  {
    title: 'IP Address',
    render: (record) => record?.ipAddress,
  },
  {
    title: 'Phone',
    render: (record) => record?.phone,
  },
  {
    title: 'Name',
    render: (record) => record?.name,
  },
  {
    title: 'Address',
    width: '10%',
    render: (record) => record?.address,
  },
];

interface Props {
  applicationId: string;
  enableDarkWebFullPassword: boolean;
  hideWhenEmpty?: boolean;
}

const PER_PAGE = 10;
export const SecurityDarkWeb = ({ applicationId, enableDarkWebFullPassword, hideWhenEmpty }: Props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);

  const { data, isLoading } = useApplicationBreachData(applicationId);

  const filteredData = useMemo(() => {
    const filtered = data?.filter((b) => {
      if (!debouncedSearchValue) {
        return true;
      }

      return (
        b.userName?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        b.email?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        b.password?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        b.breachName?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        b.ipAddress?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        b.phone?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
        b.address?.toLowerCase().includes(debouncedSearchValue.toLowerCase())
      );
    });

    return filtered?.sort((a: ApplicationBreachDataDTO, b: ApplicationBreachDataDTO) => {
      if (a.password && b.password) {
        return 0;
      } else if (a.password) {
        return -1;
      } else if (b.password) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [data, debouncedSearchValue]);

  const pageData = useMemo(() => {
    return (filteredData || []).slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE);
  }, [page, filteredData]);

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (hideWhenEmpty && (data || []).length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      {data ? (
        <>
          <div className={classes.title}>
            Dark Web Data
            <span className={classes.findings}> ({(filteredData || []).length} findings)</span>
          </div>
          <div className={classes.boxBorderPadding}>
            <div className={classes.filters}>
              {(data || []).length > 0 && (
                <Searchbar placeholder='Search...' value={searchValue} setValue={setSearchValue} />
              )}

              <DarkWebFullPasswordSwitch
                applicationId={applicationId}
                enableDarkWebFullPassword={enableDarkWebFullPassword}
                className={classes.switchForm}
              />
            </div>
            <Table<ApplicationBreachDataDTO>
              hideHeadersWhenEmpty
              columns={columns}
              data={pageData}
              rowKey={(el) => el.id}
              pagination={isLoading ? undefined : { page, perPage: PER_PAGE, total: filteredData?.length || 0 }}
              onChange={(pagination) => {
                if (pagination?.page !== undefined) {
                  setPage(pagination?.page);
                }
              }}
            />
          </div>
        </>
      ) : (
        <EmptyState
          title='Dark web data not found'
          text="Looks like we couldn't find any dark web data for this company."
          icon={<PageviewRoundedIcon />}
        />
      )}
    </div>
  );
};
