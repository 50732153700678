import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {
  BrowserPasswordDetailsDTO,
  DeepScanDetailsDTO,
  DeepScanTargetDetailsDTO,
  TelivyAgentDetailsDTO,
} from 'dtos/deep-scan';
import React, { useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: theme.spacing(2),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  section: {
    paddingLeft: theme.spacing(2),
  },
}));

interface Props {
  details?:
    | DeepScanTargetDetailsDTO
    | DeepScanDetailsDTO
    | TelivyAgentDetailsDTO
    | Record<string, BrowserPasswordDetailsDTO>
    | null;
  title: string;
  className?: string;
  showOnly?: string[] | null;
}

export const DetailsModal: React.FC<Props> = ({ details, className, title, showOnly }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const renderObject = (details: any) => {
    if (Array.isArray(details)) {
      return details.map((data, index) => {
        return (
          <div key={index}>
            <pre>{typeof data === 'object' ? <div className={classes.section}>{renderObject(data)}</div> : data}</pre>
          </div>
        );
      });
    } else if (typeof details === 'object') {
      return Object.keys((details as any) || {}).map((key) => {
        const data = ((details as any) || {})[key as string];
        if (showOnly && showOnly.indexOf(key) == -1) return;
        return (
          data && (
            <div key={key}>
              <b>{key}</b>
              <pre>{typeof data === 'object' ? <div className={classes.section}>{renderObject(data)}</div> : data}</pre>
            </div>
          )
        );
      });
    }
  };

  const getMaxVersion = (details: any) => {
    if (Array.isArray(details)) {
      return details.map((data) => {
        return data && data['version'];
      });
    } else if (typeof details === 'object' && details['get_install_keys_at']) {
      return details['version'];
    } else if (typeof details === 'object') {
      return Object.values(details).map((data) => {
        if (data && typeof data === 'object') {
          return (data as Record<string, unknown>)['version'];
        }
      });
    }
  };

  return (
    <>
      <Button className={className} onClick={() => setIsModalOpen(true)}>
        {showOnly ? 'View Logs' : `${getMaxVersion(details)}` || 'Details'}
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)} className={classes.modal}>
        <DialogContent>
          <div className={classes.title}>{title}</div>
          {renderObject(details)}
        </DialogContent>
      </Dialog>
    </>
  );
};
