import { Props as ListProps } from 'components/DetailsList';
import { Money } from 'components/Money';
import { PolicyDocumentButton } from 'components/PolicyDocumentButton';
import dayjs from 'dayjs';
import { PolicyDTO } from 'dtos/policy';
import { usePolicyDocuments } from 'queries/usePolicy';
import React from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';

interface Options {
  extraDetailsRows?: ListProps['rows'];
  extraDatesRows?: ListProps['rows'];
}

export const usePolicyDetailsList = (policy: PolicyDTO, options?: Options) => {
  const { data: documents, isLoading } = usePolicyDocuments(policy.id);

  const lists: ListProps[] = [
    {
      title: 'Policy Details',
      rows: [
        {
          title: 'Carrier',
          value: policy.quote.carrier.displayName,
        },
        {
          title: 'Quote #',
          value: policy.quote.quoteNumber,
        },
        {
          title: 'Limit',
          value: <Money value={policy.quote.aggregateLimit} />,
        },
        {
          title: 'Premium',
          value: <Money value={policy.quote.premium} />,
        },
        {
          title: 'Retention',
          value: <Money value={policy.quote.retention} />,
        },
        ...(options?.extraDetailsRows || []),
      ],
    },
    {
      title: 'Dates',
      rows: [
        {
          title: 'Start',
          value: dayjs(policy.quote.effectiveDate).format('DD/MM/YYYY'),
        },
        {
          title: 'Expiration',
          value: dayjs(policy.quote.expirationDate).format('DD/MM/YYYY'),
        },
        ...(options?.extraDatesRows || []),
      ],
    },
  ];

  if (documents && documents.length > 0) {
    lists.push({
      title: 'Documents',
      rows: documents.map((doc) => ({
        title: DOCUMENT_TYPE_HUMANIZED_NAME[doc.documentType],
        value: <PolicyDocumentButton document={doc} policyId={policy.id} key={doc.id} />,
      })),
    });
  }
  return {
    lists,
    isLoadingDocuments: isLoading,
  };
};
