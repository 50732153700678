import { LabelElement, OwnPropsOfRenderer } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

import { ApplicationSourceContext } from '../index';

const useStyles = makeStyles((theme) => ({
  clientHeading: {
    ...TYPOGRAPHY.TITLE_2,
    marginBottom: theme.spacing(5),
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.TITLE_3,
      marginBottom: theme.spacing(3),
      textAlign: 'left',
    },
  },

  agentHeading: {
    marginBottom: theme.spacing(2),
  },
}));

export const _SectionTitle = ({ uischema, visible }: OwnPropsOfRenderer) => {
  const classes = useStyles();
  const labelElement: LabelElement = uischema as LabelElement;
  const applicationSource = useContext(ApplicationSourceContext);

  return (
    <Hidden xsUp={!visible}>
      <Typography
        variant={applicationSource === 'client' ? 'h2' : 'h6'}
        className={applicationSource === 'client' ? classes.clientHeading : classes.agentHeading}
      >
        {labelElement.text !== undefined && labelElement.text !== null && labelElement.text}
      </Typography>
    </Hidden>
  );
};

export const SectionTitle = withJsonFormsLayoutProps(_SectionTitle);
