import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, makeStyles, Select, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal } from 'components/Modal';
import { SecurityFindingDTO, SecurityFindingStatus } from 'dtos/security-finding';
import { useToasters } from 'hooks/useToasters';
import { capitalize, isArray } from 'lodash';
import { useUpdateSecurityFinding } from 'queries/useSecurityFinding';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { COLORS } from 'telivy-theme';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: 0,
    border: 'none',
  },
  select: {
    width: '100%',
    padding: theme.spacing(1),
    border: `1px solid ${COLORS.GREY_4}`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
}));

export type Type = 'accept' | 'resolve' | 'reject' | 'approve';

const TYPE_TO_STATUS: { [key in Type]: SecurityFindingStatus } = {
  resolve: SecurityFindingStatus.IN_REVIEW,
  accept: SecurityFindingStatus.ACCEPTED,
  approve: SecurityFindingStatus.RESOLVED,
  reject: SecurityFindingStatus.OPEN,
};

interface Props {
  finding?: SecurityFindingDTO | SecurityFindingDTO[];
  onClose: () => void;
  type: Type;
}

interface Form {
  comment: string;
  customComment?: string;
}

const schema = yup.object().shape({
  comment: yup.string().required('Address is required'),
  customComment: yup.string().when('comment', {
    is: 'Custom Comment',
    then: yup.string().required('Custom comment is required'),
  }),
});

const COMMENTS: string[] = [
  'These issues have been identified and are actively being investigated.',
  'These issues emanate from guest Wi-Fi traffic',
  'These domains are being retired and/or shutdown.',
  'These issues are contained to an isolated environment that does not touch production (i.e isolation from the network)',
  'These issues are a known and accepted vulnerability due to valid business reasons (i.e systems required to run at maximum compatibility).',
  'Custom Comment',
];

export const ResolveFindingModal = ({ onClose, finding, type }: Props) => {
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutateAsync, isLoading } = useUpdateSecurityFinding({
    onSuccess: () => {
      handleClose();
    },
    onError: (error) => {
      toasterErrorHandler(error);
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: finding && !isArray(finding) && finding?.comment ? finding.comment : '',
    },
  });
  const comment = watch('comment');

  useEffect(() => {
    if (finding && !isArray(finding) && finding?.comment) {
      setValue('comment', finding.comment);
    }
  }, [finding, setValue]);

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleConfirm = useCallback(
    async (data: Form) => {
      if (finding) {
        if (isArray(finding)) {
          for (const f of finding) {
            await mutateAsync({
              id: f.id,
              status: TYPE_TO_STATUS[type],
              comment: data.comment === 'Custom Comment' ? data.customComment : data.comment,
            });
          }

          showToaster('Findings updated successfully');
          onClose();
        } else {
          await mutateAsync({
            id: finding.id,
            status: TYPE_TO_STATUS[type],
            comment: data.comment === 'Custom Comment' ? data.customComment : data.comment,
          });

          showToaster('Finding updated successfully');
          onClose();
        }
      }
    },
    [finding, mutateAsync, type, showToaster, onClose],
  );

  return (
    <Modal
      removePaddingRight
      handleClose={handleClose}
      isOpen={Boolean(finding)}
      title={`${capitalize(type)} issue finding`}
      actions={
        <>
          <Button variant='contained' onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button color='primary' variant='contained' onClick={handleSubmit(handleConfirm)} disabled={isLoading}>
            Submit
          </Button>
        </>
      }
    >
      <div>
        <form onSubmit={handleSubmit(handleConfirm)} className={classes.form}>
          <Controller
            name='comment'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                className={classes.select}
                placeholder='Please select'
              >
                {COMMENTS.map((comment) => (
                  <MenuItem key={comment} value={comment}>
                    {comment}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.comment && <FormHelperText error>{errors.comment?.message}</FormHelperText>}

          {comment === 'Custom Comment' && (
            <>
              <Controller
                name='customComment'
                control={control}
                defaultValue=''
                render={({ field }) => <TextField {...field} label='Custom comment' type={type} />}
              />
              {errors.customComment && <FormHelperText error>{errors.customComment?.message}</FormHelperText>}
            </>
          )}
        </form>
        <p>
          By providing additional context, you ensure transparency around your remediation activities. This gives you
          better audit history for self-monitoring and demonstrates good cybersecurity posture to third parties.
        </p>
      </div>
    </Modal>
  );
};
