/* eslint-disable @typescript-eslint/ban-types */
import { CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { ReactComponent as ArrowIcon } from 'images/arrow.svg';
import { ReactComponent as ArrowDownIcon } from 'images/arrow-down.svg';
import { ReactComponent as ArrowDropdownRounded } from 'images/arrow-drop-down-rounded.svg';
import isString from 'lodash/isString';
import React, { CSSProperties, useCallback } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const SPACER_HEIGHT: number = 2;
const BOX_SIZE_IN_PX: string = '18px';

interface StyleProps {
  bordered?: boolean;
  semiBordered?: boolean;
  noOverflow?: boolean;
  rounded?: boolean;
  rowContentCentered?: boolean;
  stickyHeader?: boolean;
  size?: 'small' | 'default';
  stickyHeaderBackgroundColor?: ValueOf<typeof COLORS>;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {},

  // Mists
  loaderContainer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(SPACER_HEIGHT),
    justifyContent: 'center',
  },

  // Pagination
  paginationContainer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    background: COLORS.GREY_6,
    color: COLORS.GREY_2,
    display: 'flex',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(6)}px`,
  },
  singlePage: {
    background: COLORS.WHITE,
  },
  paginationInfo: {},
  paginationActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  paginationButton: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    border: 0,
    background: 0,
    '& svg path': {
      fill: COLORS.GREY_2,
    },
    '&:disabled': {
      '& svg path': {
        fill: COLORS.GREY_4,
      },
    },
  },
  paginationPrevButton: {
    transform: 'rotate(180deg)',
  },
  paginationDivider: {
    height: '100%',
    width: 1,
    backgroundColor: COLORS.GREY_4,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  // Table
  table: (props) => ({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    ...(!props.noOverflow && {
      overflow: 'hidden',
    }),

    ...(props.bordered && {
      borderRadius: theme.spacing(2),
      border: `1px solid ${COLORS.GREY_5}`,
    }),

    ...(props.semiBordered && {
      borderBottom: `1px solid ${COLORS.GREY_5}`,
    }),

    ...(props.stickyHeader && {
      position: 'relative',
      overflow: 'visible',
    }),
  }),
  tableBody: {},
  tableHeader: (props) => ({
    ...(props.bordered && {
      backgroundColor: COLORS.GREY_6,
    }),

    ...(props.semiBordered && {
      '& > td': {
        backgroundColor: COLORS.BLUE_3,
      },

      '& > td:not(:last-child)': {
        borderRight: `1px solid ${COLORS.GREY_5}`,
      },

      '& > td:first-child': {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
      },
      '& > td:last-child': {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
      },
    }),

    ...(props.stickyHeader && {
      position: 'sticky',
      top: 0,
      background: props?.stickyHeaderBackgroundColor || COLORS.WHITE,
      zIndex: 2,
    }),
  }),
  columnHeader: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(2),
    borderBottom: (props) => (props.semiBordered ? 'none' : `1px solid ${COLORS.GREY_5}`),
  },
  row: (props) => ({
    backgroundColor: 'transparent',
    '&:hover': {
      zIndex: 1,
    },

    ...(props.semiBordered && {
      '& > td:not(:last-child)': {
        borderRight: `1px solid ${COLORS.GREY_5}`,
      },
    }),
  }),
  oddRow: {
    backgroundColor: COLORS.GREY_6,
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.GREY_6,
    },
  },
  disabledRow: {
    color: COLORS.GREY_3,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  columnContentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowColumn: (s) => ({
    ...(s.size === 'small' ? TYPOGRAPHY.EXTRA_SMALL_MEDIUM : TYPOGRAPHY.SMALL_MEDIUM),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    verticalAlign: s.rowContentCentered ? 'center' : 'top',
    position: 'relative',
  }),
  columnBorderBottom: {
    boxShadow: `0px 1px 0px ${COLORS.GREY_5}`,
  },
  spacer: {
    height: theme.spacing(SPACER_HEIGHT),
  },
  checkboxColumn: {
    position: 'relative',
  },
  checkbox: {
    zIndex: 1,
    cursor: 'pointer',
    display: 'inline-block',
    height: BOX_SIZE_IN_PX,
    width: BOX_SIZE_IN_PX,
    backgroundColor: COLORS.WHITE,
    border: `2px solid ${COLORS.GREY_4}`,
    borderRadius: theme.spacing(0.5),
    position: 'absolute',
    left: theme.spacing(2.5),
  },
  selectedCheckbox: {
    backgroundColor: COLORS.BLUE_1,
    borderColor: COLORS.BLUE_1,
    '&:after': {
      top: 0,
      left: 4,
      position: 'absolute',
      content: `''`,
      transform: 'rotate(45deg)',
      height: 9,
      width: 4,
      borderBottom: `2px solid ${COLORS.WHITE}`,
      borderRight: `2px solid ${COLORS.WHITE}`,
    },
  },
  disabledCheckbox: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },

  // Sort buttons
  sortButton: {
    border: 0,
    cursor: 'pointer',
    background: 0,
    marginLeft: theme.spacing(0.5),
    '& svg': {
      display: 'block',
      '& path': {
        fill: COLORS.GREY_4,
      },
    },
  },
  sortButtonRotated: {
    transform: 'rotate(180deg)',
  },
  sortButtonActive: {
    '& svg path': {
      fill: COLORS.GREY_1,
    },
  },

  expandButton: {
    border: 0,
    marginLeft: 'auto',
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    cursor: 'pointer',
    backgroundColor: '#EEEFF4',
    transition: 'all 0.2s ease-in-out',

    '& svg': {
      transition: 'all 0.2s ease-in-out',
    },

    '&:hover, &:focus': {
      backgroundColor: COLORS.BLUE_1,

      '& svg path': {
        fill: COLORS.WHITE,
      },
    },
  },
  expandedButton: {
    backgroundColor: COLORS.BLUE_2,

    '&:hover, &:focus': {
      backgroundColor: COLORS.BLUE_2,
    },

    '& svg': {
      transformOrigin: 'center',
      position: 'relative',
      transform: 'rotate(180deg)',
    },

    '& svg path': {
      fill: COLORS.BLUE_1,
    },
  },
  collapseAllButton: {
    marginLeft: 'auto',
    border: 0,
    borderRadius: theme.spacing(1),
    backgroundColor: '#E6E6E6',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
}));

export interface Pagination {
  page: number;
  total: number;
  perPage: number;
  elementName?: string;
  export?: React.ReactNode | string;
}

export interface Column<DataType, SortKey = undefined> {
  title: string | React.ReactNode;
  sortKey?: SortKey;
  width?: string | number;
  render: (data: DataType) => React.ReactNode | string;
  renderColumn?: (data: Column<DataType, SortKey>) => React.ReactNode | string;
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface Sorter<SortKey> {
  key: SortKey;
  order: SortOrder.ASC | SortOrder.DESC;
}

type OnChange<SortKey> = (pagination?: Pagination, sorter?: Sorter<SortKey>) => void;

export interface Props<DataType, SortKey> extends StyleProps {
  columns: Array<Column<DataType, SortKey>>;
  onChange?: OnChange<SortKey>;
  onRowClick?: (el: DataType) => void;
  rowKey: (el: DataType, idx: number) => string;
  data?: DataType[];
  sorter?: Sorter<SortKey>;
  loading?: boolean;
  rowContentCentered?: boolean;
  hideHeadersWhenEmpty?: boolean;
  spacer?: boolean;
  pagination?: Pagination;
  className?: string;
  oddRowsHighlight?: boolean;
  onRowSelect?: (el: DataType, shouldSelect: boolean) => void;
  selectedRowsKeys?: string[];
  disabledRowsKeys?: string[];
  selectedRowClass?: string;
  disableNotSelected?: boolean;
  emptyState?: JSX.Element;
  expandedRowRender?: (el: DataType) => React.ReactNode;
  expandedRowKeys?: string[];
  onExpandRowClick?: (el: DataType) => void;
  onCollapseExpandAllClick?: (type: 'collapse' | 'expand') => void;
}

export const Table = function <DataType, SortKey = keyof DataType>(props: Props<DataType, SortKey>) {
  const {
    columns,
    data,
    rowKey,
    pagination,
    onRowClick,
    onChange,
    oddRowsHighlight,
    bordered,
    semiBordered,
    noOverflow,
    sorter,
    rounded,
    className,
    rowContentCentered,
    loading,
    spacer,
    onRowSelect,
    size,
    selectedRowsKeys,
    disabledRowsKeys,
    disableNotSelected,
    selectedRowClass,
    hideHeadersWhenEmpty,
    emptyState,
    stickyHeader,
    stickyHeaderBackgroundColor,
    expandedRowKeys,
    expandedRowRender,
    onExpandRowClick,
    onCollapseExpandAllClick,
  } = props;

  const totalPages = Math.ceil((pagination?.total || 0) / (pagination?.perPage || 0));
  const classes = useStyles({
    rounded,
    bordered,
    semiBordered,
    noOverflow,
    stickyHeader,
    stickyHeaderBackgroundColor,
    rowContentCentered,
    size,
  });

  const handleRowClick = useCallback(
    (el: DataType) => (e: React.MouseEvent<HTMLElement>) => {
      // a dirty hack to handle clicking on other interactive elements in a row, like buttons, checkboxes etc.
      if (['submit', 'checkbox'].includes((e.target as any).type)) {
        e.preventDefault();
      } else {
        if (onRowClick) {
          onRowClick(el);
        }
      }
    },
    [onRowClick],
  );

  const handleSortingClickForElement = useCallback(
    (el) => () => {
      if (onChange) {
        if (sorter?.order === SortOrder.DESC && sorter.key === el.sortKey) {
          onChange(pagination, undefined); // REMOVE SORTING
        } else if (sorter?.order === SortOrder.ASC && sorter.key === el.sortKey) {
          onChange(pagination, { order: SortOrder.DESC, key: el.sortKey }); // CHANGE SORT ORDER
        } else if (el.sortKey) {
          onChange(pagination, { order: SortOrder.ASC, key: el.sortKey }); // SET DEFAULT ASC SORTING
        }
      }
    },
    [pagination, onChange, sorter],
  );

  const tableClasses = cx(classes.columnHeader);

  return (
    <div className={cx(classes.root, className)}>
      {hideHeadersWhenEmpty && data && data.length === 0 ? null : (
        <table className={classes.table}>
          {/* TABLE HEAD WITH COLUMNS AND SORTING */}
          <thead>
            <tr className={classes.tableHeader}>
              {onRowSelect && <td className={tableClasses}></td>}
              {columns.map((el, idx) => {
                const style: CSSProperties = {};
                const isSortingByCurrentKey = sorter?.key === el.sortKey;
                const sortButtonClasses = cx(
                  classes.sortButton,
                  isSortingByCurrentKey && classes.sortButtonActive,
                  isSortingByCurrentKey && sorter?.order === SortOrder.DESC && classes.sortButtonRotated,
                );

                if (el.width) {
                  style.width = isString(el.width) ? el.width : `${el.width}px`;
                }

                return (
                  <td key={idx} className={tableClasses} style={style}>
                    <div>
                      <div className={classes.columnContentContainer}>
                        {el.renderColumn ? el.renderColumn(el) : el.title}
                        {el.sortKey && (
                          <button className={sortButtonClasses} onClick={handleSortingClickForElement(el)}>
                            <ArrowDownIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                );
              })}
              {expandedRowRender && (
                <td className={cx(tableClasses)}>
                  {onCollapseExpandAllClick && (
                    <button
                      className={classes.collapseAllButton}
                      onClick={() =>
                        onCollapseExpandAllClick((expandedRowKeys?.length || 0) === 0 ? 'expand' : 'collapse')
                      }
                    >
                      {(expandedRowKeys?.length || 0) === 0 ? 'Expand All' : 'Collapse All'}
                    </button>
                  )}
                </td>
              )}
            </tr>
          </thead>

          {/* TABLE BODY */}
          <tbody className={classes.tableBody}>
            {spacer ? <tr className={classes.spacer} /> : null}
            {data?.map((el, dataIdx) => {
              const elKey = rowKey(el, dataIdx);
              const isRowSelected = selectedRowsKeys?.includes(elKey);
              const isRowDisabled = disabledRowsKeys?.includes(elKey) || (disableNotSelected && !isRowSelected);
              const rowClasses = cx(
                classes.row,
                onRowClick && classes.clickableRow,
                isRowDisabled && classes.disabledRow,
                isRowSelected && selectedRowClass,
                oddRowsHighlight && dataIdx % 2 === 1 && classes.oddRow,
              );

              const checkboxClasses = cx(
                classes.checkbox,
                isRowSelected && classes.selectedCheckbox,
                isRowDisabled && classes.disabledCheckbox,
              );

              return [
                <tr key={elKey} className={rowClasses} onClick={isRowDisabled ? undefined : handleRowClick(el)}>
                  {onRowSelect && (
                    <td className={classes.checkboxColumn}>
                      <button
                        disabled={isRowDisabled}
                        className={checkboxClasses}
                        onClick={(e) => {
                          e.stopPropagation();
                          onRowSelect(el, !isRowSelected);
                        }}
                      />
                    </td>
                  )}
                  {columns.map((c, idx) => {
                    const columnClasses = cx(
                      classes.rowColumn,
                      (dataIdx !== data?.length - 1 || oddRowsHighlight) && classes.columnBorderBottom,
                    );

                    const style: CSSProperties = {};
                    if (c.width) {
                      style.width = isString(c.width) ? c.width : `${c.width}px`;
                    }

                    return (
                      <td key={idx} className={columnClasses} style={style}>
                        <div className={classes.columnContentContainer}>{c.render(el)}</div>
                      </td>
                    );
                  })}
                  {onExpandRowClick && (
                    <td
                      key={`${elKey}-expand`}
                      className={cx(
                        classes.rowColumn,
                        (dataIdx !== data?.length - 1 || oddRowsHighlight) && classes.columnBorderBottom,
                      )}
                    >
                      <button
                        className={cx(
                          classes.expandButton,
                          expandedRowKeys?.includes(elKey) ? classes.expandedButton : undefined,
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          onExpandRowClick(el);
                        }}
                      >
                        <ArrowDropdownRounded />
                      </button>
                    </td>
                  )}
                </tr>,
                expandedRowKeys?.includes(elKey) && expandedRowRender ? (
                  <tr key={`${elKey}-content`} className={classes.contentRow}>
                    <td colSpan={columns.length + (onExpandRowClick ? 1 : 0)}>{expandedRowRender(el)}</td>
                  </tr>
                ) : null,
              ];
            })}
          </tbody>
        </table>
      )}

      {/* LOADER */}
      {loading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}

      {/* LOADED BUT EMPTY DATA */}
      {data && data.length === 0 && <div className={classes.loaderContainer}>{emptyState || 'No data'}</div>}

      {/* PAGINATION */}
      {pagination && (data === undefined || data.length > 0) && pagination.total > pagination.perPage && (
        <div className={cx(classes.paginationContainer, pagination.total <= pagination.perPage && classes.singlePage)}>
          {pagination.total <= pagination.perPage && (
            <div className={classes.paginationInfo}>
              {pagination.total} {pagination.elementName}
              {pagination.elementName && pagination.total > 1 ? 's' : ''}
              {pagination.export}
            </div>
          )}
          {pagination.total > pagination.perPage && (
            <>
              <div className={classes.paginationInfo}>
                {Math.max(pagination.page * pagination.perPage, 1)}-
                {Math.min((pagination.page + 1) * pagination.perPage, pagination.total)} of {pagination.total}{' '}
                {pagination.elementName}
                {pagination.elementName && pagination.total > 1 ? 's' : ''}
                {pagination.export}
              </div>
              <div className={classes.paginationActions}>
                Page: {pagination.page + 1}/{totalPages}
                <div className={classes.paginationDivider} />
                <button
                  disabled={pagination.page - 1 < 0}
                  className={classes.paginationButton}
                  onClick={onChange ? () => onChange({ ...pagination, page: pagination?.page - 1 }, sorter) : undefined}
                >
                  <ArrowIcon className={classes.paginationPrevButton} />
                </button>
                <button
                  disabled={pagination.page + 1 >= totalPages}
                  className={classes.paginationButton}
                  onClick={onChange ? () => onChange({ ...pagination, page: pagination?.page + 1 }, sorter) : undefined}
                >
                  <ArrowIcon />
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
