import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import queryString from 'query-string';
import React from 'react';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  root: {},
  stats: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  stat: {
    maxWidth: '50%',
    minWidth: 300,
  },
  color: {
    color: COLORS.BLUE_1,
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  button: {
    // display: 'block',
    minWidth: 200,
    // marginLeft: 'auto',
  },
}));

interface Props {
  agent: AgentDTO;
}

export const BillingView: React.FC<Props> = ({ agent }) => {
  const classes = useStyles();
  const billingEnabled = agent.agency?.activatedAt;
  const urlParams = queryString.stringify({ prefilled_email: agent.email }, { skipEmptyString: true, skipNull: true });

  return (
    <div className={classes.root}>
      <div>
        <h1 className={classes.title}>Billing</h1>
      </div>

      {!billingEnabled && (
        <SecurityCard.Badge variant='locked'>
          Please contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> to activate account.
        </SecurityCard.Badge>
      )}

      {billingEnabled && (
        <Button
          href={`https://billing.stripe.com/p/login/14k4ju60DgRydUY4gg?${urlParams}`}
          target='_blank'
          color='primary'
          variant='contained'
          className={classes.button}
        >
          Go to Billing Portal
        </Button>
      )}
    </div>
  );
};
