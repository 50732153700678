import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { useUpdateDeepScan } from 'queries/useDeepScan';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { CONFIGURATION_STEP } from './configuration';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 16,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  boxSubTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  boxButton: {
    marginTop: theme.spacing(2),
  },
  boxButtonMac: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  headerRow: {
    display: 'flex',
    gap: theme.spacing(3),
  },
}));

interface Props {
  deepScan: DeepScanDTO;
  application: ApplicationWithAgentDTO;
  onVerify?: () => void;
}

interface Form {
  nodewareId: string | null;
}

const FORM_ID = 'nodeware_id_form';

export const ConnectNodeware = ({ deepScan, onVerify, application }: Props) => {
  const classes = useStyles();
  const { showToaster } = useToasters();

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      nodewareId: deepScan.nodewareId,
    },
  });

  const { mutate, isLoading } = useUpdateDeepScan(deepScan.id, application.id, {
    onSuccess: () => {
      showToaster(`Successfully updated nodeware customer ID!`);
    },
  });

  const onSubmit = (data: Form) => {
    mutate({ nodewareId: data.nodewareId });
  };

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      hideVerify={true}
      type={CONFIGURATION_STEP.NODEWARE}
      title='Connect Nodeware'
      description={`Use the customer ID for ${application.applicationResponse.organization_name} to link Nodeware with Telivy`}
    >
      <div className={classes.boxesContainer}>
        <div className={classes.box}>
          <div className={classes.headerRow}>
            <img src='/nodeware.svg' alt='Nodeware' width='250' />
            <div>
              <div className={classes.boxTitle}>Connect Nodeware</div>
              <div className={classes.boxDescription}>Link Nodeware tenant to this assessment using customer ID</div>
              <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
                <section className={classes.section}>
                  <Controller
                    name='nodewareId'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <CustomTextFieldShrink {...field} label='Nodeware Customer ID' required disabled={isLoading} />
                    )}
                  />
                </section>

                <Button className={classes.boxButton} variant='contained' color='primary' type='submit'>
                  Save
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SectionTemplate>
  );
};
