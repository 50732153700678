import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Hidden, makeStyles, Typography } from '@material-ui/core';
import { BottomActionsNavigation } from 'components/BottomActionsNavigation';
import { CustomTextField } from 'components/CustomTextField';
import { LoadingContainer } from 'components/LoadingContainer';
import { ApplicationOrigin } from 'dtos/application';
import { ToasterVariant, useToasters } from 'hooks/useToasters';
import { ReactComponent as VerifyHeader } from 'images/verify-landing.svg';
import { ReactComponent as VerifyHeaderMobile } from 'images/verify-landing-mobile.svg';
import { useApplication } from 'queries/useApplications';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',

    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      paddingBottom: theme.spacing(12),
    },
  },

  formSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px`,

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },

  agentLogo: {
    width: theme.spacing(10),
    opacity: 0.3,
    alignSelf: 'start',
  },

  headingWrapper: {
    maxWidth: 335,
  },
  heading: {
    ...TYPOGRAPHY.TITLE_2,
    letterSpacing: '-0.03em',
    lineHeight: `${theme.spacing(6.5)}px`,

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.TITLE_3,
      lineHeight: `${theme.spacing(3.5)}px`,
      textAlign: 'center',
    },
  },
  description: {
    letterSpacing: '-0.03em',
    margin: `${theme.spacing(1.5)}px 0 ${theme.spacing(3)}px`,
    color: COLORS.GREY_2,

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: `0 ${theme.spacing(1.5)}px`,
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(3),
  },

  formControlWrapper: {
    width: '100%',
  },

  graphicSection: {
    backgroundColor: COLORS.BLUE_0,
    padding: `${theme.spacing(6.25)}px ${theme.spacing(5)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  headerImage: {
    margin: 'auto 0',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: `${theme.spacing(4)}px 0 ${theme.spacing(3.5)}px`,
    },
  },

  submitButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

interface QueryParamsType {
  returnUrl?: string;
}

// interface ParamsType {
//   id: string;
// }

interface Form {
  organizationName: string;
  contactEmail: string;
}

const schema = yup.object().shape({
  organizationName: yup
    .string()
    .required('Business Name is required')
    .min(3, 'Business Name must be at least 3 characters'),
  contactEmail: yup
    .string()
    .required('Business Email is required')
    .email('Business Email must be a valid email format'),
});

export const VerifyApplicationAccessView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search) as unknown as QueryParamsType, [search]);
  const { data, isLoading } = useApplication(id || '', {
    onSettled: (data) => {
      if (
        !data ||
        data.origin != ApplicationOrigin.STRIPE ||
        !data.applicationResponse.contact_email ||
        !data.applicationResponse.organization_name
      ) {
        localStorage.setItem(`authApp-${id}`, 'true');
        if (queryParams.returnUrl) {
          navigate(queryParams.returnUrl, { replace: true });
        } else {
          navigate(insertIdToRoute(ROUTES.application.FORM, id || ''), { replace: true });
        }
      }
    },
  });
  const { showToaster } = useToasters();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData: Form) => {
    if (
      formData.contactEmail === data?.applicationResponse.contact_email &&
      formData.organizationName === data?.applicationResponse.organization_name
    ) {
      localStorage.setItem(`authApp-${id}`, 'true');
      if (queryParams.returnUrl) {
        navigate(queryParams.returnUrl, { replace: true });
      } else {
        navigate(insertIdToRoute(ROUTES.application.FORM, id || ''), { replace: true });
      }
    } else {
      showToaster({ message: 'Incorrect data', variant: ToasterVariant.ERROR });
    }
  };

  if (isLoading) {
    return <LoadingContainer fullScreen />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Verify</title>
      </Helmet>

      <div className={classes.formSection}>
        <div>
          <div className={classes.headingWrapper}>
            <Hidden mdUp>
              <VerifyHeaderMobile className={classes.headerImage} />
            </Hidden>
            <Typography variant='h1' className={classes.heading}>
              Verify Your Business
            </Typography>
            <p className={classes.description}>Enter your business name and email code so we can verify it’s you.</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <div className={classes.formControlWrapper}>
              <Controller
                name='organizationName'
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    label='Company Name'
                    placeholder="Ollie's Olive Oil Company"
                    error={!!errors.organizationName}
                    InputLabelProps={{ shrink: true }}
                    {...register('organizationName')}
                  />
                )}
              />
              {errors.organizationName && <FormHelperText error>{errors.organizationName?.message}</FormHelperText>}
            </div>
            <div className={classes.formControlWrapper}>
              <Controller
                name='contactEmail'
                control={control}
                render={({ field }) => (
                  <>
                    <CustomTextField
                      {...field}
                      label='Business Email'
                      placeholder='me@email.com'
                      error={!!errors.contactEmail}
                      InputLabelProps={{ shrink: true }}
                      {...register('contactEmail')}
                    />
                  </>
                )}
              />
              {errors.contactEmail && <FormHelperText error>{errors.contactEmail?.message}</FormHelperText>}
            </div>

            <BottomActionsNavigation roundedButton>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={isLoading}
                className={classes.submitButton}
              >
                {isLoading ? 'Creating...' : 'Next'}
              </Button>
            </BottomActionsNavigation>
          </form>
        </div>
      </div>
      <Hidden smDown>
        <div className={classes.graphicSection}>
          <VerifyHeader className={classes.headerImage} />
        </div>
      </Hidden>
    </div>
  );
};
