import { AxiosError } from 'axios';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import {
  CreateDeclinationDocumentDTO,
  CreateManualDeclinationDTO,
  DeclinationDocumentDTO,
  DeclinationDocumentType,
  DeclinationDTO,
} from 'dtos/declinations';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { ApplicationService } from 'services/Application';
import { DeclinationService } from 'services/Declination';

export const useCreateManualDeclination = (
  applicationId: string,
  options?: UseMutationOptions<DeclinationDTO, AxiosError, CreateManualDeclinationDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'declination-creation',
    (data: CreateManualDeclinationDTO) => ApplicationService.createManualDeclination(applicationId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('application-declinations');

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useUpdateDeclination = (
  declinatonId: string,
  options?: UseMutationOptions<DeclinationDTO, AxiosError, CreateManualDeclinationDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'declination-update',
    (data: CreateManualDeclinationDTO) =>
      DeclinationService.updateDeclination(declinatonId, {
        requestedEffectiveDate: data.effectiveDate,
        requestedRetention: data.retention,
        requestedAggregateLimit: data.aggregateLimit,
        reason: data.reason,
        carrierId: data.carrierId,
      }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('application-declinations');

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useUploadDeclinationDocument = (
  declinationId: string,
  options?: UseMutationOptions<
    GeneratedUploadUrlResponseDTO,
    AxiosError,
    { type: DeclinationDocumentType; file: File }
  >,
) =>
  useMutation(
    'declination-document-upload',
    (data: { type: DeclinationDocumentType; file: File }) =>
      DeclinationService.uploadDeclinationDocument(declinationId, data.type, data.file),
    options,
  );

export const useDeclinationDocuments = (
  applicationId: string,
  declinationId: string,
  options?: UseQueryOptions<DeclinationDocumentDTO[]>,
) =>
  useQuery<DeclinationDocumentDTO[]>(
    ['declination-documents', applicationId, declinationId],
    () => DeclinationService.getDeclinationDocuments(applicationId, declinationId),
    options,
  );

export const useDownloadDeclinationDocument = (
  declinationId: string,
  options?: UseMutationOptions<void, AxiosError, DeclinationDocumentDTO>,
) =>
  useMutation(
    'declination-document-download',
    (document: DeclinationDocumentDTO) => DeclinationService.downloadDeclinationDocument(declinationId, document),
    options,
  );

export const useCreateDeclinationDocument = (
  declinationId: string,
  options?: UseMutationOptions<DeclinationDocumentDTO, AxiosError, CreateDeclinationDocumentDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'create-declination-document',
    (data: CreateDeclinationDocumentDTO) => DeclinationService.createDeclinationDocument(declinationId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('declination-documents');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeleteDeclinationDocument = (
  declinationId: string,
  options?: UseMutationOptions<void, AxiosError, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'delete-declination-document',
    (documentId: string) => DeclinationService.deleteDeclinationDocument(declinationId, documentId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('declination-documents');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeleteDeclination = (options?: UseMutationOptions<void, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation(
    'delete-declination',
    (declinationId: string) => DeclinationService.deleteDeclination(declinationId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('application-declinations');
        await queryClient.refetchQueries('application-quotes-count');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};
