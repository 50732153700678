import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grow,
  Paper,
  Popper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  label: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    padding: theme.spacing(2.5),
    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(1.5),
  },
  options: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
    maxHeight: 300,
    flexWrap: 'nowrap',
    overflowY: 'scroll',
  },
  saveButton: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
  },

  dropdownIcon: {
    transition: 'all 0.3s',
  },
  rotate180: {
    transform: 'rotate(-180deg)',
  },
}));

interface Props<T> {
  buttonText: string;
  options: T[];
  defaultSelectedOptions: T[];
  popperText: string;
  setSelectedOptions: (options: T[]) => void;
  type?: 'radio' | 'checkbox';
  onSave?: () => void;
  onClose?: () => void;
  startIcon?: React.ReactElement;
  showDropdownIcon?: boolean;
  disabled?: boolean;
  saveLabel?: string;
  className?: string;
}

export function SingleFilter<T>({
  buttonText,
  options,
  defaultSelectedOptions,
  setSelectedOptions,
  popperText,
  onSave,
  onClose,
  type = 'checkbox',
  startIcon,
  showDropdownIcon,
  disabled,
  saveLabel,
  className,
}: Props<T>) {
  const classes = useStyles();
  const singleFilterButtonRef = useRef<HTMLButtonElement>(null);
  const [isPopperOpen, setPopperOpen] = useState<boolean>(false);
  const [selectedOptions, setInternalSelectedOptions] = useState<Array<T>>([]);

  useEffect(() => {
    if (defaultSelectedOptions) setInternalSelectedOptions(defaultSelectedOptions);
  }, [defaultSelectedOptions]);

  const handleSave = useCallback(() => {
    setSelectedOptions(selectedOptions);
    setPopperOpen(false);
    onSave && onSave();
  }, [onSave, selectedOptions, setSelectedOptions]);

  const handleClose = () => {
    setPopperOpen(false);
    onClose && onClose();
  };

  const Options = useMemo(() => {
    switch (type) {
      case 'radio':
        return (
          <RadioGroup
            aria-labelledby='single-filter-radio-group-label'
            value={selectedOptions[0]}
            name='single-filter-radio-group'
            className={classes.options}
            onChange={(e) => setInternalSelectedOptions([e.target.value as unknown as T])}
          >
            {options.map((option, idx) => (
              <FormControlLabel key={idx} value={option} control={<Radio color='primary' />} label={option} />
            ))}
          </RadioGroup>
        );
      case 'checkbox':
        return (
          <FormGroup className={classes.options}>
            {options.map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    color='primary'
                    checked={selectedOptions.includes(option)}
                    onChange={() =>
                      setInternalSelectedOptions((prevState) =>
                        prevState.includes(option)
                          ? [...prevState.filter((op) => op !== option)]
                          : [...prevState, option],
                      )
                    }
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
        );
    }
  }, [classes.options, options, selectedOptions, type]);

  return (
    <>
      <Button
        onClick={() => setPopperOpen(true)}
        ref={singleFilterButtonRef}
        startIcon={startIcon || <FilterListRoundedIcon />}
        endIcon={
          showDropdownIcon ? (
            <ArrowDropDownRoundedIcon className={cx(classes.dropdownIcon, isPopperOpen && classes.rotate180)} />
          ) : undefined
        }
        disabled={disabled}
        className={className}
      >
        {buttonText}
      </Button>
      <Popper
        open={isPopperOpen}
        anchorEl={singleFilterButtonRef?.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 2 }}
        placement='bottom-start'
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              marginTop: 8,
              boxShadow: '0px 2px 4px rgb(35 40 48 / 16%), 0px 0px 1px rgb(35 40 48 / 50%)',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <FormControl>
                  <FormLabel id='single-filter-popper-label' className={classes.label}>
                    {popperText}
                  </FormLabel>

                  {Options}

                  <Button variant='contained' color='primary' className={classes.saveButton} onClick={handleSave}>
                    {saveLabel ?? 'Save'}
                  </Button>
                </FormControl>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
