import { ApplicationWithAgentDTO } from 'dtos/application';
import { DocumentSublimits, QuoteDTO } from 'dtos/quotes';

export enum ProposalStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
}

export interface MarketingSummaryQuote {
  carrier_name: string;
  quote_number: string;
  agg_limit: string;
  retention: string;
  premium: string;
}

export interface MarketingSummaryDeclination {
  carrier_name: string;
  reason: string;
}

export interface ProposalQuoteData extends DocumentSublimits {
  quote_id: string;
  quote_number: string;
  carrier_name: string;
  insurer_name: string;
  carrier_rating: string;
  agg_limit: string;
  premium: string;
  annual_policy_cost: string;
  surplus_lines_cost: string;
  retention: string;
  surplus_lines: number;
}

export interface ProposalRawData {
  organization_name: string;
  first_name: string;
  last_name: string;
  agent_email: string;
  agency_name: string;
  agent_phone: string;
  organization_address: string;
  primary_operations: string;
  effective_date: string;
  expiration_date: string;
  proposal_creation_date: string;
  quotes: ProposalQuoteData[];
  ubiquitous_benefit_a: string;
  ubiquitous_benefit_b: string;
  ubiquitous_benefit_c: string;
  ubiquitous_benefit_d: string;
  agency_logo: string;
  carrier_services: Array<{
    carrier_name: string;
    carrier_service: string;
  }>;
  security_report_image: string;
  proposal_expiry: string;
  marketing_quotes: MarketingSummaryQuote[];
  marketing_declinations: MarketingSummaryDeclination[];
  include_declinations: boolean;
  recommendation: string;
}

export interface ProposalDTO {
  id: string;
  status: string;
  quoteStatus: ProposalStatus;
  effectiveDate: Date;
  serialized_application_input: Record<string, unknown>;
  proposal_raw_input: Record<string, unknown>;
  applicationId: string;
  selectedQuoteId: string | null;
  createdAt: Date;
  updatedAt: Date;
  quotes: QuoteDTO[];
  proposalRawInput: ProposalRawData;
}

export interface ProposalWithApplicationDTO extends ProposalDTO {
  application: ApplicationWithAgentDTO;
}
