import { makeStyles, Theme } from '@material-ui/core';
import { Badge } from 'components/Badge';
import { SecurityGrade } from 'dtos/application';
import { SecurityFindingBySlugDTO } from 'dtos/security-finding';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SingleScanInfo } from 'templates/SecurityAssessments/views/SecurityExternalScan';

import { getGradeBadgeVariant } from '../components/ExternalScanItem';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    ['@media print']: {
      pageBreakInside: 'avoid',
      display: 'block',
    },
    padding: `${theme.spacing(3.5)}px 0 ${theme.spacing(1.5)}px`,
  },

  findingCategory: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },

  title: {
    ...TYPOGRAPHY.LARGE,
  },
  description: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },

  error: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },

  mediumGrade: {
    ...TYPOGRAPHY.LARGE,
    height: theme.spacing(4),
    minWidth: theme.spacing(4),
    width: theme.spacing(4),
    boxSizing: 'border-box',
  },
}));

const renderScanGrade = (grade?: SecurityGrade, className?: string) => {
  return (
    <Badge typography={TYPOGRAPHY.LARGE} variant={getGradeBadgeVariant(grade)} className={className}>
      {grade || '-'}
    </Badge>
  );
};

interface Props {
  grade?: SecurityGrade;
  findings?: SecurityFindingBySlugDTO[];
  isError: boolean;
  scanInfo: SingleScanInfo;
}

export const PrintFindingCategory = ({
  grade,
  findings,
  isError,
  scanInfo: { title, description, errorMessage },
}: Props) => {
  const classes = useStyles();

  if (!isError && findings && findings?.length > 0) {
    return (
      <div className={classes.root}>
        <div className={classes.findingCategory}>
          {renderScanGrade(grade, classes.mediumGrade)}
          <div>
            <span className={classes.title}>{title}</span> <span className={classes.description}>({description})</span>
          </div>
        </div>
      </div>
    );
  }

  return <p className={classes.error}>{errorMessage}</p>;
};
