import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import React from 'react';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      prefix='$'
    />
  );
}

export interface Props {
  className?: string;
  description?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  label: string;
  value: number;
  onChange: (updatedValue: number | undefined) => void;
  placeholder?: string;
  shrink?: boolean;
}

export const DollarInput = (props: Props) => {
  const classes = useStyles();
  const rootClasses = cx(classes.root, props.className);

  return (
    <div className={rootClasses}>
      <TextField
        error={!!props.error}
        disabled={props.disabled}
        required={props.required}
        label={<JsonFormsLabelDescription label={props.label} description={props.description} />}
        value={props.value !== undefined ? props.value : undefined}
        onChange={(e) => {
          props.onChange(parseFloat(e.target.value) ? parseFloat(e.target.value) : undefined);
        }}
        className={classes.input}
        placeholder={props.placeholder}
        InputLabelProps={{ shrink: props.shrink }}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
      />

      <JsonFormsErrors errors={props.error} />
    </div>
  );
};
