import { MenuItem } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { RiskBar, RiskBarDataPoint } from 'components/charts/RiskBar';
import { DeepScanTargetDTO } from 'dtos/deep-scan';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import React, { useMemo, useState } from 'react';

export enum RiskBy {
  PII = 'pii',
  FILES_SCANNED = 'filesScanned',
}

export const useDeviceByRiskChart = (
  enableCharts: boolean,
  data?: DeepScanTargetDTO[],
  forceRiskBy?: RiskBy,
  className?: string,
) => {
  const [riskBy, setRiskBy] = useState<RiskBy>(forceRiskBy || RiskBy.PII);

  const devices = useMemo(() => {
    if (data) {
      return data.map((target) => {
        const { hostname, piiToolsSummary } = target;
        return {
          hostname: hostname || '',
          piiToolsScanId: target.piiToolsScanId,
          piiToolsScanStatus: target.piiToolsScanStatus,
          piiToolsScanStatusMessage: target.piiToolsScanStatusMessage,
          riskValuation: piiToolsSummary?.risk || 0,
          files: piiToolsSummary?.filesScanned || 0,
          sensitive: piiToolsSummary?.sensitiveFiles || 0,
        };
      });
    }

    return [];
  }, [data]);

  const riskByPII = useMemo(() => {
    return (sortBy(devices, (device) => device.riskValuation).reverse() || [])
      .reduce<RiskBarDataPoint[]>((acc, device) => {
        const { hostname, riskValuation } = device;
        acc.push({ name: hostname, na: riskValuation });

        return acc;
      }, [])
      .slice(0, 10);
  }, [devices]);

  const riskByFilesScanned = useMemo(() => {
    return (sortBy(devices, (device) => device.riskValuation).reverse() || [])
      .reduce<RiskBarDataPoint[]>((acc, device) => {
        const { hostname, files, sensitive } = device;
        acc.push({ name: hostname, na: files, high: sensitive });

        return acc;
      }, [])
      .slice(0, 10);
  }, [devices]);

  const chart = useMemo(() => {
    return (
      <RiskBar
        locked={!enableCharts}
        id={`device-risk-by-${riskBy}`}
        className={className}
        data={riskBy === RiskBy.PII ? riskByPII : riskByFilesScanned}
        yAxisId={riskBy === RiskBy.FILES_SCANNED ? { high: 'right' } : undefined}
        yAxisOverrides={{
          left: {
            label: {
              value: '(Files Scanned)',
              position: 'insideLeft',
              fontSize: 12,
              angle: -90,
              style: {
                textAnchor: 'middle',
              },
            },
          },
          right: {
            stroke: '#ED6660',
            label: {
              value: '(Sensitive Files)',
              position: 'insideRight',
              fontSize: 12,
              angle: 90,
              style: {
                textAnchor: 'middle',
              },
            },
          },
        }}
        title={forceRiskBy ? `Device risk by ${startCase(riskBy)}` : 'Device risk by:'}
        actions={
          !forceRiskBy && (
            <Select
              value={riskBy}
              onChange={(el) => {
                setRiskBy(el.target.value as RiskBy);
              }}
            >
              <MenuItem key={RiskBy.PII} value={RiskBy.PII}>
                PII Risk ($)
              </MenuItem>
              <MenuItem key={RiskBy.FILES_SCANNED} value={RiskBy.FILES_SCANNED}>
                Files Scanned
              </MenuItem>
            </Select>
          )
        }
      />
    );
  }, [className, enableCharts, forceRiskBy, riskBy, riskByFilesScanned, riskByPII]);

  return {
    chart,
    riskByPII,
    riskByFilesScanned,
    devices,
  };
};
