import { makeStyles, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import WarningIcon from '@material-ui/icons/Warning';
import cx from 'classnames';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

type Variant = 'locked' | 'info' | 'warning' | 'error' | 'success';

interface StyleProps {
  variant: Variant;
  bordered?: boolean;
}

export const variantColorsMap: {
  [K in Variant]: {
    bg: string;
    text: string;
    border: string;
  };
} = {
  success: {
    bg: COLORS.GREEN_3,
    text: COLORS.GREEN_1,
    border: COLORS.GREEN_1,
  },
  warning: {
    bg: COLORS.YELLOW_3,
    text: COLORS.ORANGE_1,
    border: COLORS.ORANGE_1,
  },
  error: {
    bg: COLORS.RED_3,
    text: COLORS.RED_1,
    border: COLORS.RED_1,
  },
  info: {
    bg: COLORS.BLUE_3,
    text: COLORS.BLUE_1,
    border: COLORS.BLUE_1,
  },
  locked: {
    bg: COLORS.BLUE_3,
    text: COLORS.BLUE_1,
    border: COLORS.BLUE_1,
  },
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ variant, bordered }) => ({
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    background: variantColorsMap[variant].bg,
    color: variantColorsMap[variant].text,
    // borderBottomLeftRadius: theme.spacing(0.5),
    // borderBottomRightRadius: theme.spacing(0.5),
    borderRadius: theme.spacing(),
    width: '100%',
    padding: `${theme.spacing(1.25)}px ${theme.spacing(3)}px`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& a': {
      color: variantColorsMap[variant].text,
    },

    ...(bordered && {
      border: `1px solid ${variantColorsMap[variant].border}`,
    }),
  }),

  icon: ({ variant }) => ({
    color: variantColorsMap[variant].text,
    marginRight: theme.spacing(0.5),
    height: theme.spacing(2),

    '& path': {
      fill: variantColorsMap[variant].text,
    },
  }),
}));

interface Props extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  icon?: React.ReactNode;
  className?: string;
  bordered?: boolean;
  skipIcon?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const SecurityCardBadge = ({
  children,
  variant,
  className,
  skipIcon,
  icon,
  bordered,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const classes = useStyles({ variant, bordered });
  const iconClasses = classes.icon;

  const computedIcon = useMemo(() => {
    if (icon) {
      return React.cloneElement(icon as React.ReactElement, {
        className: cx(iconClasses, (icon as React.ReactElement).props.className),
      });
    }

    switch (variant) {
      case 'locked':
        return <LockRoundedIcon className={iconClasses} />;
      case 'info':
        return <InfoRoundedIcon className={iconClasses} />;
      case 'warning':
        return <WarningIcon className={iconClasses} />;
      case 'error':
        return <ErrorIcon className={iconClasses} />;
      case 'success':
        return <CheckCircleIcon className={iconClasses} />;

      default:
        return null;
    }
  }, [icon, iconClasses, variant]);

  return (
    <div className={cx(classes.root, className)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {!skipIcon && computedIcon}
      <div>{children}</div>
    </div>
  );
};
