import { Button, CircularProgress } from '@material-ui/core';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useGeneratePiiExcelBundle } from 'queries/useDeepScan';
import React, { useCallback } from 'react';

interface Props {
  application: ApplicationWithAgentDTO;
  className?: string;
}

export const GeneratePiiToolsBundle: React.FC<Props> = ({ application, className }) => {
  const { showToaster } = useToasters();
  const { mutate, isLoading: isGenerating } = useGeneratePiiExcelBundle(application?.deepScanId || '', application.id, {
    onSuccess: () => {
      showToaster('Scheduled report generation. Go to Reports tab to download the report.');
    },
  });

  const handleClick = useCallback(async () => {
    mutate();
  }, [mutate]);

  return (
    <Button
      variant='outlined'
      disabled={isGenerating}
      className={className}
      onClick={handleClick}
      startIcon={<CloudDownloadRoundedIcon />}
    >
      {isGenerating ? <CircularProgress size={20} style={{ marginRight: 12 }} /> : ''}
      Generate PII Tools Bundle (ZIP)
    </Button>
  );
};
