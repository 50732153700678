import { Button, makeStyles, Typography } from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Table } from 'components/Table';
import { AgentDTO } from 'dtos/agent';
import { ALERT_CATEGORIES, ALERT_CONDITIONS, ALERT_METRIC_LABELS, AlertCategory, AlertPreference } from 'dtos/alert';
// import { useCurrentAgent } from 'queries/useAgent';
import { useToasters } from 'hooks/useToasters';
import { useCurrentAgencyUsers } from 'queries/useAgency';
import { useEditAlertPreferences } from 'queries/useAlerts';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { TYPOGRAPHY } from 'telivy-theme';

import { EditAlertPreference } from './EditAlertPreference';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },

  title: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    ...TYPOGRAPHY.LARGE,
    // margin: 'auto 15px auto 0',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
  },

  subTitle: {
    // fontWeight: 'bold',
    margin: '50px 15px 10px 8px',
  },

  section: {
    // marginBottom: theme.spacing(3),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  edit: {
    paddingTop: theme.spacing(0.05),
    paddingBottom: theme.spacing(0.05),
    marginRight: theme.spacing(1),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  bordered: {
    border: 'solid 1px #ededed',
  },
  centerButton: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '75vh',
  },
}));

// const tableData = (company: CompanyDTO): TableRow[] => {
//   return []
//   const domainItem = {
//     field: 'Company Domain',
//     value: company.domain,
//   };

//   return [
//     {
//       field: 'Company Name',
//       value: company.orgName,
//     },
//     ...(company.domain !== 'nodomain.local' ? [domainItem] : []),
//     {
//       field: 'Status',
//       value: company?.clientStatus && <ClientStatusLabel clientStatus={company.clientStatus} />,
//     },
//     {
//       field: 'Category',
//       value: company.clientCategory && CLIENT_CATEGORIES[company.clientCategory],
//     },
//     {
//       field: 'Anti Virus',
//       value: company.antiVirus,
//     },
//   ];
// };

interface Props {
  agent: AgentDTO;
}

export const AlertsPreferencesView = ({ agent }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedAlertPreference, setSelectedAlertPreference] = useState<AlertPreference | null>(null);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { data: allUsers } = useCurrentAgencyUsers();

  const { mutate, isLoading } = useEditAlertPreferences({
    onSuccess: () => {
      showToaster(`Successfully deleted alert policy`);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const deleteAlertPreference = useCallback(
    (data: AlertPreference) => {
      // console.log(data);
      const user = (allUsers || []).find((a) => a.id === data.agentId);
      // console.log(user);
      if (user) {
        user.alertPreferences ||= [];

        const index = user.alertPreferences.map((ap) => ap.id).indexOf(data.id);
        if (index > -1) {
          user.alertPreferences.splice(index, 1);
        }

        mutate({
          id: user.id,
          alertPreferences: user.alertPreferences || [],
        });
      }
    },
    [mutate, allUsers],
  );

  const columns: Column<AlertPreference, keyof AlertPreference>[] = useMemo(() => {
    const baseColumns: Column<AlertPreference, keyof AlertPreference>[] = [
      {
        title: 'When',
        width: '50%',
        render: (row) =>
          `${ALERT_CATEGORIES[row.category]} ${
            row.condition && `${ALERT_METRIC_LABELS[row.condition?.metric]} ${row.condition?.value}`
          }`,
      },
      {
        title: `Notify on Email`,
        width: '20%',
        render: (row) => (row.email ? 'Yes' : '-'),
      },
      // {
      //   title: `Notify Text`,
      //   width: '20%',
      //   render: (row) => (row.text ? 'Yes' : '-'),
      // },
    ];

    baseColumns.push({
      title: 'Actions',
      width: '30%',
      render: (row) => (
        <>
          <Button
            variant='outlined'
            color='default'
            onClick={() => setSelectedAlertPreference(row)}
            className={classes.edit}
          >
            Edit
          </Button>

          <Button
            variant='outlined'
            color='default'
            onClick={() => deleteAlertPreference(row)}
            className={classes.edit}
          >
            Remove
          </Button>
        </>
      ),
    });

    return baseColumns;
  }, [classes, deleteAlertPreference]);

  const sortedUsers = useMemo(() => {
    return (allUsers || []).sort((a, b) => {
      if (a.id === agent.id) return -1;
      if (b.id === agent.id) return 1;
      return 0;
    });
  }, [allUsers, agent]);

  const data = useMemo(() => {
    return ([] as AlertPreference[]).concat(...(allUsers || []).map((agent) => agent.alertPreferences || []));
  }, [allUsers]);

  const addAlertPreferenceAndEdit = useCallback(() => {
    const pref = {
      id: 'id' + new Date().getTime(),
      agentId: 'new',
      category: AlertCategory.INTERNAL_VULNERABILITIES,
      condition: ALERT_CONDITIONS[AlertCategory.INTERNAL_VULNERABILITIES],
      email: false,
      text: false,
    } as AlertPreference;

    // agent.alertPreferences ||= [];
    // agent.alertPreferences.push(pref);
    setSelectedAlertPreference(pref);
  }, []);

  const handleViewAllAlerts = useCallback(
    () => navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.alerts.ROOT}/${ROUTES.agent.alerts.HISTORY}`),
    [navigate],
  );

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <div className={classes.actionsContainer}>
          <Typography variant='h2' className={classes.title}>
            Alerts Policies
          </Typography>

          <div>
            <Button
              variant='outlined'
              color='default'
              startIcon={<ChevronLeftRoundedIcon />}
              onClick={() => handleViewAllAlerts()}
              className={classes.actionButton}
            >
              All Alerts
            </Button>

            <Button
              variant='contained'
              color='primary'
              startIcon={<PlaylistAddRoundedIcon />}
              onClick={() => addAlertPreferenceAndEdit()}
              className={classes.actionButton}
            >
              Add Policy
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        {data.length === 0 && (
          <div className={classes.centerButton}>
            <p>No policies configured</p>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<PlaylistAddRoundedIcon />}
              onClick={() => addAlertPreferenceAndEdit()}
              className={classes.actionButton}
            >
              Add Policy
            </Button>
          </div>
        )}

        {sortedUsers.map((user) => {
          const prefs = user.alertPreferences || [];
          if (prefs.length === 0) return;

          return (
            <div key={user.id}>
              <div className={classes.subTitle}>
                Policies for {user.firstName} {user.lastName} ({user.email})
              </div>

              <Table<AlertPreference>
                // bordered
                // hideHeadersWhenEmpty
                loading={isLoading}
                columns={columns}
                data={prefs}
                rowKey={(el, id) => id.toString()}
              />
            </div>
          );
        })}

        {selectedAlertPreference && (
          <EditAlertPreference
            alertPreference={selectedAlertPreference}
            onClose={() => setSelectedAlertPreference(null)}
            allUsers={allUsers}
          />
        )}
      </div>
    </div>
  );
};
