import { FormControl, InputLabel } from '@material-ui/core';
import { Button, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from 'components/CustomTextField';
import { Modal } from 'components/Modal';
// import { RadioSelect } from 'components/RadioSelect';
import { AgentDTO, DeleteAgentInAgencyDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useDeleteUser } from 'queries/useAgency';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    display: 'block',
    minWidth: 200,
    marginLeft: 'auto',
    marginTop: theme.spacing(2),
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '12px',
      color: '#232830',
      fontWeight: 500,
    },
  },
  form: {
    minWidth: '400px',
    marginTop: theme.spacing(1),
  },
  edit: {
    marginLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
  invite: {},
}));

interface Props {
  agent: AgentDTO;
  allAgents: AgentDTO[];
}

export const DeleteAgent = ({ agent, allAgents }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useDeleteUser(agent.agencyId || '', {
    onSuccess: () => {
      showToaster(`Successfully deleted user`);
      setOpen(false);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const { handleSubmit, control } = useForm<DeleteAgentInAgencyDTO>({
    defaultValues: {
      transferAgentId: '',
      email: agent.email,
    },
  });

  const onSubmit = (data: DeleteAgentInAgencyDTO) => {
    mutate({
      transferAgentId: data.transferAgentId,
      email: data.email || agent.email,
    });
  };

  const agents: Array<AgentDTO> = useMemo(() => {
    return (allAgents || []).filter((u) => u.id !== agent.id);
  }, [allAgents, agent]);

  return (
    <>
      <Modal
        title='Delete User'
        isOpen={open}
        onClose={() => setOpen(false)}
        openButton={
          <Button variant='outlined' color='default' onClick={() => setOpen(true)} className={classes.edit}>
            Delete
          </Button>
        }
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              disabled={true}
              render={({ field }) => <CustomTextField {...field} label='Email of user to be deletd' required />}
            />
            <Controller
              name='transferAgentId'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth className={classes.form}>
                  <InputLabel id='select-user-label'>Transfer assessments to</InputLabel>
                  <Select
                    disabled={isLoading}
                    labelId='select-account-user'
                    value={field.value}
                    onChange={(el) => {
                      field.onChange(el.target.value as string);
                    }}
                    label='Select user to transfer assessments to'
                    required
                  >
                    {agents.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstName} {user.lastName} ({user.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Button type='submit' color='secondary' variant='contained' disabled={isLoading} className={classes.button}>
              {isLoading ? 'Deleting...' : 'Delete'}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
