import { makeStyles } from '@material-ui/core/styles';
import { CarrierDTO } from 'dtos/carrier';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles(() => ({
  root: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
  },
}));

interface Props {
  carrier: CarrierDTO;
}

export const CarrierColumn = ({ carrier }: Props) => {
  const classes = useStyles();
  return <div className={classes.root}>{carrier.displayName}</div>;
};
