import { HorizontalLayout, LayoutProps } from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@material-ui/core';
import { useWindowSize } from 'hooks/useWindowSize';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

type Direction = 'column' | 'row';

export const _CustomHorizontalLayout = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as HorizontalLayout;
  const { width } = useWindowSize();
  const direction: Direction = width < 1280 ? 'column' : 'row';

  if (isEmpty(layout.elements) || !visible) {
    return null;
  }

  return (
    <Grid container direction={direction} spacing={direction === 'row' ? 2 : 0}>
      {layout.elements.map((child, index) => (
        <Grid item key={`${path}-${index}`} xs>
          <ResolvedJsonFormsDispatch
            uischema={child}
            schema={schema}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const CustomHorizontalLayout = withJsonFormsLayoutProps(_CustomHorizontalLayout);
