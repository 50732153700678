import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { MonitoringChartContainer, Props as ChartContainerProps } from 'components/charts/MonitoringChartContainer';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useMarkApplicationRescanAll } from 'queries/useApplications';
import React, { useState } from 'react';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  disclaimer: {
    margin: theme.spacing(4),
  },
  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  input: {},
  content: {
    display: 'block',
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  className?: string;
  isRescanAll?: boolean;
  locked?: boolean;
  options?: ChartContainerProps;
  monitoringEnabled?: boolean;
}

export const RescanAllButton: React.FC<Props> = ({
  application,
  className,
  isRescanAll,
  locked,
  options,
  monitoringEnabled,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useMarkApplicationRescanAll(application.id, {
    onSuccess: () => {
      showToaster(`Scheduled rescan on all online devices successfully.`);
      setIsModalOpen(false);
    },
    onError: (e) => {
      toasterErrorHandler(e);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const MODAL_TITLE = !isRescanAll ? 'Confirm Rescan All' : 'Rescan In Progress';
  const MODAL_TEXT = !isRescanAll ? (
    <div>
      This will remove existing data and:
      <br />
      <ul>
        <li>Schedule rescans on all devices</li>
        <li>Schedule external scan on targets, if added</li>
        <li>Refetch M365 data, if connected</li>
      </ul>
      The latest report will be generated, so you have the snapshot of current data before its removed.
      <br />
      <br />
      <FormControl fullWidth>
        <CustomTextFieldShrink
          disabled={isLoading}
          placeholder='Enter the reason for triggering rescan'
          value={reason}
          onChange={(event) => setReason(event.currentTarget.value)}
          className={classes.input}
          label='Enter Reason *'
        />
      </FormControl>
      <br />
      <br />
      Do you want to proceed?
    </div>
  ) : (
    'Rescan is currently ongoing on online devices.'
  );

  return (
    <>
      <Tooltip title='Rescan All'>
        <Button
          className={className}
          onClick={handleModalOpen}
          variant={isRescanAll ? 'text' : 'outlined'}
          color='primary'
          disabled={isRescanAll}
          startIcon={<RefreshRoundedIcon />}
        >
          {isRescanAll ? 'Rescan All In Progress' : 'Rescan All'}
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <MonitoringChartContainer
          {...options}
          locked={!monitoringEnabled}
          skipPadding
          application={application}
          text='Enable monitoring to trigger rescan'
          className={classes.content}
        >
          {locked ? (
            <div className={classes.disclaimer}>
              <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
                Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> to upgrade account.
              </SecurityCard.Badge>
            </div>
          ) : (
            <>
              <DialogContent>
                <Typography variant='h3' className={classes.title}>
                  {MODAL_TITLE}
                </Typography>
                <Typography variant='body1'>{MODAL_TEXT}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsModalOpen(false)}>{isRescanAll ? 'Close' : 'Cancel'}</Button>
                {!isRescanAll && (
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={isLoading || !reason}
                    onClick={() => mutate(reason)}
                    startIcon={<RefreshRoundedIcon />}
                  >
                    {isLoading ? (isRescanAll ? 'Saving...' : 'Submitting...') : 'Confirm'}
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </MonitoringChartContainer>
      </Dialog>
    </>
  );
};
