import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { QuoteDocumentType } from 'dtos/quotes';
import { useQuoteDocuments } from 'queries/useQuote';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { DocumentRow } from './DocumentRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  list: {},
  description: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.RED_1,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(3),
  },

  // Actions
  button: {
    marginTop: theme.spacing(2),
  },
  footer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    marginLeft: 'auto',
  },

  label: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.GREY_2,
    marginBottom: theme.spacing(1.5),
  },
}));

interface Props {
  quoteId: string;
  replicateToQuotesIds?: string[];
  applicationId: string;
  onClose: () => void;
}

export const Documents = ({ quoteId, onClose, applicationId, replicateToQuotesIds }: Props) => {
  const classes = useStyles();
  const { data } = useQuoteDocuments(applicationId, quoteId);
  const hasQuoteLetter = data?.some((d) => d.documentType === QuoteDocumentType.QUOTE_LETTER) || false;

  return (
    <div className={classes.root}>
      {!hasQuoteLetter && (
        <p className={classes.description}>
          Please upload a <b>Quote Letter</b> to use this quote in proposal.
        </p>
      )}

      <div className={classes.label}>Upload Documents</div>
      <div className={classes.list}>
        {data?.map((doc, idx) => (
          <DocumentRow
            quoteId={quoteId}
            document={doc}
            key={idx}
            hasQuoteLetter={hasQuoteLetter}
            replicateToQuotesIds={replicateToQuotesIds}
          />
        ))}

        {/* Upload quote form */}
        <DocumentRow quoteId={quoteId} hasQuoteLetter={hasQuoteLetter} replicateToQuotesIds={replicateToQuotesIds} />
      </div>

      <footer className={classes.footer}>
        <div className={classes.actions}>
          <Button onClick={onClose} className={classes.button}>
            Close
          </Button>
        </div>
      </footer>
    </div>
  );
};
