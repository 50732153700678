import { makeStyles, Theme } from '@material-ui/core';
import { Placeholder } from 'components/Placeholder';
import { ReportData } from 'dtos/deep-scan';
import React from 'react';

import { ExecutiveSummaryItem } from '../ExecutiveSummaryItem';

const useStyles = makeStyles<Theme>((theme) => ({
  executiveSummaryWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  doubleColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },
}));

export interface Props {
  isLoading: boolean;
  reportData?: ReportData;
}

export const ExecutiveSummary = ({ isLoading, reportData }: Props) => {
  const classes = useStyles();

  return (
    <Placeholder isActive={isLoading || !reportData} className={classes.executiveSummaryWrapper}>
      <div className={classes.doubleColumn} style={{ minHeight: 200, gap: 64 }}>
        <div>
          <ExecutiveSummaryItem
            title='Network Security'
            score={reportData?.networkSecurity?.score}
            scoreText={reportData?.networkSecurity?.scoreText}
          />
          <ExecutiveSummaryItem
            title='Application Security'
            score={reportData?.applicationSecurity?.score}
            scoreText={reportData?.applicationSecurity?.scoreText}
          />
          <ExecutiveSummaryItem
            title='Data Security'
            score={reportData?.dataSecurity?.score}
            scoreText={reportData?.dataSecurity?.scoreText}
            noBottomBorder
          />
        </div>
        <div>
          <ExecutiveSummaryItem
            title='Identity and Access Management'
            score={reportData?.identityAccessManagement?.score}
            scoreText={reportData?.identityAccessManagement?.scoreText}
          />
          <ExecutiveSummaryItem
            title='Dark Web Presence'
            score={reportData?.securityAwarenessTraining?.score}
            scoreText={reportData?.securityAwarenessTraining?.scoreText}
            noBottomBorder
          />
        </div>
      </div>
    </Placeholder>
  );
};
