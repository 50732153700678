import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CustomTextField } from 'components/CustomTextField';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { DeepScanDTO, PII_TYPE_LABELS, PiiFileDTO } from 'dtos/deep-scan';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useToasters } from 'hooks/useToasters';
import moment from 'moment';
import { useDeepScanDataRiskMatches, useTogglePiiFileFalsePositive } from 'queries/useDeepScan';
import React, { useCallback, useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

// import { MarkFalsePositiveModal } from './MarkFalsePositiveModal';
import { PiiFileDetailsModal } from './PiiFileDetailsModal';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  input: {
    flex: 1,
  },

  scoreBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  red: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED_1,
  },
  yellow: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.YELLOW_1,
  },
  green: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_1,
  },
}));

interface Props {
  deepScan?: DeepScanDTO;
  deepScanTargetId?: string;
  piiType?: string;
  hostname?: string;
  className?: string;
  isOpen: boolean;
  handleClose: () => void;
}

const PER_PAGE = 10;

function getFileSizeUser(size: number | null) {
  if (size) {
    if (size < 1024) {
      return `${size.toFixed(0)}B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024.0).toFixed(1)}KB`;
    } else if (size < 1024 * 1024 * 1024) {
      return `${(size / (1024.0 * 1024.0)).toFixed(1)}MB`;
    } else if (size < 1024 * 1024 * 1024 * 1024) {
      return `${(size / (1024.0 * 1024.0 * 1024.0)).toFixed(1)}GB`;
    } else {
      return `${size.toFixed(2)} B`;
    }
  }
  return '';
}

function getFileLastModified(lastModified: string | null) {
  if (lastModified) {
    const m = moment(lastModified);
    return (
      <>
        {m.format('Do MMM YYYY')}
        <br />
        {m.format('hh:mm:ss A')}
      </>
    );
  }
  return '';
}

export const DataRiskDetailsModal: React.FC<Props> = ({
  deepScan,
  deepScanTargetId,
  piiType,
  hostname,
  className,
  isOpen,
  handleClose,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [markPiiFile, setMarkPiiFile] = useState<PiiFileDTO | null>(null);
  const [selectedPiiFile, setSelectedPiiFile] = useState<PiiFileDTO | null>(null);
  const [search, setSearch] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(search, 200);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [sorter, setSorter] = useState<Sorter<keyof PiiFileDTO> | undefined>({
    key: 'dataRisk',
    order: SortOrder.DESC,
  });

  const { showToaster } = useToasters();

  const { mutate, isLoading: isMutateLoading } = useTogglePiiFileFalsePositive(deepScan?.id || '', {
    onSuccess: () => {
      showToaster(`Successfully marked as ${markPiiFile?.falsePositive ? 'not' : ''} false positive.`);
      setMarkPiiFile(null);
    },
  });

  const { data, isLoading } = useDeepScanDataRiskMatches(
    deepScan?.id || '',
    {
      q: debouncedSearchValue,
      piiType: piiType,
      deepScanTargetId: deepScanTargetId,
      hostname: hostname,
      limit: PER_PAGE,
      offset: PER_PAGE * page,
      sortBy: sorter?.key,
      sortOrder: sorter?.order,
    },
    { enabled: Boolean(deepScan?.id && isOpen) },
  );

  const { data: piiFiles, count: total } = data || {};

  const handleCloseModal = () => {
    handleClose();
    setSearch('');
    setPage(0);
  };

  const handleToggleFalsePositive = useCallback(
    (row) => {
      setMarkPiiFile(row);
      mutate(row);
    },
    [setMarkPiiFile, mutate],
  );

  const columns = useMemo((): Column<PiiFileDTO, keyof PiiFileDTO>[] => {
    return [
      {
        title: 'Device',
        sortKey: 'hostname',
        render: (row) => row.hostname,
      },
      {
        title: 'File',
        sortKey: 'path',
        render: (row) => row.path,
      },
      {
        title: 'Size',
        sortKey: 'fileSize',
        render: (row) => getFileSizeUser(row.fileSize),
      },
      {
        title: 'Last Modified',
        sortKey: 'fileLastModified',
        render: (row) => getFileLastModified(row.fileLastModified),
      },
      {
        title: 'PII Type',
        sortKey: 'piiType',
        render: (row) => PII_TYPE_LABELS[deepScan?.piiConfig?.country || 'US'][row.piiType] || row.piiType,
      },
      {
        title: 'Matches',
        render: (row) => row.matchesCount,
      },
      {
        title: `Risk (in ${deepScan?.piiConfig?.currency})`,
        sortKey: 'dataRisk',
        render: (row) =>
          `${deepScan?.piiConfig?.currency}${(row.falsePositive
            ? 0
            : ((deepScan?.piiConfig?.costs || {})[row.piiType] || 0) * (row.matchesCount || 0)
          ).toLocaleString()}`,
      },
      // {
      //   title: 'Confidence',
      //   sortKey: 'confidence',
      //   render: (row) => row.confidence,
      // },
      {
        title: 'Action',
        width: '190px',
        render: (row) => (
          <>
            <Button className={className} onClick={() => setSelectedPiiFile(row)}>
              View
            </Button>
            <Button className={className} onClick={() => handleToggleFalsePositive(row)} disabled={isMutateLoading}>
              {row.falsePositive ? 'Unmark False' : 'Mark False'}
            </Button>
          </>
        ),
      },
    ];
  }, [className, deepScan, handleToggleFalsePositive, isMutateLoading]);

  return (
    <>
      <Dialog fullWidth={true} open={isOpen} maxWidth='lg' onClose={handleCloseModal}>
        <DialogContent>
          <div className={classes.row}>
            <div className={classes.input}>
              <CustomTextField
                placeholder='Search for items'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(0);
                }}
              />
            </div>
          </div>

          <Table<PiiFileDTO, keyof PiiFileDTO>
            pagination={isLoading ? undefined : { page, perPage: PER_PAGE, total: total || 0 }}
            rowKey={(row) => `${row.id}-${row.path}`}
            data={isLoading ? undefined : piiFiles}
            // onRowClick={(row) => setSelectedPiiFile(row)}
            className={classes.table}
            loading={isLoading}
            rowContentCentered
            columns={columns}
            sorter={sorter}
            onChange={(pagination, sorter) => {
              if (pagination?.page !== undefined) {
                setPage(pagination?.page);
              }
              if (sorter) setSorter(sorter);
            }}
          />
        </DialogContent>
      </Dialog>

      <PiiFileDetailsModal
        handleClose={() => setSelectedPiiFile(null)}
        data={selectedPiiFile || undefined}
        isOpen={Boolean(selectedPiiFile)}
        deepScan={deepScan}
      />

      {/*<MarkFalsePositiveModal
        handleClose={() => setMarkPiiFile(null)}
        data={markPiiFile || undefined}
        isOpen={Boolean(markPiiFile)}
        deepScan={deepScan}
      />*/}
    </>
  );
};
