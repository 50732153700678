import { Button, Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import cx from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Modal } from 'components/Modal';
import { SingleFilter } from 'components/SingleFilter';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO, MonitoringSnapshotDTO } from 'dtos/application';
import { DataRiskDistributionDTO, Device, PII_TYPE_INFOS, PII_TYPE_LABELS } from 'dtos/deep-scan';
import { useMonitoringDataSecurityChart } from 'hooks/charts/monitoring/useDataSecurityChart';
import { useChartImage } from 'hooks/useChartImage';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import {
  useDeepScan,
  useDeepScanDataRiskDistribution,
  useDeepScanParsedReports,
  useDeepScanReportData,
} from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ApplicationViewContainer } from 'views/agent/views/application-details/views/ApplicationViewContainer';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

import { ScoreSecurityCard } from '../../components/common/ScoreSecurityCard';
import { ConfigurePiiCostsModal } from '../../components/ConfigurePiiCostsModal';
import { DataRiskDetailsModal } from '../../components/DataRiskDetailsModal';
import { EncryptionDetailsModal } from '../../components/EncryptionDetailsModal';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';
import { DeepScanDataSecurityPiiTools } from './DataSecurityPiiTools';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },
  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },
  red: {
    color: COLORS.RED_1,
  },

  chartContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    '&:first-child': {
      flex: 2,
    },
  },
  chart: {
    height: 380,
  },
  doughnutChart: {
    height: 390,
  },

  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
  headerRow: {
    display: 'flex',
    // marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  headerColumn: {
    width: '50%',
  },
  headerColumn1: {
    width: '35%',
  },
  headerColumn2: {
    width: '100%',
  },

  actionButton: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginRight: theme.spacing(0.5),
  },

  configuePiiCosts: {
    float: 'right',
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  lineChart: {
    height: 400,
  },
  redItalic: {
    color: COLORS.RED_1,
    fontStyle: 'italic',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  securityCard: {
    flex: 1,
  },
}));

export enum RiskBy {
  PII = 'pii',
  FILES_SCANNED = 'filesScanned',
}

interface Props {
  application: ApplicationWithAgentDTO;
  isAdmin: boolean;
  monitoringSnapshots?: MonitoringSnapshotDTO[];
  selectedAssets: string[];
  setSelectedAssets: (a: string[]) => void;
  allAssets: string[];
  selectedLimit: string;
  setSelectedLimit: (a: string) => void;
}

export const DeepScanDataSecurity = ({
  application,
  isAdmin,
  monitoringSnapshots,
  selectedAssets,
  setSelectedAssets,
  allAssets,
  selectedLimit,
  setSelectedLimit,
}: Props) => {
  const classes = useStyles();
  const [riskBy, setRiskBy] = useState(RiskBy.PII);

  const [selectedPiiType, setSelectedPiiType] = useState<string | undefined>(undefined);
  const [selectedHostname, setSelectedHostname] = useState<string | undefined>(undefined);
  const handleClose = () => {
    setSelectedPiiType(undefined);
    setSelectedHostname(undefined);
  };

  const deepScanId = application.deepScanId || '';
  const { data, isLoading } = useDeepScanParsedReports(deepScanId, { enabled: Boolean(deepScanId) });
  const { devices } = data?.actifileRiskReport || {};

  const [sorter, setSorter] = useState<Sorter<keyof Device> | undefined>({
    key: 'riskValuation',
    order: SortOrder.DESC,
  });
  const { data: deepScan } = useDeepScan(deepScanId);
  const { data: dataRiskDistribution, isLoading: isLoadingDataRiskDistribution } = useDeepScanDataRiskDistribution(
    deepScanId,
    { enabled: Boolean(deepScanId) },
  );
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const filterDevices = useMemo(
    () => uniq(dataRiskDistribution?.map((device) => device.hostname) ?? []),
    [dataRiskDistribution],
  );

  const totalDataRiskDistribution = useMemo(() => {
    const all = dataRiskDistribution?.reduce((acc: { [key: string]: DataRiskDistributionDTO }, d) => {
      acc[d.piiType] ||= {
        piiType: d.piiType,
        hostname: d.hostname,
        sensitiveFiles: 0,
        matchesCount: 0,
        totalRisk: 0,
      };

      if (selectedDevices.length === 0 || selectedDevices.includes(d.hostname)) {
        acc[d.piiType].sensitiveFiles += d.sensitiveFiles;
        acc[d.piiType].matchesCount += d.matchesCount;
        acc[d.piiType].totalRisk += d.totalRisk;
      }

      return acc;
    }, {});

    return all && Object.values(all);
  }, [dataRiskDistribution, selectedDevices]);

  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  const byPiiTypeChart = useChartImage(
    {
      className: cx(classes.chart, classes.doughnutChart),
      title: 'Data Risk By PII Type',
    },
    charts?.piiRiskByType,
    deepScan,
    application,
  );

  const devicesFilesScannedChart = useChartImage(
    {
      className: classes.chart,
      title: 'Device Risk by:',
      actions: (
        <Select
          value={riskBy}
          onChange={(el) => {
            setRiskBy(el.target.value as RiskBy);
          }}
        >
          <MenuItem key={RiskBy.PII} value={RiskBy.PII}>
            PII Risk (${deepScan?.piiConfig?.currency})
          </MenuItem>
          <MenuItem key={RiskBy.FILES_SCANNED} value={RiskBy.FILES_SCANNED}>
            Sensitive Files
          </MenuItem>
        </Select>
      ),
    },
    riskBy === RiskBy.FILES_SCANNED ? charts?.devicesFilesScanned : charts?.devicesRiskValuation,
    deepScan,
    application,
  );
  const [datRiskDistributionSorter, setDatRiskDistributionSorter] = useState<
    Sorter<keyof DataRiskDistributionDTO> | undefined
  >({
    key: 'totalRisk',
    order: SortOrder.DESC,
  });

  const dataRiskDistributionColumns = useMemo((): Column<DataRiskDistributionDTO, keyof DataRiskDistributionDTO>[] => {
    return [
      {
        title: 'PII Type',
        sortKey: 'piiType',
        render: (row) => {
          const piiTypeLabel = PII_TYPE_LABELS[deepScan?.piiConfig?.country || 'US'][row.piiType] || row.piiType;
          const piiTypeInfo = PII_TYPE_INFOS['US'] && PII_TYPE_INFOS['US'][row.piiType];

          if (!piiTypeInfo) return piiTypeLabel;

          return (
            <span>
              {piiTypeLabel}
              <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title={piiTypeLabel}>
                <p>{piiTypeInfo}</p>
              </Modal>
            </span>
          );
        },
      },
      {
        title: `Risk per match (in ${deepScan?.piiConfig?.currency})`,
        // sortKey: 'piiType',
        render: (row) =>
          `${deepScan?.piiConfig?.currency}${((deepScan?.piiConfig?.costs || {})[row.piiType] || 0).toLocaleString()}`,
      },
      {
        title: `Total PII Risk (in ${deepScan?.piiConfig?.currency})`,
        sortKey: 'totalRisk',
        render: (row) => `${deepScan?.piiConfig?.currency}${(row.totalRisk || 0).toLocaleString()}`,
      },
      {
        title: 'Total PII Matches',
        sortKey: 'matchesCount',
        render: (row) => (row.matchesCount || 0).toLocaleString(),
      },
      {
        title: 'Sensitive Files',
        sortKey: 'sensitiveFiles',
        render: (row) => (row.sensitiveFiles || 0).toLocaleString(),
      },
      {
        title: 'Action',
        render: (row) => (
          <Button className={classes.actionButton} onClick={() => setSelectedPiiType(row.piiType)}>
            Details
          </Button>
        ),
      },
    ];
  }, [classes, deepScan]);

  const sortedTotalDataRiskDistribution = useMemo(() => {
    if (datRiskDistributionSorter) {
      const data = sortBy(totalDataRiskDistribution, datRiskDistributionSorter.key);

      if (datRiskDistributionSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(totalDataRiskDistribution, (d) => d.totalRisk).reverse();
  }, [totalDataRiskDistribution, datRiskDistributionSorter]);

  const columns = useMemo((): Column<Device, keyof Device>[] => {
    return [
      {
        title: 'Host Name',
        sortKey: 'deviceName',
        render: (row) => row.deviceName.toLocaleString(),
      },
      {
        title: 'Scan Status',
        render: (row) => {
          if (row.source === 'actifile') return row.deviceName;

          let ranking: ScoreRanking = 'Medium';
          let status = 'Pending';

          if (row.completed !== undefined && row.completed !== null) {
            ranking = 'Low';
            status = 'Finished';
            // } else if (row.error) {
            //   ranking = 'High';
            //   status = 'Error';
          } else if (!row.started) {
            ranking = 'None';
            status = 'Not started';
          }

          return <ScoreBox ranking={ranking} label={status} />;
        },
      },
      {
        title: 'Disk Encryption Status',
        render: (row) => <EncryptionDetailsModal className={classes.actionButton} device={row} />,
      },
      {
        title: `Total PII Risk (in ${deepScan?.piiConfig?.currency})`,
        sortKey: 'riskValuation',
        render: (row) => `${deepScan?.piiConfig?.currency}${row.riskValuation.toLocaleString()}`,
      },
      {
        title: 'Files Scanned',
        sortKey: 'files',
        render: (row) => {
          const showProgress = !row.completed && row.dryRunTotal;
          return `${(row.files || 0).toLocaleString()}${
            showProgress ? `/${(row.dryRunTotal || 0).toLocaleString()}` : ''
          }`;
        },
      },
      {
        title: 'Sensitive Files',
        sortKey: 'sensitive',
        render: (row) => row.sensitive.toLocaleString(),
      },
      {
        title: 'Action',
        render: (row) =>
          row.sensitive ? (
            <Button className={classes.actionButton} onClick={() => setSelectedHostname(row.deviceName)}>
              Details
            </Button>
          ) : null,
      },
    ];
  }, [classes, deepScan]);

  const sortedDevices = useMemo(() => {
    if (sorter) {
      const data = sortBy(devices, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(devices, (device) => device.riskValuation).reverse();
  }, [devices, sorter]);

  const totalFilesScanned = useMemo(() => {
    return devices?.reduce((acc, device) => acc + (device.files || 0), 0);
  }, [devices]);

  const totalSensitiveFiles = useMemo(() => {
    return devices?.reduce((acc, device) => acc + device.sensitive, 0);
  }, [devices]);

  const totalEncryptionStatus = useMemo(() => {
    const total = devices?.reduce((acc, device) => {
      const drives = device.encryptionStatus || [];
      const encPercent = drives.length
        ? drives.reduce((acc, drive) => acc + (drive.EncryptionPercentage || 0), 0) / drives.length
        : 0;
      return acc + encPercent;
    }, 0);

    return devices?.length && total ? total / devices.length : 0;
  }, [devices]);

  const { chart: dataSecurityChart } = useMonitoringDataSecurityChart(
    application,
    application.monitoringEnabled,
    monitoringSnapshots,
    selectedAssets,
    false,
    classes.lineChart,
  );

  const { data: reportData } = useDeepScanReportData(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  if (isLoading || isLoadingDataRiskDistribution) {
    return <LoadingContainer />;
  }

  if (!devices) {
    return (
      <ApplicationViewContainer>
        <EmptyState title='No data available yet' icon={<SignalWifiOffIcon />} />

        {isAdmin && deepScan?.piiToolsHost && <DeepScanDataSecurityPiiTools application={application} />}
      </ApplicationViewContainer>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Data Security</div>

      <div className={classes.section}>
        <div className={classes.headerRow}>
          <div className={classes.headerColumn2}>
            <div className={classes.summary} id='summary'>
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Total Assets Scanned'
                skipGrading={data?.actifileRiskReport?.overallSummary.deviceRisk?.toLocaleString()}
              />
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Data Risk'
                skipGrading={`${deepScan?.piiConfig?.currency}${(
                  data?.actifileRiskReport?.overallSummary.totalRisk || 0
                )?.toLocaleString()}`}
                score={(reportData?.dataSecurity.score || 0) * 100}
              />
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Total Files Scanned'
                skipGrading={(totalFilesScanned || 0)?.toLocaleString()}
              />
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Sensitive Files Found'
                skipGrading={totalSensitiveFiles?.toLocaleString()}
                score={(totalSensitiveFiles || 0) > 0 ? 50 : (reportData?.dataSecurity.score || 0) * 100}
              />
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Disk Encryption Status'
                skipGrading={`${totalEncryptionStatus?.toLocaleString()}%`}
              />
              {/*<div className={classes.item}>
                <div className={classes.number}>
                  {data?.actifileRiskReport?.overallSummary.deviceRisk?.toLocaleString()}
                </div>
                <div className={classes.description}>Total Assets Scanned</div>
              </div>
              <div className={classes.item}>
                <div className={cx(classes.number, classes.red)}>
                  {deepScan?.piiConfig?.currency}
                  {(data?.actifileRiskReport?.overallSummary.totalRisk || 0)?.toLocaleString()}
                </div>
                <div className={classes.description}>
                  Total Data Risk
                  <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Total Data Risk'>
                    <p>
                      This amount is generated via estimating value per record and multiplying it with the total
                      instances of the records found. The value per record is derived from sources released by insurance
                      partners, governmental agencies and private researchers.
                    </p>
                  </Modal>
                </div>
              </div>
            </div>
            <div className={classes.summary} id='summary'>
              <div className={classes.item}>
                <div className={classes.number}>{(totalFilesScanned || 0)?.toLocaleString()}</div>
                <div className={classes.description}>Total Files Scanned</div>
              </div>
              <div className={classes.item}>
                <div className={cx(classes.number, classes.red)}>{totalSensitiveFiles?.toLocaleString()}</div>
                <div className={classes.description}>Sensitive Files</div>
              </div>
              <div className={classes.item}>
                <div className={classes.number}>{totalEncryptionStatus?.toLocaleString()}%</div>
                <div className={classes.description}>Disk Encryption Status</div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>Data Liability</div>

        <div className={classes.headerRow}>
          <div className={classes.chartContainer}>{devicesFilesScannedChart}</div>
          <div className={classes.chartContainer}>{byPiiTypeChart}</div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.title}>
            Risk Over Time
            <br />
            <span className={classes.redItalic}> Total Data Risk ({deepScan?.piiConfig?.currency})</span>
          </div>
          <div>
            <SingleFilter
              buttonText={`Assets ${selectedAssets.length > 0 ? `(${selectedAssets.length})` : ''}`}
              defaultSelectedOptions={selectedAssets}
              popperText='Filter Assets'
              showDropdownIcon
              options={allAssets}
              setSelectedOptions={(a) => {
                setSelectedAssets(a);
              }}
            />
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>
        <div className={classes.chartContainer}>{dataSecurityChart}</div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>Risk by Data</div>
        <div className={classes.boxBorderPadding}>
          <div>
            <SingleFilter
              buttonText={`Filter Devices ${selectedDevices.length > 0 ? `[${selectedDevices}]` : ''}`}
              defaultSelectedOptions={selectedDevices}
              popperText='Devices'
              showDropdownIcon
              options={filterDevices}
              setSelectedOptions={(d) => {
                setSelectedDevices(d);
              }}
            />
            {deepScan && (
              <ConfigurePiiCostsModal
                deepScan={deepScan}
                applicationId={application.id}
                className={classes.configuePiiCosts}
              />
            )}
          </div>
          <Table<DataRiskDistributionDTO, keyof DataRiskDistributionDTO>
            columns={dataRiskDistributionColumns}
            sorter={datRiskDistributionSorter}
            onChange={(pagination, sorter) => {
              setDatRiskDistributionSorter(sorter);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.piiType}`}
            data={sortedTotalDataRiskDistribution}
            loading={isLoadingDataRiskDistribution}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Risk by Devices
          <span className={classes.findings}> ({sortedDevices.length} devices)</span>
        </div>
        <div className={classes.boxBorder}>
          <Table<Device, keyof Device>
            columns={columns}
            sorter={sorter}
            onChange={(pagination, sorter) => {
              setSorter(sorter);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.userName}-${row.deviceName}`}
            data={sortedDevices}
            loading={isLoading}
          />
        </div>
      </div>

      <DataRiskDetailsModal
        deepScan={deepScan}
        className={classes.actionButton}
        piiType={selectedPiiType}
        hostname={selectedHostname}
        isOpen={Boolean(selectedPiiType || selectedHostname)}
        handleClose={handleClose}
      />

      {isAdmin && deepScan?.piiToolsHost && <DeepScanDataSecurityPiiTools application={application} />}
    </div>
  );
};
