import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { AxiosError } from 'axios';
import { DollarInput } from 'components/DollarInput';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { CreateManualDeclinationDTO, DeclinationDTO } from 'dtos/declinations';
import { useToasters } from 'hooks/useToasters';
import { useCarriers } from 'queries/useCarriers';
import { useCreateManualDeclination, useUpdateDeclination } from 'queries/useDeclinations';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  footer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    marginLeft: 'auto',
  },
  button: {
    '& + $button': {
      marginLeft: theme.spacing(1),
    },
  },
  row: {
    display: 'flex',
    '& + $row': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    flex: 1,
    '& + $input': {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface Props {
  application: ApplicationDTO;
  existingDeclination?: DeclinationDTO;
  onCloseRequest: () => void;
  onDeclinationCreation: (quote: DeclinationDTO) => void;
}

export const DeclinationForm: React.FC<Props> = ({
  application,
  existingDeclination,
  onCloseRequest,
  onDeclinationCreation,
}) => {
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { data: carriers, isLoading: isLoadingCarriers } = useCarriers();

  const options = {
    onSuccess: (declination: DeclinationDTO) => {
      onDeclinationCreation(declination);
      showToaster('Declination created');
    },
    onError: (e: AxiosError | unknown) => toasterErrorHandler(e),
  };

  const updateQuoteMutation = useUpdateDeclination(existingDeclination ? existingDeclination.id : 'nope', options);
  const createQuoteMutation = useCreateManualDeclination(application.id, options);
  const isLoading = updateQuoteMutation.isLoading || createQuoteMutation.isLoading;

  const { handleSubmit, control, setValue, watch } = useForm<CreateManualDeclinationDTO>({
    defaultValues: {
      carrierId: existingDeclination?.carrier.id || '',
      reason: existingDeclination?.reason || '',
      aggregateLimit: existingDeclination?.requestedAggregateLimit,
      retention: existingDeclination?.requestedRetention,
      effectiveDate: existingDeclination?.requestedEffectiveDate || dayjs().add(1, 'day').format('YYYY-MM-DD'),
    },
  });

  const carrierId = watch('carrierId');
  const handleCreateQuote = useCallback(
    (data: CreateManualDeclinationDTO) => {
      const fn = existingDeclination ? updateQuoteMutation.mutate : createQuoteMutation.mutate;
      fn({
        aggregateLimit: data.aggregateLimit,
        retention: data.retention,
        reason: data.reason,
        effectiveDate: dayjs(data.effectiveDate).format('YYYY-MM-DD'),
        carrierId: data.carrierId,
      });
    },
    [existingDeclination, createQuoteMutation, updateQuoteMutation],
  );

  const renderInput = useCallback(
    (name: keyof CreateManualDeclinationDTO, label: string, type: string, overrides?: Partial<TextFieldProps>) => (
      <Controller
        name={name}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <TextField {...field} label={label} type={type} className={classes.input} {...overrides} />
        )}
      />
    ),
    [control, classes.input],
  );

  const renderDollarInput = useCallback(
    (name: keyof CreateManualDeclinationDTO, label: string) => (
      <Controller
        name={name}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <DollarInput
            value={parseFloat(field.value as string)}
            onChange={field.onChange}
            label={label}
            className={classes.input}
          />
        )}
      />
    ),
    [control, classes.input],
  );

  return (
    <>
      <div className={classes.row}>
        <FormControl className={classes.input}>
          <InputLabel id='carrier-select'>Carrier</InputLabel>
          <Select
            disabled={isLoadingCarriers}
            labelId='carrier-select'
            value={carrierId}
            onChange={(el) => setValue('carrierId', el.target.value as string)}
          >
            {carriers?.map((carrier) => (
              <MenuItem key={carrier.id} value={carrier.id}>
                {carrier.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderInput('reason', 'Reason', 'text')}
      </div>
      <div className={classes.row}>
        {renderDollarInput('aggregateLimit', 'Limit')}
        {renderDollarInput('retention', 'Retention')}
        {renderInput('effectiveDate', 'Effective Date', 'date')}
      </div>

      <footer className={classes.footer}>
        <div className={classes.actions}>
          <Button onClick={onCloseRequest} className={classes.button} disabled={isLoading}>
            Close
          </Button>
          <Button
            onClick={handleSubmit(handleCreateQuote)}
            color='primary'
            variant='contained'
            disabled={isLoading}
            className={classes.button}
          >
            {isLoading ? 'Loading...' : existingDeclination ? 'Update' : 'Create'}
          </Button>
        </div>
      </footer>
    </>
  );
};
