import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { ControllerLog } from 'dtos/deep-scan';
import React, { useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: theme.spacing(2),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  section: {
    paddingLeft: theme.spacing(2),
  },
}));

interface Props {
  logs: ControllerLog[] | null;
  title: string;
  className?: string;
}

export const LogsModal: React.FC<Props> = ({ logs, className, title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const renderLogs = (logs: ControllerLog[]) => {
    return logs.map((data, index) => {
      return (
        <div key={index}>
          <div className={classes.section}>
            {data.timestamp} [{data.status}]
            <br />
            {data.value}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Button className={className} onClick={() => setIsModalOpen(true)}>
        {(logs || []).length} Logs
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)} className={classes.modal}>
        <DialogContent>
          <div className={classes.title}>{title}</div>
          <pre>{renderLogs(logs || [])}</pre>
        </DialogContent>
      </Dialog>
    </>
  );
};
