import { ApplicationQuickChartsDTO, ApplicationQuickChartsUrlsDTO } from '../dtos/charts';
import RequestService from './Request';

class _ChartsService {
  async getSecurityCharts(applicationId: string): Promise<ApplicationQuickChartsDTO> {
    return await RequestService.client
      .get<ApplicationQuickChartsDTO>(`/quickchart/charts/${applicationId}`)
      .then((response) => response.data);
  }

  async getSecurityChartsUrls(applicationId: string): Promise<ApplicationQuickChartsUrlsDTO> {
    return await RequestService.client
      .get<ApplicationQuickChartsUrlsDTO>(`/quickchart/charts/${applicationId}/urls`)
      .then((response) => response.data);
  }

  async getExternalExposure(applicationId: string, scanId: string): Promise<Buffer> {
    return await RequestService.client
      .get<Buffer>(`/quickchart/external-surface/${applicationId}/${scanId}`)
      .then((response) => response.data);
  }
}

const ChartsService = new _ChartsService();

export { ChartsService };
