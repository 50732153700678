import { makeStyles } from '@material-ui/core';
// import { Link, makeStyles } from '@material-ui/core';
// import { Modal } from 'components/Modal';
// import { SecurityScanDTO, SecurityScanStatus } from 'dtos/application';
import { SecurityScanDTO } from 'dtos/application';
// import { difference } from 'lodash';
import React from 'react';
// import { COLORS } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  modalLink: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  className?: string;
}

export const DomainInfo = ({ securityScan }: Props) => {
  const classes = useStyles();
  // const scannedSubDomains = (securityScan.subDomainScans || [])
  //   .filter((s) => s.status == SecurityScanStatus.COMPLETED)
  //   .map((s) => s.domain);

  return (
    <span className={classes.root}>
      <span>
        {securityScan.domain} ({securityScan.subDomains?.length || 0} domains and ip addresses)
      </span>

      {/*securityScan.subDomains && (
        <Modal
          openButton={<Link className={classes.modalLink}>({securityScan.subDomains.length} subdomains)</Link>}
          title='Subdomains'
          padding={4}
          autoWidth
        >
          {scannedSubDomains?.map((subdomain) => (
            <p key={subdomain} style={{ margin: 0, fontWeight: 500 }}>
              {subdomain}
            </p>
          ))}
          {difference(securityScan.subDomains, scannedSubDomains || [])?.map((subdomain) => (
            <p style={{ margin: 0, color: COLORS.GREY_4 }} key={subdomain}>
              {subdomain}
            </p>
          ))}
        </Modal>
      )*/}
    </span>
  );
};
