import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { ApplicationDTO } from 'dtos/application';
import { PolicyDTO } from 'dtos/policy';
import { useToasters } from 'hooks/useToasters';
import { useCancelPolicy } from 'queries/usePolicy';
import React, { useCallback, useMemo, useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  application: ApplicationDTO;
  policy: PolicyDTO;
  className?: string;
}

export const CancelPolicyButton: React.FC<Props> = ({ className, policy, application }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const [text, setText] = useState('');
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const isValid = text === (policy.policyNumber || '');
  const { mutate, isLoading } = useCancelPolicy(application.id, {
    onSuccess: () => {
      handleClose();
      showToaster('Policy has been cancelled');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handleCreate = useCallback(() => {
    setShowValidation(true);

    if (isValid) {
      mutate(policy.id);
    }
  }, [isValid, mutate, policy.id]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const errorMessage = useMemo(() => {
    if (!policy.policyNumber) {
      return null;
    }

    if (!text) {
      return 'Type your policy number.';
    }

    if (text.toLowerCase() !== policy.policyNumber.toLowerCase()) {
      return 'Policy number does not match.';
    }

    return null;
  }, [policy.policyNumber, text]);

  return (
    <>
      <Button startIcon={<CloseRoundedIcon />} className={className} onClick={() => setOpen(true)}>
        Cancel Policy
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseRoundedIcon />
          </IconButton>
          <h3 className={classes.title}>Cancel Policy</h3>
          {policy.policyNumber ? (
            <p>
              Cancelling policy <b>{policy.policyNumber}</b> is permanent and cannot be undone. Type the policy number
              into the field below to confirm cancellation.
            </p>
          ) : (
            <p>This action is permanent and cannot be undone. Are you sure you want to Cancel this Policy?</p>
          )}
          {policy.policyNumber && (
            <div className={classes.center}>
              <CustomTextFieldShrink
                value={text}
                onChange={handleChange}
                label='Policy Number'
                required
                error={showValidation ? !isValid : false}
                helperText={showValidation ? errorMessage : null}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type='submit' color='secondary' variant='contained' disabled={isLoading} onClick={handleCreate}>
            {isLoading ? 'Creating...' : 'Cancel Policy'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
