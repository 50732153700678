import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { LinkButton } from 'components/LinkButton';
import { ApplicationDTO } from 'dtos/application';
import { ProposalDTO } from 'dtos/proposal';
import { useDownloadProposalPdf } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';

interface Props {
  proposal: ProposalDTO;
  application: ApplicationDTO;
}

export const PdfButton = ({ proposal, application }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const businessName = application.applicationResponse.organization_name;
  const { mutate } = useDownloadProposalPdf(proposal.applicationId, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClick = useCallback(() => {
    mutate({ proposal, businessName });
    setIsModalOpen(true);
  }, [proposal, mutate, businessName]);

  return (
    <>
      <LinkButton onClick={handleClick}>Download PDF</LinkButton>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Downloading proposal PDF</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};
