import { makeStyles } from '@material-ui/core/styles';
import { EmptyState } from 'components/EmptyState';
import { QuotesTable } from 'components/QuotesTable';
import { Option, RadioSelect } from 'components/RadioSelect';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDTO } from 'dtos/application';
import { DeclinationDTO } from 'dtos/declinations';
import { QuoteDTO, QuoteStatus } from 'dtos/quotes';
import { ReactComponent as QuoteIcon } from 'images/quote.svg';
import { useApplicationQuotesCount } from 'queries/useApplications';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { isAgentAnAdmin } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { DeclinationButton } from './DeclinationButton';
import { DeclinationsTable } from './DeclinationsTable';
import { QuoteButton } from './QuoteButton';
import { ScheduleQuotingButton } from './ScheduleQuotingButton';
import { SelectedDeclination } from './SelectedDeclination';
import { SelectedQuote } from './SelectedQuote';
import { UnderReviewTable } from './UnderReviewTable';

const useStyles = makeStyles((theme) => ({
  // Select component
  select: {
    marginBottom: theme.spacing(3),
  },

  // Selected table row
  selectedTableRow: {
    backgroundColor: COLORS.BLUE_3,
    boxShadow: `inset 0px -2px 0px ${COLORS.BLUE_1}`,
  },
}));

interface Props {
  application: ApplicationDTO;
  agent: AgentDTO;
}

enum Filter {
  Active = 'active',
  Expired = 'expired',
  Failed = 'failed',
  In_Review = 'in-review',
}

const FILTERS: Option<Filter>[] = [
  {
    label: 'Active',
    value: Filter.Active,
  },
  {
    label: 'Expired',
    value: Filter.Expired,
  },
  {
    label: 'Failed',
    value: Filter.Failed,
  },
  {
    label: 'Under review',
    value: Filter.In_Review,
  },
];

export const ApplicationQuotesView = ({ application, agent }: Props) => {
  const classes = useStyles();
  const { data: counts } = useApplicationQuotesCount(application.id);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = useState<Filter>(Filter.Active);
  const [selectedQuote, setSelectedQuote] = useState<QuoteDTO | undefined>(undefined);
  const [selectedDeclination, setSelectedDeclination] = useState<DeclinationDTO | undefined>(undefined);

  const actions = (
    <>
      {isAgentAnAdmin(agent) && <ScheduleQuotingButton application={application} />}
      {(filter === Filter.Active || filter === Filter.Expired) && (
        <QuoteButton
          uploadOnly={!isAgentAnAdmin(agent)}
          application={application}
          onQuoteUpdate={(quote) => setSelectedQuote(quote)}
        />
      )}
      {filter === Filter.Failed && (
        <DeclinationButton application={application} onDeclinationUpdate={(quote) => setSelectedDeclination(quote)} />
      )}
    </>
  );

  const sidebar = useMemo(() => {
    if (selectedQuote) {
      return (
        <SelectedQuote
          onQuoteUpdate={(quote) => setSelectedQuote(quote)}
          onQuoteDelete={() => {
            setDrawerOpen(false);
            setSelectedQuote(undefined);
          }}
          showEditQuote={isAgentAnAdmin(agent)}
          quote={selectedQuote}
          application={application}
        />
      );
    }

    if (selectedDeclination) {
      return (
        <SelectedDeclination
          onDeclinationUpdate={(quote) => setSelectedDeclination(quote)}
          showEditDeclination={isAgentAnAdmin(agent)}
          declination={selectedDeclination}
          application={application}
        />
      );
    }

    return null;
  }, [selectedQuote, agent, application, selectedDeclination]);

  const filterOptions = useMemo(() => {
    return FILTERS.map((filter): Option<Filter> => {
      let count: number | undefined = undefined;

      switch (filter.value) {
        case Filter.Active:
          count = counts?.activeCount;
          break;
        case Filter.Expired:
          count = counts?.deactivatedCount;
          break;
        case Filter.Failed:
          count = counts?.failedCount;
          break;
        case Filter.In_Review:
          count = counts?.inReviewCount;
          break;
      }

      return {
        count,
        ...filter,
      };
    });
  }, [counts]);

  return (
    <ApplicationViewContainer
      title='Quotes'
      actions={actions}
      sidebar={sidebar}
      onSidebarCloseClick={() => {
        setDrawerOpen(false);
        setSelectedQuote(undefined);
        setSelectedDeclination(undefined);
      }}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
    >
      <Helmet>
        <title>Application Quotes</title>
      </Helmet>
      <RadioSelect<Filter>
        options={filterOptions}
        className={classes.select}
        selectedValue={filter}
        onOptionSelect={(opt) => setFilter(opt.value)}
      />

      {filter === Filter.Failed && (
        <DeclinationsTable
          application={application}
          onDeclinationSelect={(item) => {
            setSelectedDeclination(item);
            setSelectedQuote(undefined);
          }}
          selectedRowsKeys={selectedDeclination ? [selectedDeclination.id] : []}
          selectedRowClass={classes.selectedTableRow}
        />
      )}

      {[Filter.Expired, Filter.Active].includes(filter) && (
        <QuotesTable
          emptyState={
            filter === Filter.Active ? (
              <EmptyState
                icon={<QuoteIcon />}
                title='No Active Quotes'
                text='Looks like you don’t have any active quotes. Start by creating or uploading a quote.'
                actions={
                  <QuoteButton
                    uploadOnly={!isAgentAnAdmin(agent)}
                    application={application}
                    onQuoteUpdate={(quote) => setSelectedQuote(quote)}
                    buttonProps={{
                      color: 'primary',
                    }}
                  />
                }
              />
            ) : (
              <EmptyState title='No Expired Quotes' icon={<QuoteIcon />} />
            )
          }
          spacer
          dataFilter={
            filter === Filter.Active
              ? (i) => i.quoteStatus === QuoteStatus.ACTIVE
              : (i) => i.quoteStatus === QuoteStatus.DEACTIVATED
          }
          hideHeadersWhenEmpty
          application={application}
          selectedRowsKeys={selectedQuote ? [selectedQuote.id] : []}
          selectedRowClass={classes.selectedTableRow}
          onRowClick={(quote) => {
            setDrawerOpen(true);
            setSelectedQuote(quote);
            setSelectedDeclination(undefined);
          }}
        />
      )}

      {filter === Filter.In_Review && <UnderReviewTable application={application} />}
    </ApplicationViewContainer>
  );
};
