import { makeStyles } from '@material-ui/core';
import { LoadingContainer } from 'components/LoadingContainer';
import { ReactComponent as HeaderImage } from 'images/tell-us-about.svg';
import { useBaseAgent } from 'queries/useAgent';
import { useApplication } from 'queries/useApplications';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { InitForm } from './InitForm';
import { SecurityUserSignupOptionalForm } from './SecurityUserSignupOptionalForm';
import { ThankYou } from './ThankYou';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px`,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(12),
    },
  },

  header: {
    padding: `${theme.spacing(2)}px`,
    paddingBottom: 0,
  },
  headerImage: {
    margin: 'auto 0',
    width: '100%',
  },
  agentLogo: {
    width: 200,
    alignSelf: 'start',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  disclaimer: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_3,
    position: 'absolute',
    top: '50%',
    left: '50%',
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'all',
  },
}));

// interface ParamsType {
//   id: string;
// }

export const ApplicationStartView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [createdAppId, setCreatedAppId] = React.useState<string | null>(null);
  const [agentWillReachOut, setAgentWillReachOut] = React.useState<boolean>(false);
  const { data: agent, isLoading } = useBaseAgent(id || '', {
    retry: 1,
  });

  const { data: application } = useApplication(createdAppId || '', {
    retry: 1,
    enabled: !!createdAppId,
  });

  // useEffect(() => {
  //   if (agent?.agency?.logoUrl) {
  //     const links: NodeListOf<HTMLLinkElement> | null = document.querySelectorAll(
  //       "link[rel~='icon']",
  //     ) as NodeListOf<HTMLLinkElement>;
  //     links.forEach((link: HTMLLinkElement) => (link.href = agent?.agency?.logoUrl || ''));
  //   }
  // }, [agent]);

  const securityFlow = isSecurityAgency(agent);
  const handleApplicationCreated = useCallback(
    (url: string) => {
      const applicationId = url.split('/').pop();
      localStorage.setItem(`authApp-${applicationId}`, 'true');

      if (securityFlow && applicationId) {
        setCreatedAppId(applicationId);
        return;
      } else {
        window.location.assign(url);
      }
    },
    [securityFlow],
  );

  const handleFinish = useCallback(() => {
    if (agent?.agency?.redirectLink) {
      window.location.href = agent?.agency?.redirectLink;
    } else {
      setAgentWillReachOut(true);
    }
  }, [agent]);

  const renderContent = () => {
    if (!isLoading && !agent?.agency?.enableLeadMagnet) {
      return (
        <div className={classes.disclaimer}>
          <SecurityCard.Badge variant='locked'>
            Unauthorized. Please reach out to <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        </div>
      );
    }

    if (agentWillReachOut) {
      return <ThankYou agent={agent} />;
    }

    if (!createdAppId) {
      return <InitForm onApplicationCreated={handleApplicationCreated} agent={agent} />;
    }

    if (securityFlow && createdAppId && application) {
      return <SecurityUserSignupOptionalForm application={application} onSave={handleFinish} />;
    }

    return null;
  };

  if (isLoading) {
    return <LoadingContainer fullScreen />;
  }

  return (
    <div className={classes.root}>
      <Helmet titleTemplate={`%s – ${agent?.agency?.name || ''}`}>
        <title>Start</title>
        <link id='favicon-32' rel='icon' type='image/x-icon' sizes='32x32' href={agent?.agency?.logoUrl || ''} />
        <link id='favicon-16' rel='icon' type='image/x-icon' sizes='16x16' href={agent?.agency?.logoUrl || ''} />
      </Helmet>
      <div className={classes.header}>
        {agent?.agency?.logoUrl ? (
          <img src={agent.agency.logoUrl} alt='logo' className={classes.agentLogo} />
        ) : (
          <HeaderImage className={classes.headerImage} />
        )}
      </div>

      <div className={classes.content}>{renderContent()}</div>
    </div>
  );
};
