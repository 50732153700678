import { CreateDocumentDTO, DocumentDTO } from 'dtos/_common';
import { PaymentDTO } from 'dtos/payment';
import { ProposalDTO } from 'dtos/proposal';
import { QuoteDTO } from 'dtos/quotes';

import { ApplicationDTO } from './application';

export enum PolicyStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

export interface PolicyDTO {
  id: string;
  status: PolicyStatus;
  createdAt: string;
  updatedAt: string;
  attestationDone: boolean;
  billingDone: boolean;
  bindDone: boolean;
  policyIssuance: boolean;
  policyNumber: string | null;
  premium: number;
  retention: number;
  limit: number;
  applicationId: string;
  application: ApplicationDTO;
  quote: QuoteDTO;
  quoteId: string;
  proposal: ProposalDTO | null;
  signedDocumentsPath: string;
  proposalId: string | null;
  documents: PolicyDocumentDTO[];
  payment: PaymentDTO | null;
}

export interface CreatePolicyDTO {
  quoteId: string;
  applicationId: string;
  proposalId?: string;
  policyNumber?: string;
  premium: number;
  retention: number;
  limit: number;
}

export interface UpdatePolicyDTO {
  policyNumber: string;
}

export enum PolicyDocumentType {
  CARRIER_INVOICE = 'carrier_invoice',
  INVOICE = 'invoice',
  POLICY = 'policy',
  SIGNED_APPLICATION = 'signed_application',
  CONFIRMATION_OF_INSURANCE = 'confirmation_of_insurance',
  PREFILLED_APP = 'prefilled_app',
  TELIVY_APP = 'telivy_app',
  TELIVY_PROPOSAL = 'telivy_proposal',
  QUOTE_LETTER = 'quote_letter',
  SECURITY_REPORT = 'security_report',
  OTHER = 'other',
}

export enum PolicyDocumentSignatureStatus {
  NOT_REQUIRED = 'not_required', // Signature is not needed for this document
  NOT_STARTED = 'not_started', // Client has not started signature process
  ENVELOPE_CREATED = 'envelope_created', // Envelope has been created for this document
  SIGNED = 'signed', // The document has been signed
}

export type PolicyDocumentDTO = DocumentDTO<PolicyDocumentType> & {
  signatureStatus: PolicyDocumentSignatureStatus;
};

export type CreatePolicyDocumentDTO = CreateDocumentDTO<PolicyDocumentType>;
