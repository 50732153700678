import { makeStyles } from '@material-ui/core/styles';
import { AgentLogo } from 'components/AgentLogo';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { MonitoringFrequency } from 'dtos/application';
import uniq from 'lodash/uniq';
import { useApplication, useApplicationMonitoringSnapshots, useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { TYPOGRAPHY } from 'telivy-theme';
import { SecurityAssessments } from 'templates/SecurityAssessments';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

const useStyles = makeStyles((theme) => ({
  root: {},
  nav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: 0,
    padding: `0 ${theme.spacing(7.5)}px`,
    height: 60,
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      flexDirection: 'column',
    },
  },
  agentLogo: {
    position: 'absolute',

    [theme.breakpoints.down('xs')]: {
      position: 'static',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    margin: '0 auto',
  },

  container: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(7.5)}px ${theme.spacing(7.5)}px ${theme.spacing(7.5)}px`,

    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    },

    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
}));

// interface ParamsType {
//   id: string;
// }

export const SecurityAssessmentsView: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: applicationData, isLoading: isLoadingApplication } = useApplication(id || '');
  const { data, isLoading, isError } = useApplicationSecurityScan(id || '', 'latest');

  const deepScanId = applicationData?.deepScanId || '';
  const { data: deepScan } = useDeepScan(deepScanId, { enabled: Boolean(deepScanId) });

  const [selectedLimit, setSelectedLimit] = useState<keyof typeof LIMITS>('YTD');
  useEffect(() => {
    if (applicationData?.monitoringFrequency == MonitoringFrequency.WEEKLY) {
      setSelectedLimit('30 days');
    }
  }, [applicationData, setSelectedLimit]);

  const { data: monitoringScanpshots } = useApplicationMonitoringSnapshots(
    applicationData?.id || '',
    LIMITS[selectedLimit],
    { enabled: applicationData?.monitoringEnabled },
  );

  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const allAssets = useMemo(
    () => uniq(([] as string[]).concat(...(monitoringScanpshots?.map((d) => d.hostnames) ?? []))),
    [monitoringScanpshots],
  );

  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const allAccounts = useMemo(
    () =>
      uniq(([] as string[]).concat(...(monitoringScanpshots?.map((d) => Object.keys(d.darkWebScanFindings)) ?? []))),
    [monitoringScanpshots],
  );

  if (isLoading || isLoadingApplication) {
    return <LoadingContainer fullScreen />;
  }

  if (isError || !data || !applicationData) {
    return <NotFoundContainer fullScreen />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Security Overview</title>
      </Helmet>

      <nav className={classes.nav}>
        <AgentLogo applicationId={id || ''} logoSize={30} className={classes.agentLogo} />
        <h2 className={classes.title}>Security Overview</h2>
      </nav>

      <div className={classes.container}>
        <SecurityAssessments
          sourceRoute={ROUTES.application}
          applicationId={id || ''}
          application={applicationData}
          securityScan={data}
          deepScan={deepScan}
          onViewQuestionnaireClick={() => navigate(insertIdToRoute(ROUTES.application.FORM, id || ''))}
          monitoringSnapshots={monitoringScanpshots}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
          allAssets={allAssets}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
          allAccounts={allAccounts}
          selectedLimit={selectedLimit}
          setSelectedLimit={setSelectedLimit}
        />
      </div>
    </div>
  );
};
