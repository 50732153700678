import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  makeStyles,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ScannedSubdomainsModal } from '../ScannedSubdomainsModal';

export const Accordion = withStyles(() => ({
  root: {
    boxShadow: 'none',
    border: 0,
    borderRadius: `0 !important`,

    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.Mui-disabled': {
      backgroundColor: 'initial',
    },
  },
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: 0,
    alignItems: 'start',

    '&.Mui-expanded': {
      minHeight: 0,
    },
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
    margin: `${theme.spacing(0.25)}px 0 ${theme.spacing(1.5)}px`,

    '&.Mui-expanded': {
      margin: `${theme.spacing(0.25)}px 0 ${theme.spacing(1.5)}px`,
    },
  },
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: `0 0 ${theme.spacing(2)}px`,
    display: 'block',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  heading: {
    ...TYPOGRAPHY.SMALL_BOLD,
    display: 'flex',
    gap: theme.spacing(),
  },
  titleWrapper: {
    display: 'flex',
  },
  title: {
    ...TYPOGRAPHY.LARGE,
  },
  titleDescriptionWrapper: {
    marginLeft: theme.spacing(2.5),
  },
  description: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_1,
  },
  findings: () => ({
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_1,
    marginLeft: 'auto',

    '.Mui-disabled &': {
      paddingRight: theme.spacing(5),
    },
  }),

  accordionSummary: {},
  icon: () => ({
    fontSize: theme.spacing(3.5),
    color: COLORS.GREY_1,
    padding: 0,
  }),
}));

interface Props {
  title: string | React.ReactNode;
  numberOfFindings?: number;
  prevNumberOfFindings?: number;
  children: React.ReactNode;

  description?: string | React.ReactNode;
  onChange?: (event: any, expanded: boolean) => void;
  expanded?: boolean;
  grade?: React.ReactNode;
  isDefaultExpanded?: boolean;

  isLoading?: boolean;
  scannedSubdomains?: string[];
  allSubdomains?: string[];

  onClick: () => void;
}

export const ScanAccordion = ({
  title,
  numberOfFindings,
  prevNumberOfFindings,
  children,
  grade,
  description,
  isDefaultExpanded,
  isLoading,
  scannedSubdomains,
  allSubdomains,
  onClick,
}: Props) => {
  const classes = useStyles();

  const isSubDomainsLoading = allSubdomains && scannedSubdomains && scannedSubdomains.length < allSubdomains.length;

  return (
    <Accordion defaultExpanded={isDefaultExpanded} onClick={(e) => e.stopPropagation()}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreRoundedIcon className={classes.icon} />}
        IconButtonProps={{
          style: {
            padding: 0,
          },
        }}
        onClick={() => onClick()}
      >
        <div className={classes.heading}>
          <div className={classes.titleWrapper}>
            {grade}
            <div className={classes.titleDescriptionWrapper}>
              <div className={classes.title}>{title}</div>
              <div className={classes.description}>{description}</div>
            </div>
          </div>
          {isLoading || (allSubdomains && allSubdomains?.length > 0 && isSubDomainsLoading) ? (
            <ScannedSubdomainsModal scannedSubdomains={scannedSubdomains} allSubdomains={allSubdomains || []} />
          ) : (
            <span className={classes.findings} data-cy='security-findings-value'>
              {numberOfFindings !== prevNumberOfFindings && prevNumberOfFindings && prevNumberOfFindings > 0 ? (
                <Tooltip title={`Last week ${prevNumberOfFindings} finding(s)`}>
                  <span>{numberOfFindings} finding(s)</span>
                </Tooltip>
              ) : (
                <span>{numberOfFindings} finding(s)</span>
              )}
            </span>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
