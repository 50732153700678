import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { DeepScanTargetDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import { useRemoveDeepScanTarget } from 'queries/useDeepScan';
import React, { useCallback } from 'react';

interface Props {
  deepScanTarget: DeepScanTargetDTO;
  className?: string;
}

export const RemoveButton: React.FC<Props> = ({ deepScanTarget, className }) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useRemoveDeepScanTarget(deepScanTarget.deepScanId, deepScanTarget.id, {
    onSuccess: () => {
      setOpen(false);
      showToaster('Device removed successfully');
    },
    onError: (error) => {
      setOpen(false);
      toasterErrorHandler(error, 'Failed to remove device');
    },
  });

  const handleConfirmClick = useCallback(() => {
    mutate();
  }, [mutate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        color='default'
        className={className}
        onClick={handleClickOpen}
        startIcon={<DeleteRoundedIcon />}
      >
        Remove
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Remove Device</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will remove existing data and remove device &quot;{deepScanTarget.hostname}&quot;. Uninstall will
            happen the next time when device connects to Telivy servers. Please ensure you have latest report generated,
            if you want a snapshot of current data.
            <br />
            <br />
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmClick}
            variant='contained'
            color='secondary'
            disabled={isLoading}
            startIcon={<DeleteRoundedIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
