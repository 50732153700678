import qs from 'query-string';

import {
  SecurityFindingByDomainDTO,
  SecurityFindingBySlugDTO,
  SecurityFindingDTO,
  SecurityFindingsByDomainQueryDTO,
  SecurityFindingsBySlugQueryDTO,
  SecurityFindingsDetailsDTO,
  SecurityFindingSlug,
  SecurityFindingsQueryDTO,
  SecurityGradesDTO,
  SecurityGradesQueryDTO,
  UpdateSecurityFindingDTO,
} from '../dtos/security-finding';
import RequestService from './Request';

class _SecurityFindingService {
  getSecurityFindingsDetails() {
    return RequestService.client
      .get<{ [key in SecurityFindingSlug]: SecurityFindingsDetailsDTO }>('/security-finding/details')
      .then((response) => response.data);
  }

  getSecurityFindings(query: SecurityFindingsQueryDTO): Promise<SecurityFindingDTO[]> {
    const parsedQuery = qs.stringify(query);
    return RequestService.client
      .get<SecurityFindingDTO[]>(`/security-finding?${parsedQuery}`)
      .then((response) => response.data);
  }

  getSecurityGrades(query: SecurityGradesQueryDTO): Promise<SecurityGradesDTO> {
    const parsedQuery = qs.stringify(query);
    return RequestService.client
      .get<SecurityGradesDTO>(`/security-finding/grades?${parsedQuery}`)
      .then((response) => response.data);
  }

  getSecurityFindingsCountsBySlug(query: SecurityFindingsBySlugQueryDTO): Promise<SecurityFindingBySlugDTO[]> {
    const parsedQuery = qs.stringify(query);
    return RequestService.client
      .get<SecurityFindingBySlugDTO[]>(`/security-finding/counts-by-slug?${parsedQuery}`)
      .then((response) => response.data);
  }

  getSecurityFindingsCountsByDomain(query: SecurityFindingsByDomainQueryDTO): Promise<SecurityFindingByDomainDTO[]> {
    const parsedQuery = qs.stringify(query);
    return RequestService.client
      .get<SecurityFindingByDomainDTO[]>(`/security-finding/counts-by-domain?${parsedQuery}`)
      .then((response) => response.data);
  }

  updateSecurityFinding(id: string, data: UpdateSecurityFindingDTO): Promise<SecurityFindingDTO> {
    return RequestService.client
      .patch<SecurityFindingDTO>(`/security-finding/${id}`, data)
      .then((response) => response.data);
  }
}

const SecurityFindingService = new _SecurityFindingService();

export { SecurityFindingService };
