import { createTheme } from '@material-ui/core';

export const TYPOGRAPHY = {
  TITLE: {
    fontSize: 56,
    fontWeight: 700,
  },
  TITLE_2: {
    fontSize: 36,
    fontWeight: 700,
  },
  TITLE_3: {
    fontSize: 24,
    fontWeight: 700,
  },
  LARGE: {
    fontSize: 18,
    fontWeight: 700,
  },
  REGULAR_MEDIUM: {
    fontSize: 16,
    fontWeight: 500,
  },
  REGULAR_REGULAR: {
    fontSize: 16,
    fontWeight: 400,
  },
  REGULAR_BOLD: {
    fontSize: 16,
    fontWeight: 700,
  },
  SMALL_MEDIUM: {
    fontSize: 14,
    fontWeight: 500,
  },
  SMALL_REGULAR: {
    fontSize: 14,
    fontWeight: 400,
  },
  SMALL_BOLD: {
    fontSize: 14,
    fontWeight: 700,
  },
  MICRO_MEDIUM: {
    fontSize: 9,
    fontWeight: 500,
  },
  MICRO_REGULAR: {
    fontSize: 9,
    fontWeight: 400,
  },
  MICRO_BOLD: {
    fontSize: 9,
    fontWeight: 700,
  },
  EXTRA_SMALL_REGULAR: {
    fontSize: 12,
    fontWeight: 400,
  },
  EXTRA_SMALL_MEDIUM: {
    fontSize: 12,
    fontWeight: 500,
  },
  EXTRA_SMALL_BOLD: {
    fontSize: 12,
    fontWeight: 700,
  },

  // Mono Font
  MONO_TITLE3: {
    fontSize: 22,
    fontWeight: 700,
    fontFamily: 'Roboto Mono',
  },
  MONO_REGULAR_REGULAR: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
  },
  MONO_MEDIUM_REGULAR: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Roboto Mono',
  },
  MONO_BOLD_REGULAR: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto Mono',
  },
  MONO_REGULAR_SMALL: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
  },
  MONO_MEDIUM_SMALL: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Roboto Mono',
  },
  MONO_BOLD_SMALL: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Roboto Mono',
  },
  MONO_REGULAR_EXTRA_SMALL: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
  },
  MONO_MEDIUM_EXTRA_SMALL: {
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Roboto Mono',
  },
};

export const COLORS = {
  TEXT: '#232830',
  YELLOW_TEXT: '#DBB534',
  TEXT_DISABLED: '#686D76',

  GREY_1: '#232830',
  GREY_2: '#686D76',
  GREY_3: '#ACAEB1',
  GREY_4: '#D4D7D7',
  GREY_5: '#EDEDED',
  GREY_6: '#F7F7F7',
  WHITE: '#FFF',
  BLACK: '#000',
  BLUE_0: '#3644CA',
  BLUE_1: '#516AE6',
  BLUE_2: '#B9C3F5',
  BLUE_3: '#F6F5FA',
  BLUE_4: '#F9FBFC',
  PEACH_1: '#F68899',
  PEACH_2: '#FADBDD',
  PEACH_3: '#FBF4F5',
  GREEN_1: '#4EAE8B',
  GREEN_2: '#C2EDDB',
  GREEN_3: '#E9F9F2',
  RED_0: '#AD4A45',
  RED_1: '#ED6660',
  RED_2: '#F7BDC4',
  RED_3: '#FCE5E7',
  YELLOW_1: '#F2CB46',
  YELLOW_2: '#F7E092',
  YELLOW_3: '#FCF5DA',
  ORANGE_1: '#DF9C1B',
};

export const TELIVY_THEME = createTheme({
  typography: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fontFamily: ['Noto Sans JP'],
    // title
    h1: {
      fontSize: 56,
      fontWeight: 700,
    },
    // title2
    h2: {
      fontSize: 32,
      fontWeight: 700,
    },
    // title3
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    // large
    h4: {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
      elevation24: {
        boxShadow: '0px 2px 4px rgba(35, 40, 48, 0.16), 0px 0px 1px rgba(35, 40, 48, 0.5)',
      },
    },
    MuiDialogContent: {
      root: {
        ...TYPOGRAPHY.SMALL_REGULAR,
        padding: '24px !important',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px',
        paddingTop: 0,
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Noto Sans JP',
        fontSize: '14px !important',
        borderRadius: '8px !important',
        boxShadow: 'none !important',
        'text-transform': 'none !important',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: COLORS.WHITE,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        backgroundColor: COLORS.WHITE,
      },
      formControl: {
        zIndex: 1,
        padding: '0 8px',
        left: 10,
        pointerEvents: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          display: 'none',
        },
      },
      formControl: {
        backgroundColor: COLORS.WHITE,
        border: `1px solid ${COLORS.GREY_4}`,
        padding: '10px 14px !important',
        borderRadius: 2,
        marginTop: '8px !important', // ?
        minHeight: 54,
      },
    },
    MuiButton: {
      text: {
        paddingLeft: 12,
        paddingRight: 12,
      },
      root: {
        color: COLORS.GREY_1,
        border: `1px solid ${COLORS.GREY_5}`,
      },
      containedSecondary: {
        backgroundColor: COLORS.RED_1,
      },
      textPrimary: {
        ...TYPOGRAPHY.SMALL_MEDIUM,
        borderRadius: 8,
      },
      outlinedPrimary: {
        ...TYPOGRAPHY.SMALL_MEDIUM,
        borderRadius: 8,
      },
      containedPrimary: {
        ...TYPOGRAPHY.SMALL_MEDIUM,
        borderRadius: 8,
        backgroundColor: COLORS.BLUE_1,
      },
      outlined: {
        ...TYPOGRAPHY.SMALL_MEDIUM,
        borderRadius: 8,
      },
      contained: {
        backgroundColor: COLORS.GREY_6,
        border: 'none',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(226, 232, 240, 0.9)',
        mixBlendMode: 'normal',
        backdropFilter: 'blur(4px)',
      },
    },
  },
});
