import { Props as ListProps } from 'components/DetailsList';
import { Money } from 'components/Money';
import { QuoteDocumentButton } from 'components/QuoteDocumentButton';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { QuoteDTO } from 'dtos/quotes';
import startCase from 'lodash/startCase';
import { useQuoteDocuments } from 'queries/useQuote';
import React, { useMemo } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME, QUOTE_SUBLIMITS_MAP } from 'telivy-maps';

interface Options {
  extraCoverageRows?: ListProps['rows'];
  extraDatesRows?: ListProps['rows'];
}

export const useQuoteDetailsList = (quote: QuoteDTO, application: ApplicationDTO, options?: Options) => {
  const { data: documents, isLoading } = useQuoteDocuments(application.id, quote.id);

  const sublimits = useMemo(() => {
    if (!quote.sublimits) {
      return [];
    }

    return Object.keys(QUOTE_SUBLIMITS_MAP).map((k) => {
      const cov = quote.sublimits ? quote.sublimits[k as keyof typeof QUOTE_SUBLIMITS_MAP] : 0;

      return {
        title: QUOTE_SUBLIMITS_MAP[k as keyof typeof QUOTE_SUBLIMITS_MAP]?.title || 'Unknown',
        value: cov || typeof cov === 'number' ? <Money value={cov} /> : 'N/A',
      };
    });
  }, [quote]);

  const lists: ListProps[] = [
    {
      title: 'Details',
      rows: [
        {
          title: 'Carrier',
          value: quote.carrier.displayName,
        },
        {
          title: 'Product',
          value: startCase(quote.carrierProduct),
        },
        {
          title: 'Limit',
          value: <Money value={quote.aggregateLimit} />,
        },
        {
          title: 'Premium',
          value: <Money value={quote.premium} />,
        },
        {
          title: 'Annual Policy Cost',
          value: <Money value={quote.annualPolicyCost} />,
        },
        {
          title: 'Surplus Lines Cost',
          value: quote.surplusLinesCost || 'N/A',
        },
        {
          title: 'Retention',
          value: <Money value={quote.retention} />,
        },
        ...(options?.extraCoverageRows || []),
      ],
    },
    {
      title: 'Dates',
      rows: [
        {
          title: 'Start',
          value: dayjs(quote.effectiveDate).format('DD/MM/YYYY'),
        },
        {
          title: 'Expiration',
          value: dayjs(quote.expirationDate).format('DD/MM/YYYY'),
        },
        ...(options?.extraDatesRows || []),
      ],
    },
  ];

  if (documents && documents.length > 0) {
    const rows = documents.map((doc) => ({
      title: DOCUMENT_TYPE_HUMANIZED_NAME[doc.documentType],
      value: <QuoteDocumentButton document={doc} quoteId={quote.id} key={doc.id} />,
    }));

    lists.push({
      title: 'Documents',
      rows: isLoading ? [{ title: 'Loading...', value: '' }] : rows,
    });
  }

  if (quote.sublimits) {
    lists.push({
      title: 'Sublimits',
      rows: sublimits,
    });
  }

  return {
    lists,
    isLoadingDocuments: isLoading,
  };
};
