import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';

import {
  SecurityFindingByDomainDTO,
  SecurityFindingBySlugDTO,
  SecurityFindingDTO,
  SecurityFindingsByDomainQueryDTO,
  SecurityFindingsDetailsDTO,
  SecurityFindingSlug,
  SecurityFindingsQueryDTO,
  SecurityGradesDTO,
  SecurityGradesQueryDTO,
  UpdateSecurityFindingDTO,
} from '../dtos/security-finding';
import { SecurityFindingService } from '../services/SecurityFinding';

export const useSecurityFindingsDetails = (
  options?: UseQueryOptions<{ [key in SecurityFindingSlug]: SecurityFindingsDetailsDTO }, AxiosError>,
) =>
  useQuery<{ [key in SecurityFindingSlug]: SecurityFindingsDetailsDTO }, AxiosError>(
    ['findings-details'],
    () => SecurityFindingService.getSecurityFindingsDetails(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
    },
  );

export const useSecurityFindings = (
  query: SecurityFindingsQueryDTO,
  options?: UseQueryOptions<SecurityFindingDTO[], AxiosError>,
) =>
  useQuery<SecurityFindingDTO[], AxiosError>(
    ['findings', query],
    () => SecurityFindingService.getSecurityFindings(query),
    options,
  );

export const useSecurityGrades = (
  query: SecurityGradesQueryDTO,
  options?: UseQueryOptions<SecurityGradesDTO, AxiosError>,
) =>
  useQuery<SecurityGradesDTO, AxiosError>(
    ['grades', query],
    () => SecurityFindingService.getSecurityGrades(query),
    options,
  );

export const useSecurityFindingsBySlug = (
  query: SecurityFindingsQueryDTO,
  options?: UseQueryOptions<SecurityFindingBySlugDTO[], AxiosError>,
) =>
  useQuery<SecurityFindingBySlugDTO[], AxiosError>(
    ['findings-by-slug', query],
    () => SecurityFindingService.getSecurityFindingsCountsBySlug(query),
    options,
  );

export const useSecurityFindingsByDomain = (
  query: SecurityFindingsByDomainQueryDTO,
  options?: UseQueryOptions<SecurityFindingByDomainDTO[], AxiosError>,
) =>
  useQuery<SecurityFindingByDomainDTO[], AxiosError>(
    ['findings-by-domain', query],
    () => SecurityFindingService.getSecurityFindingsCountsByDomain(query),
    options,
  );

export const useUpdateSecurityFinding = (
  options?: UseMutationOptions<SecurityFindingDTO, AxiosError, UpdateSecurityFindingDTO & { id: string }>,
) => {
  const queryClient = useQueryClient();
  return useMutation<SecurityFindingDTO, AxiosError, UpdateSecurityFindingDTO & { id: string }>(
    'update-finding',
    ({ id, ...data }: UpdateSecurityFindingDTO & { id: string }) =>
      SecurityFindingService.updateSecurityFinding(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(['findings']);
        await options?.onSuccess?.(data, variables, context);
      },
    },
  );
};
