import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { LinkButton } from 'components/LinkButton';
import { ApplicationDTO } from 'dtos/application';
import { ProposalDTO } from 'dtos/proposal';
import { useDownloadProposalDocx } from 'queries/useApplications';
import React, { CSSProperties, useCallback, useState } from 'react';

interface Props {
  proposal: ProposalDTO;
  application: ApplicationDTO;
  style?: CSSProperties;
}

export const DocxButton = ({ proposal, application, style }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const businessName = application.applicationResponse.organization_name;
  const { mutate } = useDownloadProposalDocx(proposal.applicationId, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClick = useCallback(() => {
    mutate({ proposal, businessName });
    setIsModalOpen(true);
  }, [proposal, mutate, businessName]);

  return (
    <>
      <LinkButton onClick={handleClick} style={style}>
        Download DOCX
      </LinkButton>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Downloading proposal DOCX</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};
