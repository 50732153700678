import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from 'components/CustomTextField';
import { Modal } from 'components/Modal';
import { RadioSelect } from 'components/RadioSelect';
import { AgentDTO, AgentRole, CreateEditAgentInAgencyDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useInviteEditUser } from 'queries/useAgency';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    display: 'block',
    minWidth: 200,
    marginLeft: 'auto',
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '12px',
      color: '#232830',
      fontWeight: 500,
    },
  },
  edit: {
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
  invite: {},
}));

interface Props {
  agent?: AgentDTO;
}

export const InviteEditAgent = ({ agent }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useInviteEditUser({
    onSuccess: () => {
      showToaster(`Successfully ${agent ? 'updated' : 'created'} user`);
      setOpen(false);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const { handleSubmit, control } = useForm<CreateEditAgentInAgencyDTO>({
    defaultValues: {
      firstName: agent?.firstName || '',
      lastName: agent?.lastName || '',
      email: agent?.email || '',
      password: '',
      role: agent?.role || AgentRole.REGULAR,
    },
  });

  const onSubmit = (data: CreateEditAgentInAgencyDTO) => {
    mutate({
      firstName: data.firstName,
      lastName: data.lastName,
      email: agent?.email || data.email,
      password: data.password,
      role: data.role,
    });
  };

  return (
    <>
      <Modal
        title={agent ? 'Edit User' : 'Add User'}
        isOpen={open}
        onClose={() => setOpen(false)}
        openButton={
          <Button
            variant={agent ? 'outlined' : 'contained'}
            color={agent ? 'default' : 'primary'}
            onClick={() => setOpen(true)}
            className={agent ? classes.edit : classes.invite}
          >
            {agent ? 'Edit' : 'Add User'}
          </Button>
        }
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='firstName'
              control={control}
              defaultValue=''
              render={({ field }) => <CustomTextField {...field} label='First Name' required />}
            />
            <Controller
              name='lastName'
              control={control}
              defaultValue=''
              render={({ field }) => <CustomTextField {...field} label='Last Name' required />}
            />
            <Controller
              name='email'
              control={control}
              defaultValue=''
              disabled={!!agent}
              render={({ field }) => <CustomTextField {...field} label='Email' required />}
            />
            {!agent && (
              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({ field }) => <CustomTextField {...field} label='Password' type='password' required />}
              />
            )}
            <Controller
              name='role'
              control={control}
              defaultValue={AgentRole.REGULAR}
              render={({ field }) => (
                <div className={classes.fieldRow}>
                  <p>Role *</p>
                  <RadioSelect<AgentRole>
                    className={classes.radioSelect}
                    selectedValue={field.value}
                    onOptionSelect={(opt) => field.onChange(opt.value)}
                    selectedFn={(optionValue, selectedValue) => optionValue === selectedValue}
                    options={[
                      { label: 'Own Assessments', value: AgentRole.REGULAR },
                      { label: 'All Assessments', value: AgentRole.AGENCY_ADMIN },
                    ]}
                  />
                </div>
              )}
            />
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} className={classes.button}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
