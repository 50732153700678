import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.BLUE_3,
    border: `1px solid ${COLORS.BLUE_1}`,
    color: COLORS.BLUE_1,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
  },
  content: {
    marginLeft: ({ icon }) => (icon ? theme.spacing(1) : 0),
  },
}));

export enum Type {
  Info = 'info',
}

interface Props {
  type: Type;
  className?: string;
  icon?: JSX.Element;
}

export const Message: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={cx(classes.root, props.className)}>
      {props.icon}
      <span className={classes.content}>{props.children}</span>
    </div>
  );
};
