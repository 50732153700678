import { Button, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import { useToasters } from 'hooks/useToasters';
import { useDownloadApplication } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';

interface Props {
  applicationId: string;
  className?: string;

  handleSave: () => void;
  shouldSave?: boolean;
  isFormValid?: boolean;
}

export const ExportApplicationButton: React.FC<Props> = ({
  applicationId,
  handleSave,
  shouldSave,
  isFormValid,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toasterErrorHandler } = useToasters();
  const { mutate: downloadPdf } = useDownloadApplication(applicationId, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleDownload = useCallback(() => {
    if (!isFormValid) {
      toasterErrorHandler('Validation error', 'All required fields must be filled out');
    } else {
      if (shouldSave) handleSave();
      setIsModalOpen(true);
      downloadPdf();
    }
  }, [downloadPdf, handleSave, isFormValid, shouldSave, toasterErrorHandler]);

  return (
    <>
      <Button className={className} onClick={handleDownload}>
        <CloudDownloadRoundedIcon />
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Generating PDF</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};
