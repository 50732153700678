import {
  Button,
  ClickAwayListener,
  FormControlLabel,
  FormLabel,
  Grow,
  Paper,
  Popper,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { ApplicationStatusLabel } from 'components/ApplicationStatusLabel';
import { ApplicationDTO, ApplicationStatus, InsuranceApplicationStatus, ScanApplicationStatus } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useChangeApplicationStatus } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { isScanApplication, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  label: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    paddingTop: theme.spacing(2.25),
    paddingLeft: theme.spacing(2.5),
  },
  options: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
  },
  saveButton: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
  },
}));

interface Props {
  isOpen: boolean;
  application: ApplicationDTO;
  onClose: () => void;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  isAdmin?: boolean;
}

export const ChangeStatusModal = ({ application, isOpen, onClose, buttonRef, isAdmin }: Props) => {
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const [selectedStatus, setSelectedStatus] = useState(application.applicationStatus);
  const { mutate, isLoading } = useChangeApplicationStatus(application.id, {
    onSuccess: () => {
      showToaster(`${isSecurityApplication(application) ? 'Assessment' : 'Application'} status updated`);
      onClose();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handleSubmit = useCallback(() => mutate(selectedStatus), [mutate, selectedStatus]);
  const handleClose = () => {
    setSelectedStatus(application.applicationStatus);
    onClose();
  };

  return (
    <Popper
      open={isOpen}
      anchorEl={buttonRef?.current}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 2 }}
      placement='bottom-start'
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            marginTop: 8,
            boxShadow: '0px 2px 4px rgb(35 40 48 / 16%), 0px 0px 1px rgb(35 40 48 / 50%)',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <FormControl>
                <FormLabel id='application-status-radio-group-label' className={classes.label}>
                  Edit Status
                </FormLabel>
                <RadioGroup
                  aria-labelledby='application-status-radio-group-label'
                  value={selectedStatus}
                  name='application-status-radio-group'
                  className={classes.options}
                  onChange={(e) => setSelectedStatus(e.currentTarget?.value as ApplicationStatus)}
                >
                  {Object.values(
                    isScanApplication(application) ? ScanApplicationStatus : InsuranceApplicationStatus,
                  ).map((status) => (
                    <FormControlLabel
                      key={status}
                      value={status}
                      control={<Radio color='primary' />}
                      label={<ApplicationStatusLabel status={status} isAdmin={isAdmin} />}
                    />
                  ))}
                </RadioGroup>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!!isLoading}
                  className={classes.saveButton}
                  onClick={handleSubmit}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </FormControl>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
