import { FormControlLabel, FormGroup, Switch as SwitchComponent } from '@material-ui/core';
import { useUpdateApplicationSettings } from 'queries/useApplications';
import React, { useCallback } from 'react';

interface Props {
  applicationId: string;
  enableDarkWebFullPassword: boolean;
  className?: string;
}

export const DarkWebFullPasswordSwitch: React.FC<Props> = ({ applicationId, enableDarkWebFullPassword }) => {
  const { mutate } = useUpdateApplicationSettings(applicationId);

  const updateEnableDarkWebFullPassword = useCallback(
    (enableDarkWebFullPassword) => {
      mutate({ enableDarkWebFullPassword });
    },
    [mutate],
  );

  const handleChange = () => updateEnableDarkWebFullPassword(!enableDarkWebFullPassword);

  return (
    <FormGroup>
      <FormControlLabel
        control={<SwitchComponent checked={enableDarkWebFullPassword} onChange={handleChange} color='primary' />}
        label='Unmask Passwords' /*{enableDarkWebFullPassword ? 'Showing Full Passwords' : 'Show Full Passwords'}*/
      />
    </FormGroup>
  );
};
