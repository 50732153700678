import { RiskAnalysisScore } from 'dtos/deep-scan';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import { CartesianGrid, Cell, Scatter, ScatterChart, Tooltip, XAxis, XAxisProps, YAxis, YAxisProps } from 'recharts';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

import { formatMillionsValue } from '../Money';
import { ChartContainer, Props as ChartContainerProps } from './ChartContainer';

const COLORS = [
  '#AECCE5',
  '#FFBD80',
  '#8FE598',
  '#D9E781',
  '#8CE7ED',
  '#FB8C8C',
  '#CDCDCD',
  '#F791F9',
  '#E98DE0',
  '#516AE6',
];

const STROKES = [
  '#5E99CC',
  '#FF7A00',
  '#1FCC31',
  '#B4D003',
  '#1ACFDB',
  '#F81919',
  '#9B9B9B',
  '#F024F4',
  '#D41CC2',
  '#516AE6',
];

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={3} textAnchor='end' fill='#666' style={{ fontSize: 10 }}>
        {capitalize(payload.value) || 'N/A'}
      </text>
    </g>
  );
};

const formatter: Formatter<string, string> = (value, name, props) => {
  if (name === 'risk') {
    return [capitalize(value), 'Risk', props];
  }

  if (name === 'dataRisk') {
    return [`$${value.toLocaleString()}`, 'Data Cost', props];
  }

  return [value, name, props];
};

export interface RiskScatterDataPoint {
  name: string;
  risk: RiskAnalysisScore | null;
  dataRisk: number;
}

export interface Props extends ChartContainerProps {
  data: RiskScatterDataPoint[];
}

export const RiskScatter = (props: Props) => {
  const xAxisProps: XAxisProps = {
    fontSize: 10,
    type: 'number',
    dataKey: 'dataRisk',
    allowDecimals: false,
    orientation: 'bottom',
    tickFormatter: (tick: number) => {
      const val = tick / 1000;
      let formatted = tick.toLocaleString();

      if (tick >= 1000) {
        formatted = `${parseFloat(val.toFixed(1))}k`;
      }

      if (tick >= 1_000_000) {
        formatted = formatMillionsValue(tick);
      }

      return `$${formatted}`;
    },
  };

  const yAxisProps: YAxisProps = {
    fontSize: 10,
    dataKey: 'risk',
    type: 'category',
    interval: 0,
    allowDuplicatedCategory: false,
    orientation: 'left',
    allowDecimals: false,
    tick: CustomizedAxisTick,
  };

  const dataWithDefaults = useMemo(() => {
    return [
      // Default data points to always show the all risk levels
      {
        risk: 'low',
        name: 'default-3',
      },
      {
        risk: 'medium',
        name: 'default-2',
      },
      {
        risk: 'high',
        name: 'default-1',
      },
      ...props.data,
    ];
  }, [props.data]);

  return (
    <ChartContainer {...props}>
      <ScatterChart
        width={500}
        height={220}
        margin={{
          top: 20,
          right: 0,
          bottom: 0,
          left: -14,
        }}
      >
        <CartesianGrid />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} formatter={formatter} />
        <Scatter data={dataWithDefaults} fill={'#8884d8'} isAnimationActive={false} name='chart'>
          {dataWithDefaults.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                stroke={STROKES[index % STROKES.length]}
                strokeWidth={1}
              />
            );
          })}
        </Scatter>
      </ScatterChart>
    </ChartContainer>
  );
};
