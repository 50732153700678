import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS } from 'telivy-theme';
import { OverallScoreBadge } from 'templates/SecurityAssessments/components/OverallScoreBadge';

import { Company } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(),
  },
  wrapper: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${COLORS.GREY_5}`,
    },
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    padding: `${theme.spacing(1)}px 0`,
  },

  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    justifyContent: 'space-between',
  },
  findings: {
    color: COLORS.GREY_3,
  },
  findingsActive: {
    color: COLORS.BLUE_1,
    cursor: 'pointer',
  },
  overallScoreBadge: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  companies: Company[];
  overallScoresByDomain: Record<string, number>;
  className?: string;
  onClick: (company: Company) => void;
}

export const CompanyFindings = ({ companies, overallScoresByDomain, className, onClick }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {(companies || []).map((company) => (
        <div className={classes.wrapper} key={company.name}>
          <OverallScoreBadge
            variant='small'
            securityOverallScore={overallScoresByDomain[company.name]}
            className={classes.overallScoreBadge}
          />
          <div className={classes.content}>
            {company.name}{' '}
            <span
              className={cx(classes.findings, company.numberOfFindings > 0 && classes.findingsActive)}
              onClick={company.numberOfFindings > 0 ? () => onClick(company) : undefined}
              role='button'
              tabIndex={0}
            >
              ({company.numberOfFindings} {company.numberOfFindings === 1 ? 'finding' : 'findings'})
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
