import { TextField, withStyles } from '@material-ui/core';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

export const CustomTextField = withStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiInputLabel': {
      '&-root': {
        ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(0.5),
        padding: 0,
        position: 'static',
        transform: 'none',

        '&:not(.Mui-error):not(.Mui-focused)': {
          color: COLORS.GREY_1,
        },
      },
      '&-animated': {
        transition: 'none',
      },
      '&-shrink': {
        transform: 'none',
      },
    },

    '& .MuiInput': {
      '&-input::placeholder': {
        color: COLORS.GREY_3,
      },
      '&-underline': {
        '&:after': {
          display: 'none',
        },
      },
      '&-formControl': {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px !important`,
        borderRadius: theme.spacing(),
        marginTop: `${theme.spacing(0.5)}px !important`,
        minHeight: 'auto',

        '&.Mui-focused': {
          border: `1px solid ${COLORS.BLUE_1}`,
        },
        '&.Mui-error': {
          border: `1px solid ${COLORS.RED_1}`,
        },
      },
    },
  },
}))(TextField);
