import { makeStyles } from '@material-ui/core/styles';
import { Props as ListProps } from 'components/DetailsList';
import { Money } from 'components/Money';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { DeclinationDTO } from 'dtos/declinations';
import { useDeclinationDocuments } from 'queries/useDeclinations';
import React from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { Sidebar } from '../Sidebar';
import { DeclinationButton } from './DeclinationButton';
import { DeclinationsDocumentButton } from './DeclinationsDocumentButton';

const useStyles = makeStyles((theme) => ({
  editButton: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    width: 170,
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    margin: 0,
    color: COLORS.BLUE_1,
  },
  documentButton: {
    marginTop: theme.spacing(0.5),
  },
}));

interface Props {
  declination: DeclinationDTO;
  application: ApplicationDTO;
  showEditDeclination: boolean;
  onDeclinationUpdate: (data: DeclinationDTO) => void;
}

export const SelectedDeclination = ({ declination, application, showEditDeclination, onDeclinationUpdate }: Props) => {
  const classes = useStyles();
  const { data: documents } = useDeclinationDocuments(application.id, declination.id);

  const header = (
    <>
      <div>{declination.carrier.displayName}</div>
      {showEditDeclination && (
        <DeclinationButton
          className={classes.editButton}
          application={application}
          existingDeclination={declination}
          onDeclinationUpdate={onDeclinationUpdate}
        />
      )}
    </>
  );

  const lists: ListProps[] = [
    {
      title: 'Info',
      rows: [
        {
          title: 'Reason',
          value: declination.reason,
        },
      ],
    },
    {
      title: 'Details',
      rows: [
        {
          title: 'Requested Limit',
          value: <Money value={declination.requestedAggregateLimit} />,
        },
        {
          title: 'Requested Retention',
          value: <Money value={declination.requestedRetention} />,
        },
      ],
    },
    {
      title: 'Dates',
      rows: [
        {
          title: 'Requested Start',
          value: dayjs(declination.requestedEffectiveDate).format('DD/MM/YYYY'),
        },
      ],
    },
  ];

  if (documents && documents.length > 0) {
    lists.push({
      title: 'Documents',
      rows: documents.map((doc) => ({
        title: DOCUMENT_TYPE_HUMANIZED_NAME[doc.documentType],
        value: (
          <DeclinationsDocumentButton
            className={classes.documentButton}
            document={doc}
            quoteId={declination.id}
            key={doc.id}
          />
        ),
      })),
    });
  }

  return <Sidebar header={header} lists={lists} />;
};
