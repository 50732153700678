import { LinearProgress, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(1.25),
    width: 175,
    // https://github.com/mui/material-ui/issues/23204
    zIndex: 0,
  },
  colorPrimary: {
    backgroundColor: COLORS.GREY_5,
  },
  bar: {
    borderRadius: theme.spacing(1.25),
    backgroundColor: COLORS.GREEN_1,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progressTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
    marginBottom: theme.spacing(1.75),
    display: 'block',
    marginTop: 0,
    lineHeight: `${theme.spacing(1.75)}px`,
  },
}));

interface Props {
  label: string;
  progress?: number;
}

export const ProgressBarWithTitle = ({ label, progress }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.progressWrapper}>
      <span className={classes.progressTitle}>{label}</span>
      <CustomLinearProgress variant='determinate' value={progress || 100} />
    </div>
  );
};
