import { makeStyles, useTheme } from '@material-ui/core';
import cx from 'classnames';
import { formatNumberWithCommas, Money } from 'components/Money';
import { ApplicationWithAgentDTO, SecurityScanDTO } from 'dtos/application';
import { SecurityScanType } from 'dtos/security-finding';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanGrades } from 'hooks/external-scan/useExternalScanGrades';
import { ReactComponent as SaluswallLogo } from 'images/saluswall-logo.svg';
import moment from 'moment';
import React, { LegacyRef } from 'react';
import { Helmet } from 'react-helmet';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { AssessmentData } from '../components/AssessmentData';
import { DomainInfo } from '../components/DomainInfo';
import { ExternalScanItem, Props as ExternalScanItemProps } from '../components/ExternalScanItem';
import { SecurityCard } from '../components/SecurityCard';
import { VerticalStepper } from '../components/VerticalStepper';
import { PrintFinding } from '../print/PrintFinding';
import { PrintFindingCategory } from '../print/PrintFindingCategory';
import { SCAN_INFO, useStyles as useStylesExternalScan } from './SecurityExternalScan';
import { useStyles as useStylesOverview } from './SecurityOverview';

const PAGE_MARGIN = 0;

const getPageMargins = () => {
  return `@page { margin: ${PAGE_MARGIN}mm 0 0 !important; }
  @page:first { margin: 0 !important; }`;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    fontFamily: '"Sailec", "Noto Sans JP"',

    '& h1': {
      fontSize: theme.spacing(6.5),
      lineHeight: 1.2,
      margin: `${theme.spacing(6)}px 0 ${theme.spacing(4)}px`,
    },
    '& h2': {
      ...TYPOGRAPHY.TITLE_3,
      margin: `${theme.spacing(5)}px 0 ${theme.spacing(3)}px`,
    },
    '& h3': {
      ...TYPOGRAPHY.LARGE,
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    },
    '& h4': {
      ...TYPOGRAPHY.REGULAR_BOLD,
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
    },
    '& h5': {
      ...TYPOGRAPHY.REGULAR_REGULAR,
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    },
    // '& h6': {},
  },
  firstPage: {
    margin: -theme.spacing(2),
    pageBreakAfter: 'always',
    breakAfter: 'page',
  },
  firstPageTop: {
    height: '40vh',
    background: COLORS.BLUE_1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
  },
  firstPageTopContent: {
    width: '75%',
    color: COLORS.WHITE,
  },
  firstPageBottom: {
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  firstPageBottomContent: {
    width: '75%',

    '& > p': {
      fontWeight: 500,
    },
  },
  saluswallLogo: {
    width: theme.spacing(20),
  },

  contentWrapper: {
    width: '75%',
    margin: '0 auto',
  },

  assessmentWrapper: {
    '& h3': {
      marginTop: theme.spacing(8),
    },
    '& h4': {
      marginTop: theme.spacing(4),
    },
    '& h3 + h4, & h2 + h3': {
      marginTop: 0,
    },
  },

  lastScanned: {
    fontWeight: 500,
  },

  findingsWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),

    '&:not(:first-of-type)': {
      pageBreakBefore: 'always',
      breakBefore: 'page',
    },
  },

  // reusable
  pageBreakBefore: {
    pageBreakBefore: 'always',
    breakBefore: 'page',
  },

  avoidPageBreak: {
    pageBreakInside: 'avoid',
    display: 'block',
  },
}));

interface Props {
  application?: ApplicationWithAgentDTO;
  applicationId: string;
  securityScan: SecurityScanDTO;
  isNotSubmitted: boolean;
}

export const SecurityPrintView = React.forwardRef(({ application, securityScan, isNotSubmitted }: Props, ref) => {
  const theme = useTheme();
  const classes = useStyles();

  // Overview
  const classesApplicationDetailsOverview = useStylesOverview();

  const dataBreachCost = securityScan.securityStats.dataBreachItems.reduce((prev, curr) => prev + curr.cost, 0);

  const dataBreachItems = securityScan.securityStats.dataBreachItems.map((item) => ({
    title: (
      <span className={classesApplicationDetailsOverview.stepperItem}>
        ${formatNumberWithCommas(Math.trunc(item.cost))}
      </span>
    ),
    description: item.name,
  }));

  const handleGoToForm = () => null;

  // External Scan
  const classesApplicationDetailsExternalScan = useStylesExternalScan({ tile: false });

  const handleClick = () => null;

  const getFindingUrl = () => '';

  const {
    isErrorScanEmail,
    isErrorPatchingCadence,
    isErrorScanDns,
    isErrorScanNetwork,
    isErrorScanIpReputation,
    findingsByType,
  } = useExternalScanFindings({ securityScan });

  const { getGrateByType } = useExternalScanGrades({ securityScan });

  const commonProps: Pick<ExternalScanItemProps, 'tile' | 'onClick' | 'getFindingUrl' | 'isDefaultExpanded'> = {
    tile: true,
    onClick: handleClick,
    getFindingUrl,
    isDefaultExpanded: true,
  };

  const findingsInCategory = (type: SecurityScanType) => {
    return findingsByType?.[type].filter((f) => f.count > 0) || [];
  };

  return (
    <>
      <Helmet>
        {/* workaround to set page margins, as @page rule doesn't exist in jsx */}
        <style>{getPageMargins()}</style>
      </Helmet>
      <div ref={ref as LegacyRef<HTMLDivElement>} className={classes.root}>
        <div className={classes.firstPage}>
          <div className={classes.firstPageTop}>
            <div className={classes.firstPageTopContent}>
              <h1>Assessment & Analysis Report</h1>
              <h2>{application?.applicationResponse?.organization_name}</h2>
              <p>{moment().format('DD MMMM YYYY')}</p>
            </div>
          </div>
          <div className={classes.firstPageBottom}>
            <div className={classes.firstPageBottomContent}>
              <p>presented by</p>
              <SaluswallLogo className={classes.saluswallLogo} />
            </div>
          </div>
        </div>

        <div className={classes.contentWrapper}>
          {/* Assesment */}
          {application && (
            <div className={cx(classes.pageBreakBefore, classes.assessmentWrapper)}>
              <AssessmentData application={application} />
            </div>
          )}

          {/* Overview */}
          <div className={classes.pageBreakBefore}>
            <h2>Financial Risk:</h2>

            <div className={classesApplicationDetailsOverview.root} style={{ marginTop: theme.spacing(2.5) }}>
              <SecurityCard.Container>
                <SecurityCard.Header title='Costs' />
                <SecurityCard.Content
                  gap={theme.spacing(2.5)}
                  isNotSubmitted={isNotSubmitted}
                  handleGoToForm={handleGoToForm}
                  showFinancialCalculator
                >
                  <div>
                    <Money
                      value={
                        securityScan.securityStats.ransomwareCost
                          ? Math.trunc(securityScan.securityStats.ransomwareCost)
                          : 0
                      }
                      typography={TYPOGRAPHY.LARGE}
                      className={classesApplicationDetailsOverview.redText}
                    />

                    <p className={classesApplicationDetailsOverview.greyText}>
                      <b>Ransomware Cost: </b> <b>70%</b> of all cyber attacks are ransomware.{' '}
                    </p>

                    <p className={classesApplicationDetailsOverview.greyText}>
                      <a
                        href='https://telivy.notion.site/Ransomware-05292a9956234d1d82433caf0071d36b'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Learn more
                      </a>
                    </p>
                  </div>

                  <div>
                    <Money
                      value={
                        securityScan.securityStats.businessInterruptionCost
                          ? Math.trunc(securityScan.securityStats.businessInterruptionCost)
                          : 0
                      }
                      typography={TYPOGRAPHY.LARGE}
                      className={classesApplicationDetailsOverview.redText}
                    />

                    <p className={classesApplicationDetailsOverview.greyText}>
                      <b>Interruption Cost: </b>Lost business represented <b>38%</b> of the overall loss.{' '}
                    </p>

                    <p className={classesApplicationDetailsOverview.greyText}>
                      <a
                        href='https://telivy.notion.site/Business-Interruption-bb93e193f2794867847edbfd2ffc8415'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Learn more
                      </a>
                    </p>
                  </div>

                  <div>
                    <Money
                      value={dataBreachCost}
                      typography={TYPOGRAPHY.LARGE}
                      className={classesApplicationDetailsOverview.redText}
                    />

                    <div>
                      <p className={classesApplicationDetailsOverview.greyText}>
                        <b>Data Breach Cost: </b>
                        <b>80%</b> of the breaches included records containing customer PII, at an average cost of{' '}
                        <b>$150</b> per record
                      </p>

                      <p className={classesApplicationDetailsOverview.greyText}>
                        <a
                          href='https://telivy.notion.site/Data-Breach-891d5dec117546f7b034a313cd494d04'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Learn more
                        </a>
                      </p>

                      <br />

                      <div>
                        <VerticalStepper
                          mainValue={
                            <Money
                              value={dataBreachCost}
                              typography={TYPOGRAPHY.TITLE_3}
                              className={classesApplicationDetailsOverview.redText}
                            />
                          }
                          items={dataBreachItems}
                        />
                      </div>
                    </div>
                  </div>
                </SecurityCard.Content>
              </SecurityCard.Container>
            </div>
          </div>

          {/* ExternalScan */}
          <div className={classes.pageBreakBefore}>
            <h2>External Scan Results:</h2>
            <h4>
              Last scanned <DomainInfo securityScan={securityScan} />{' '}
              <span className={classes.lastScanned}>
                : {moment(securityScan.updatedAt).format('Do MMMM YYYY hh:mm:ss A')}
              </span>
            </h4>

            <div className={classesApplicationDetailsExternalScan.root} style={{ marginTop: theme.spacing(2.5) }}>
              {!isErrorScanEmail && (
                <ExternalScanItem
                  {...commonProps}
                  grade={getGrateByType(SecurityScanType.SOCIAL_ENGINEERING)}
                  findings={findingsByType?.[SecurityScanType.SOCIAL_ENGINEERING]}
                  isError={isErrorScanEmail}
                  scanInfo={SCAN_INFO.socialEngineering}
                />
              )}

              {!isErrorScanNetwork && (
                <ExternalScanItem
                  {...commonProps}
                  grade={getGrateByType(SecurityScanType.NETWORK_SECURITY)}
                  findings={findingsByType?.[SecurityScanType.NETWORK_SECURITY]}
                  isError={isErrorScanNetwork}
                  scanInfo={SCAN_INFO.networkScan}
                />
              )}

              {!isErrorScanNetwork && (
                <ExternalScanItem
                  {...commonProps}
                  grade={getGrateByType(SecurityScanType.APPLICATION_SECURITY)}
                  findings={findingsByType?.[SecurityScanType.APPLICATION_SECURITY]}
                  isError={isErrorScanNetwork}
                  scanInfo={SCAN_INFO.applicationSecurity}
                />
              )}

              {!isErrorScanDns && (
                <ExternalScanItem
                  {...commonProps}
                  grade={getGrateByType(SecurityScanType.DNS_HEALTH)}
                  findings={findingsByType?.[SecurityScanType.DNS_HEALTH]}
                  isError={isErrorScanDns}
                  scanInfo={SCAN_INFO.dnsHealth}
                />
              )}

              {!isErrorScanIpReputation && (
                <ExternalScanItem
                  {...commonProps}
                  grade={getGrateByType(SecurityScanType.IP_REPUTATION)}
                  findings={findingsByType?.[SecurityScanType.IP_REPUTATION]}
                  isError={isErrorScanIpReputation}
                  scanInfo={SCAN_INFO.ipReputation}
                  noBottomBorder={false}
                />
              )}

              {!isErrorPatchingCadence && (
                <ExternalScanItem
                  {...commonProps}
                  grade={getGrateByType(SecurityScanType.PATCHING_CADENCE)}
                  findings={findingsByType?.[SecurityScanType.PATCHING_CADENCE]}
                  isError={isErrorPatchingCadence}
                  scanInfo={SCAN_INFO.patchingCadence}
                  noBottomBorder={true}
                />
              )}
            </div>

            {/* Findings */}
            <div className={classes.pageBreakBefore}>
              <h2>External Scan Findings:</h2>
              <h4>
                Last scanned <DomainInfo securityScan={securityScan} />
                <span className={classes.lastScanned}>
                  : {moment(securityScan.updatedAt).format('Do MMMM YYYY hh:mm:ss A')}
                </span>
              </h4>
              {!isErrorScanEmail && findingsInCategory(SecurityScanType.SOCIAL_ENGINEERING).length > 0 && (
                <div className={classes.findingsWrapper}>
                  <PrintFindingCategory
                    grade={getGrateByType(SecurityScanType.SOCIAL_ENGINEERING)}
                    findings={findingsByType?.[SecurityScanType.SOCIAL_ENGINEERING]}
                    isError={isErrorScanEmail}
                    scanInfo={SCAN_INFO.socialEngineering}
                  />
                  {findingsByType?.[SecurityScanType.SOCIAL_ENGINEERING]
                    .filter((f) => f.count > 0)
                    .map((finding) => (
                      <PrintFinding securityScan={securityScan} finding={finding} key={finding.slug} />
                    ))}
                </div>
              )}
              {!isErrorScanNetwork && findingsInCategory(SecurityScanType.NETWORK_SECURITY).length > 0 && (
                <div className={classes.findingsWrapper}>
                  <PrintFindingCategory
                    grade={getGrateByType(SecurityScanType.NETWORK_SECURITY)}
                    findings={findingsByType?.[SecurityScanType.NETWORK_SECURITY]}
                    isError={isErrorScanNetwork}
                    scanInfo={SCAN_INFO.networkScan}
                  />
                  {findingsByType?.[SecurityScanType.NETWORK_SECURITY]
                    .filter((f) => f.count > 0)
                    .map((finding) => (
                      <PrintFinding securityScan={securityScan} finding={finding} key={finding.slug} />
                    ))}
                </div>
              )}
              {!isErrorScanNetwork && findingsInCategory(SecurityScanType.APPLICATION_SECURITY).length > 0 && (
                <div className={classes.findingsWrapper}>
                  <PrintFindingCategory
                    grade={getGrateByType(SecurityScanType.APPLICATION_SECURITY)}
                    findings={findingsByType?.[SecurityScanType.APPLICATION_SECURITY]}
                    isError={isErrorScanNetwork}
                    scanInfo={SCAN_INFO.applicationSecurity}
                  />
                  {findingsByType?.[SecurityScanType.APPLICATION_SECURITY]
                    .filter((f) => f.count > 0)
                    .map((finding) => (
                      <PrintFinding securityScan={securityScan} finding={finding} key={finding.slug} />
                    ))}
                </div>
              )}
              {!isErrorScanDns && findingsInCategory(SecurityScanType.DNS_HEALTH).length > 0 && (
                <div className={classes.findingsWrapper}>
                  <PrintFindingCategory
                    grade={getGrateByType(SecurityScanType.DNS_HEALTH)}
                    findings={findingsByType?.[SecurityScanType.DNS_HEALTH]}
                    isError={isErrorScanDns}
                    scanInfo={SCAN_INFO.dnsHealth}
                  />
                  {findingsByType?.[SecurityScanType.DNS_HEALTH]
                    .filter((f) => f.count > 0)
                    .map((finding) => (
                      <PrintFinding securityScan={securityScan} finding={finding} key={finding.slug} />
                    ))}
                </div>
              )}
              {!isErrorScanIpReputation && findingsInCategory(SecurityScanType.IP_REPUTATION).length > 0 && (
                <div className={classes.findingsWrapper}>
                  <PrintFindingCategory
                    grade={getGrateByType(SecurityScanType.IP_REPUTATION)}
                    findings={findingsByType?.[SecurityScanType.IP_REPUTATION]}
                    isError={isErrorScanIpReputation}
                    scanInfo={SCAN_INFO.ipReputation}
                  />
                  {findingsByType?.[SecurityScanType.IP_REPUTATION]
                    .filter((f) => f.count > 0)
                    .map((finding) => (
                      <PrintFinding securityScan={securityScan} finding={finding} key={finding.slug} />
                    ))}
                </div>
              )}
              {!isErrorPatchingCadence && findingsInCategory(SecurityScanType.PATCHING_CADENCE).length > 0 && (
                <div className={classes.findingsWrapper}>
                  <PrintFindingCategory
                    grade={getGrateByType(SecurityScanType.PATCHING_CADENCE)}
                    findings={findingsByType?.[SecurityScanType.PATCHING_CADENCE]}
                    isError={isErrorPatchingCadence}
                    scanInfo={SCAN_INFO.patchingCadence}
                  />
                  {findingsByType?.[SecurityScanType.PATCHING_CADENCE]
                    .filter((f) => f.count > 0)
                    .map((finding) => (
                      <PrintFinding securityScan={securityScan} finding={finding} key={finding.slug} />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
