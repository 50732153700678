import { EmptyState } from 'components/EmptyState';
import { Money } from 'components/Money';
import { CarrierColumn } from 'components/QuotesTable/CarrierColumn';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { QuoteInReviewDTO } from 'dtos/quotes';
import { ReactComponent as QuoteIcon } from 'images/quote.svg';
import { useInReviewQuotes } from 'queries/useQuote';
import React from 'react';

interface Props {
  application: ApplicationDTO;
}

const columns: Array<Column<QuoteInReviewDTO, keyof QuoteInReviewDTO>> = [
  {
    title: 'Carrier',
    width: '20%',
    render: (item) => <CarrierColumn carrier={item.carrier} />,
  },
  {
    title: 'Requested Retention',
    width: '20%',
    render: (item) => <Money value={item.requestedRetention} />,
  },
  {
    title: 'Requested Aggregate Limit',
    width: '20%',
    render: (item) => <Money value={item.requestedAggregateLimit} />,
  },
  {
    title: 'Requested Effective Date',
    width: '20%',
    render: (item) => dayjs(item.requestedEffectiveDate).format('M/D/YYYY'),
  },
];

export const UnderReviewTable = ({ application }: Props) => {
  const { data, isLoading } = useInReviewQuotes(application.id);

  if (!isLoading && data && data.length === 0) {
    return <EmptyState title='No Quotes Under Review' icon={<QuoteIcon />} />;
  }

  return (
    <Table<QuoteInReviewDTO>
      hideHeadersWhenEmpty
      columns={columns}
      loading={isLoading}
      data={data}
      rowKey={(el) => el.id}
    />
  );
};
