import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { AgentDTO } from 'dtos/agent';
import { useCreateEditAgency } from 'queries/useAgency';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  agent: AgentDTO;
  className?: string;
}

interface Form {
  name: string;
}

export const EditAgencyNameButton = ({ agent, className }: Props) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isLoading } = useCreateEditAgency(agent.agencyId || '', {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      name: agent.agency?.name,
    },
  });

  const onSubmit = (data: Form) => {
    if (agent.agency) agent.agency.name = data.name;
    mutate({ name: data.name });
  };

  const FORM_ID = 'edit_agency_form';

  return (
    <>
      <Button className={className} onClick={handleClick} variant='outlined' color='primary'>
        Edit Name
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent key='content'>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                render={({ field }) => <CustomTextFieldShrink {...field} label='Name' required />}
              />
            </section>
          </form>
        </DialogContent>
        <DialogActions key='actions'>
          <div>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} form={FORM_ID}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
