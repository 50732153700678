import MomentUtils from '@date-io/moment';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CustomControlProps } from 'components/JsonForms/customProps';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import isString from 'lodash/isString';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

export const _DatePicker = (props: CustomControlProps) => {
  const classes = useStyles();
  const disabled = !props.enabled;

  if (!props.visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          label={
            <JsonFormsLabelDescription
              label={isString(props.label) ? props.label : props.label.default}
              description={props.schema.description}
            />
          }
          className={classes.input}
          error={!!props.errors}
          required={props.required}
          value={props.data || undefined}
          format='YYYY-MM-DD'
          onChange={(datetime: any) =>
            props.handleChange(props.path, datetime ? moment(datetime).format('YYYY-MM-DD') : undefined)
          }
          disabled={disabled}
          leftArrowIcon={<KeyboardArrowLeftIcon />}
          rightArrowIcon={<KeyboardArrowRightIcon />}
          keyboardIcon={<EventIcon />}
          placeholder={props.uischema.placeholder}
          InputLabelProps={{ shrink: false }}
        />
      </MuiPickersUtilsProvider>

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const DatePicker = withJsonFormsControlProps(_DatePicker);
