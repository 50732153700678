import { RiskLine, RiskLineDataPoint } from 'components/charts/RiskLine';
import { MonitoringSnapshotDTO } from 'dtos/application';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';

export const useMonitoringM365SecurityChart = (
  enableCharts: boolean,
  data?: MonitoringSnapshotDTO[],
  classname?: string,
) => {
  const m365SecurityScore = useMemo(() => {
    const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
      const message = snapshot.reportData.m365Security?.score ? 'Latest Scan' : 'Scan In Progress';
      const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
      acc[date] = acc[date] || snapshot;

      return acc;
    }, {});

    const snapshotsDates = sortBy(
      Object.keys(snapshotsByDate).map((date) => {
        return { date, createdAt: snapshotsByDate[date].createdAt };
      }),
      (snapshot) => snapshot.createdAt,
    ).map((d) => d.date);

    const summedByDate = sortBy(
      snapshotsDates.map((date, index) => {
        const prevDate = snapshotsDates[index - 1];

        const m365SecurityScore = snapshotsByDate[date].reportData.m365Security?.score;
        const m365SecurityScorePrev = prevDate ? snapshotsByDate[prevDate].reportData.m365Security?.score : 0;

        return {
          date,
          m365SecurityScore,
          m365SecurityScorePrev,
          createdAt: snapshotsByDate[date].createdAt,
        };
      }),
      (snapshot) => snapshot.createdAt,
    );

    return summedByDate
      .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
        acc.push({
          name: snapshot.date,
          score: snapshot.m365SecurityScore || 0,
          distribution: [
            {
              name: 'Total',
              value: snapshot.m365SecurityScore || 0,
              previous: snapshot.m365SecurityScorePrev || 0,
              opposite: true,
            },
          ],
        });

        return acc;
      }, [])
      .slice(0, 30);
  }, [data]);

  const chart = useMemo(() => {
    return (
      <RiskLine
        locked={!enableCharts}
        id='monitoring-m365-security'
        className={classname}
        data={m365SecurityScore}
        showTooltip
        // title='Critical Vulnerabilities'
      />
    );
  }, [classname, enableCharts, m365SecurityScore]);

  return {
    chart,
    m365SecurityScore,
  };
};
