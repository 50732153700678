import { Button } from '@material-ui/core';
import { ApplicationDocumentsGroupDTO, ApplicationWithAgentDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useDeleteApplicationDocumentsGroup } from 'queries/useApplications';
import React from 'react';

interface Props {
  application: ApplicationWithAgentDTO;
  group: ApplicationDocumentsGroupDTO;
}

export const DeleteGroupButton = ({ application, group }: Props) => {
  const { showToaster } = useToasters();
  const { mutate: deleteGroup, isLoading } = useDeleteApplicationDocumentsGroup(application.id, {
    onSuccess: () => {
      showToaster('Documents group and its files deleted successfully');
    },
  });

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        deleteGroup(group.id);
      }}
      color='secondary'
      disabled={isLoading}
    >
      {isLoading ? 'Deleting...' : 'Delete group and files'}
    </Button>
  );
};
