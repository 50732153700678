import { FormHelperText } from '@material-ui/core';
import React from 'react';

interface Props {
  errors?: string;
}

export const JsonFormsErrors = ({ errors }: Props) => {
  const errorMessages = errors ? errors.split('\n') : [];

  return (
    <>
      {errorMessages.map((error, idx) => (
        <FormHelperText error key={idx}>
          {error}
        </FormHelperText>
      ))}
      {/* <FormHelperText error>{errors[0]}</FormHelperText> */}
    </>
  );
};
