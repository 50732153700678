import { makeStyles } from '@material-ui/core';
// import cx from 'classnames';
// import { BarsBy, useApplicationsByChart } from 'hooks/charts/useApplicationsByChart';
// import { useByBreachesChart } from 'hooks/charts/useByBreachesChart';
// import { useByRiskBySeverityChart } from 'hooks/charts/useByRiskBySeverityChart';
// import { useByStrengthChart } from 'hooks/charts/useByStrengthChart';
// import { useDataRiskByDarkWebSecurityChart } from 'hooks/charts/useDataRiskByDarkWebSecurityChart';
// import { useDataRiskByPasswordSecurityChart } from 'hooks/charts/useDataRiskByPasswordSecurityChart';
// import { useDataRiskByVulnerabilityChart } from 'hooks/charts/useDataRiskByVulnerabilityChart';
// import { useDeviceByRiskChart } from 'hooks/charts/useDeviceByRiskChart';
// import { RiskBy, useDeviceRisk } from 'hooks/charts/useDeviceRisk';
// import { useM365FailedLoginsChart } from 'hooks/charts/useM365FailedLoginsChart';
// import { useM365LoginActivityChart } from 'hooks/charts/useM365LoginActivityChart';
// import { useM365LoginMapChart } from 'hooks/charts/useM365LoginMapChart';
// import { useM365SecurityScoreChart } from 'hooks/charts/useM365SecurityScoreChart';
// import { useVulnerabilityByScoreChart } from 'hooks/charts/useVulnerabilityByScoreChart';
// import { useApplication, useApplicationSecurityScan } from 'queries/useApplications';
// import {
//   useDeepScan,
//   useDeepScanBrowserPasswords,
//   useDeepScanM365SecurityData,
//   useDeepScanNodeware,
//   useDeepScanParsedReports,
//   useDeepScanRiskAnalysis,
//   useDeepScanTargets,
// } from 'queries/useDeepScan';
import React from 'react';
import { Helmet } from 'react-helmet';
// import { useParams } from 'react-router-dom';
import { COLORS } from 'telivy-theme';
// import { DarkWebPresenceChart } from 'templates/SecurityAssessments/components/DarkWebPresenceChart';
// import { ExternalScanRadarChart } from 'templates/SecurityAssessments/components/ExternalScanRadarChart';

const RADAR_GRAPH_HEIGHT = 300;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: COLORS.WHITE,
    width: 1200,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    margin: '0 auto',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
  lineChart: {
    height: 300,
  },

  radarSecurityCardContent: {
    position: 'relative',
    minHeight: RADAR_GRAPH_HEIGHT + 35,
  },
  radarWrapper: {
    width: '100%',
    height: RADAR_GRAPH_HEIGHT,
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
  },
}));

// interface ParamsType {
//   id: string;
// }

export const AdminApplicationCharts = () => {
  const classes = useStyles();
  // const { id } = useParams<ParamsType>();
  // const { data: app } = useApplication(id);
  // const { data: securityScan } = useApplicationSecurityScan(id, 'latest');
  // const enabledDeepScan = !!app?.deepScanId;
  // const { data: deepScan } = useDeepScan(app?.deepScanId || '', { enabled: enabledDeepScan });
  // const { data: nodewareAssets } = useDeepScanNodeware(app?.deepScanId || '', { enabled: enabledDeepScan });
  // const { data: riskAnalysis } = useDeepScanRiskAnalysis(app?.deepScanId || '', { enabled: enabledDeepScan });
  // const { data: m365 } = useDeepScanM365SecurityData(app?.deepScanId || '', '', { enabled: enabledDeepScan });
  // const { data: browserPasswords } = useDeepScanBrowserPasswords(app?.deepScanId || '', { enabled: enabledDeepScan });
  // const { data: report } = useDeepScanParsedReports(app?.deepScanId || '', { enabled: enabledDeepScan });
  // const { devices } = report?.actifileRiskReport || {};
  // const { data: targets } = useDeepScanTargets(app?.deepScanId || '', { enabled: enabledDeepScan });

  // const { chart: chart1 } = useByRiskBySeverityChart(true, nodewareAssets);
  // const { chart: chart2 } = useDataRiskByDarkWebSecurityChart(true, riskAnalysis);
  // const { chart: chart3 } = useDataRiskByPasswordSecurityChart(true, riskAnalysis);
  // const { chart: chart4 } = useDataRiskByVulnerabilityChart(true, riskAnalysis);
  // const { chart: chart5 } = useVulnerabilityByScoreChart(true, nodewareAssets, classes.lineChart);
  // const { chart: chart6 } = useM365SecurityScoreChart(true, m365?.securityScore.value, classes.lineChart);
  // const { chart: chart7 } = useM365LoginMapChart(m365?.auditLogsSignIns.value || []);
  // const { chart: chart8 } = useM365LoginActivityChart(
  //   enabledDeepScan,
  //   m365?.auditLogsSignIns.value || [],
  //   classes.lineChart,
  // );
  // const { chart: chart9 } = useM365FailedLoginsChart(
  //   enabledDeepScan,
  //   m365?.users.value,
  //   m365?.auditLogsSignIns.value || [],
  //   classes.lineChart,
  // );
  // const { chart: chart10 } = useByBreachesChart(true, browserPasswords);
  // const { chart: chart11 } = useByStrengthChart(true, browserPasswords);
  // const { chart: chart12 } = useApplicationsByChart(
  //   true,
  //   report?.applicationsRiskReport || undefined,
  //   BarsBy.USERS,
  //   classes.lineChart,
  // );
  // const { chart: chart13 } = useApplicationsByChart(
  //   true,
  //   report?.applicationsRiskReport || undefined,
  //   BarsBy.VISITS,
  //   classes.lineChart,
  // );
  // const { chart: chart14 } = useDeviceByRiskChart(true, targets, RiskBy.FILES_SCANNED, classes.lineChart);
  // const { chart: chart15 } = useDeviceByRiskChart(true, targets, RiskBy.PII, classes.lineChart);
  // const { chart: chart16 } = useDeviceRisk(true, devices, RiskBy.FILES_SCANNED, classes.lineChart);
  // const { chart: chart17 } = useDeviceRisk(true, devices, RiskBy.PII, classes.lineChart);

  // if (!app || !securityScan || !deepScan) {
  //   return null;
  // }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Application Charts</title>
      </Helmet>

      {/*<div className={classes.container}>
        <div className={classes.fullWidth}>
          <DarkWebPresenceChart securityScan={securityScan} findingsUrl='/admin' forceShouldPullFindings={false} />
        </div>
        <div className={classes.fullWidth}>
          <div className={classes.radarSecurityCardContent} id='score-benchmark-external-surface-exposure-chart'>
            <div className={classes.radarWrapper}>
              <ExternalScanRadarChart securityScan={securityScan} />
            </div>
          </div>
        </div>
        {chart1}
        {chart2}
        {chart3}
        {chart4}
        <div className={cx(classes.fullWidth)}>{chart5}</div>
        <div className={cx(classes.fullWidth)}>{chart6}</div>
        {chart7}
        <div className={cx(classes.fullWidth)}>{chart8}</div>
        <div className={cx(classes.fullWidth)}>{chart9}</div>
        {chart10}
        {chart11}
        <div className={cx(classes.fullWidth)}>{chart12}</div>
        <div className={cx(classes.fullWidth)}>{chart13}</div>
        <div className={cx(classes.fullWidth)}>{chart14}</div>
        <div className={cx(classes.fullWidth)}>{chart15}</div>
        <div className={cx(classes.fullWidth)}>{chart16}</div>
        <div className={cx(classes.fullWidth)}>{chart17}</div>
      </div>*/}
    </div>
  );
};
