import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

export type BadgeVariant =
  | 'green'
  | 'red'
  | 'yellow'
  | 'black'
  | 'grey'
  | 'darkRed'
  | 'blue'
  | 'greenWhite'
  | 'blueWhite'
  | 'yellowWhite'
  | 'redWhite';

interface StyleProps {
  color?: ValueOf<typeof COLORS>;
  backgroundColor?: ValueOf<typeof COLORS>;
  typography?: ValueOf<typeof TYPOGRAPHY>;
  variant: BadgeVariant;
  bordered?: boolean;
  borderWidth?: number;
}

export const variantColorsMap = {
  green: {
    bg: COLORS.GREEN_3,
    text: COLORS.GREEN_1,
  },
  yellow: {
    bg: COLORS.YELLOW_3,
    text: COLORS.ORANGE_1,
  },
  red: {
    bg: COLORS.RED_3,
    text: COLORS.RED_1,
  },
  black: {
    bg: `${COLORS.GREY_4}66`,
    text: COLORS.GREY_1,
  },
  grey: {
    bg: `${COLORS.GREY_4}66`,
    text: COLORS.GREY_3,
  },
  darkRed: {
    bg: COLORS.RED_2,
    text: COLORS.RED_0,
  },
  blue: {
    bg: COLORS.BLUE_3,
    text: COLORS.BLUE_1,
  },
  greenWhite: {
    bg: COLORS.GREEN_1,
    text: COLORS.WHITE,
  },
  blueWhite: {
    bg: COLORS.BLUE_1,
    text: COLORS.WHITE,
  },
  yellowWhite: {
    bg: COLORS.YELLOW_1,
    text: COLORS.WHITE,
  },
  redWhite: {
    bg: COLORS.RED_1,
    text: COLORS.WHITE,
  },
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ backgroundColor, color, typography, variant, bordered, borderWidth }) => ({
    ...typography,
    backgroundColor: backgroundColor ? backgroundColor : variantColorsMap[variant].bg,
    color: color ? color : variantColorsMap[variant].text,
    borderRadius: theme.spacing(0.625),
    border: bordered ? (color ? color : `${borderWidth || 1}px solid ${variantColorsMap[variant].text}`) : 'none',
    width: 'fit-content',
    padding: `0 ${theme.spacing()}px ${theme.spacing(0.125)}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    ['@media print']: {
      border: `${borderWidth || 1}px solid ${color ? color : variantColorsMap[variant].text}`,
    },
  }),
}));

interface Props extends Partial<StyleProps> {
  className?: string;
  style?: CSSProperties;
}

export const Badge: React.FC<Props> = ({
  children,
  className,
  backgroundColor,
  color,
  typography,
  variant = 'red',
  bordered,
  borderWidth,
  style,
}) => {
  const classes = useStyles({ backgroundColor, color, typography, variant, bordered, borderWidth });

  return (
    <div className={cx(classes.root, className)} style={style}>
      {children}
    </div>
  );
};
