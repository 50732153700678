import { AgencyDTO } from 'dtos/agent';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDTO } from 'dtos/application';
import { DeepScanM365AuditLogSignIn, DeepScanM365User, NmapVulnerabilityData } from 'dtos/deep-scan';
import { SecurityFindingDTO } from 'dtos/security-finding';

export enum AlertCategory {
  INTERNAL_VULNERABILITIES = 'internal_vulnerabilities',
  M365_FAILED_LOGINS = 'm365_failed_logins',
  M365_NO_MFA = 'm365_no_mfa',
  TYPOSQUATTING = 'typosquatting',
}

export const ALERT_CATEGORIES: { [key in AlertCategory]: string } = {
  [AlertCategory.INTERNAL_VULNERABILITIES]: 'Internal Vulnerabilities',
  [AlertCategory.M365_FAILED_LOGINS]: 'M365 Failed Logins',
  [AlertCategory.M365_NO_MFA]: 'M365 No MFA User',
  [AlertCategory.TYPOSQUATTING]: 'Typosquatting Domains',
};

export enum AlertMetric {
  SEVERITY = 'severity',
  EPSS = 'epss',
  CVSS = 'cvss',
  COUNT = 'count',
  ANY = 'any',
}

export enum AlertSeverity {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  INFO = 'Info',
}

export const ALERT_METRIC_LABELS: { [key in AlertMetric]: string } = {
  [AlertMetric.SEVERITY]: 'Severity Equals',
  [AlertMetric.EPSS]: 'EPSS Greater Than',
  [AlertMetric.CVSS]: 'CVSS Greater Than',
  [AlertMetric.COUNT]: 'Count Greater Than',
  [AlertMetric.ANY]: 'Any New',
};

export const ALERT_METRIC_VALUES: { [key in AlertMetric]: string } = {
  [AlertMetric.SEVERITY]: AlertSeverity.HIGH as string,
  [AlertMetric.EPSS]: '50',
  [AlertMetric.CVSS]: '8.0',
  [AlertMetric.COUNT]: '10',
  [AlertMetric.ANY]: '',
};

export enum AlertConditionOperator {
  END = 'end',
  AND = 'and',
  OR = 'or',
}

export interface AlertCondition {
  metric: AlertMetric;
  value: string;
  operator: AlertConditionOperator;
}

export interface AlertPreference {
  id: string;
  agentId: string;
  category: AlertCategory;
  conditions?: AlertCondition[];
  condition?: AlertCondition;
  email: boolean;
  text: boolean;
}

export const ALERT_METRICS: { [key in AlertCategory]: AlertMetric[] } = {
  [AlertCategory.INTERNAL_VULNERABILITIES]: [AlertMetric.SEVERITY, AlertMetric.EPSS, AlertMetric.CVSS],
  [AlertCategory.M365_FAILED_LOGINS]: [AlertMetric.COUNT],
  [AlertCategory.M365_NO_MFA]: [AlertMetric.ANY],
  [AlertCategory.TYPOSQUATTING]: [AlertMetric.ANY],
};

export const ALERT_CONDITIONS: { [key in AlertCategory]: AlertCondition } = {
  [AlertCategory.INTERNAL_VULNERABILITIES]: {
    metric: AlertMetric.SEVERITY,
    value: ALERT_METRIC_VALUES[AlertMetric.SEVERITY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_FAILED_LOGINS]: {
    metric: AlertMetric.COUNT,
    value: ALERT_METRIC_VALUES[AlertMetric.COUNT],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.M365_NO_MFA]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
  [AlertCategory.TYPOSQUATTING]: {
    metric: AlertMetric.ANY,
    value: ALERT_METRIC_VALUES[AlertMetric.ANY],
    operator: AlertConditionOperator.END,
  },
};

export interface AlertData {
  content: string;
  previous: string;
  filteredUsers?: DeepScanM365User[];
  filteredLogins?: DeepScanM365AuditLogSignIn[];
  filteredFindings?: SecurityFindingDTO[];
  filteredCves?: NmapVulnerabilityData[];
  hostName?: string;
}

export interface AlertLatestState {
  cvePublishedDate?: string;
  typosquattingRegisteredDate?: string;
  m365UserCreatedAt?: string;
  m365LoginAuditCreatedAt?: string;
}

export interface AlertDTO {
  id: string;
  application: ApplicationDTO;
  applicationId: string;
  agent: AgentDTO | null;
  agentId: string;
  agency: AgencyDTO | null;
  agencyId: string;
  createdAt: Date;
  updatedAt: Date;
  data: AlertData | null;
  category: AlertCategory;
  read: boolean;
}

export interface AlertsQuery {
  q?: string;
  unread?: boolean;
  alertCategory?: AlertCategory;
  offset?: number;
  limit?: number;
  creationDateDays?: number;
  demo?: boolean;
}

export interface AlertsResponse {
  message: string;
  alerts: AlertDTO[];
  alertsCount: number;
}

export interface EditAlertDTO {
  read: boolean | null;
}

export interface EditAlertPreferenceDTO {
  id: string;
  alertPreferences: AlertPreference[];
}
