import { withJsonFormsControlProps } from '@jsonforms/react';
import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomControlProps } from 'components/JsonForms/customProps';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import { Money } from 'components/Money';
import { kebabCase } from 'lodash';
import isString from 'lodash/isString';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

export const _SelectInput: React.FC<CustomControlProps> = (props) => {
  const classes = useStyles();
  const options = props.schema.enum as string[];
  const variant = props.uischema.options?.money && 'money';

  if (!props.visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <TextField
        id={props.id}
        select
        label={
          <JsonFormsLabelDescription
            label={isString(props.label) ? props.label : props.label.default}
            description={props.schema.description}
          />
        }
        value={props?.data || ''}
        disabled={!props.enabled}
        error={!!props.errors}
        required={!!props.required}
        placeholder={props.uischema.placeholder}
        fullWidth={true}
        InputLabelProps={{ shrink: false }}
        onChange={(ev) => props.handleChange(props.path, ev?.target?.value)}
        data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-select`}
      >
        {options.map((optionValue) => (
          <MenuItem
            value={optionValue}
            key={optionValue}
            data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-select-option`}
          >
            {variant === 'money' ? (
              <Money value={parseFloat(optionValue.replaceAll(',', ''))} typography={TYPOGRAPHY.REGULAR_REGULAR} />
            ) : (
              optionValue
            )}
          </MenuItem>
        ))}
      </TextField>

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const SelectInput = withJsonFormsControlProps(_SelectInput);
