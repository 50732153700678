import './board.css';

import { Board, Card, KanbanBoard } from '@caldwell619/react-kanban';
import { makeStyles } from '@material-ui/core';
import { Badge } from 'components/Badge';
import { LoadingContainer } from 'components/LoadingContainer';
import { Searchbar } from 'components/SearchBar';
import { SingleFilter } from 'components/SingleFilter';
// import { Column, Table } from 'components/Table';
import { SecurityFindingBySlugDTO, SecurityScanType } from 'dtos/security-finding';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { startCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { sortBySeverityAndCount } from 'templates/SecurityAssessments/components/FindingsTable';
import { getSeverityBadgeVariant } from 'templates/SecurityAssessments/helpers';
import { SCAN_TYPE_NAME } from 'templates/SecurityAssessments/views/SecurityExternalScan';

import { ApplicationViewContainer } from '../ApplicationViewContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    // marginBottom: theme.spacing(1),
    alignSelf: 'center',
  },

  section: {
    marginBottom: theme.spacing(3),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  bordered: {
    border: 'solid 1px #ededed',
  },

  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    marginBottom: theme.spacing(2),
  },

  kanbanCard: {
    borderRadius: '10px',
    backgroundColor: '#fff',
    padding: '10px',
    marginTop: '16px',
    border: '2px solid #cdcdcd',
    width: '100%',
    boxSizing: 'border-box',
    flex: 1,
  },
  kanbanCardLabels: {
    display: 'flex',
    marginBottom: '8px',
    justifyContent: 'space-between',
  },
  kanbanCardLabel: {
    border: '1px solid #eee',
    display: 'inline-block',
    padding: '3px 10px',
    borderRadius: '5px',
    marginLeft: '8px',
  },
  kanbanCardTitle: {
    borderBottom: '1px solid #eee',
    paddingBottom: '5px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
  },
  kanbanCardDescription: {
    paddingTop: '10px',
  },
}));

interface Props {
  data?: SecurityFindingBySlugDTO[];
  domains?: string[];
}

export interface FindingCard extends Omit<Card & SecurityFindingBySlugDTO, ''> {}

export const RemediationBoardView = ({ data, domains }: Props) => {
  const classes = useStyles();

  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);

  const filteredData = useMemo(() => {
    return (data || [])
      .filter((f) => f.count > 0 && f.scanType != SecurityScanType.HACKER_CHATTER)
      .filter((f) => {
        return !debouncedSearchValue || f.name.toLowerCase().includes(debouncedSearchValue.toLowerCase());
      })
      .sort(sortBySeverityAndCount);
  }, [data, debouncedSearchValue]);

  const boardData = useMemo(() => {
    const board: KanbanBoard<FindingCard> = {
      columns: [
        {
          id: 'open',
          title: 'Open',
          cards: filteredData
            .filter((f) => f.count > 20)
            .map((f) => {
              return {
                id: f.slug,
                title: f.name,
                ...f,
              };
            }),
        },
        {
          id: 'in_progress',
          title: 'In Progress',
          cards: filteredData
            .filter((f) => f.count <= 20 && f.count > 10)
            .map((f) => {
              return {
                id: f.slug,
                title: f.name,
                ...f,
              };
            }),
        },
        {
          id: 'completed',
          title: 'Completed',
          cards: filteredData
            .filter((f) => f.count <= 10)
            .map((f) => {
              return {
                id: f.slug,
                title: f.name,
                ...f,
              };
            }),
        },
      ],
    };

    return board;
  }, [filteredData]);

  // You need to control the state yourself.
  // const [controlledBoard, setBoard] = useState<KanbanBoard<Card>>({ ...boardData });

  // const handleCardMove: OnDragEndNotification<Card> = (_card, source, destination) =>
  //   setBoard((currentBoard) => moveCard(currentBoard, source, destination));

  if (data === undefined) {
    return <LoadingContainer />;
  }

  return (
    <ApplicationViewContainer>
      <div className={classes.root}>
        <div className={classes.section}>
          <div className={classes.actionsContainer}>
            <div className={classes.title}>Remediation Board</div>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.filters}>
            <Searchbar placeholder='Search...' value={searchValue} setValue={setSearchValue} />
            <SingleFilter
              buttonText={`Filter Targets ${selectedDomains.map((d) => `${d}`).join(', ')}`}
              options={domains || []}
              popperText='Targets'
              setSelectedOptions={setSelectedDomains}
              defaultSelectedOptions={selectedDomains}
              showDropdownIcon
            />
          </div>

          <div>
            <Board
              allowRemoveColumn={false}
              allowAddCard={false}
              allowRemoveCard={false}
              // onCardDragEnd={handleCardMove}
              disableColumnDrag
              // onCardRemove={({ board, card, column }) => console.log({ board, card, column })}
              renderCard={(card: FindingCard) => (
                <div className={classes.kanbanCard}>
                  <span>
                    <div className={classes.kanbanCardLabels}>
                      <Badge
                        bordered
                        variant={getSeverityBadgeVariant(card.severity)}
                        style={{ minWidth: 80, width: 80 }}
                      >
                        {startCase(card.severity)}
                      </Badge>
                      <span className={classes.kanbanCardLabel}>{SCAN_TYPE_NAME[card.scanType]}</span>
                    </div>
                    <div className={classes.kanbanCardTitle}>
                      <span>
                        ({card.count}) {card.title}
                      </span>
                    </div>
                  </span>
                  <div
                    className={classes.kanbanCardDescription}
                    dangerouslySetInnerHTML={{ __html: card.description || '' }}
                  ></div>
                </div>
              )}
            >
              {boardData}
            </Board>
          </div>
        </div>
      </div>
    </ApplicationViewContainer>
  );
};
