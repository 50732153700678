import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDTO } from 'dtos/application';
import { ReactComponent as ConfirmIcon } from 'images/confirm.svg';
import { useApplicationSkipDuns, usePrefillApplication } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    width: 360,
    margin: '0 auto',
  },

  // Block
  block: {
    padding: theme.spacing(2),
    borderRadius: 6,
    border: `1px solid ${COLORS.GREY_5}`,
    display: 'flex',

    '& + $block': {
      marginTop: theme.spacing(2),
    },
  },
  checkbox: {
    width: 16,
    height: 16,
    border: `2px solid ${COLORS.GREY_3}`,
    position: 'relative',
    borderRadius: '50%',
  },
  selectedCheckbox: {
    borderColor: COLORS.BLUE_1,

    '&::after': {
      content: `''`,
      position: 'absolute',
      backgroundColor: COLORS.BLUE_1,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
  },
  details: {
    marginLeft: theme.spacing(1.5),
  },
  name: {
    ...TYPOGRAPHY.REGULAR_MEDIUM,
    color: COLORS.GREY_1,
    margin: 0,
    marginBottom: theme.spacing(0.5),
  },
  address: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.GREY_2,
    margin: 0,
  },
}));

interface Props {
  agent?: AgentDTO;
  application: ApplicationDTO;
  onDunsSelect: (application: ApplicationDTO) => void;
  onSkip: () => void;
  submitButtonText?: string;
  persistSkip?: boolean;
}

export const DnbSelect = ({ persistSkip, agent, application, onSkip, onDunsSelect, submitButtonText }: Props) => {
  const classes = useStyles();
  const [selectedDunes, setSelectedDunes] = useState<string | null>(null);
  const { mutate: skipDuns, isLoading: isSkipLoading } = useApplicationSkipDuns(application.id, {
    onSuccess: () => {
      onSkip();
    },
  });

  const { mutate, isLoading } = usePrefillApplication(application.id, {
    onSuccess: (data) => {
      onDunsSelect(data);
    },
  });

  const handleSubmit = () => {
    if (selectedDunes) {
      mutate(selectedDunes);
    }
  };

  const handleSkip = useCallback(() => {
    if (persistSkip) {
      skipDuns();
    } else {
      onSkip();
    }
  }, [persistSkip, skipDuns, onSkip]);

  return (
    <>
      <DialogContent key='content'>
        <div className={classes.heading}>
          <ConfirmIcon />
          <h2 className={classes.title}>{isSecurityAgency(agent) ? 'Assessment Created' : 'Application Created'}</h2>
          <p className={classes.text}>
            Do any of the following match your business?
            <br />
            Select one, or skip to manually enter information.
          </p>
        </div>
        {(application.dnbData?.matchResponse || []).map((response, idx) => (
          <div
            role='button'
            tabIndex={idx}
            className={classes.block}
            key={idx}
            data-cy='dnb-select-option'
            onClick={() => setSelectedDunes(response.duns)}
          >
            <div className={cx(classes.checkbox, response.duns === selectedDunes && classes.selectedCheckbox)} />
            <div className={classes.details}>
              <h4 className={classes.name}>{response.companyName}</h4>
              <p className={classes.address}>
                {[response.streetAddressLine1, response.streetAddressLine2].filter(Boolean).join(' ')}, {response.city},{' '}
                {response.state}
              </p>
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions key='actions'>
        <Button onClick={handleSkip}>{isSkipLoading ? 'Skipping...' : 'Skip'}</Button>
        <Button
          color='primary'
          variant='contained'
          disabled={isLoading || !selectedDunes}
          onClick={handleSubmit}
          data-cy={'dnb-submit-button'}
        >
          {isLoading ? 'Loading...' : submitButtonText || 'Next'}
        </Button>
      </DialogActions>
    </>
  );
};
