import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';

import { TechstackTools } from '../components/techstack/TechstackTools';
interface Props {
  application: ApplicationWithAgentDTO;
}

export const SecurityTechStack = ({ application }: Props) => {
  return <TechstackTools application={application} />;
};
