import { PolicyDTO } from 'dtos/policy';
import { usePolicyDetailsList } from 'hooks/usePolicyDetails';
import React from 'react';

import { Sidebar } from '../Sidebar';

interface Props {
  policy: PolicyDTO;
}

export const SelectedPolicy = ({ policy }: Props) => {
  const { lists } = usePolicyDetailsList(policy);

  const header = <div>{policy.policyNumber || '-'}</div>;

  return <Sidebar header={header} lists={lists} />;
};
