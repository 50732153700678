import { makeStyles } from '@material-ui/core';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import React from 'react';

import { CONFIGURATION_STEP } from './configuration';
import { ConfigureGoogleWS } from './ConfigureGoogleWS';
import { ConfigureMicrosoft365 } from './ConfigureMicrosoft365';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 16,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
  },
  boxSubTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  boxButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  boxButtonMac: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerRow: {
    display: 'flex',
    gap: theme.spacing(3),
  },
}));

interface Props {
  deepScan: DeepScanDTO;
  onVerify?: () => void;
  application: ApplicationWithAgentDTO;
}

export const ConfigureSoftwares = ({ deepScan, onVerify, application }: Props) => {
  const classes = useStyles();

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      hideVerify={true}
      type={CONFIGURATION_STEP.CLOUD_SOFTWARES}
      title='Connect Cloud'
      description='Allow telivy to pull data from your cloud applications to assess risk.'
    >
      <div className={classes.boxesContainer}>
        <div className={classes.box}>
          <div className={classes.headerRow}>
            <img src='/m365.png' alt='M365' width='120' />
            <div>
              <div className={classes.boxTitle}>Connect Microsoft 365</div>
              <ConfigureMicrosoft365 deepScan={deepScan} application={application} />
            </div>
          </div>
        </div>
      </div>

      {application.agent.agency?.enableGoogleWorkspace && (
        <div className={classes.boxesContainer}>
          <div className={classes.box}>
            <div className={classes.headerRow}>
              <img src='/gws.png' alt='Google Workspace' width='120' />
              <div>
                <div className={classes.boxTitle}>Connect Google Workspace</div>
                <ConfigureGoogleWS deepScan={deepScan} application={application} />
              </div>
            </div>
          </div>
        </div>
      )}
    </SectionTemplate>
  );
};
