import { Button, ButtonProps, Dialog, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { ApplicationDTO } from 'dtos/application';
import { QuoteDTO } from 'dtos/quotes';
import React, { useCallback, useMemo, useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

import { Documents } from './Documents';
import { QuoteForm } from './QuoteForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  application: ApplicationDTO;
  existingQuote?: QuoteDTO;
  onQuoteUpdate: (data: QuoteDTO) => void;
  className?: string;
  uploadOnly?: boolean;
  buttonProps?: ButtonProps;
}

enum ModalType {
  AddManually,
  AddByUploading,
  Edit,
}

export const QuoteButton: React.FC<Props> = ({
  application,
  className,
  existingQuote,
  onQuoteUpdate,
  uploadOnly,
  buttonProps,
}) => {
  const [openedModalType, setModalType] = useState<ModalType | null>(null);
  const classes = useStyles();
  const [quotes, setQuotes] = useState<QuoteDTO[] | null>(existingQuote ? [existingQuote] : null);
  const [showDocuments, setShowDocuments] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (uploadOnly) {
      setModalType(ModalType.AddByUploading);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = useCallback(() => {
    setModalType(null);
    setQuotes(null);
  }, []);

  const handleQuoteData = useCallback(
    (quote: QuoteDTO[]) => {
      setQuotes(quote);
      setShowDocuments(true);

      if (existingQuote) {
        onQuoteUpdate(quote[0]);
      }
    },
    [existingQuote, onQuoteUpdate],
  );

  const modalTitle = useMemo(() => {
    if (quotes) {
      return 'Documents Upload';
    }

    if (existingQuote) {
      return 'Update Quote';
    }

    return 'Create Quote';
  }, [quotes, existingQuote]);

  const buttonText = useMemo(() => {
    if (existingQuote) {
      return 'Edit Quote';
    }

    if (uploadOnly) {
      return 'Upload Quote Document';
    }

    return 'Create Quote';
  }, [existingQuote, uploadOnly]);

  return (
    <>
      <Button
        variant={uploadOnly || existingQuote ? 'contained' : 'text'}
        className={className}
        startIcon={!uploadOnly && !existingQuote && <AddIcon />}
        onClick={existingQuote ? () => setModalType(ModalType.Edit) : handleOpenMenuClick}
        {...buttonProps}
      >
        {buttonText}
      </Button>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            setModalType(ModalType.AddManually);
            setAnchorEl(null);
          }}
        >
          Create Manually
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalType(ModalType.AddByUploading);
            setAnchorEl(null);
          }}
        >
          Upload Quote Letter
        </MenuItem>
      </Menu>

      <Dialog fullWidth={true} open={openedModalType !== null} maxWidth='lg' onClose={handleCloseModal}>
        <div className={classes.root}>
          <h3 className={classes.title}>{modalTitle}</h3>

          {showDocuments && quotes ? (
            <Documents
              replicateToQuotesIds={quotes.map((quote) => quote.id).filter((id) => id !== quotes[0].id)}
              quoteId={quotes[0].id}
              onClose={handleCloseModal}
              applicationId={application.id}
            />
          ) : (
            <QuoteForm
              uploadFlow={openedModalType === ModalType.AddByUploading}
              application={application}
              onCloseRequest={handleCloseModal}
              onQuoteCreation={handleQuoteData}
              existingQuote={existingQuote}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};
