import { makeStyles } from '@material-ui/core/styles';
import { ApplicationDTO } from 'dtos/application';
import { PolicyStatus } from 'dtos/policy';
import { QuoteDTO } from 'dtos/quotes';
import { useQuoteDetailsList } from 'hooks/useQuoteDetailsList';
import { useApplicationPolicies } from 'queries/useApplications';
import React from 'react';
import { CreatePolicyButton } from 'views/agent/views/application-details/views/quotes/CreatePolicyButton';
import { DeleteQuoteButton } from 'views/agent/views/application-details/views/quotes/DeleteQuoteButton';

import { Sidebar } from '../Sidebar';
import { QuoteButton } from './QuoteButton';

const useStyles = makeStyles((theme) => ({
  button: {
    '& + $button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface Props {
  quote: QuoteDTO;
  application: ApplicationDTO;
  showEditQuote: boolean;
  onQuoteUpdate: (data: QuoteDTO) => void;
  onQuoteDelete: () => void;
}

export const SelectedQuote = ({ quote, application, showEditQuote, onQuoteUpdate, onQuoteDelete }: Props) => {
  const classes = useStyles();
  const { data } = useApplicationPolicies(application.id);
  const hasActivePolicy = data?.some((policy) => [PolicyStatus.ACTIVE, PolicyStatus.DRAFT].includes(policy.status));
  const { lists } = useQuoteDetailsList(quote, application);

  const header = (
    <>
      <div>{quote.quoteNumber}</div>
      <div>
        <CreatePolicyButton
          application={application}
          quote={quote}
          className={classes.button}
          hasActivePolicy={!!hasActivePolicy}
        />

        {showEditQuote && (
          <QuoteButton
            className={classes.button}
            application={application}
            existingQuote={quote}
            onQuoteUpdate={onQuoteUpdate}
          />
        )}
        <DeleteQuoteButton quote={quote} className={classes.button} onQuoteDelete={onQuoteDelete} />
      </div>
    </>
  );

  return <Sidebar header={header} lists={lists} />;
};
