import { Button, CircularProgress, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationDTO } from 'dtos/application';
import { PolicyDTO } from 'dtos/policy';
import { useToasters } from 'hooks/useToasters';
import { useApplicationPolicies } from 'queries/useApplications';
import { usePolicyApiBind } from 'queries/usePolicy';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { generateRefetchIntervalMs } from 'telivy-constants';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  policy: PolicyDTO;
  application: ApplicationDTO;
  className?: string;
}

export const BindPolicyButton: React.FC<Props> = ({ className, policy, application }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate } = usePolicyApiBind(policy.id, {
    onSuccess: () => {
      setChecking(true);
      showToaster('Successfully scheduled policy binding');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  useApplicationPolicies(policy.applicationId, {
    enabled: checking,
    refetchIntervalInBackground: true,
    refetchInterval: generateRefetchIntervalMs(),
    onSuccess: (data) => {
      const updatedPolicy = data?.find((p) => p.id === policy.id);

      // Refresh UI after policy binding
      if (updatedPolicy && updatedPolicy.policyNumber) {
        queryClient.refetchQueries(['application', application.id]);
        queryClient.refetchQueries(['policy-documents', policy.id]);
      }
    },
  });

  const handleBindClick = useCallback(() => {
    setOpen(true);
    mutate();
  }, [mutate]);

  return (
    <>
      <Button
        startIcon={<AssignmentIcon />}
        className={className}
        onClick={handleBindClick}
        variant='contained'
        color='primary'
      >
        Bind Policy via API
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <h3 className={classes.title}>{policy.policyNumber ? 'Policy bound' : 'Binding policy...'}</h3>
          {policy.policyNumber ? (
            <p>
              Policy number: <strong>{policy.policyNumber}</strong>
            </p>
          ) : (
            <>
              <p>Binding process can take up to six minutes.</p>
              <CircularProgress />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={!policy.policyNumber} color='primary' variant='contained'>
            Success
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
