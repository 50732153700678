import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Badge } from 'components/Badge';
import { SecurityScanDTO } from 'dtos/application';
import { SecurityFindingBySlugDTO } from 'dtos/security-finding';
import startCase from 'lodash/startCase';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { getSeverityBadgeVariant } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)}px 0 0`,
    ['@media print']: {
      // pageBreakInside: 'avoid',
      // display: 'block',
    },
  },

  pageBreakInside: {
    ['@media print']: {
      pageBreakInside: 'avoid',
      display: 'block',
    },
    padding: `${theme.spacing(1)}px 0`,
    boxDecorationBreak: 'clone',

    '& table': {
      padding: `${theme.spacing(1)}px 0`,
      boxDecorationBreak: 'clone',
    },
  },

  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  title: {
    margin: 0,
    textDecoration: 'underline',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),

    ['@media print']: {
      // pageBreakInside: 'avoid',
      // display: 'block',
    },

    '& pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  sectionContent: {
    ...TYPOGRAPHY.SMALL_REGULAR,

    ['@media print']: {
      // pageBreakInside: 'avoid',
      // display: 'block',
    },
  },
  findingAdvice: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.BLUE_1,
  },

  borderedBottom: {
    borderBottom: `1px solid ${COLORS.GREY_5}`,
  },
}));

// const columns: Array<Column<SecurityFindingDTO, keyof SecurityFindingDTO>> = [
//   {
//     title: 'Domain',
//     width: '50%',
//     render: (v) => <span style={{ wordBreak: 'break-word' }}>{v?.domain}</span>,
//   },
//   {
//     title: 'Finding',
//     width: '50%',
//     render: (v) => <span dangerouslySetInnerHTML={{ __html: v.value }} />,
//   },
// ];

interface Props {
  finding: SecurityFindingBySlugDTO;
  securityScan: SecurityScanDTO;
}

// export const PrintFinding = ({ securityScan, finding }: Props) => {
export const PrintFinding = ({ finding }: Props) => {
  const classes = useStyles();
  // const { data } = useSecurityFindings({ scanId: securityScan.id, slug: finding.slug });

  return (
    <div className={cx(classes.root, finding?.recommendation && classes.borderedBottom)}>
      <div className={classes.pageBreakInside}>
        <div className={classes.heading}>
          <h4 className={classes.title}>{finding.name}</h4>
          <Badge
            bordered
            variant={getSeverityBadgeVariant(finding.severity)}
            style={{ minWidth: 80, width: 80 }}
            typography={TYPOGRAPHY.SMALL_BOLD}
          >
            {startCase(finding.severity)}
          </Badge>
        </div>

        <div className={classes.content}>
          {finding.description && (
            <div className={classes.sectionContent} dangerouslySetInnerHTML={{ __html: finding.description }}></div>
          )}

          {/* {data && data?.length > 0 && (
            <div className={classes.sectionContent}>
              <Table<SecurityFindingDTO>
                semiBordered
                hideHeadersWhenEmpty
                columns={columns}
                data={data}
                rowKey={(el, idx) => `${el.domain}-${idx}`}
              />
            </div>
          )} */}

          {finding.recommendation && (
            <div className={classes.sectionContent}>
              <span className={classes.findingAdvice}>Our advice: </span>
              <span dangerouslySetInnerHTML={{ __html: finding.recommendation }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
