import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  searchIcon: {
    position: 'absolute',
    left: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchInput: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${COLORS.GREY_4}`,
    background: COLORS.WHITE,
    borderRadius: theme.spacing(1),
    width: 270,
    '&:focus': {
      borderColor: COLORS.BLUE_1,
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: COLORS.GREY_3,
    },
  },
  clearIcon: {
    display: 'flex',
    border: 'none',
    margin: 0,
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    background: 'none',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
}));

interface Props {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
}

export function Searchbar({ value, setValue, placeholder }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchIcon className={classes.searchIcon} />
      <input
        placeholder={placeholder || 'Search...'}
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        className={classes.searchInput}
      />
      {value.length > 0 && (
        <button className={classes.clearIcon} onClick={() => setValue('')}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
