import { ControlProps, Layout } from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, uniq } from 'lodash';
import isString from 'lodash/isString';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { JsonFormsLabelDescription } from '../JsonFormsLabelDescription';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  checkboxWrapperTitle: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    display: 'flex',
    justifyContent: 'space-between',
  },

  checkButton: {
    cursor: 'pointer',
    marginLeft: 'auto',
    marginTop: 'auto',
    color: COLORS.BLUE_1,
    textAlign: 'center',
    minWidth: theme.spacing(10),
  },

  elementsWrapper: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',

      '& .MuiCardHeader-root': {
        padding: 0,
      },
    },

    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
}));

const _CheckboxWrapper: React.FC<ControlProps> = ({
  data,
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  description,
  errors,
  label,
  handleChange,
  required,
}) => {
  const classes = useStyles();

  if (isEmpty((uischema as unknown as Layout).elements) || !visible) {
    return null;
  }

  const elementsState = uniq(Object.values(data));
  const handleChangeAll = () => {
    for (const key in data) {
      handleChange(`${path}.${key}`, !elementsState[0]);
    }
  };

  return (
    <Grid container direction='column' spacing={0} className={classes.root}>
      <InputLabel error={!!errors} required={!!required} className={classes.checkboxWrapperTitle}>
        <JsonFormsLabelDescription label={isString(label) ? label : label.default} description={description} />
        <div onClick={handleChangeAll} role='button' tabIndex={0} className={classes.checkButton}>
          {!elementsState[0] ? 'Check all' : 'Uncheck all'}
        </div>
      </InputLabel>
      {(uischema as unknown as Layout).elements.map((child, index) => (
        <Grid item key={`${path}-${index}`} xs className={classes.elementsWrapper}>
          <ResolvedJsonFormsDispatch
            uischema={child}
            schema={schema}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const CheckboxWrapper = withJsonFormsControlProps(_CheckboxWrapper);
