import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { CustomTextField } from 'components/CustomTextField';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { Modal } from 'components/Modal';
// import { RadioSelect } from 'components/RadioSelect';
import { AgentDTO } from 'dtos/agent';
import {
  ALERT_CATEGORIES,
  ALERT_CONDITIONS,
  ALERT_METRIC_LABELS,
  ALERT_METRIC_VALUES,
  ALERT_METRICS,
  AlertCategory,
  AlertMetric,
  AlertPreference,
  AlertSeverity,
} from 'dtos/alert';
import { useToasters } from 'hooks/useToasters';
// import startCase from 'lodash/startCase';
import { useEditAlertPreferences } from 'queries/useAlerts';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioSelectVertical: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  button: {
    display: 'block',
    minWidth: 200,
    // marginLeft: 'auto',
    marginTop: theme.spacing(4),
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '12px',
      color: '#232830',
      fontWeight: 500,
    },
  },
  edit: {
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
  invite: {},
  checkbox: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'top',
    marginTop: theme.spacing(1.5),

    '& label': {
      color: '#232830',
      cursor: 'pointer',
    },
  },
}));

interface Props {
  alertPreference: AlertPreference;
  onClose?: () => void;
  allUsers?: AgentDTO[];
}

export const EditAlertPreference = ({ alertPreference, onClose, allUsers }: Props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const [selectUsers, setSelectUsers] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    alertPreference.agentId !== 'new' && alertPreference?.email ? [alertPreference.agentId] : [],
  );

  // useEffect(() => {
  //   if (alertPreference?.email) {
  //     setSelectedEmails([...(selectedEmails || []), alertPreference.agentId]);
  //   }
  // }, [alertPreference, selectedEmails, setSelectedEmails]);

  const { mutate, isLoading } = useEditAlertPreferences({
    onSuccess: () => {
      // showToaster(`Successfully updated alert preferences`);
      onClose && onClose();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const { handleSubmit, control, watch, setValue } = useForm<AlertPreference>({
    defaultValues: {
      id: alertPreference?.id || 'id' + new Date().getTime(),
      category: alertPreference?.category || AlertCategory.INTERNAL_VULNERABILITIES,
      condition: alertPreference?.condition || ALERT_CONDITIONS[AlertCategory.INTERNAL_VULNERABILITIES],
      email: alertPreference?.email || false,
      text: alertPreference?.text || false,
      agentId: alertPreference?.agentId,
    },
  });

  const selectCategory = watch('category');
  const selectMetric = watch('condition.metric');

  const onSubmit = (data: AlertPreference) => {
    const editUser = (allUsers || []).find((a) => a.id === data.agentId);
    const selectedEmailUsers = (allUsers || []).filter((a) => selectedEmails.includes(a.id));
    const finalUsers = data.agentId === 'new' ? selectedEmailUsers : editUser ? [editUser] : [];

    // console.log(data, finalUsers, selectedEmails);

    for (const user of finalUsers) {
      const ap = {} as AlertPreference;
      Object.assign(ap, data);

      if (!editUser) {
        ap.agentId = user.id;
        ap.email = selectedEmails.includes(user.id);
      }

      user.alertPreferences ||= [];

      const index = user.alertPreferences.map((ap) => ap.id).indexOf(ap.id);
      if (index > -1) {
        user.alertPreferences[index] = ap;
      } else {
        user.alertPreferences.push(ap);
      }

      mutate({
        id: user.id,
        alertPreferences: user.alertPreferences || [],
      });
    }

    showToaster(`Successfully updated alert policies`);
  };

  const editUser = useMemo(() => {
    return (allUsers || []).find((a) => a.id === alertPreference?.agentId);
  }, [allUsers, alertPreference]);

  return (
    <>
      <Modal
        title={selectUsers ? `Configure Alert Delivery` : `Configure Alert Policy`}
        isOpen={alertPreference !== null}
        onClose={() => onClose && onClose()}
        openButton={
          <Button variant='outlined' color='default' className={classes.edit}>
            Edit
          </Button>
        }
        removePaddingRight
        fullWidth
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!selectUsers && (
              <>
                <Controller
                  name='category'
                  control={control}
                  defaultValue={AlertCategory.INTERNAL_VULNERABILITIES}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='select-alert-category'>Alert Category</InputLabel>
                      <Select
                        {...field}
                        labelId='select-alert-category'
                        label='Alert Category'
                        value={field.value}
                        onChange={(el) => {
                          const alertCategory = el.target.value as AlertCategory;
                          field.onChange(alertCategory);
                          setValue('condition', ALERT_CONDITIONS[alertCategory]);
                          // setValue('condition.value', ALERT_CONDITIONS[alertCategory].value);
                        }}
                      >
                        {Object.keys(ALERT_CATEGORIES)
                          .map((c) => {
                            return {
                              label: ALERT_CATEGORIES[c as any as AlertCategory],
                              value: c,
                            };
                          })
                          .map((category) => (
                            <MenuItem key={category.value} value={category.value}>
                              {category.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                />

                <p>Notify When {ALERT_CATEGORIES[selectCategory]}</p>

                <Controller
                  name='condition.metric'
                  control={control}
                  defaultValue={ALERT_METRICS[selectCategory][0]}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id='select-alert-metric'>Alert Metric and Condition</InputLabel>
                      <Select
                        {...field}
                        labelId='select-alert-metric'
                        label='Alert Metric'
                        value={field.value}
                        onChange={(el) => {
                          const alertMetric = el.target.value as AlertMetric;
                          field.onChange(alertMetric);
                          setValue('condition.value', ALERT_METRIC_VALUES[alertMetric]);
                        }}
                      >
                        {ALERT_METRICS[selectCategory]
                          .map((c) => {
                            return {
                              label: ALERT_METRIC_LABELS[c],
                              value: c,
                            };
                          })
                          .map((metric) => (
                            <MenuItem key={metric.value} value={metric.value}>
                              {metric.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                />

                {selectMetric === AlertMetric.SEVERITY && (
                  <Controller
                    name='condition.value'
                    control={control}
                    defaultValue={AlertSeverity.HIGH}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id='select-alert-value'>Value</InputLabel>
                        <Select {...field} labelId='select-alert-value' label='Value' value={field.value}>
                          {Object.values(AlertSeverity)
                            .map((c) => {
                              return {
                                label: c,
                                value: c,
                              };
                            })
                            .map((metric) => (
                              <MenuItem key={metric.value} value={metric.value}>
                                {metric.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}

                {(selectMetric === AlertMetric.EPSS ||
                  selectMetric === AlertMetric.CVSS ||
                  selectMetric === AlertMetric.COUNT) && (
                  <Controller
                    name='condition.value'
                    control={control}
                    defaultValue={''}
                    render={({ field }) => <CustomTextFieldShrink {...field} label={'Threshold Value'} type='number' />}
                  />
                )}

                <p>Is Detected.</p>

                <div className={classes.fieldRow}>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.button}
                    onClick={() => setSelectUsers(true)}
                  >
                    Next Step: Select Users
                  </Button>
                </div>
              </>
            )}

            {selectUsers && (
              <>
                <p>Select Users to Notify</p>

                {editUser && (
                  <>
                    <FormControlLabel
                      label={`Email: ${editUser?.email}`}
                      control={
                        <Controller
                          name='email'
                          control={control}
                          render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                        />
                      }
                    />

                    {/*agent.phone && (
                      <FormControlLabel
                        label={`Text: ${editUser?.phone}`}
                        control={
                          <Controller
                            name='text'
                            control={control}
                            render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                          />
                        }
                      />
                    )*/}
                  </>
                )}
                {!editUser && (
                  <div>
                    {(allUsers || []).map((v) => (
                      <div key={v.id} className={classes.checkboxContainer}>
                        <InputLabel id={`select-email-${v.id}`}>
                          <Checkbox
                            color='primary'
                            checked={(selectedEmails || []).includes(v.id)}
                            inputProps={{ 'aria-labelledby': `select-email-${v.id}` }}
                            className={classes.checkbox}
                            onClick={() => {
                              if ((selectedEmails || []).includes(v.id)) {
                                setSelectedEmails((selectedEmails || []).filter((s) => s !== v.id));
                              } else {
                                setSelectedEmails([...(selectedEmails || []), v.id]);
                              }
                            }}
                          />
                          {v.email}
                        </InputLabel>
                      </div>
                    ))}
                  </div>
                )}

                <div className={classes.fieldRow}>
                  <Button
                    color='default'
                    variant='outlined'
                    className={classes.button}
                    onClick={() => setSelectUsers(false)}
                  >
                    Previous Step
                  </Button>
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={isLoading}
                    className={classes.button}
                  >
                    {isLoading ? 'Saving...' : 'Finish: Save'}
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      </Modal>
    </>
  );
};
