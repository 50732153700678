import { makeStyles, Switch as SwitchComponent } from '@material-ui/core';
import { Column, Table } from 'components/Table';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useCurrentAgent } from 'queries/useAgent';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ApplicationViewContainer } from '../ApplicationViewContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    // marginBottom: theme.spacing(1),
    alignSelf: 'center',
  },

  section: {
    marginBottom: theme.spacing(3),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
  bordered: {
    border: 'solid 1px #ededed',
  },
}));

// const tableData = (company: CompanyDTO): TableRow[] => {
//   return []
//   const domainItem = {
//     field: 'Company Domain',
//     value: company.domain,
//   };

//   return [
//     {
//       field: 'Company Name',
//       value: company.orgName,
//     },
//     ...(company.domain !== 'nodomain.local' ? [domainItem] : []),
//     {
//       field: 'Status',
//       value: company?.clientStatus && <ClientStatusLabel clientStatus={company.clientStatus} />,
//     },
//     {
//       field: 'Category',
//       value: company.clientCategory && CLIENT_CATEGORIES[company.clientCategory],
//     },
//     {
//       field: 'Anti Virus',
//       value: company.antiVirus,
//     },
//   ];
// };

interface Props {
  application: ApplicationWithAgentDTO;
}

interface AlertPreference {
  alert: string;
  enabled: boolean;
}

export const AlertsPreferencesView = ({ application }: Props) => {
  const classes = useStyles();
  const { data: agent } = useCurrentAgent();
  // const [selectedAlertPreference, setSelectedAlertPreference] = useState<AlertPreference | null>(null);

  const columns: Column<AlertPreference, keyof AlertPreference>[] = useMemo(() => {
    return [
      {
        title: 'Alert',
        render: (row) => row.alert,
      },
      {
        title: 'Status',
        width: '20%',
        render: (row) => (
          <SwitchComponent checked={row.enabled} onChange={() => (row.enabled = !row.enabled)} color='primary' />
        ),
      },
    ];
  }, []);

  const data = useMemo(() => {
    return [
      {
        alert: 'New Critical Vulnerabilities',
        enabled: true,
      },
      {
        alert: 'New Typo-squatting Domains',
        enabled: false,
      },
      {
        alert: 'New Failed M365 Logins',
        enabled: application.monitoringEnabled,
      },
    ];
  }, [application]);

  return (
    <ApplicationViewContainer>
      <div className={classes.root}>
        <div className={classes.section}>
          <div className={classes.actionsContainer}>
            <div className={classes.title}>Preferences for {agent?.email}</div>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.bordered}>
            <Table<AlertPreference>
              // bordered
              hideHeadersWhenEmpty
              columns={columns}
              data={data}
              rowKey={(el) => el.alert}
            />
          </div>
        </div>
      </div>
    </ApplicationViewContainer>
  );
};
