import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { QuoteDocumentDTO, QuoteDocumentType } from 'dtos/quotes';
import { useToasters } from 'hooks/useToasters';
import { useCreateQuoteDocument } from 'queries/useQuote';
import React, { useCallback, useRef, useState } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { DeleteDocumentButton } from './DeleteDocumnetButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 45,
    border: `1px solid ${COLORS.GREY_4}`,
    display: 'flex',
    '& + $root': {
      borderTop: 'none',
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },

    '&:last-of-type': {
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(1 / 4)}px  ${theme.spacing(2)}px`,
    paddingRight: theme.spacing(0.5),
  },
  select: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    width: 200,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${COLORS.GREY_4}`,
  },
  label: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
    color: COLORS.RED_1,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    border: 0,
    background: 0,
  },
  fileSelectButton: {
    marginLeft: theme.spacing(-1),
  },
}));

interface Props {
  hasQuoteLetter: boolean;
  quoteId: string;
  replicateToQuotesIds?: string[];
  document?: QuoteDocumentDTO;
}

const DEFAULT_DOCUMENT_TYPE = QuoteDocumentType.QUOTE_LETTER;

export const DocumentRow = ({ quoteId, document, replicateToQuotesIds }: Props) => {
  const [selectedType, setSelectedType] = useState(DEFAULT_DOCUMENT_TYPE);
  const fileInput = useRef<null | HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const { showToaster, toasterErrorHandler } = useToasters();
  const classes = useStyles();

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const list = e.target.files;

      if (list) {
        setFile(list[0]);
      }
    },
    [setFile],
  );

  const { mutate: createQuoteDocument, isLoading: isUploading } = useCreateQuoteDocument();

  const handleUploadClick = useCallback(async () => {
    if (file) {
      try {
        await createQuoteDocument({
          quoteId,
          file: file,
          type: selectedType,
        });

        if (replicateToQuotesIds) {
          for (const id of replicateToQuotesIds) {
            await createQuoteDocument({
              quoteId: id,
              file: file,
              type: selectedType,
            });
          }
        }

        showToaster(`Successfully uploaded a ${DOCUMENT_TYPE_HUMANIZED_NAME[selectedType]}`);
        setFile(null);
        setSelectedType(DEFAULT_DOCUMENT_TYPE);

        if (fileInput.current) {
          fileInput.current.value = '';
        }
      } catch (e) {
        toasterErrorHandler(e);
      }
    }
  }, [file, createQuoteDocument, quoteId, selectedType, replicateToQuotesIds, showToaster, toasterErrorHandler]);

  return (
    <div className={classes.root}>
      <Select
        className={classes.select}
        disabled={!!document}
        value={document?.documentType || selectedType}
        onChange={(el) => {
          setSelectedType(el.target.value as QuoteDocumentType);
        }}
      >
        {Object.values(QuoteDocumentType).map((type: QuoteDocumentType) => (
          <MenuItem key={type} value={type}>
            {DOCUMENT_TYPE_HUMANIZED_NAME[type]}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.content}>
        {document ? (
          <div className={classes.label}>{document.fileName}</div>
        ) : (
          <>
            <input style={{ display: 'none' }} type='file' ref={fileInput} onChange={handleInputChange} />
            <Button
              className={cx(classes.button, classes.fileSelectButton)}
              onClick={() => {
                if (fileInput.current) {
                  fileInput.current.click();
                }
              }}
            >
              {file ? `File: ${file.name}` : 'Upload Document'}
            </Button>
          </>
        )}
        <div className={classes.actions}>
          {!document && (
            <Button
              variant='outlined'
              disabled={!file || isUploading}
              className={classes.button}
              onClick={handleUploadClick}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
          )}
          {document && <DeleteDocumentButton quoteId={quoteId} document={document} className={classes.button} />}
        </div>
      </div>
    </div>
  );
};
