import { SecurityScanDTO } from 'dtos/application';
import { SecurityScanType } from 'dtos/security-finding';
import { useSecurityGrades } from 'queries/useSecurityFinding';
import { useCallback } from 'react';
import { generateRefetchIntervalMs } from 'telivy-constants';

import { useExternalScan } from './useExternalScan';

interface Props {
  securityScan?: SecurityScanDTO;
}

export const useExternalScanGrades = ({ securityScan }: Props) => {
  const { isLoading, shouldPullFindings } = useExternalScan({
    securityScan,
  });

  const { data: grades, isLoading: isLoadingGrades } = useSecurityGrades(
    { scanId: securityScan?.id || '' },
    {
      enabled: !!securityScan,
      refetchInterval: shouldPullFindings ? generateRefetchIntervalMs() : false,
    },
  );

  const { data: previousGrades } = useSecurityGrades(
    {
      scanId: securityScan?.previousScan?.id || '',
    },
    {
      enabled: !!securityScan?.previousScan,
      refetchInterval: shouldPullFindings ? generateRefetchIntervalMs() : false,
    },
  );

  const getGradeByType = useCallback(
    (type: SecurityScanType) => {
      if (!grades) {
        if (!isLoadingGrades && previousGrades && previousGrades[type]) {
          return previousGrades[type];
        }

        return undefined;
      }

      return grades[type];
    },
    [grades, previousGrades, isLoadingGrades],
  );

  return {
    isLoading,
    isLoadingGrades,
    grades,
    previousGrades,
    getGrateByType: getGradeByType,
  };
};
