import { FormControlLabel, FormGroup, Switch as SwitchComponent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { MonitoringFrequencyModal } from './MonitoringFrequencyModal';
import { RiskAssessmentHistory } from './RiskAssessmentHistory';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    paddingBottom: theme.spacing(),
  },

  section: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  isAdmin?: boolean;
}

export const ApplicationMonitoringView = ({ application, isAdmin }: Props) => {
  const classes = useStyles();

  const [onlyActive, setOnlyActive] = useState(false);

  if (
    !isAdmin &&
    (!isSecurityApplication(application) ||
      !application.monitoringEnabled ||
      !application.agent.agency?.enableRiskMonitoring)
  ) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Risk Monitoring</title>
      </Helmet>

      <div className={classes.section}>
        <div>
          <div className={classes.title}>Risk Monitoring</div>
          <FormGroup>
            <FormControlLabel
              control={
                <SwitchComponent checked={onlyActive} onChange={() => setOnlyActive(!onlyActive)} color='primary' />
              }
              label='Compare only active devices from latest scan'
            />
          </FormGroup>
        </div>

        <MonitoringFrequencyModal application={application} />
      </div>

      <RiskAssessmentHistory application={application} onlyActive={onlyActive} />
    </ApplicationViewContainer>
  );
};
