import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Badge } from 'components/Badge';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { getGradeBadgeVariant } from 'templates/SecurityAssessments/components/ExternalScanItem';

export const useStyles = makeStyles((theme) => ({
  smallGrade: {
    fontSize: theme.spacing(1.5),
    height: theme.spacing(3),
    minWidth: theme.spacing(3),
    width: theme.spacing(3),
    boxSizing: 'border-box',
  },

  bigGrade: {
    height: theme.spacing(6),
    minWidth: theme.spacing(6),
    width: theme.spacing(6),
    boxSizing: 'border-box',
  },
}));

export enum OverallScoreBadgeVariant {
  small = 'small',
  // medium = 'medium',
  // large = 'large',
  big = 'big',
}

interface Props {
  securityOverallScore?: number | null;
  variant?: keyof typeof OverallScoreBadgeVariant;
  // variant?: OverallScoreBadgeVariant;
  className?: string;
  typography?: ValueOf<typeof TYPOGRAPHY>;
  style?: React.CSSProperties;
  bordered?: boolean;
}

export const OverallScoreBadge = ({
  securityOverallScore,
  variant = OverallScoreBadgeVariant.big,
  className,
  typography,
  bordered,
  style,
}: Props) => {
  const classes = useStyles();

  const primaryClassName = {
    [OverallScoreBadgeVariant.small]: classes.smallGrade,
    [OverallScoreBadgeVariant.big]: classes.bigGrade,
  };

  const primaryTypography = {
    [OverallScoreBadgeVariant.small]: TYPOGRAPHY.REGULAR_BOLD,
    [OverallScoreBadgeVariant.big]: TYPOGRAPHY.TITLE_3,
  };

  return (
    <Badge
      className={cx(primaryClassName[variant], className)}
      typography={typography || primaryTypography[variant]}
      bordered={bordered}
      style={style}
      variant={getGradeBadgeVariant(parseScoreToGrade(securityOverallScore))}
    >
      {parseScoreToGrade(securityOverallScore) || '-'}
    </Badge>
  );
};
