import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch as SwitchComponent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { AgentDTO } from 'dtos/agent';
import { useCreateEditAgency } from 'queries/useAgency';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  agent: AgentDTO;
  className?: string;
}

interface Form {
  redirectLink: string | null;
}

export const RedirectLinkSwitch: React.FC<Props> = ({ agent }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const currentRedirectLink = useMemo(() => agent.agency?.redirectLink, [agent]);
  const [selected] = useState(Boolean(currentRedirectLink));

  const { mutate, isLoading } = useCreateEditAgency(agent.agencyId || '', {
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      redirectLink: currentRedirectLink,
    },
  });

  const onSubmit = (data: Form) => {
    if (selected) {
      mutate({ redirectLink: null });
    } else {
      mutate({ redirectLink: data.redirectLink });
    }
  };

  const FORM_ID = 'redirect_link_form';

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<SwitchComponent checked={selected} onChange={() => setIsOpen(true)} color='primary' />}
          label={selected ? `Redirect enabled to ${currentRedirectLink}` : 'Enable Redirect'}
        />
      </FormGroup>

      <Dialog fullWidth={true} open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{`${selected ? 'Disable' : 'Enable'} Redirect Link`}</DialogTitle>
        <DialogContent key='content'>
          <IconButton className={classes.closeButton} onClick={() => setIsOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
          {/*<Typography variant='body1' className={classes.sectionTitle}>
            Redirect Link
          </Typography>*/}
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              <Controller
                name='redirectLink'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <CustomTextFieldShrink {...field} label='Redirect Link' required disabled={selected} />
                )}
              />
            </section>
          </form>
        </DialogContent>
        <DialogActions key='actions'>
          <div>
            <Button disabled={isLoading} type='submit' color='primary' variant='contained' form={FORM_ID}>
              {selected ? 'Clear and Disable' : 'Save and Enable'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
