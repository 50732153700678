import { RiskScatter, RiskScatterDataPoint } from 'components/charts/RiskScatter';
import { RiskAnalysisItem } from 'dtos/deep-scan';
import React, { useMemo } from 'react';

export const useDataRiskByDarkWebSecurityChart = (enableCharts: boolean, data?: RiskAnalysisItem[]) => {
  const riskByDarkWebSecurity = useMemo((): RiskScatterDataPoint[] => {
    return (data || [])
      .map((row) => ({
        risk: row.darkWebSecurity || null,
        dataRisk: row.dataRisk || 0,
        name: row.hostname,
      }))
      .filter((row) => row.risk !== null && row.dataRisk !== 0);
  }, [data]);

  const chart = useMemo(() => {
    return (
      <RiskScatter
        locked={!enableCharts}
        id='risk-by-data-cost'
        title='Data Risk by Dark Web Security'
        data={riskByDarkWebSecurity}
      />
    );
  }, [enableCharts, riskByDarkWebSecurity]);

  return {
    chart,
    riskByDarkWebSecurity,
  };
};
