import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DeepScanDTO, PII_TYPE_LABELS, PiiFileDTO } from 'dtos/deep-scan';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },

  paragraph: {
    maxWidth: 1000,
  },
}));

interface Props {
  data?: PiiFileDTO;
  isOpen: boolean;
  handleClose: () => void;
  deepScan?: DeepScanDTO;
}

export const PiiFileDetailsModal: React.FC<Props> = ({ data, isOpen, handleClose, deepScan }) => {
  const classes = useStyles();

  const renderParagraph = (label: string, text?: string | number | null) => {
    if (!text) return null;

    return (
      <p className={classes.paragraph}>
        <b>{label}:</b>
        <br />
        {text}
      </p>
    );
  };

  return (
    <Dialog fullWidth={true} open={isOpen} onClose={handleClose}>
      <DialogContent>
        <h3 className={classes.title}>
          {data?.piiType ? PII_TYPE_LABELS[deepScan?.piiConfig?.country || 'US'][data?.piiType] : data?.piiType}
        </h3>
        {renderParagraph('File Path', data?.path)}
        {renderParagraph('Matches', data?.matchesCount)}
        {data?.piiType &&
          renderParagraph(
            `Risk (in ${deepScan?.piiConfig?.currency})`,
            `${deepScan?.piiConfig?.currency}${(data?.falsePositive
              ? 0
              : ((deepScan?.piiConfig?.costs || {})[data?.piiType] || 0) * (data?.matchesCount || 0)
            ).toLocaleString()}`,
          )}
        {/*renderParagraph('Confidence', data?.confidence)*/}
        {renderParagraph('Data Found', data?.matches)}
      </DialogContent>
    </Dialog>
  );
};
