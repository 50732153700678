import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import { useRiskAssessmentDisabled } from 'hooks/useAccountDisabled';
import { useToasters } from 'hooks/useToasters';
import { useConvertToRiskAssessment } from 'queries/useApplications';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  applicationId: string;
  className?: string;
  conversionPermitted?: boolean;
}

export const ConvertButton: React.FC<Props> = ({ applicationId, className, conversionPermitted }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const riskAssessmentDisabled = useRiskAssessmentDisabled();

  const { mutate, isLoading } = useConvertToRiskAssessment(applicationId, {
    onSuccess: () => {
      showToaster(`Converted to Risk Assessment successfully.`);
      setIsModalOpen(false);
      navigate(insertIdToRoute(ROUTES.agent.application.ROOT, applicationId));
    },
    onError: (e) => {
      toasterErrorHandler(e);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const MODAL_TITLE = 'Confirm Convert to Risk Assessment';
  const MODAL_TEXT = 'This External Assessment will be converted to Risk Assessment. Do you want to proceed?';

  return (
    <>
      <Tooltip title='Convert to Risk Assessment'>
        <Button
          className={className}
          onClick={handleModalOpen}
          variant='outlined'
          color='default'
          disabled={!conversionPermitted}
          startIcon={<UpdateRoundedIcon />}
        >
          Convert
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            {MODAL_TITLE}
          </Typography>
          <Typography variant='body1'>{MODAL_TEXT}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>{conversionPermitted ? 'Cancel' : 'Close'}</Button>
          {riskAssessmentDisabled ? (
            <div>
              <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
                Please upgrade account to convert. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
              </SecurityCard.Badge>
            </div>
          ) : (
            <Button
              variant='contained'
              color='primary'
              disabled={isLoading}
              onClick={() => mutate()}
              startIcon={<UpdateRoundedIcon />}
            >
              {isLoading ? 'Submitting...' : 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
