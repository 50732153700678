import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import React from 'react';

import { EditAgencyNameButton } from './EditAgencyNameButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    // marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AgencyData = ({ agent }: Props) => {
  const classes = useStyles();

  const isAgencyAdmin = agent.role === 'agency_admin' || agent.role === 'telivy_admin';
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h2>{agent.agency?.name || 'Not named yet'}</h2>
      </div>
      {isAgencyAdmin ? (
        <div className={classes.flexContainer}>
          <EditAgencyNameButton agent={agent} />
        </div>
      ) : null}
    </div>
  );
};
