import { AxiosError } from 'axios';
import {
  CreateManualQuoteDTO,
  QuoteDocumentDTO,
  QuoteDocumentType,
  QuoteDTO,
  QuoteLetterCheckResultDTO,
  ScheduleApiQuotingDTO,
  UpdateManualQuoteDTO,
} from 'dtos/quotes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { ApplicationService } from 'services/Application';
import { QuoteService } from 'services/Quote';

export const useCreateManualQuote = (
  applicationId: string,
  options?: UseMutationOptions<QuoteDTO[], AxiosError, Array<CreateManualQuoteDTO & { quoteLetter?: File }>>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'quote-creation',
    (data: Array<CreateManualQuoteDTO & { quoteLetter?: File }>) =>
      Promise.all(
        data.map(({ quoteLetter, ...quoteData }) =>
          ApplicationService.createManualQuote(applicationId, quoteData, quoteLetter),
        ),
      ),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('application-quotes');
        await queryClient.refetchQueries('application-quotes-count');

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useScheduleQuoting = (
  applicationId: string,
  options?: UseMutationOptions<void, AxiosError, ScheduleApiQuotingDTO>,
) => {
  return useMutation(
    'quote-schedule-api-quoting',
    (data: ScheduleApiQuotingDTO) => QuoteService.scheduleApiQuoting(applicationId, data),
    options,
  );
};

export const useCheckQuoteLetter = (options?: UseMutationOptions<QuoteLetterCheckResultDTO[], AxiosError, File>) => {
  return useMutation('check-quote-letter', (data: File) => QuoteService.checkQuoteLetter(data), options);
};

export const useInReviewQuotes = (applicationId: string) => {
  return useQuery(['quote-in-review', applicationId], () => QuoteService.getAllQuotesInReview(applicationId));
};

export const useUpdateQuote = (
  options?: UseMutationOptions<QuoteDTO[], AxiosError, Array<UpdateManualQuoteDTO & { id: string }>>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'quote-update',
    (data: Array<UpdateManualQuoteDTO & { id: string }>) =>
      Promise.all(data.map(({ id, ...data }) => QuoteService.updateQuote(id, data))),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('application-quotes');

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useQuoteDocuments = (
  applicationId: string,
  quoteId: string,
  options?: UseQueryOptions<QuoteDocumentDTO[]>,
) =>
  useQuery<QuoteDocumentDTO[]>(
    ['quote-documents', applicationId, quoteId],
    () => ApplicationService.getApplicationQuoteDocuments(applicationId, quoteId),
    options,
  );

export const useDownloadQuoteDocument = (
  quoteId: string,
  options?: UseMutationOptions<void, AxiosError, QuoteDocumentDTO>,
) =>
  useMutation(
    'quote-document-download',
    (document: QuoteDocumentDTO) => QuoteService.downloadQuoteDocument(quoteId, document),
    options,
  );

interface CreateQuoteDocumentData {
  file: File;
  type: QuoteDocumentType;
  quoteId: string;
}

export const useCreateQuoteDocument = (
  options?: UseMutationOptions<QuoteDocumentDTO, AxiosError, CreateQuoteDocumentData>,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    'create-quote-document',
    async (data: CreateQuoteDocumentData) => {
      const uploaded = await QuoteService.uploadQuoteDocument(data.quoteId, data.type, data.file);
      return QuoteService.createQuoteDocument(data.quoteId, {
        documentPath: uploaded.path,
        fileName: data.file.name,
        documentType: data.type,
      });
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('quote-documents');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeleteQuoteDocument = (quoteId: string, options?: UseMutationOptions<void, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation(
    'delete-quote-document',
    (documentId: string) => QuoteService.deleteQuoteDocument(quoteId, documentId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries('quote-documents');

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeleteQuote = (options?: UseMutationOptions<void, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation('delete-quote', (quoteId: string) => QuoteService.deleteQuote(quoteId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries('application-quotes');
      await queryClient.refetchQueries('application-quotes-count');

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
