import { CreateDocumentDTO, DocumentDTO } from 'dtos/_common';
import { CarrierDTO } from 'dtos/carrier';

export enum DeclinationCreationType {
  MANUAL = 'manual',
  API = 'api',
}

export enum DeclinationDocumentType {
  SECURITY_REPORT = 'security_report',
  PREFILLED_APP = 'prefilled_app',
}

export type DeclinationDocumentDTO = DocumentDTO<DeclinationDocumentType>;
export type CreateDeclinationDocumentDTO = CreateDocumentDTO<DeclinationDocumentType>;

export interface DeclinationDTO {
  id: string;
  reason: string | null;
  rawResponse: Record<string, unknown> | null;
  requestedEffectiveDate: string;
  requestedAggregateLimit: number;
  requestedRetention: number;
  serializedApplicationInput: Record<string, unknown>;
  creationType: DeclinationCreationType;
  carrier: CarrierDTO;
}

export interface CreateManualDeclinationDTO {
  effectiveDate: string;
  reason?: string;
  aggregateLimit: number;
  retention: number;
  carrierId: string;
}

export interface UpdateManualDeclinationDTO {
  requestedEffectiveDate?: string;
  requestedAggregateLimit?: number;
  reason?: string;
  requestedRetention?: number;
  carrierId?: string;
}
