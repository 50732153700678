import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Column, Table } from 'components/Table';
import { Device, ENCRYPTION_METHODS, EncryptionStatus } from 'dtos/deep-scan';
import React, { useMemo, useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

interface Props {
  device: Device;
  className?: string;
}

export const EncryptionDetailsModal: React.FC<Props> = ({ device, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const columns = useMemo((): Column<EncryptionStatus>[] => {
    return [
      {
        title: 'Drive',
        render: (row) => row.MountPoint,
      },
      {
        title: 'Capacity (GB)',
        render: (row) => row.CapacityGB,
      },
      {
        title: 'Volume Status',
        render: (row) => row.VolumeStatus || '',
      },
      {
        title: 'Disk Encryption Method',
        render: (row) => ENCRYPTION_METHODS[row.EncryptionMethod] || 'Unknown',
      },
      {
        title: 'Disk Encryption Status',
        render: (row) => `${row.EncryptionPercentage}%`,
      },
      {
        title: 'Protection Status',
        render: (row) => row.ProtectionStatus || '',
      },
      {
        title: 'Lock Status',
        render: (row) => row.LockStatus || '',
      },
    ];
  }, []);

  const label = useMemo(() => {
    const drives = device.encryptionStatus || [];
    const encPercent = drives.length
      ? drives.reduce((acc, drive) => acc + (drive.EncryptionPercentage || 0), 0) / drives.length
      : 0;
    return drives.length > 0 ? `${encPercent.toLocaleString()}%` : 'NA';
  }, [device]);

  return (
    <>
      <Button className={className} onClick={() => setIsModalOpen(true)}>
        {label}
      </Button>

      <Dialog fullWidth={true} maxWidth='lg' open={isModalOpen} onClose={handleClose}>
        <DialogContent>
          <div className={classes.title}>Disk Encryption Status for Device {device.deviceName}</div>

          <Table<EncryptionStatus, undefined>
            rowKey={(row) => `${row.MountPoint}`}
            data={(device?.encryptionStatus || []).sort(
              (a, b) => a.MountPoint.charCodeAt(0) - b.MountPoint.charCodeAt(0),
            )}
            rowContentCentered
            columns={columns}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
