import { makeStyles } from '@material-ui/core';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { ApplicationFormView } from '../form';
import { CompanyDetailsView } from './Details';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  targets: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    DEVICES: string;
  };
  company: {
    PARENT: string;
    ROOT: string;
    DETAILS: string;
    FORM: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
}

export const ApplicationCompanyView = ({ application, sourceRoute }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const viewOptions: Option<ValueOf<SourceRoute['company']>>[] = React.useMemo(() => {
    const options = [];

    options.push({
      label: `Details`,
      value: createSecurityRoute(sourceRoute.company.DETAILS, application.id),
    });

    options.push({
      label: `Questionnaire`,
      value: createSecurityRoute(sourceRoute.company.FORM, application.id),
    });

    return options;
  }, [sourceRoute, application]);

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Company - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute['company']>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route path={sourceRoute.company.DETAILS} element={<CompanyDetailsView application={application} />} />

        <Route path={sourceRoute.company.FORM} element={<ApplicationFormView application={application} />} />

        <Route index element={<Navigate replace to={sourceRoute.company.DETAILS} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
