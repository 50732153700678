import { withJsonFormsControlProps } from '@jsonforms/react';
import { DollarInput as TelivyDollarInput } from 'components/DollarInput';
import { CustomControlProps } from 'components/JsonForms/customProps';
import isString from 'lodash/isString';
import React from 'react';

export const _DollarInput = (props: CustomControlProps) => {
  const disabled = !props.enabled;

  if (!props.visible) {
    return null;
  }

  return (
    <TelivyDollarInput
      error={props.errors}
      disabled={disabled}
      required={props.required}
      label={isString(props.label) ? props.label : props.label.default}
      value={props.data || undefined}
      description={props.schema.description}
      onChange={(value) => {
        props.handleChange(props.path, value || undefined);
      }}
      placeholder={props.uischema.placeholder}
    />
  );
};

export const DollarInput = withJsonFormsControlProps(_DollarInput);
