// import { variantColorsMap } from 'components/Badge';
// import { CHART_OPTION } from 'components/charts/HistoryGrades';
// import { InternalScanType as SecurityScanType } from 'dtos/security-finding';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { TooltipProps } from 'recharts';
import { COLORS } from 'telivy-theme';
// import { parseScoreToGrade } from 'templates/SecurityAssessments';
// import { getGradeBadgeVariant } from 'templates/SecurityAssessments/components/ExecutiveSummaryItem';

// import { OverallScoreBadge } from '../OverallScoreBadge';

interface TooltipData {
  name: string;
  value: number;
  previous: number;
  opposite?: boolean;
}

interface CustomTooltipProps extends TooltipProps<number, string> {}

export const CustomGraphTooltipContent = (props: CustomTooltipProps) => {
  const { label, payload } = props;
  const hasLabel = !isNil(label);
  const finalLabel = hasLabel ? label : '';
  // const securityOverallScore = payload?.find((item) => item?.dataKey === 'securityOverallScore');
  // const items = payload || []; //)?.filter((item) => item?.dataKey !== 'securityOverallScore');

  const content = useMemo(() => {
    const items = payload || [];
    if (items && items.length > 0) {
      const dataItem = items.find((d) => d.dataKey === 'score');
      const tooltipData = (dataItem?.payload.distribution || []).sort((a: TooltipData, b: TooltipData) => {
        if (b?.name == 'Total' || a?.name == 'Total') return 1;

        return (b?.value || 0) - (a?.value || 0);
      });

      // console.log(items, tooltipData);

      return (
        <ul className='recharts-tooltip-item-list' style={{ padding: 0, margin: 0 }}>
          {tooltipData.map((item: TooltipData) => {
            let arrowIcon = undefined;
            if (item.value < (item.previous || 0)) {
              arrowIcon = <ArrowDropDownRoundedIcon style={{ color: item.opposite ? COLORS.RED_1 : COLORS.GREEN_1 }} />;
            } else if (item.value > (item.previous || 0)) {
              arrowIcon = <ArrowDropUpRoundedIcon style={{ color: item.opposite ? COLORS.GREEN_1 : COLORS.RED_1 }} />;
            }

            return (
              <li
                className='recharts-tooltip-item'
                key={`tooltip-item-${item.name}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: '#000',
                }}
              >
                <span>
                  {item.name == 'Total' ? (
                    <strong>
                      <i>{item.name}</i>
                    </strong>
                  ) : (
                    item.name
                  )}
                </span>
                <span
                  style={{
                    marginLeft: 'auto',
                    paddingLeft: 64,
                    width: 32,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {item.value.toLocaleString()}
                  {arrowIcon}
                </span>
              </li>
            );
          })}
        </ul>
      );
    }

    return null;
  }, [payload]);

  return (
    <div
      className='recharts-default-tooltip'
      style={{
        margin: 0,
        backgroundColor: '#fff',
        whiteSpace: 'nowrap',
        borderRadius: 24,
        border: '1px solid #EDEDED',
        padding: '16px 20px',
      }}
    >
      <p className='recharts-tooltip-label' style={{ margin: 0, fontSize: 16, fontWeight: 700 }}>
        {React.isValidElement(finalLabel) ? finalLabel : `${finalLabel}`}
      </p>
      {content}
    </div>
  );
};
