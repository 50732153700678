import { makeStyles } from '@material-ui/core/styles';
import { DetailsList } from 'components/DetailsList';
import { Message, Type } from 'components/Message';
import { Money } from 'components/Money';
import { PolicyDTO, PolicyStatus } from 'dtos/policy';
import { ReactComponent as InfoIcon } from 'images/info.svg';
import React from 'react';

import { Documents } from './Documents';
import { Payments } from './Payments';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    display: 'flex',
    gap: 50,
  },
  item: {
    flex: 1,
  },
  message: {
    marginBottom: theme.spacing(2.5),
  },
}));

interface Props {
  policy: PolicyDTO;
}

export const PolicyDetails = ({ policy }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {policy.status === PolicyStatus.DRAFT && (
        <Message type={Type.Info} icon={<InfoIcon />} className={classes.message}>
          You can send your client the purchase link on the top right corner.
        </Message>
      )}
      <div className={classes.row}>
        <div className={classes.item}>
          <DetailsList
            rows={[
              {
                value: policy.quote.carrier.displayName,
                title: 'Carrier',
              },
              {
                value: policy.quote.quoteNumber,
                title: 'Quote Number',
              },
              {
                value: policy.policyNumber || 'N/A',
                title: 'Policy Number',
              },
            ]}
          />
        </div>

        <div className={classes.item}>
          <DetailsList
            rows={[
              {
                value: <Money value={policy.limit} />,
                title: 'Limit',
              },
              {
                value: <Money value={policy.retention} />,
                title: 'Retention',
              },
              {
                value: <Money value={policy.premium} />,
                title: 'Premium',
              },
            ]}
          />
        </div>
      </div>

      <Documents policy={policy} />

      <Payments policy={policy} />
    </div>
  );
};
