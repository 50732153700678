import { withJsonFormsControlProps } from '@jsonforms/react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ComboBox from '@material-ui/lab/Autocomplete';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import { CustomControlProps } from 'components/JsonForms/customProps';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import { kebabCase } from 'lodash';
import isString from 'lodash/isString';
import React, { useCallback, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
}));

interface Option {
  const: string;
  title: string;
}

const renderOption = (option: Option, state: AutocompleteRenderOptionState) => {
  if (state.selected || !state.inputValue || state.inputValue.length === 0) {
    return option.title;
  }

  const parts = option.title.split(new RegExp(`(${state.inputValue})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={part.toLowerCase() === state.inputValue.toLowerCase() ? { fontWeight: 'bold', color: '#516AE6' } : {}}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export type Options = Array<{ const: string; title: string }>;

export const _Autocomplete: React.FC<CustomControlProps> = (props) => {
  const classes = useStyles();
  const [onlyFilteredOptionValue, setOnlyFilteredOptionValue] = useState<null | string>(null);
  const disabled = !props.enabled;
  const options: Options = props.schema.oneOf as Options;
  const selectedValue = props.data ? options.find((o) => o.const === props.data) : null;

  // Related issue: https://app.clickup.com/t/1x0wcq4
  // Many of our users use tab to navigate between fields. If user ends up filtering down the
  // options to only one value and there were no previously selected value, we want to save this value.
  const handleBlur = useCallback(() => {
    if (!selectedValue && onlyFilteredOptionValue) {
      props.handleChange(props.path, onlyFilteredOptionValue);
    }
  }, [onlyFilteredOptionValue, props, selectedValue]);

  const handleFilterOptions = useCallback(
    (options: Option[], params) => {
      const filtered = options.filter((option) => {
        return option.title.toLowerCase().includes(params.inputValue.toLowerCase());
      });

      if (filtered.length === 1) {
        setOnlyFilteredOptionValue(filtered[0].const);
      } else if (onlyFilteredOptionValue !== null) {
        setOnlyFilteredOptionValue(null);
      }

      return filtered;
    },
    [onlyFilteredOptionValue],
  );

  if (!props.visible) {
    return null;
  }

  return (
    <div
      className={classes.container}
      data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-autocomplete`}
    >
      <ComboBox
        fullWidth
        onBlur={handleBlur}
        disabled={disabled}
        disableListWrap
        value={selectedValue}
        options={options}
        filterSelectedOptions
        filterOptions={handleFilterOptions}
        getOptionLabel={(option) => option.title}
        renderOption={renderOption}
        onChange={(e, value) => {
          props.handleChange(props.path, value ? (value.const ? value.const : value) : undefined);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required}
            label={
              <JsonFormsLabelDescription
                label={isString(props.label) ? props.label : props.label.default}
                description={props.schema.description}
              />
            }
            error={!!props.errors}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            placeholder={props.uischema.placeholder}
            InputLabelProps={{ shrink: false }}
          />
        )}
      />

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const Autocomplete = withJsonFormsControlProps(_Autocomplete);
