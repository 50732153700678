import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from 'components/CustomTextField';
import { Modal } from 'components/Modal';
// import { RadioSelect } from 'components/RadioSelect';
import { AgencyDTO, CreateUpdateAgencyDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useCreateEditAgency } from 'queries/useAgency';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    display: 'block',
    minWidth: 200,
    marginLeft: 'auto',
    marginTop: theme.spacing(3),
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '12px',
      color: '#232830',
      fontWeight: 500,
    },
  },
  edit: {
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
  invite: {},
  formContainer: {
    minWidth: '400px',
  },
}));

interface Props {
  agency?: AgencyDTO;
}

export const InviteEditAgency = ({ agency }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useCreateEditAgency(agency?.id || '', {
    onSuccess: () => {
      showToaster(`Successfully ${agency ? 'updated' : 'created'} account`);
      setOpen(false);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const { handleSubmit, control, watch } = useForm<CreateUpdateAgencyDTO>({
    defaultValues: {
      name: agency?.name || agency?.domain || '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      createFirstUser: true,
    },
  });

  const onSubmit = (data: CreateUpdateAgencyDTO) => {
    const agentData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    };

    mutate({
      name: data.name,
      ...(agency ? {} : agentData),
    });
  };

  const createFirstUser = watch('createFirstUser');

  return (
    <>
      <Modal
        title={agency ? 'Edit Account' : 'Add Account'}
        isOpen={open}
        onClose={() => setOpen(false)}
        openButton={
          <Button
            variant={agency ? 'outlined' : 'contained'}
            color={agency ? 'default' : 'primary'}
            onClick={() => setOpen(true)}
            className={agency ? classes.edit : classes.invite}
          >
            {agency ? 'Edit Name' : 'Add Account'}
          </Button>
        }
      >
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='name'
              control={control}
              defaultValue=''
              render={({ field }) => <CustomTextField {...field} label='Company Name' required />}
            />
            {!agency && (
              <>
                <br />
                <br />
                <FormControlLabel
                  label='Create admin user for this account'
                  control={
                    <Controller
                      name='createFirstUser'
                      control={control}
                      render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                    />
                  }
                />
                {createFirstUser && (
                  <>
                    <Controller
                      name='firstName'
                      control={control}
                      defaultValue=''
                      render={({ field }) => <CustomTextField {...field} label='First Name' required />}
                    />
                    <Controller
                      name='lastName'
                      control={control}
                      defaultValue=''
                      render={({ field }) => <CustomTextField {...field} label='Last Name' required />}
                    />
                    <Controller
                      name='email'
                      control={control}
                      defaultValue=''
                      render={({ field }) => <CustomTextField {...field} label='Email' required />}
                    />
                    <Controller
                      name='password'
                      control={control}
                      defaultValue=''
                      render={({ field }) => <CustomTextField {...field} label='Password' type='password' required />}
                    />
                  </>
                )}
              </>
            )}
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} className={classes.button}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
