import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Divider, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import { Money } from 'components/Money';
import { kebabCase } from 'lodash';
import isString from 'lodash/isString';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  optionsContainer: {
    background: COLORS.GREY_6,
    padding: theme.spacing(0.5),
    display: 'flex',
    borderRadius: theme.spacing(1),
    gap: theme.spacing(0.5),

    [theme.breakpoints.down('sm')]: {
      background: 'none',
      flexDirection: 'column',
      padding: `${theme.spacing(0.5)}px 0`,
      gap: theme.spacing(1.5),
    },
  },
  button: {
    flex: 1,
    background: 'none',
    textAlign: 'center',
    color: COLORS.GREY_2,
    border: `1px solid ${COLORS.GREY_6}`,
    ...TYPOGRAPHY.SMALL_MEDIUM,
    padding: `${theme.spacing(2)}px 0`,
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      background: COLORS.GREY_6,
    },

    '&:disabled': {
      cursor: 'not-allowed',
      color: COLORS.GREY_3,
    },

    '&:hover:not($selected):not(:disabled)': {
      backgroundColor: COLORS.BLUE_3,
      borderColor: COLORS.BLUE_2,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: COLORS.BLUE_3,
    borderColor: COLORS.BLUE_1,
    borderRadius: theme.spacing(1),
    color: COLORS.GREY_1,

    '&:disabled': {
      color: COLORS.GREY_2,
      borderColor: COLORS.BLUE_2,
    },
  },
  money: {
    ...TYPOGRAPHY.MONO_REGULAR_REGULAR,
    padding: `${theme.spacing(0.5)}px 0`,

    '&$selected': {
      color: COLORS.BLUE_1,
    },
  },

  divider: {
    width: 1,
    backgroundColor: COLORS.GREY_5,
    height: 'auto',
    margin: `${theme.spacing(1)}px 0`,

    '&:last-of-type': {
      display: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type Variant = 'money' | undefined;

interface StylesProps {
  variant?: Variant;
}

interface RadioOptionProps {
  value: string;
  isSelected: boolean;
  disabled: boolean;
  onClick: (value: string) => void;
}

const RadioOption = (props: RadioOptionProps & StylesProps): JSX.Element => {
  const { variant, onClick, disabled, ...rest } = props;
  const classes = useStyles({ variant });

  return (
    <button
      className={cx(classes.button, props.variant && classes.money, props.isSelected && classes.selected)}
      onClick={() => onClick(props.value)}
      disabled={disabled}
      {...rest}
    >
      {variant === 'money' ? <Money value={parseFloat(props.value.replaceAll(',', ''))} /> : props.value}
    </button>
  );
};

export const _RadioGroup: React.FC<ControlProps & StylesProps> = (props) => {
  const classes = useStyles({});
  const options = props.schema.enum as string[];
  const disabled = !props.enabled;
  const variant = props.uischema.options?.money && 'money';

  if (!props.visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <InputLabel error={!!props.errors} required={!!props.required}>
        <JsonFormsLabelDescription
          label={isString(props.label) ? props.label : props.label.default}
          description={props.schema.description}
        />
      </InputLabel>
      <div
        className={classes.optionsContainer}
        data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-radio`}
      >
        {options.map((o, idx) => (
          <React.Fragment key={idx}>
            <RadioOption
              disabled={disabled}
              isSelected={props.data === o}
              value={o}
              onClick={(value) => props.handleChange(props.path, value)}
              variant={variant}
              data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-radio-option`}
            />
            <Divider orientation='horizontal' className={classes.divider} />
          </React.Fragment>
        ))}
      </div>

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const RadioGroup = withJsonFormsControlProps(_RadioGroup);
