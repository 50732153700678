import { ApplicationStatus } from 'dtos/application';
import { useClientApplicationActivePolicy } from 'queries/useApplications';
import React from 'react';

interface Props {
  applicationId: string;
  applicationStatus: ApplicationStatus;
}

export const PolicyEffectiveDate = ({ applicationId, applicationStatus }: Props) => {
  const { data: policyData } = useClientApplicationActivePolicy(applicationId, { retry: false });

  return policyData?.quote.effectiveDate && applicationStatus === ApplicationStatus.BOUND ? (
    <>{policyData?.quote.effectiveDate}</>
  ) : (
    <>{'-'}</>
  );
};
