import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useEditApplication } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    // position: 'absolute',
    marginRight: theme.spacing(1),
    // top: theme.spacing(3),
    // color: theme.palette.grey[500],
  },
  disclaimer: {
    margin: theme.spacing(4),
  },
  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  className?: string;
  locked?: boolean;
}

interface Form {
  organizationName: string;
}

export const EditAssessmentButton = ({ application, className, locked }: Props) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isLoading } = useEditApplication(application.id, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      organizationName: application.applicationResponse?.organization_name,
    },
  });

  const onSubmit = (data: Form) => {
    application.applicationResponse.organization_name = data.organizationName;
    mutate({
      applicationResponse: {
        ...application.applicationResponse,
        organization_name: data.organizationName,
      },
    });
  };

  const FORM_ID = 'edit_assessment_form';

  return (
    <>
      <Button className={className} onClick={handleClick} variant='outlined'>
        <EditRoundedIcon />
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {/*<DialogTitle>Edit Assessment Name</DialogTitle>*/}
        {locked ? (
          <div className={classes.disclaimer}>
            <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
              Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> to upgrade account.
            </SecurityCard.Badge>
          </div>
        ) : (
          <>
            <DialogContent key='content'>
              {/*<IconButton className={classes.closeButton} onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
              <Typography variant='body1' className={classes.sectionTitle}>
                Company details
              </Typography>*/}
              <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
                <section className={classes.section}>
                  <Controller
                    name='organizationName'
                    control={control}
                    defaultValue=''
                    render={({ field }) => <CustomTextFieldShrink {...field} label='Company Name' required />}
                  />

                  {/*<Controller
                    name='domain'
                    control={control}
                    defaultValue=''
                    render={({ field }) => <CustomTextFieldShrink {...field} label='Domain' required />}
                  />*/}
                </section>
              </form>
            </DialogContent>
            <DialogActions key='actions'>
              <div>
                <Button color='primary' variant='outlined' onClick={onClose} className={classes.closeButton}>
                  Cancel
                </Button>
                <Button type='submit' color='primary' variant='contained' disabled={isLoading} form={FORM_ID}>
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
