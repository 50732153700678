import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import cx from 'classnames';
import { CustomTextField } from 'components/CustomTextField';
import { Modal } from 'components/Modal';
import { RadioSelect } from 'components/RadioSelect';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { DeepScanTargetDTO, NmapVulnerabilityData } from 'dtos/deep-scan';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useDeepScanTargetNmapData } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { NmapVulnerabilityDetailsModal } from './NmapVulnerabilityDetailsModal';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  input: {
    flex: 1,
  },

  scoreBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  red: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED_1,
  },
  yellow: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.YELLOW_1,
  },
  green: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_1,
  },
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
}));

interface Props {
  deepScanId: string;
  target: DeepScanTargetDTO;
  className?: string;
  label: string;
}

const PER_PAGE = 10;

export enum SEVERITY {
  INFO = 'INFO',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const SEVERITY_TO_SCORE_RANGE = {
  // [SEVERITY.INFO]: [0, 0.1],
  [SEVERITY.LOW]: [0, 4],
  [SEVERITY.MEDIUM]: [4, 7],
  [SEVERITY.HIGH]: [7, 1000],
};

export const NmapVulnerabilitiesDetailsModal: React.FC<Props> = ({ deepScanId, target, className, label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVulnerability, setSelectedVulnerability] = useState<NmapVulnerabilityData | null>(null);
  const [severity, setSeverity] = useState<SEVERITY | null>(null);
  const [search, setSearch] = useState('');
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [cvesSorter, setCvesSorter] = useState<Sorter<keyof NmapVulnerabilityData> | undefined>({
    key: 'epss',
    order: SortOrder.DESC,
  });

  const { data, isLoading } = useDeepScanTargetNmapData(deepScanId, target.id, {
    enabled: Boolean(deepScanId && isModalOpen),
  });

  const handleClose = () => {
    setIsModalOpen(false);
    setSeverity(null);
    setSearch('');
    setPage(0);
  };

  const columns = useMemo((): Column<NmapVulnerabilityData, keyof NmapVulnerabilityData>[] => {
    return [
      {
        width: 180,
        title: '# / CVE',
        sortKey: 'id',
        render: (row) =>
          row.score && row.id !== 'INFO' ? (
            <a href={`https://nvd.nist.gov/vuln/detail/${row.id}`} target='_blank' rel='noreferrer'>
              {row.id}
            </a>
          ) : (
            'INFO'
          ),
      },
      {
        title: 'Title',
        sortKey: 'description',
        render: (row) =>
          row.title || `${row.description?.slice(0, 50)}${(row.description?.length ?? 0) > 50 ? '...' : ''}`,
      },
      {
        title: 'Source',
        // sortKey: 'foundIn.product',
        render: (row) =>
          row?.score ? (
            <span>
              {row?.foundIn?.product}
              <br />
              {row?.foundIn?.version}
            </span>
          ) : row?.foundIn?.port ? (
            `Port ${row?.foundIn?.port}`
          ) : (
            ''
          ),
      },
      {
        title: 'Published Date',
        sortKey: 'publishedDate',
        render: (row) => (row.publishedDate ? moment(row.publishedDate).format('DD MMM YYYY') : null),
      },
      {
        title: (
          <span>
            EPSS
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='EPSS'>
              <p>
                The EPSS (Exploit Prediction Scoring System) score represents the probability of exploitation in the
                wild during the next 30 days in the range from 0 to 1. For example, a CVE with EPSS of 0.96 has 96%
                chance to be exploited in the following 30 days.
              </p>
            </Modal>
          </span>
        ),
        sortKey: 'epss',
        render: (row) => row.epss && `${(row.epss * 100).toFixed(2)}%`,
      },
      {
        title: 'CVSS',
        sortKey: 'score',
        render: (row) => row.score,
      },
      {
        width: 80,
        title: 'Severity',
        render: (row) => {
          let color = classes.green;
          let label = row.score || 'Info';
          if (row.score && row.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.HIGH][0]) {
            color = classes.red;
            label = 'High';
          } else if (row.score && row.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.MEDIUM][0]) {
            color = classes.yellow;
            label = 'Medium';
          } else if (row.score && row.score > SEVERITY_TO_SCORE_RANGE[SEVERITY.LOW][0]) {
            color = classes.blue;
            label = 'Low';
          }

          return (
            <div className={classes.row}>
              <b className={cx(classes.scoreBox, color)}>{label}</b>
            </div>
          );
        },
      },
    ];
  }, [classes]);

  const sortedDeepScanInternalVulnerabilities = useMemo(() => {
    if (cvesSorter && data) {
      const notNullCves = data.filter((d) => d.score);
      const nullCves = data.filter((d) => !d.score);

      const notNullData = sortBy(notNullCves, cvesSorter.key);
      const nullData = sortBy(nullCves, cvesSorter.key);

      if (cvesSorter.order === SortOrder.DESC) {
        return ([] as NmapVulnerabilityData[]).concat(notNullData.reverse(), nullData.reverse());
      }

      return ([] as NmapVulnerabilityData[]).concat(notNullData, nullData);
    }

    return data;
  }, [data, cvesSorter]);

  const sortedAndFilteredData = useMemo(() => {
    let filteredData = sortedDeepScanInternalVulnerabilities || [];
    if (severity) {
      let minFound: number | undefined = undefined;
      let maxFound: number | undefined = undefined;
      filteredData = filteredData.filter((p) => {
        if (severity === SEVERITY.INFO) return !p.score;

        const score = parseFloat(`${p.score}`);
        const [min, max] = SEVERITY_TO_SCORE_RANGE[severity];

        if (minFound === undefined && score <= min) {
          minFound = score;
        }

        if (maxFound === undefined && score <= max) {
          maxFound = score;
        }

        return score > min && score <= max;
      });
    }

    if (search) {
      filteredData = filteredData.filter((p) => {
        const titleMatch = p.title?.toLowerCase().includes(search.toLowerCase());
        const descMatch = p.description?.toLowerCase().includes(search.toLowerCase());
        const idMatch = p.id?.toLowerCase().includes(search.toLowerCase());

        return titleMatch || idMatch || descMatch;
      });
    }

    // return sortBy(filteredData, (p) => parseFloat(`${p.score}`) || 0).reverse();
    return filteredData;
  }, [sortedDeepScanInternalVulnerabilities, search, severity]);

  const pageData = useMemo(() => {
    return sortedAndFilteredData.slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE);
  }, [page, sortedAndFilteredData]);

  return (
    <>
      <Button className={className} disabled={isLoading} onClick={() => setIsModalOpen(true)}>
        {isLoading ? <CircularProgress size={16} /> : label}
      </Button>

      <Dialog fullWidth={true} open={isModalOpen} maxWidth='lg' onClose={handleClose}>
        <DialogContent>
          <div className={classes.row}>
            <div className={classes.input}>
              <CustomTextField
                placeholder='Search for items'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(0);
                }}
              />
            </div>
            <RadioSelect<SEVERITY | null>
              selectedValue={severity}
              options={[
                { label: 'All', value: null },
                { label: 'High', value: SEVERITY.HIGH },
                { label: 'Medium', value: SEVERITY.MEDIUM },
                { label: 'Low', value: SEVERITY.LOW },
                { label: 'Info', value: SEVERITY.INFO },
              ]}
              onOptionSelect={(s) => {
                setSeverity(s.value);
                setPage(0);
              }}
            />
          </div>

          <Table<NmapVulnerabilityData, keyof NmapVulnerabilityData>
            pagination={isLoading ? undefined : { page, perPage: PER_PAGE, total: sortedAndFilteredData.length }}
            rowKey={(row) => `${row.id}-${row.title}`}
            onRowClick={(row) => setSelectedVulnerability(row)}
            data={isLoading ? undefined : pageData}
            className={classes.table}
            loading={isLoading}
            rowContentCentered
            columns={columns}
            sorter={cvesSorter}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setPage(pagination?.page);
              }

              const isSorterChanging = cvesSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setCvesSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setPage(0);
              }
            }}
          />
        </DialogContent>
      </Dialog>

      <NmapVulnerabilityDetailsModal
        handleClose={() => setSelectedVulnerability(null)}
        data={selectedVulnerability || undefined}
        isOpen={Boolean(selectedVulnerability)}
      />
    </>
  );
};
