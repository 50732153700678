import { SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > .MuiDrawer-paperAnchorBottom': {
      borderTopLeftRadius: theme.spacing(4),
      borderTopRightRadius: theme.spacing(4),
      maxHeight: '95%',
      minHeight: '95%',
    },
  },

  cardHeader: {
    ...TYPOGRAPHY.SMALL_BOLD,
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.GREY_5}`,
    paddingBottom: theme.spacing(3),
  },

  icon: {
    color: COLORS.GREY_3,
    fontSize: 18,
    cursor: 'pointer',
    width: 30,
    visibility: 'hidden',
  },
  closeIcon: {
    fontSize: 30,
    visibility: 'visible',
  },

  contentWrapper: {
    padding: theme.spacing(3),
    overflow: 'auto',
    boxSizing: 'border-box',
  },
}));

interface Props {
  title: string;
  position?: 'bottom';
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const OverlayCard: React.FC<Props> = ({ title, open, position = 'bottom', onOpen, onClose, children }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor={position}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      className={classes.root}
      disableDiscovery={true}
      disableSwipeToOpen={true}
    >
      <div className={classes.cardHeader}>
        <ArrowBackIosRoundedIcon className={classes.icon} />
        {title} <CloseRoundedIcon onClick={onClose} className={cx(classes.icon, classes.closeIcon)} />
      </div>
      <div className={classes.contentWrapper}>{children}</div>
    </SwipeableDrawer>
  );
};
