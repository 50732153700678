import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_BOLD,
    height: theme.spacing(5.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  actions: {
    display: 'flex',
    gap: theme.spacing(),

    '& button, & svg': {
      padding: 0,
      color: COLORS.GREY_1,
    },

    ['@media print']: {
      // Do not remove, this is a workaround for a bug in the print view
      display: 'none',
    },
  },
}));

export interface Props extends StyleProps {
  title: string | React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  comingSoon?: boolean;
}

export const SecurityCardHeader = ({ title, actions, className, comingSoon }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      {title}
      {comingSoon ? ' (Coming Soon!)' : null}
      <div className={classes.actions}>{actions}</div>
    </div>
  );
};
