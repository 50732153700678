import { Button } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { useToasters } from 'hooks/useToasters';
import React, { useCallback } from 'react';

interface Props {
  deepScanId: string;
  label?: string;
  title?: string;
  className?: string;
}

export const CopyDeepScanIdButton: React.FC<Props> = ({ deepScanId, label, title, className }) => {
  const { showToaster } = useToasters();

  const handleCopyLink = useCallback(() => {
    if (deepScanId) {
      navigator.clipboard.writeText(deepScanId);
      showToaster(`${title || 'Telivy Key'} is copied to clipboard`);
    }
  }, [deepScanId, showToaster, title]);

  const handleClick = useCallback(() => {
    handleCopyLink();
  }, [handleCopyLink]);

  return (
    <Button variant='outlined' className={className} onClick={handleClick} startIcon={<FileCopyRoundedIcon />}>
      {label || 'Copy'}
    </Button>
  );
};
