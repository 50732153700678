import { makeStyles } from '@material-ui/core/styles';
import { DetailsList, Props as ListProps } from 'components/DetailsList';
import { PolicyDTO } from 'dtos/policy';
// import { useToasters } from 'hooks/useToasters';
import { startCase } from 'lodash';
import moment from 'moment';
// import { useCurrentAgent } from 'queries/useAgent';
// import { usePolicyDocuments } from 'queries/usePolicy';
import React, { useMemo } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';
import { SharePolicyButton } from 'views/agent/views/application-details/views/policies/SharePolicyButton';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  dnd: {
    marginTop: theme.spacing(1),
  },
  title: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  select: {
    width: 300,
  },
}));

interface Props {
  policy: PolicyDTO;
}

export const Payments = ({ policy }: Props) => {
  const classes = useStyles();
  // const { handleAsyncAction } = useToasters();
  // const { data: agentData } = useCurrentAgent();
  // const { data } = usePolicyDocuments(policy.id);
  // const { mutate } = useCreatePolicyDocument(policy.id);

  const rows = useMemo((): ListProps['rows'] => {
    if (!policy.payment) {
      return [
        {
          value: <SharePolicyButton applicationId={policy.applicationId} buttonText='Generate Link' />,
          title: 'Link',
        },
      ];
    }

    const rowsData = [
      {
        value: <SharePolicyButton applicationId={policy.applicationId} buttonText='Copy Link' />,
        title: 'Link',
      },
      {
        value: startCase(policy.payment.status.split('_').join(' ')),
        title: 'Status',
      },
      {
        value: moment(policy.payment.updatedAt).format('Do MMMM YYYY hh:mm:ss A'),
        title: 'Updated At',
      },
    ];

    return rowsData;
  }, [policy]);

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Payment</h3>
      {rows.length ? <DetailsList rows={rows} className={classes.list} /> : null}
    </div>
  );
};
