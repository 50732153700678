import { variantColorsMap } from 'components/Badge';
import React from 'react';
import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { getGradeBadgeVariant } from 'templates/SecurityAssessments/components/ExecutiveSummaryItem';

export const CustomGraphTick = (props: any) => {
  return (
    <text
      orientation={props.orientation}
      width={props.width}
      height={props.height}
      type={props.type}
      x={props.x}
      y={props.y}
      stroke={props.stroke}
      fill={
        variantColorsMap[
          getGradeBadgeVariant(props.payload.value >= 25 ? parseScoreToGrade(props.payload.value) : undefined)
        ].text
      }
      className={props.className}
      textAnchor={props.textAnchor}
      fontWeight={700}
    >
      <tspan x={props.x - 10} dy='0.355em'>
        {parseScoreToGrade(props.payload.value)}
      </tspan>
    </text>
  );
};
