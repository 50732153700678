import { Button, FormControlLabel, FormGroup, makeStyles, Switch as SwitchComponent, Theme } from '@material-ui/core';
// import cx from 'classnames';
import { LoadingContainer } from 'components/LoadingContainer';
import { Searchbar } from 'components/SearchBar';
import { SingleFilter } from 'components/SingleFilter';
import { Sorter, SortOrder } from 'components/Table';
import { ApplicationWithAgentDTO, MonitoringSnapshotDTO, SecurityScanDTO } from 'dtos/application';
import {
  BrowserPasswordDTO,
  BrowserPasswordStatsDTO,
  // decryptData,
  // encryptData,
  getDeepScanPassPhrase,
} from 'dtos/deep-scan';
import { SecurityFindingBySlugDTO, SecurityScanType } from 'dtos/security-finding';
import { useMonitoringIdentityAccessChart } from 'hooks/charts/monitoring/useIdentityAccessChart';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanPreviousFindings } from 'hooks/external-scan/useExternalScanPreviousFindings';
import { useChartImage } from 'hooks/useChartImage';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import {
  useDeepScan,
  useDeepScanBrowserPasswords,
  useDeepScanCommonBrowserPasswords,
  useDeepScanDarkWebBrowserPasswords,
  useDeepScanReportData,
} from 'queries/useDeepScan';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

import { SourceRoute } from '../..';
import { BrowserPasswordsTable } from '../../components/BrowserPasswordsTable';
import { CommonBrowserPasswordsTable } from '../../components/BrowserPasswordsTable/CommonBrowserPasswordsTable';
import { DarkWebBrowserPasswordsTable } from '../../components/BrowserPasswordsTable/DarkWebBrowserPasswordsTable';
import { ScoreSecurityCard } from '../../components/common/ScoreSecurityCard';
import { renderScanResults } from '../../components/ExternalScanItem';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },

  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },

  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },

  red: {
    color: COLORS.RED_1,
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
  },

  headerRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  chartContainer: {
    flex: 1,
    display: 'flex',
    width: '100%',
    // height: '100%',
  },

  decryptToggle: {
    marginLeft: 'auto',
  },
  actionButton: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  lineChart: {
    height: 400,
  },
  redItalic: {
    color: COLORS.RED_1,
    fontStyle: 'italic',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  securityCard: {
    flex: 1,
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  sourceRoute: SourceRoute;
  securityScanId: string;
  application: ApplicationWithAgentDTO;
  monitoringSnapshots?: MonitoringSnapshotDTO[];
  selectedAssets: string[];
  setSelectedAssets: (a: string[]) => void;
  allAssets: string[];
  selectedLimit: string;
  setSelectedLimit: (a: string) => void;
}

interface QueryParams {
  user?: string;
  q?: string;
}

const DEFAULT_SORTING = (
  a: BrowserPasswordDTO | BrowserPasswordStatsDTO,
  b: BrowserPasswordDTO | BrowserPasswordStatsDTO,
) => {
  if (a.passwordBreached !== b.passwordBreached) {
    return (b.passwordBreached || 0) - (a.passwordBreached || 0);
  } else {
    return (a.passwordStrength || 2) - (b.passwordStrength || 2);
  }
};

const PER_PAGE = 10;
export const DeepScanIdentityAccessManagement = ({
  applicationId,
  sourceRoute,
  securityScan,
  securityScanId,
  application,
  monitoringSnapshots,
  selectedAssets,
  setSelectedAssets,
  allAssets,
  selectedLimit,
  setSelectedLimit,
}: Props) => {
  const classes = useStyles();

  const { findingsByType, isErrorScanDns, isLoadingScanDns } = useExternalScanFindings({ securityScan });

  const { previousFindingsByType } = useExternalScanPreviousFindings({ securityScan });

  const getFindingUrl = (finding: SecurityFindingBySlugDTO) =>
    createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug);

  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search) as unknown as QueryParams, [search]);

  const [page, setPage] = useState(0);
  const [statsPage, setStatsPage] = useState(0);
  const [darkWebPage, setDarkWebPage] = useState(0);

  const [sorter, setSorter] = useState<Sorter<keyof BrowserPasswordDTO> | undefined>(undefined);
  const [statsSorter, setStatsSorter] = useState<Sorter<keyof BrowserPasswordStatsDTO> | undefined>({
    key: 'total',
    order: SortOrder.DESC,
  });
  const [darkWebSorter, setDarkWebSorter] = useState<Sorter<keyof BrowserPasswordStatsDTO> | undefined>({
    key: 'total',
    order: SortOrder.DESC,
  });

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedStrengths, setSelectedStrengths] = useState<string[]>([]);
  const [searchDigest, setSearchDigest] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);

  const deepScanId = application.deepScanId || '';
  const { data: deepScan } = useDeepScan(deepScanId);

  const passPhraseStored = useMemo(() => Boolean(getDeepScanPassPhrase(deepScanId)), [deepScanId]);
  const [decrypt, setDecrypt] = useState<boolean>(false);
  const handleDecryptToggle = useCallback(() => setDecrypt(!decrypt), [decrypt, setDecrypt]);

  const { data: browserPasswords, isLoading: isLoadingBrowserPassword } = useDeepScanBrowserPasswords(deepScanId);
  const { data: commonBrowserPasswords, isLoading: isLoadingCommonBrowserPassword } =
    useDeepScanCommonBrowserPasswords(deepScanId);
  const { data: darkWebBrowserPasswords, isLoading: isLoadingDarkWebBrowserPassword } =
    useDeepScanDarkWebBrowserPasswords(deepScanId);

  const { chart: identityAccessChart } = useMonitoringIdentityAccessChart(
    application,
    application.monitoringEnabled,
    monitoringSnapshots,
    selectedAssets,
    false,
    classes.lineChart,
  );

  const users = useMemo(
    () => uniq(browserPasswords?.map((browserPassword) => browserPassword.localUser) ?? []),
    [browserPasswords],
  );

  const { data: charts } = useApplicationSecurityChartsUrls(applicationId);
  const byStrengthPassword = useChartImage(
    { title: 'Browser Passwords by Strength', className: classes.chart },
    charts?.browserPasswordsByStrength,
    deepScan,
    application,
  );
  const byBreachesChart = useChartImage(
    { title: 'Browser Passwords by Breaches', className: classes.chart },
    charts?.browserPasswordsByBreaches,
    deepScan,
    application,
  );

  const strengths = ['Weak', 'Moderate', 'Strong', 'N/A'];

  useEffect(() => {
    if (queryParams.user) {
      setSelectedUsers([queryParams.user]);
    }
  }, [queryParams.user]);

  useEffect(() => {
    if (queryParams.q) {
      setSearchValue(queryParams.q);
    }
  }, [queryParams.q]);

  const filteredData = useMemo(() => {
    let filtered = browserPasswords
      ?.filter((b) => {
        if (!debouncedSearchValue) {
          return true;
        }

        return (
          b.localUser.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          b.hostname.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          b.url.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          b.username.toLowerCase().includes(debouncedSearchValue.toLowerCase())
        );
      })
      .filter((b) => {
        if (!searchDigest) {
          return true;
        }

        return b.passwordDigest === searchDigest;
      })
      .filter((b) => {
        if (selectedUsers.length) {
          return selectedUsers.includes(b.localUser);
        } else {
          return true;
        }
      })
      .filter((b) => {
        if (selectedStrengths.length) {
          if (selectedStrengths.includes('Strong') && b.passwordStrength && b.passwordStrength > 0.62) {
            return true;
          }
          if (
            selectedStrengths.includes('Moderate') &&
            b.passwordStrength &&
            b.passwordStrength > 0.38 &&
            b.passwordStrength <= 0.62
          ) {
            return true;
          }
          if (selectedStrengths.includes('Weak') && b.passwordStrength && b.passwordStrength <= 0.38) {
            return true;
          }
          if (selectedStrengths.includes('N/A') && !b.passwordStrength) {
            return true;
          }
        } else {
          return true;
        }
      });

    if (decrypt && getDeepScanPassPhrase(deepScanId)) {
      const deepScanPassPhrase = getDeepScanPassPhrase(deepScanId);

      filtered = filtered?.map((bp) => {
        const fullPassword = bp.passwordMasked;
        if (bp.passwordEncrypted && deepScanPassPhrase) {
          // const encPassword = encryptData(deepScanId, deepScanPassPhrase);
          // if (encPassword) {
          //   fullPassword = decryptData(encPassword, bp.passwordEncrypted) || bp.passwordMasked;
          // }
        }

        return {
          ...bp,
          passwordMasked: fullPassword,
        };
      });
    }

    let sorted = filtered?.sort(DEFAULT_SORTING);

    if (sorter?.key) {
      sorted = sortBy(filtered, sorter?.key);
    }

    if (sorter?.order === 'DESC') {
      sorted = sorted?.reverse();
    }

    return sorted;
  }, [
    deepScanId,
    browserPasswords,
    debouncedSearchValue,
    searchDigest,
    selectedStrengths,
    selectedUsers,
    decrypt,
    sorter?.key,
    sorter?.order,
  ]);

  const filteredStatsData = useMemo(() => {
    let sorted = commonBrowserPasswords
      ?.filter((c) => c.passwordMasked?.trim().length > 0 && c.passwordMasked !== 'NA')
      .sort(DEFAULT_SORTING);

    if (statsSorter?.key && sorted) {
      sorted = sortBy(sorted, statsSorter?.key);
    }

    if (statsSorter?.order === 'DESC') {
      sorted = sorted?.reverse();
    }

    return sorted;
  }, [commonBrowserPasswords, statsSorter]);

  const filteredDarkWebData = useMemo(() => {
    let sorted = darkWebBrowserPasswords?.filter((c) => c.passwordMasked?.trim().length > 0).sort(DEFAULT_SORTING);

    if (darkWebSorter?.key && sorted) {
      sorted = sortBy(sorted, darkWebSorter?.key);
    }

    if (darkWebSorter?.order === 'DESC') {
      sorted = sorted?.reverse();
    }

    return sorted;
  }, [darkWebBrowserPasswords, darkWebSorter]);

  const pageData = useMemo(() => {
    return (filteredData || []).slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE);
  }, [page, filteredData]);

  const statsPageData = useMemo(() => {
    return (filteredStatsData || []).slice(statsPage * PER_PAGE, statsPage * PER_PAGE + PER_PAGE);
  }, [statsPage, filteredStatsData]);

  const darkWebPageData = useMemo(() => {
    return (filteredDarkWebData || []).slice(darkWebPage * PER_PAGE, darkWebPage * PER_PAGE + PER_PAGE);
  }, [darkWebPage, filteredDarkWebData]);

  const weakPasswords = useMemo(() => {
    return (browserPasswords || []).filter((a) => a.passwordStrength && a.passwordStrength <= 0.38);
  }, [browserPasswords]);

  const breachedPasswords = useMemo(() => {
    return (browserPasswords || []).filter((a) => a.passwordMasked !== 'NA' && (a.passwordBreached || 0) > 0);
  }, [browserPasswords]);

  const { data: reportData } = useDeepScanReportData(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const isLoadingAny =
    !securityScan || isLoadingBrowserPassword || isLoadingCommonBrowserPassword || isLoadingDarkWebBrowserPassword;

  if (isLoadingAny) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Identity and Access Management</div>

      <div className={classes.section}>
        <div className={classes.summary} id='summary'>
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Total Passwords Found'
            skipGrading={(browserPasswords || []).length.toLocaleString()}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Weak Passwords'
            skipGrading={(weakPasswords.length || 0).toLocaleString()}
            score={weakPasswords.length > 0 ? 50 : (reportData?.identityAccessManagement.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Breached Passwords'
            skipGrading={(breachedPasswords.length || 0).toLocaleString()}
            score={breachedPasswords.length > 0 ? 50 : (reportData?.identityAccessManagement.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Reused Passwords'
            skipGrading={(commonBrowserPasswords?.length || 0).toLocaleString()}
            score={
              (commonBrowserPasswords?.length || 0) > 0 ? 50 : (reportData?.identityAccessManagement.score || 0) * 100
            }
          />
          {/*<div className={classes.item}>
            <div className={classes.number}>{(browserPasswords || []).length.toLocaleString()}</div>
            <div className={classes.description}>Total Passwords Found</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>
              {(browserPasswords || [])
                .filter((a) => a.passwordStrength && a.passwordStrength <= 0.38)
                .length.toLocaleString()}
            </div>
            <div className={classes.description}>Weak Passwords</div>
          </div>
          <div className={classes.item}>
            <div className={classes.number}>
              {(browserPasswords || [])
                .filter((a) => a.passwordMasked !== 'NA' && (a.passwordBreached || 0) > 0)
                .length.toLocaleString()}
            </div>
            <div className={classes.description}>Breached Passwords</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>
              {(commonBrowserPasswords || []).length.toLocaleString()}
            </div>
            <div className={classes.description}>Reused Passwords</div>
          </div>*/}
        </div>
        <div className={classes.headerRow}>
          {byStrengthPassword}
          {byBreachesChart}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.title}>
            Risk Over Time
            <br />
            <span className={classes.redItalic}> Number of Weak Password</span>
          </div>
          <div>
            <SingleFilter
              buttonText={`Assets ${selectedAssets.length > 0 ? `(${selectedAssets.length})` : ''}`}
              defaultSelectedOptions={selectedAssets}
              popperText='Filter Assets'
              showDropdownIcon
              options={allAssets}
              setSelectedOptions={(a) => {
                setSelectedAssets(a);
              }}
            />
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>
        <div className={classes.chartContainer}>{identityAccessChart}</div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Browser Passwords
          <span className={classes.findings}> ({(filteredData || []).length} findings)</span>
        </div>

        <div className={classes.boxBorderPadding}>
          <div className={classes.filters}>
            <Searchbar placeholder='Search...' value={searchValue} setValue={setSearchValue} />
            <SingleFilter
              buttonText={`Filter Users ${selectedUsers.length > 0 ? `[${selectedUsers}]` : ''}`}
              defaultSelectedOptions={selectedUsers}
              popperText='Users'
              showDropdownIcon
              options={users}
              setSelectedOptions={(u) => {
                setSelectedUsers(u);
                setPage(0);
              }}
            />
            <SingleFilter
              buttonText={`Filter Strength ${selectedStrengths.length > 0 ? `[${selectedStrengths}]` : ''}`}
              defaultSelectedOptions={selectedStrengths}
              popperText='Password Strength'
              options={strengths}
              showDropdownIcon
              setSelectedOptions={(o) => {
                setSelectedStrengths(o);
                setPage(0);
              }}
            />
            {searchDigest && (
              <>
                <span>[Show all password: {filteredData && filteredData[0]?.passwordMasked}]</span>
                <Button onClick={() => setSearchDigest('')}>Reset</Button>
              </>
            )}
            {passPhraseStored && (
              <FormGroup className={classes.decryptToggle}>
                <FormControlLabel
                  control={<SwitchComponent checked={decrypt} onChange={handleDecryptToggle} color='primary' />}
                  label={decrypt ? 'Showing Decrypted' : 'Show Decrypted'}
                />
              </FormGroup>
            )}
          </div>

          <BrowserPasswordsTable
            data={pageData}
            pagination={
              isLoadingBrowserPassword ? undefined : { page, perPage: PER_PAGE, total: filteredData?.length || 0 }
            }
            sorter={sorter}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setPage(pagination?.page);
              }

              const isSorterChanging = sorter?.key !== sorting?.key || sorter?.order !== sorting?.order;
              setSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setPage(0);
              }
            }}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Reused Passwords
          <span className={classes.findings}> ({(filteredStatsData || []).length} findings)</span>
        </div>

        <div className={classes.boxBorderPadding}>
          <CommonBrowserPasswordsTable
            className={classes.actionButton}
            data={statsPageData}
            pagination={
              isLoadingCommonBrowserPassword
                ? undefined
                : { page: statsPage, perPage: PER_PAGE, total: filteredStatsData?.length || 0 }
            }
            sorter={statsSorter}
            onRowClick={(row) => {
              if (row.passwordDigest) setSearchDigest(row.passwordDigest);
              setPage(0);
              setSelectedUsers([]);
              setSelectedStrengths([]);
            }}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setStatsPage(pagination?.page);
              }

              const isSorterChanging = statsSorter?.key !== sorting?.key || statsSorter?.order !== sorting?.order;
              setStatsSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setStatsPage(0);
              }
            }}
          />
        </div>

        {(filteredDarkWebData || []).length > 0 && (
          <>
            <div className={classes.title}>
              Browser Credentials on Dark Web
              <span className={classes.findings}> ({(filteredDarkWebData || []).length} findings)</span>
            </div>

            <div className={classes.boxBorderPadding}>
              <DarkWebBrowserPasswordsTable
                className={classes.actionButton}
                data={darkWebPageData}
                pagination={
                  isLoadingDarkWebBrowserPassword
                    ? undefined
                    : { page: darkWebPage, perPage: PER_PAGE, total: filteredDarkWebData?.length || 0 }
                }
                sorter={darkWebSorter}
                onRowClick={(row) => {
                  if (row.passwordDigest) setSearchDigest(row.passwordDigest);
                  setPage(0);
                  setSelectedUsers([]);
                  setSelectedStrengths([]);
                }}
                onChange={(pagination, sorting) => {
                  if (pagination?.page !== undefined) {
                    setDarkWebPage(pagination?.page);
                  }

                  const isSorterChanging =
                    darkWebSorter?.key !== sorting?.key || darkWebSorter?.order !== sorting?.order;
                  setDarkWebSorter(sorting);

                  if (isSorterChanging && pagination?.page !== 0) {
                    setDarkWebPage(0);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          DNS Health
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.DNS_HEALTH].filter((f) => f.count > 0).length} findings)
            {isLoadingScanDns ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanDns || securityScan.previousScan?.dnsScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.DNS_HEALTH] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.DNS_HEALTH] || undefined,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
