import { Button, makeStyles } from '@material-ui/core';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { Column, Table } from 'components/Table';
import { VerifyEmail } from 'components/VerifyEmail';
import dayjs from 'dayjs';
import { AgentDTO } from 'dtos/agent';
import { SortOrder } from 'dtos/application';
import { ClientCategory, ClientStatus, CompanyDTO, CompanySortBy } from 'dtos/company';
import { intersection, startCase } from 'lodash';
import { useCompanies } from 'queries/useCompanies';
import queryString from 'query-string';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';

import { ClientStatusLabel } from './ClientStatusLabel';
import { EditCompany } from './EditCompany';
import { AvailableFilter, Filters, QueryParams } from './Filters';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },
  filters: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  overallScoreBadge: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  edit: {
    paddingTop: theme.spacing(0.05),
    paddingBottom: theme.spacing(0.05),
    marginLeft: theme.spacing(1),
  },
}));

const generateColumns = (
  setSelectedCompany: (company: CompanyDTO | null) => void,
  actionClass: string,
  handleViewAssessments: (company: CompanyDTO | null) => void,
): Array<Column<CompanyDTO, CompanySortBy>> => {
  const remainingSpacePercentages = 80;
  const statusColumnWidth = 120;

  const baseColumns: Array<Column<CompanyDTO, CompanySortBy>> = [
    {
      title: 'Status',
      width: `${statusColumnWidth}px`,
      sortKey: CompanySortBy.CLIENT_STATUS,
      render: (company) => <ClientStatusLabel clientStatus={company.clientStatus} fullWidth />,
    },
    {
      title: 'Company Name',
      width: '25%',
      sortKey: CompanySortBy.ORG_NAME,
      render: (company) => company.orgName,
    },
    {
      title: 'Domain',
      width: `calc(X)`,
      sortKey: CompanySortBy.DOMAIN,
      render: (company) => company.domain,
    },
    {
      title: 'Category',
      width: `calc(X)`,
      sortKey: CompanySortBy.CLIENT_CATEGORY,
      render: (company) => startCase((company.clientCategory || '').split('_').join(' ')),
    },
    {
      title: 'Anti Virus',
      width: `calc(X)`,
      sortKey: CompanySortBy.ANTI_VIRUS,
      render: (company) => company.antiVirus,
    },
  ];

  baseColumns.push({
    title: 'Created',
    width: `calc(X)`,
    sortKey: CompanySortBy.CREATED_AT,
    render: (company) => dayjs(company.createdAt).format('M/D/YYYY'),
  });

  baseColumns.push({
    title: 'Actions',
    width: `calc(X)`,
    // sortKey: CompanySortBy.CREATED_AT,
    render: (company) => (
      <>
        <Button variant='outlined' color='default' onClick={() => setSelectedCompany(company)} className={actionClass}>
          Edit
        </Button>

        <Button
          variant='outlined'
          color='default'
          onClick={() => handleViewAssessments(company)}
          className={actionClass}
        >
          View Assessments
        </Button>
      </>
    ),
  });

  return baseColumns.map((column) => ({
    ...column,
    width:
      column.width === 'calc(X)'
        ? `calc(${remainingSpacePercentages / baseColumns.length}% - ${statusColumnWidth}px)`
        : column.width,
  }));
};

interface Props {
  agent: AgentDTO;
}

const PER_PAGE = 15;
export const AgentCrmView = ({ agent }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  // const { data: securityApplicationVersion } = useSecurityApplicationVersion();
  // const isAdmin = isAgentAnAdmin(agent);
  // const isAgencyAdmin = agent.role === 'agency_admin' || agent.role === 'telivy_admin';

  const [selectedCompany, setSelectedCompany] = React.useState<CompanyDTO | null>(null);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const filtersList: AvailableFilter<QueryParams> = useMemo(() => {
    const filters = {
      clientStatus: {
        label: 'Status',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: Object.values(ClientStatus).map((status) => ({
          label: startCase(status),
          value: status || null || undefined,
        })),
      },
      clientCategory: {
        label: 'Category',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: Object.values(ClientCategory).map((category) => ({
          label: startCase(category),
          value: category || null || undefined,
        })),
      },
      creationDateDays: {
        label: 'Creation Date',
        icon: <CalendarTodayRoundedIcon />,
        options: [
          { label: 'In the last 7 days', value: 7 },
          { label: 'In the last 14 days', value: 14 },
          { label: 'In the last 21 days', value: 21 },
          { label: 'In the last 30 days', value: 30 },
        ],
      },
    };

    return filters;
  }, []);

  const queryParams = useMemo(
    () =>
      queryString.parse(search, {
        parseNumbers: true,
      }) as unknown as QueryParams,
    [search],
  );

  // default params values
  const params = useMemo(
    () => ({
      q: queryParams.q || '',
      page: queryParams.page || 0,
      sortBy: queryParams.sortBy || CompanySortBy.CREATED_AT,
      sortOrder: queryParams.sortOrder || SortOrder.DESC,
      clientStatus: queryParams.clientStatus,
      clientCategory: queryParams.clientCategory,
      creationDateDays: queryParams.creationDateDays,
    }),
    [queryParams],
  );

  const { data, isLoading } = useCompanies({
    q: params.q,
    limit: PER_PAGE,
    offset: params.page * PER_PAGE,
    sortBy: queryParams.sortBy || CompanySortBy.CREATED_AT,
    sortOrder: queryParams.sortOrder || SortOrder.DESC,
    clientStatus: params.clientStatus || undefined,
    clientCategory: params.clientCategory || undefined,
    creationDateDays: params.creationDateDays,
  });

  const handleUpdateUrl = useCallback(
    (paramsToUpdate: Partial<QueryParams>) => {
      const paramsToUpdateList = Object.keys(paramsToUpdate);
      const filterListKeys = Object.keys(filtersList);
      const parsedParams = { ...queryParams, ...paramsToUpdate };

      // if you add any of the filterList or q -> change the page to 0
      if (intersection(filterListKeys, paramsToUpdateList).length || paramsToUpdateList.includes('q')) {
        parsedParams.page = 0;
      }

      const urlWithParams = queryString.stringify(parsedParams, {
        skipEmptyString: true,
        skipNull: true,
      });

      navigate(`?${urlWithParams}`, { replace: true });
    },
    [navigate, queryParams, filtersList],
  );

  const handleViewAssessments = useCallback(
    (company) => {
      const urlWithParams = queryString.stringify({
        q: company.orgName,
      });
      navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.DASHBOARD}?${urlWithParams}`);
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return generateColumns(setSelectedCompany, classes.edit, handleViewAssessments);
  }, [setSelectedCompany, classes.edit, handleViewAssessments]);

  if (agent && !agent.isEmailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Dashboard - {agent?.agency?.name}</title>
      </Helmet>
      <Filters
        title='Companies'
        className={classes.filters}
        onFilterChange={(paramsToUpdate) => handleUpdateUrl(paramsToUpdate)}
        currentFilters={params}
        filterList={filtersList}
        applicationIds={selectedIds}
        onBulkAction={() => {
          setSelectedIds([]);
        }}
      />
      <Table<CompanyDTO, CompanySortBy>
        data={data?.companies}
        columns={columns}
        sorter={{
          key: params.sortBy,
          order: params.sortOrder,
        }}
        loading={isLoading}
        onChange={(pagination, sorter) => {
          if (pagination) {
            sorter
              ? handleUpdateUrl({ page: pagination.page, sortBy: sorter.key, sortOrder: sorter.order })
              : handleUpdateUrl({ page: pagination.page, sortBy: undefined, sortOrder: undefined });
          } else {
            sorter
              ? handleUpdateUrl({ sortBy: sorter.key, sortOrder: sorter.order })
              : handleUpdateUrl({ sortBy: undefined, sortOrder: undefined });
          }
        }}
        rowKey={(company) => company.id}
        pagination={{
          page: params.page,
          perPage: PER_PAGE,
          total: data?.companiesCount || 0,
          elementName: 'Companie',
        }}
      />
      {selectedCompany && <EditCompany company={selectedCompany} onClose={() => setSelectedCompany(null)} />}
    </div>
  );
};
