// import { makeStyles } from '@material-ui/core';
// import { Option, RadioSelect } from 'components/RadioSelect';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';

import { ApplicationViewContainer } from '../application-details/views/ApplicationViewContainer';
import { AlertDetailsView } from './Details';
import { AlertsHistoryView } from './History';
import { AlertsPreferencesView } from './Preferences';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     position: 'relative',
//     width: '100%',
//     gap: theme.spacing(2),
//   },
//   table: {
//     width: '100%',
//   },

//   selectsContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     marginBottom: theme.spacing(2),
//   },
//   select: {},
// }));

interface Props {
  agent: AgentDTO;
}

export const AgentAlertsView = ({ agent }: Props) => {
  // const classes = useStyles();
  // const navigate = useNavigate();
  // const location = useLocation();

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Alerts</title>
      </Helmet>

      <Routes>
        <Route path={ROUTES.agent.alerts.HISTORY} element={<AlertsHistoryView agent={agent} />} />

        <Route path={ROUTES.agent.alerts.POLICIES} element={<AlertsPreferencesView agent={agent} />} />

        <Route path={ROUTES.agent.alerts.DETAILS} element={<AlertDetailsView />} />

        <Route index element={<Navigate replace to={`${ROUTES.agent.alerts.HISTORY}`} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
