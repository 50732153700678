import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React, { useMemo } from 'react';
import { COLORS } from 'telivy-theme';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 80,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
  red: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED_1,
  },
  yellow: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.YELLOW_1,
  },
  green: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREEN_1,
  },
  grey: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.GREY_2,
  },
}));

export type ScoreRanking = 'Low' | 'Medium' | 'High' | 'None';

interface Props {
  ranking: ScoreRanking;
  label?: string;
}

export const ScoreBox = ({ ranking, label }: Props) => {
  const classes = useStyles();

  const colorClass = useMemo(() => {
    switch (ranking) {
      case 'Low':
        return classes.green;
      case 'Medium':
        return classes.yellow;
      case 'High':
        return classes.red;
      case 'None':
        return classes.grey;
      default:
        return classes.green;
    }
  }, [classes.green, classes.grey, classes.red, classes.yellow, ranking]);

  return <b className={cx(classes.root, colorClass)}>{label || ranking}</b>;
};
