import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { Placeholder } from 'components/Placeholder';
import { PiiCountry, PiiCurrencyConversion } from 'dtos/deep-scan';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

export function formatNumberWithCommas(value: number, piiCountry?: PiiCountry) {
  let finalValue = value;
  if (piiCountry) {
    finalValue = value * PiiCurrencyConversion[piiCountry];
  }
  return finalValue
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatMillionsValue(value: number) {
  const floatMillions = value / 1000000;

  return `${parseFloat(floatMillions.toFixed(1))}M`;
}

interface StylesProps {
  typography?: ValueOf<typeof TYPOGRAPHY>;
  color?: ValueOf<typeof COLORS>;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: (props) => {
    return {
      ...(props.typography || TYPOGRAPHY.MONO_MEDIUM_SMALL),
      color: props.color || COLORS.GREY_1,
    };
  },
  bold: (props) => {
    return {
      ...(props.typography || TYPOGRAPHY.MONO_BOLD_SMALL),
    };
  },

  placeholder: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface Props extends StylesProps {
  value: number;
  bold?: boolean;
  className?: string;
  isLoading?: boolean;
  piiCountry?: PiiCountry;
  piiCurrency?: string;
}

export const Money = ({ value, bold, className, typography, isLoading, color, piiCountry, piiCurrency }: Props) => {
  const classes = useStyles({ typography, color });
  let finalValue = value;
  if (piiCountry) {
    finalValue = value * PiiCurrencyConversion[piiCountry];
  }
  const parsedValue = finalValue < 1000000 ? formatNumberWithCommas(finalValue) : formatMillionsValue(finalValue);
  const combinedClasses = cx(classes.root, className, bold && classes.bold);

  const loading = Boolean(isLoading);

  return (
    <span className={combinedClasses}>
      {piiCurrency || '$'}
      <Placeholder isActive={loading} inline className={cx(loading && classes.placeholder)}>
        <>{parsedValue}</>
      </Placeholder>
    </span>
  );
};
