import { CompaniesQuery, CompaniesResponse, CompanyDTO, EditCompanyDTO } from 'dtos/company';
import pickBy from 'lodash/pickBy';
import qs from 'query-string';

import RequestService from './Request';

class _CompaniesService {
  getAllCompanies(query: CompaniesQuery) {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client
      .get<CompaniesResponse>(`/applications/companies?${params}`)
      .then((response) => response.data);
  }

  getCompany(id: string) {
    return RequestService.client.get<CompanyDTO>(`/applications/companies/${id}`).then((response) => response.data);
  }

  updateCompany(id: string, data: EditCompanyDTO) {
    return RequestService.client
      .post<CompanyDTO>(`/applications/companies/${id}`, data)
      .then((response) => response.data);
  }
}

const CompaniesService = new _CompaniesService();

export { CompaniesService };
