import { Button } from '@material-ui/core';
import { Badge } from 'components/Badge';
import { Column, Props as TableProps, Table } from 'components/Table';
import { BrowserPasswordStatsDTO } from 'dtos/deep-scan';
import React, { useMemo } from 'react';

import { getPasswordStrengthBadgeVariant, getPasswordStrengthTitle } from '.';

type Props = Omit<TableProps<BrowserPasswordStatsDTO, keyof BrowserPasswordStatsDTO>, 'columns' | 'rowKey'> & {
  className?: string;
  onRowClick: (row: BrowserPasswordStatsDTO) => void;
};

export const DarkWebBrowserPasswordsTable = ({ onRowClick, className, ...restProps }: Props) => {
  const columns = useMemo((): Array<Column<BrowserPasswordStatsDTO, keyof BrowserPasswordStatsDTO>> => {
    return [
      {
        title: 'Username',
        width: '16%',
        // sortKey: 'email',
        render: (browserPasswordStats) => {
          return (
            <span style={{ wordBreak: 'break-word' }}>
              {browserPasswordStats.email || browserPasswordStats.userName}
            </span>
          );
        },
      },
      {
        title: 'Password',
        width: '14%',
        sortKey: 'passwordMasked',
        render: (browserPasswordStats) => {
          return <span style={{ wordBreak: 'break-word' }}>{browserPasswordStats.passwordMasked}</span>;
        },
      },
      {
        title: 'Total Reuse',
        width: '10%',
        sortKey: 'total',
        render: (browserPasswordStats) => {
          return browserPasswordStats.total;
        },
      },
      {
        title: 'Hosts',
        width: '8%',
        sortKey: 'hostnames',
        render: (browserPasswordStats) => {
          return browserPasswordStats.hostnames;
        },
      },
      {
        title: 'Users',
        width: '8%',
        sortKey: 'localusers',
        render: (browserPasswordStats) => {
          return browserPasswordStats.localusers;
        },
      },
      {
        title: 'Browsers',
        width: '8%',
        sortKey: 'browsers',
        render: (browserPasswordStats) => {
          return browserPasswordStats.browsers;
        },
      },
      {
        title: 'URLs',
        width: '8%',
        sortKey: 'urls',
        render: (browserPasswordStats) => {
          return <span style={{ wordBreak: 'break-word' }}>{browserPasswordStats.urls}</span>;
        },
      },
      {
        title: 'Password Strength',
        sortKey: 'passwordStrength',
        width: '10%',
        render: (browserPasswordStats) => (
          <Badge
            bordered
            variant={getPasswordStrengthBadgeVariant(browserPasswordStats.passwordStrength)}
            style={{ minWidth: 80, width: 80 }}
          >
            {getPasswordStrengthTitle(browserPasswordStats.passwordStrength)}
          </Badge>
        ),
      },
      {
        title: 'Password Breaches',
        width: '10%',
        sortKey: 'passwordBreached',
        render: (browserPasswordStats) => {
          return browserPasswordStats.passwordMasked !== 'NA'
            ? browserPasswordStats.passwordBreached?.toLocaleString()
            : null;
        },
      },
      {
        title: 'Action',
        width: '8%',
        render: (row) => (
          <Button className={className} onClick={() => onRowClick(row)}>
            Show All
          </Button>
        ),
      },
    ];
  }, [onRowClick, className]);

  return (
    <Table<BrowserPasswordStatsDTO>
      hideHeadersWhenEmpty
      columns={columns}
      onRowClick={onRowClick}
      rowKey={(el) => el.passwordMasked}
      {...restProps}
    />
  );
};
