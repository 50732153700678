/* eslint-disable indent */
import { makeStyles, Theme } from '@material-ui/core';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import cx from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Placeholder } from 'components/Placeholder';
import { ApplicationWithAgentDTO } from 'dtos/application';
import MissingImage from 'images/missing-img.png';
import { useDomainTechStack } from 'queries/useDomainTechStack';
import React from 'react';
import { COLORS } from 'telivy-theme';

interface StyleProps {
  tile?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  toolsWrapper: ({ tile }) => ({
    display: 'grid',

    ...(tile
      ? {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: theme.spacing(1.5),
        }
      : {
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: theme.spacing(5),
          marginTop: theme.spacing(5),

          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
        }),
  }),
  tool: ({ tile }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),

    ...(tile && {
      borderBottom: `1px solid ${COLORS.GREY_5}`,
      paddingBottom: theme.spacing(1.5),
    }),
  }),
  toolImage: ({ tile }) => ({
    width: tile ? theme.spacing(6) : theme.spacing(10),
    height: tile ? theme.spacing(6) : theme.spacing(10),
    borderRadius: tile ? theme.spacing(0.5) : theme.spacing(1.5),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',

    ...(!tile && {
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(8),
        height: theme.spacing(8),
      },
    }),
  }),
  toolContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
  },

  toolLink: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  emptyState: ({ tile }) => ({
    ...(tile && {
      padding: `${theme.spacing(2)}px 0`,
      marginTop: 'auto',
      marginBottom: 'auto',
    }),
  }),

  noBorderBottom: {
    borderBottom: 'none',
  },
}));

interface Props extends StyleProps {
  application: ApplicationWithAgentDTO;
}

export const TechstackTools = ({ tile, application }: Props) => {
  const classes = useStyles({ tile });

  const domain = application?.applicationResponse?.domain_prim;

  const { data, isLoading } = useDomainTechStack(domain, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!domain,
    retry: false,
  });

  if (!tile && isLoading) {
    return <LoadingContainer />;
  }

  return (
    <Placeholder isActive={Boolean(isLoading && tile)} className={classes.root}>
      {data && data?.tools?.length > 0 ? (
        <>
          {!tile ? (
            <>
              <h1>Technology stack for this domain</h1>
              <p>
                Based on third-party data and internal crawling we believe the technology stack of this domain comp o of
                of the following:
              </p>
            </>
          ) : null}

          <div className={classes.toolsWrapper}>
            {data?.tools?.map((tool, idx) =>
              (tile && idx < 8) || !tile ? (
                <div key={tool.slug} className={cx(classes.tool)}>
                  {tool?.imageUrl ? (
                    <img
                      src={tool.imageUrl}
                      alt={tool.name}
                      className={classes.toolImage}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = MissingImage;
                      }}
                    />
                  ) : (
                    <img src={MissingImage} alt={tool.name} className={classes.toolImage} />
                  )}
                  <div className={classes.toolContent}>
                    {/*{tool.websiteUrl && (*/}
                    {/*  <a href={tool.websiteUrl} target='_blank' className={classes.toolLink} rel='noreferrer'>*/}
                    {/*    <LaunchRoundedIcon />*/}
                    {/*  </a>*/}
                    {/*)}*/}
                    {tool.name}
                  </div>
                </div>
              ) : null,
            )}
          </div>
        </>
      ) : (
        <EmptyState
          title='Technology Stack not found'
          text="Looks like we couldn't find any tools for that domain."
          className={classes.emptyState}
          icon={<BuildRoundedIcon />}
        />
      )}
    </Placeholder>
  );
};
