import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded';
import { useToasters } from 'hooks/useToasters';
import { useUpdateApplicationSettings } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(3),
    '& > h2': {
      ...TYPOGRAPHY.TITLE_3,
    },
  },

  dialogContent: {
    paddingTop: `0 !important`,
    paddingBottom: `${theme.spacing(4)}px !important`,
  },

  dialogContentText: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(3),
  },

  emailEnabledSection: {
    marginTop: theme.spacing(3),
  },

  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: 0,

    '& + &': {
      marginTop: theme.spacing(2),
    },
  },

  radioGroupTitle: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(),
  },

  radioLabel: {
    ...TYPOGRAPHY.REGULAR_MEDIUM,
  },

  radioDescription: {
    marginLeft: theme.spacing(4),
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },
}));

interface Props {
  applicationId: string;
  pentestRequested: boolean;
  className?: string;
}

export const RequestPenTestButton: React.FC<Props> = ({ applicationId, pentestRequested, className }) => {
  const classes = useStyles();
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const { mutate, isLoading } = useUpdateApplicationSettings(applicationId);
  const { showToaster } = useToasters();

  const togglePentestRequested = useCallback(() => {
    mutate(
      { pentestRequested: !pentestRequested },
      {
        onSuccess: () => {
          showToaster('Redirecting...');
          setLinkModalOpen(false);
          window.open('https://holtdatasolutions.com/pentesting-with-telivy', '_blank')?.focus();
        },
      },
    );
  }, [mutate, pentestRequested, showToaster]);

  const handleClick = useCallback(() => {
    setLinkModalOpen(true);
  }, []);

  return (
    <>
      <Button
        variant={pentestRequested ? 'outlined' : 'contained'}
        color='primary'
        className={className}
        onClick={handleClick}
        startIcon={<PolicyRoundedIcon />}
        disabled={pentestRequested}
      >
        {pentestRequested ? 'Pen Test Requested' : 'Request Pen Test'}
      </Button>
      <Dialog fullWidth={true} open={linkModalOpen} onClose={() => setLinkModalOpen(false)}>
        <DialogTitle className={classes.dialogTitle}>Request Pen Test</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            Pentest Services offered by our vendor partner Holt Data Services. Telivy partners get 10% off.
            <br />
            <br />
            Please confirm to be redirected to an external website
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLinkModalOpen(false)}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={togglePentestRequested} disabled={isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
