import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { StepIcon } from './StepIcon';

const ACTIVE_BG_COLOR = COLORS.GREY_6;
const DEFAULT_COLOR = '#ACAEB1';
const DEFAULT_TEXT = '#232830';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: `0px 1px 0px ${COLORS.GREY_5}`,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    cursor: 'pointer',
    backgroundColor: (props: Props) => {
      if (props.isActive) {
        return ACTIVE_BG_COLOR;
      }

      return COLORS.WHITE;
    },
  },
  locked: {
    cursor: 'not-allowed',
    opacity: 0.25,
  },

  content: {
    flex: 1,
    textAlign: 'right',
    color: (props: Props) => {
      if (props.isActive || props.doNotGreyOut) {
        return DEFAULT_TEXT;
      }

      return DEFAULT_COLOR;
    },
  },
  title: {
    ...TYPOGRAPHY.SMALL_BOLD,
    marginBottom: theme.spacing(0.5),
  },
  description: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
    colors: COLORS.GREY_2,
  },
  progress: {},

  changes: {
    backgroundColor: (props: Props) => {
      if (props.isActive) {
        return COLORS.YELLOW_2;
      }

      return COLORS.YELLOW_3;
    },
    marginLeft: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
  },
}));

export interface Props {
  doNotGreyOut?: boolean;
  idx: number;
  title: string;
  isActive?: boolean;
  isError?: boolean;
  description?: string;
  descriptionClassName?: string;
  progress: number;
  currIndex: number;
  checkV2?: boolean;
  changesCount?: number;
  totalQuestions: number;
  readonly?: boolean;
  onClick: () => void;
  hideErrors?: boolean;
  isClient?: boolean;
  locked?: boolean;
  hasBeenSeen?: boolean;
}

export const Step = (props: Props): JSX.Element => {
  const classes = useStyles(props);

  return (
    <div
      className={cx(classes.root, props.locked && classes.locked)}
      onClick={props.onClick}
      role='button'
      tabIndex={0}
    >
      <StepIcon
        doNotGreyOut={props.doNotGreyOut}
        hideErrors={props.hideErrors}
        isError={props.isError}
        idx={props.idx}
        checkV2={props.checkV2}
        currIndex={props.currIndex}
        readonly={props.readonly}
        isClient={props.isClient}
        isActive={props.isActive}
        hasBeenSeen={props.hasBeenSeen}
      />
      <div className={classes.content}>
        <div className={classes.title}>{props.title}</div>
        <div className={cx(classes.description, props.descriptionClassName)}>
          {props.description}
          {props.changesCount ? <span className={classes.changes}>changes {props.changesCount}</span> : null}
        </div>
      </div>
      <div
        className={classes.progress}
        style={{
          width: (props.progress / props.totalQuestions) * 100 + '%',
        }}
      />
    </div>
  );
};
