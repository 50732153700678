import { isBooleanControl, JsonFormsRendererRegistryEntry, rankWith, uiTypeIs } from '@jsonforms/core';
import { createAjv, JsonSchema } from '@jsonforms/core';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms as _JsonForms } from '@jsonforms/react';
import { JsonFormsInitStateProps, JsonFormsReactProps } from '@jsonforms/react/lib/JsonForms';
import { makeStyles } from '@material-ui/core';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import isURL from 'validator/lib/isURL';

import { Autocomplete } from './Autocomplete';
import { CheckboxWithDescription } from './CheckboxWithDescription';
import { CheckboxWrapper } from './CheckboxWrapper';
import { CustomHorizontalLayout } from './CustomHorizontalLayout';
import { CustomVerticalLayout } from './CustomVerticalLayout';
import { DatePicker } from './DatePicker';
import { DollarInput } from './DollarInput';
import { Input } from './Input';
import { NaicsAutocomplete } from './NaicsAutocomplete';
import { NestedCheckbox } from './NestedCheckbox';
import { NumberInput } from './NumberInput';
import { PercentageInput } from './PercentageInput';
import { RadioGroup } from './RadioGroup';
import { RadioOrSelect } from './RadioOrSelect';
import { SectionTitle } from './SectionTitle';
import { SelectInput } from './SelectInput';
import { Subtitle } from './Subtitle';
import { YearInput } from './YearInput';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputLabel': {
      '&-root': {
        ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(0.5),
        padding: 0,
        position: 'static',
        transform: 'none',

        '&:not(.Mui-error):not(.Mui-focused)': {
          color: COLORS.GREY_1,
        },
      },
      '&-animated': {
        transition: 'none',
      },
      '&-shrink': {
        transform: 'none',
      },
    },

    '& .MuiInput': {
      '&-input::placeholder': {
        color: COLORS.GREY_3,
      },
      '&-underline': {
        '&:after': {
          display: 'none',
        },
      },
      '&-formControl': {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px !important`,
        borderRadius: theme.spacing(),
        marginTop: `${theme.spacing(0.5)}px !important`,
        minHeight: 'auto',

        '&.Mui-focused': {
          border: `1px solid ${COLORS.BLUE_1}`,
        },
        '&.Mui-error': {
          border: `1px solid ${COLORS.RED_1}`,
        },
      },
    },

    '& .MuiInputAdornment-root': {
      '& .MuiIconButton-root': {
        padding: theme.spacing(0.25),
      },
    },
  },
}));

const autocompleteTester = rankWith(5, uiTypeIs('Autocomplete'));
const naicsAutocompleteTester = rankWith(5, uiTypeIs('NaicsAutocomplete'));
const dollarInputTester = rankWith(5, uiTypeIs('DollarInput'));
const customInputTester = rankWith(5, uiTypeIs('CustomInput'));
const numberInputTester = rankWith(5, uiTypeIs('NumberInput'));
const percentageInputTester = rankWith(5, uiTypeIs('PercentageInput'));
const datePickerTester = rankWith(5, uiTypeIs('DatePicker'));
const yearInputTester = rankWith(5, uiTypeIs('YearInput'));
const selectInputTester = rankWith(5, uiTypeIs('SelectInput'));
const radioGroupTester = rankWith(5, uiTypeIs('RadioGroup'));
const radioOrSelectTester = rankWith(5, uiTypeIs('RadioOrSelect'));
const verticalLayoutTester = rankWith(5, uiTypeIs('VerticalLayout'));
const horizontalLayoutTester = rankWith(5, uiTypeIs('HorizontalLayout'));
const checkboxTester = rankWith(5, isBooleanControl);
const nestedChekboxTester = rankWith(5, uiTypeIs('NestedCheckbox'));
const checkboxLabelTester = rankWith(5, uiTypeIs('CheckboxWrapper'));
const checkboxWithDescriptionTester = rankWith(5, uiTypeIs('CheckboxWithDescription'));
const subtitleTester = rankWith(5, uiTypeIs('Subtitle')); // TODO move UISchema types to enums
const sectionTitleTester = rankWith(5, uiTypeIs('SectionTitle'));

const renderers: JsonFormsRendererRegistryEntry[] = [
  { tester: autocompleteTester, renderer: Autocomplete },
  { tester: naicsAutocompleteTester, renderer: NaicsAutocomplete },
  { tester: customInputTester, renderer: Input },
  { tester: numberInputTester, renderer: NumberInput },
  { tester: percentageInputTester, renderer: PercentageInput },
  { tester: datePickerTester, renderer: DatePicker },
  { tester: dollarInputTester, renderer: DollarInput },
  { tester: yearInputTester, renderer: YearInput },
  { tester: selectInputTester, renderer: SelectInput },
  { tester: radioGroupTester, renderer: RadioGroup },
  { tester: radioOrSelectTester, renderer: RadioOrSelect },
  { tester: checkboxTester, renderer: CheckboxWithDescription },
  { tester: checkboxWithDescriptionTester, renderer: CheckboxWithDescription },
  { tester: nestedChekboxTester, renderer: NestedCheckbox },
  { tester: checkboxLabelTester, renderer: CheckboxWrapper },
  { tester: verticalLayoutTester, renderer: CustomVerticalLayout },
  { tester: horizontalLayoutTester, renderer: CustomHorizontalLayout },
  { tester: subtitleTester, renderer: Subtitle },
  { tester: sectionTitleTester, renderer: SectionTitle },
  ...materialRenderers,
];

const ajv = createAjv({ useDefaults: true });
ajv.addKeyword('isNotEmpty', {
  type: 'string',
  validate: (schema: JsonSchema, data: any) => {
    return data && typeof data === 'string' && data.trim() !== '';
  },
  errors: false,
});
ajv.addKeyword('isPhoneNumber', {
  type: 'string',
  validate: (schema: JsonSchema, data: any) => {
    return data && typeof data === 'string' && isValidPhoneNumber(data, 'US');
  },
  errors: false,
});
ajv.addKeyword('isValidDomain', {
  type: 'string',
  validate: (schema: JsonSchema, data: any) => {
    return data && typeof data === 'string' && isURL(data);
  },
  errors: false,
});
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ajvCustomErrors = require('ajv-errors')(ajv, { keepErrors: false });

type DefaultProps = JsonFormsInitStateProps & JsonFormsReactProps;
type CustomProps = Omit<DefaultProps, 'renderers' | 'cells'>;

export const ApplicationSourceContext = React.createContext('agent');
type ApplicationSourceContextType = 'agent' | 'client';

export const JsonForms: React.FC<CustomProps & { applicationSource: ApplicationSourceContextType }> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ApplicationSourceContext.Provider value={props.applicationSource}>
        <_JsonForms {...props} renderers={renderers} cells={materialCells} ajv={ajvCustomErrors} />
      </ApplicationSourceContext.Provider>
    </div>
  );
};
