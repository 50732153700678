import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${COLORS.GREY_4}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2, 2, 2),
    gap: theme.spacing(1),
  },
  count: {
    ...TYPOGRAPHY.TITLE,
    color: COLORS.BLACK,
  },
  label: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: COLORS.GREY_2,
  },
  color: {
    color: COLORS.BLUE_1,
  },
}));

interface Props {
  count: number;
  className?: string;
  color?: boolean;
  label: string | React.ReactNode;
}

export const StatBox = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, props.className)}>
      <div className={cx(classes.count, props.color && classes.color)}>{props.count}</div>
      <div className={classes.label}>{props.label}</div>
    </div>
  );
};
