import { SecurityCardBadge } from './SecurityCardBadge';
import { SecurityCardContainer } from './SecurityCardContainer';
import { SecurityCardContent } from './SecurityCardContent';
import { SecurityCardHeader } from './SecurityCardHeader';

interface SecurityCard {
  Container: typeof SecurityCardContainer;
  Header: typeof SecurityCardHeader;
  Content: typeof SecurityCardContent;
  Badge: typeof SecurityCardBadge;
}

const SecurityCard: SecurityCard = {
  Container: SecurityCardContainer,
  Header: SecurityCardHeader,
  Content: SecurityCardContent,
  Badge: SecurityCardBadge,
};

export { SecurityCard };
