import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { ReactComponent as ErrorIcon } from 'images/error.svg';
import { ReactComponent as InfoIcon } from 'images/info-toaster.svg';
import { ReactComponent as OkIcon } from 'images/ok.svg';
import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles<Theme, { variant?: SnackbarVariant }>((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  row: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px rgba(35, 40, 48, 0.16), 0px 0px 1px rgba(35, 40, 48, 0.5)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(5),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: ({ variant }) => {
      switch (variant) {
        case SnackbarVariant.ERROR:
          return COLORS.RED_1;
        case SnackbarVariant.INFO:
          return COLORS.BLUE_1;
        default:
          return COLORS.GREEN_1;
      }
    },
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    marginBottom: theme.spacing(0.5),
  },
  message: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },
  close: {
    border: 0,
    background: 0,
    padding: 0,
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    display: 'block',
    zIndex: 1,

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

// Custom snackbars don't support variants... see: https://github.com/iamhosseindhv/notistack/issues/364
export enum SnackbarVariant {
  ERROR = 'error',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
}

export interface Props {
  id: string | number;
  variant?: SnackbarVariant;
  message: string | React.ReactNode;
  title?: string;
}

export const CustomSnackbar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = useStyles({ variant: props.variant || SnackbarVariant.SUCCESS });
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const title = useMemo(() => {
    if (props.title) {
      return props.title;
    }
    switch (props.variant) {
      case SnackbarVariant.ERROR:
        return 'Error';
      case SnackbarVariant.INFO:
        return 'Info';
      default:
        return 'Great!';
    }
  }, [props.title, props.variant]);

  const icon = useMemo(() => {
    if (props.title) {
      return props.title;
    }
    switch (props.variant) {
      case SnackbarVariant.ERROR:
        return <ErrorIcon />;
      case SnackbarVariant.INFO:
        return <InfoIcon />;
      default:
        return <OkIcon />;
    }
  }, [props.title, props.variant]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div className={classes.row}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          <div className={classes.message}>{props.message}</div>
        </div>
        <button className={classes.close} onClick={handleDismiss}>
          <CloseIcon className={classes.closeIcon} />
        </button>
      </div>
    </SnackbarContent>
  );
});
