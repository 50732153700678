import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS } from 'telivy-theme';

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {},

  mainValue: {},
  stepContainer: {
    borderLeft: `1px solid ${COLORS.RED_3}`,
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  stepDot: {
    marginLeft: theme.spacing(-0.875),
    marginRight: theme.spacing(1),
    color: COLORS.RED_1,
    fontSize: theme.spacing(3),
    lineHeight: `${theme.spacing(1.5)}px`,
  },
  title: {
    color: COLORS.RED_1,
    marginRight: theme.spacing(0.5),
  },
  description: {
    color: COLORS.GREY_2,
  },
}));

interface Props {
  mainValue?: number | string | React.ReactNode;
  items: Array<{
    description: string | React.ReactNode;
    title: number | React.ReactNode;
  }>;
  className?: string;
}

export const VerticalStepper = ({ mainValue, items, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.mainValue}>{mainValue}</div>
      {items.map((item, idx) => (
        <div className={classes.stepContainer} key={idx}>
          <span className={classes.stepDot}>&#183;</span>
          <span className={classes.title}>{item.title}</span>
          <span className={classes.description}>{item.description}</span>
        </div>
      ))}
    </div>
  );
};
