import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { kebabCase } from 'lodash';
import isString from 'lodash/isString';
import React, { useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(0.75)}px 0`,
    cursor: 'pointer',
  },
  disabledCursor: {
    cursor: 'not-allowed',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    margin: 0,
    background: COLORS.GREY_6,
    border: `2px solid ${COLORS.GREY_6}`,
    borderRadius: theme.spacing(1.5),
    outline: 'none',

    '&:hover, &:focus': {
      '&:not($checked)': {
        '&:not($disabled)': {
          borderColor: COLORS.BLUE_2,
          background: COLORS.BLUE_3,
        },
      },
    },
  },
  checked: {
    borderColor: COLORS.BLUE_1,
    background: COLORS.BLUE_3,

    '&:hover, &:focus': {
      '&:not($disabled)': {
        boxShadow: `0px 0px 24px -10px ${COLORS.BLUE_1}`,
      },
    },
  },
  disabled: {
    borderColor: COLORS.GREY_6,
    background: COLORS.GREY_6,
    pointerEvents: 'none',
    color: COLORS.GREY_2,

    '&$checked': {
      borderColor: COLORS.GREY_5,
    },

    '& $description, & $descriptionText': {
      color: COLORS.GREY_3,
    },
  },
  contentWrapper: {
    padding: `${theme.spacing()}px ${theme.spacing(1.5)}px ${theme.spacing()}px 0`,
  },
  label: {
    color: COLORS.GREY_1,
    ...TYPOGRAPHY.SMALL_MEDIUM,
  },

  description: {
    marginBottom: theme.spacing(0.5),
    color: COLORS.GREY_2,
    ...TYPOGRAPHY.SMALL_REGULAR,
  },
  descriptionText: {
    color: COLORS.GREY_2,
    ...TYPOGRAPHY.SMALL_REGULAR,
    cursor: 'pointer',
    textDecoration: 'underline',
    pointerEvents: 'all',
  },
}));

export const _CheckboxWithDescription: React.FC<ControlProps & { indeterminate?: boolean }> = (props) => {
  const classes = useStyles();
  const checked = Boolean(props.data);
  const isDescriptionVisible = props.visible || props.description;
  const disabled = !props.enabled;
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  if (!props.visible) {
    return null;
  }

  return (
    <div className={cx(classes.root, disabled && classes.disabledCursor)}>
      <FormControl
        className={cx(classes.box, checked && classes.checked, disabled && classes.disabled)}
        role='button'
        tabIndex={disabled ? -1 : 0}
        onClick={() => props.handleChange(props.path, !checked)}
        onKeyPress={(e) =>
          (e.code == 'Space' || e.code == 'Enter') && !disabled && props.handleChange(props.path, !checked)
        }
      >
        <Checkbox
          color='primary'
          checked={checked}
          indeterminate={props.indeterminate}
          id={`${props.id}-input`}
          disabled={disabled}
          tabIndex={-1}
          data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-checkbox`}
        />
        <div className={classes.contentWrapper}>
          <div>{props.label}</div>
          {isDescriptionVisible && props.description && (
            <>
              <span
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html:
                    descriptionExpanded || props.description.length <= 80
                      ? `${props.description} `
                      : `${props.description.substring(0, 80)}... `,
                }}
              />
              {props.description.length > 80 && (
                <span
                  role='button'
                  tabIndex={0}
                  className={classes.descriptionText}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDescriptionExpanded((prevState) => !prevState);
                  }}
                  onKeyPress={(e) => {
                    if (e.code == 'Space' || e.code == 'Enter') {
                      e.stopPropagation();
                      setDescriptionExpanded((prevState) => !prevState);
                    }
                  }}
                >
                  {descriptionExpanded ? 'Collapse' : 'Expand'}
                </span>
              )}
            </>
          )}
        </div>
      </FormControl>

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const CheckboxWithDescription = withJsonFormsControlProps(_CheckboxWithDescription);
