import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch as SwitchComponent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { AgentDTO } from 'dtos/agent';
import { useCreateEditAgency } from 'queries/useAgency';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  agent: AgentDTO;
  className?: string;
}

interface Form {
  nodewareApiKey: string | null;
}

export const NodewareApiKey: React.FC<Props> = ({ agent }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {
      nodewareApiKey: agent.agency?.nodewareApiKey,
    },
  });

  const [selected, setSelected] = useState(Boolean(agent.agency?.nodewareApiKey));

  const { mutate, isLoading } = useCreateEditAgency(agent.agencyId || '', {
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  const onSubmit = (data: Form) => {
    if (selected) {
      mutate({ nodewareApiKey: null });
      setSelected(false);
    } else {
      mutate({ nodewareApiKey: data.nodewareApiKey });
      setSelected(true);
    }
  };

  const FORM_ID = 'nodeware_api_key_form';

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<SwitchComponent checked={selected} onChange={() => setIsOpen(true)} color='primary' />}
          label={selected ? `Nodeware Enabled` : 'Enable Nodeware'}
        />
      </FormGroup>

      <Dialog fullWidth={true} open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{`${selected ? 'Disable' : 'Enable'} Nodeware Integration`}</DialogTitle>
        <DialogContent key='content'>
          <IconButton className={classes.closeButton} onClick={() => setIsOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
          {/*<Typography variant='body1' className={classes.sectionTitle}>
            Redirect Link
          </Typography>*/}
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              <Controller
                name='nodewareApiKey'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <CustomTextFieldShrink {...field} label='Nodeware API Key' required disabled={selected} />
                )}
              />
            </section>
          </form>
        </DialogContent>
        <DialogActions key='actions'>
          <div>
            <Button disabled={isLoading} type='submit' color='primary' variant='contained' form={FORM_ID}>
              {selected ? 'Clear and Disable' : 'Save and Enable'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
