import { LinkButton } from 'components/LinkButton';
import { QuoteDocumentDTO } from 'dtos/quotes';
import { useDownloadQuoteDocument } from 'queries/useQuote';
import React, { useCallback } from 'react';

interface Props {
  className?: string;
  quoteId: string;
  document: QuoteDocumentDTO;
}

export const QuoteDocumentButton = ({ className, document, quoteId }: Props) => {
  const { mutate } = useDownloadQuoteDocument(quoteId);

  const handleClick = useCallback(() => {
    mutate(document);
  }, [mutate, document]);

  return (
    <LinkButton className={className} onClick={handleClick}>
      {document.fileName}
    </LinkButton>
  );
};
