import { makeStyles } from '@material-ui/core/styles';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationDTO } from 'dtos/application';
import { PolicyDTO, PolicyStatus } from 'dtos/policy';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplicationPolicies } from 'queries/useApplications';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { isAgentAnAdmin } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';
import { BindPolicyButton } from 'views/agent/views/application-details/views/policies/BindPolicyButton';
import { SharePolicyButton } from 'views/agent/views/application-details/views/policies/SharePolicyButton';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { CancelPoliciesTable } from './CancelledPoliciesTable';
import { CancelPolicyButton } from './CancelPolicyButton';
import { EditPolicyButton } from './EditPolicyButton';
import { NoActivePolicies } from './NoActivePolicies';
import { PolicyDetails } from './PolicyDetails';
import { SelectedPolicy } from './SelectedPolicy';

const useStyles = makeStyles((theme) => ({
  // Select component
  select: {
    marginBottom: theme.spacing(3),
  },

  // Selected table row
  selectedTableRow: {
    backgroundColor: COLORS.BLUE_3,
    boxShadow: `inset 0px -2px 0px ${COLORS.BLUE_1}`,
  },
}));

interface Props {
  application: ApplicationDTO;
}

enum Filter {
  Active = 'active',
  Cancelled = 'cancelled',
}

const FILTERS: Option<Filter>[] = [
  {
    label: 'Active',
    value: Filter.Active,
  },
  {
    label: 'Cancelled',
    value: Filter.Cancelled,
  },
];

export const ApplicationPoliciesView = ({ application }: Props) => {
  const classes = useStyles();
  const { data } = useApplicationPolicies(application.id);
  const { data: userData } = useCurrentAgent();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = useState<Filter>(Filter.Active);
  const [selectedPolicy, setSelectedPolicy] = useState<PolicyDTO | undefined>(undefined);

  const policy = data?.find((policy) => [PolicyStatus.ACTIVE, PolicyStatus.DRAFT].includes(policy.status));
  const isPolicyBindable = isAgentAnAdmin(userData) && !policy?.policyNumber && policy?.quote.carrier.apiBinding;

  const actions = policy ? (
    <>
      {policy && <SharePolicyButton applicationId={application.id} />}

      {filter === Filter.Active && isPolicyBindable && <BindPolicyButton application={application} policy={policy} />}
      {filter === Filter.Active && isAgentAnAdmin(userData) && (
        <EditPolicyButton application={application} policy={policy} />
      )}

      {filter === Filter.Active && <CancelPolicyButton application={application} policy={policy} />}
    </>
  ) : (
    []
  );

  const sidebar = useMemo(() => {
    if (selectedPolicy) {
      return <SelectedPolicy policy={selectedPolicy} />;
    }

    return null;
  }, [selectedPolicy]);

  const cancelledPolicies = data?.filter((policy) => policy.status === PolicyStatus.CANCELLED);
  const filterOptions = useMemo(() => {
    return FILTERS.map((filter): Option<Filter> => {
      let count: number | undefined = undefined;

      switch (filter.value) {
        case Filter.Cancelled:
          count = cancelledPolicies?.length;
          break;
      }

      return {
        count,
        ...filter,
      };
    });
  }, [cancelledPolicies?.length]);

  return (
    <ApplicationViewContainer
      title='Policies'
      sidebar={sidebar}
      actions={actions}
      onSidebarCloseClick={() => {
        setDrawerOpen(false);
        setSelectedPolicy(undefined);
      }}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
    >
      <Helmet>
        <title>Application Policies</title>
      </Helmet>
      {data && data.length ? (
        <>
          <RadioSelect<Filter>
            options={filterOptions}
            className={classes.select}
            selectedValue={filter}
            onOptionSelect={(opt) => setFilter(opt.value)}
          />

          {filter === Filter.Cancelled && (
            <CancelPoliciesTable
              data={cancelledPolicies}
              onPolicySelect={(item) => {
                setDrawerOpen(true);
                setSelectedPolicy(item);
              }}
              selectedRowsKeys={selectedPolicy ? [selectedPolicy.id] : []}
              selectedRowClass={classes.selectedTableRow}
            />
          )}
          {filter === Filter.Active &&
            (policy ? <PolicyDetails policy={policy} /> : <NoActivePolicies applicationId={application.id} />)}
        </>
      ) : (
        <NoActivePolicies applicationId={application.id} />
      )}
    </ApplicationViewContainer>
  );
};
