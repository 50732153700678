import { AgentDTO } from 'dtos/agent';
import { AlertDTO, AlertsQuery, AlertsResponse, EditAlertDTO, EditAlertPreferenceDTO } from 'dtos/alert';
import pickBy from 'lodash/pickBy';
import qs from 'query-string';

import RequestService from './Request';

class _AlertsService {
  getAllAlerts(query: AlertsQuery) {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client
      .get<AlertsResponse>(`/applications/alerts?${params}`)
      .then((response) => response.data);
  }

  getAlert(id: string) {
    return RequestService.client.get<AlertDTO>(`/applications/alerts/${id}`).then((response) => response.data);
  }

  updateAlert(id: string, data: EditAlertDTO) {
    return RequestService.client.post<AlertDTO>(`/applications/alerts/${id}`, data).then((response) => response.data);
  }

  updateAlertPreferences(data: EditAlertPreferenceDTO) {
    return RequestService.client
      .post<AgentDTO>(`/agents/${data.id}/alert-preferences`, data.alertPreferences)
      .then((response) => response.data);
  }
}

const AlertsService = new _AlertsService();

export { AlertsService };
