import { DomainTechStackDTO } from 'dtos/domain-tech-stack';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { DomainTechStackService } from 'services/DomainTechStack';

export const useDomainTechStack = (domain: string, options?: UseQueryOptions<DomainTechStackDTO>) =>
  useQuery<DomainTechStackDTO>(['domain-tech-stack', domain], () => DomainTechStackService.getDomainTechStack(domain), {
    staleTime: 5 * 60 * 1000, // 5 min
    ...options,
  });
