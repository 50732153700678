import { DeepScanDTO, DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
import React, { useMemo } from 'react';
import Graph from 'react-graph-vis';

export const useNetworkTopologyChart = (
  deepScanTargets: DeepScanTargetDTO[],
  deepScanNetworkHosts?: DeepScanNetworkHostDTO[],
  deepScan?: DeepScanDTO,
) => {
  const getOptions = (hosts: DeepScanNetworkHostDTO[]) => {
    return {
      randomSeed: 'telivy',
      layout: {
        // hierarchical: true,
      },
      edges: {
        color: '#5d5d5d',
      },
      height: `${((hosts?.length || 1) + 9) * 40}px`,
      // width: '85%',
      interaction: {
        zoomView: false,
        // zoomSpeed: 0.1,
      },
      physics: {
        solver: 'repulsion',
        repulsion: {
          nodeDistance: ((hosts?.length || 1) + 1) * 5,
          // centralGravity: 0.08,
          // springLength: ((hosts?.length || 1) + 1) * 8,
          // springConstant: 0.01,
          // damping: 0.09,
        },
      },
    };
  };

  const getGraph = (hosts: DeepScanNetworkHostDTO[], deepScanTargets: DeepScanTargetDTO[], deepScan?: DeepScanDTO) => {
    return {
      nodes: (hosts || []).map((host) => {
        const target = deepScanTargets.find((t) => t.macAddress === host.macAddress);

        let targetText = '';
        if (target) {
          targetText += `\n\n${target.nmapScanStats?.cveCounts?.high || 0} Critical Vulnerabilities`;
          targetText += `\n${deepScan?.piiConfig?.currency || '$'}${(
            target.piiToolsSummary?.risk || 0
          ).toLocaleString()} Data Risk`;
        }

        return {
          id: host.macAddress,
          label: `${host.hostName || host.ipAddress}${target ? targetText : ''}`,
          title: host.ipAddress,
          color: host.macAddress == host.gatewayMacAddress ? '#F7E092' : target ? '#C2EDDB' : '#B9C3F5',
          shape: host.macAddress == host.gatewayMacAddress ? 'circle' : 'box',
          margin: 10,
        };
      }),
      edges: (hosts || [])
        .filter(
          (host) =>
            host.gatewayMacAddress && host.gatewayMacAddress !== '' && host.gatewayMacAddress !== host.macAddress,
        )
        .map((host) => {
          return {
            from: host.macAddress,
            to: host.gatewayMacAddress,
            // length: parseInt(Math.ceil((((hosts?.length || 1) + 24) * 10 * 1) / 2).toFixed(2)),
          };
        }),
    };
  };

  const networks: { [key: string]: DeepScanNetworkHostDTO[] } = useMemo(() => {
    const results: { [key: string]: DeepScanNetworkHostDTO[] } = {};

    for (const host of deepScanNetworkHosts || []) {
      if (host.gatewayMacAddress) {
        results[host.gatewayMacAddress] ||= [];
        results[host.gatewayMacAddress].push(host);
      }
    }

    return results;
  }, [deepScanNetworkHosts]);

  const charts = useMemo(() => {
    return Object.values(networks).map((hosts, i) => {
      const graph = getGraph(hosts, deepScanTargets, deepScan);
      const options = getOptions(hosts);

      return <Graph key={i} graph={graph} options={options} events={{}} />;
    });
  }, [networks, deepScanTargets, deepScan]);

  // const events = useMemo(() => {
  //   return {
  //     select: (event) => {
  //       const { nodes, edges } = event;
  //     },
  //   };
  // }, []);

  // const criticalVulnerabilities = useMemo(() => {
  //   const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
  //     const finished = Object.keys(snapshot.scanStatus || {}).every(
  //       (h) =>
  //         snapshot.scanStatus[h].telivyAgentInstalledAt ||
  //         (snapshot.scanStatus[h].system.scan_apps_os_version && snapshot.nmapScanStats[h]),
  //     );
  //     const message = finished ? 'Latest Scan' : 'Scan In Progress';
  //     const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
  //     acc[date] = acc[date] || snapshot;

  //     return acc;
  //   }, {});

  //   const snapshotsDates = sortBy(
  //     Object.keys(snapshotsByDate).map((date) => {
  //       return { date, createdAt: snapshotsByDate[date].createdAt };
  //     }),
  //     (snapshot) => snapshot.createdAt,
  //   ).map((d) => d.date);

  //   let selectedAssetsFinal: string[] | undefined = undefined;
  //   if (onlyActive && snapshotsDates.length > 1) {
  //     const latestSnapshot = snapshotsDates[snapshotsDates.length - 2];
  //     selectedAssetsFinal = Object.keys(snapshotsByDate[latestSnapshot].nmapScanStats).filter((s) => {
  //       return (
  //         !snapshotsByDate[latestSnapshot].scanStatus ||
  //         !snapshotsByDate[latestSnapshot].scanStatus[s]?.system?.rescan_scheduled
  //       );
  //     });
  //   } else {
  //     selectedAssetsFinal = selectedAssets;
  //   }

  //   const summedByDate = sortBy(
  //     snapshotsDates.map((date, index) => {
  //       const prevDate = snapshotsDates[index - 1];

  //       const filteredAssets =
  //         (selectedAssetsFinal || []).length === 0
  //           ? Object.keys(snapshotsByDate[date].nmapScanStats)
  //           : selectedAssetsFinal || [];

  //       const assetsData = filteredAssets
  //         .filter((s) => {
  //           return !snapshotsByDate[date].scanStatus || !snapshotsByDate[date].scanStatus[s]?.system?.rescan_scheduled;
  //         })
  //         .map((s) => {
  //           const completed =
  //             snapshotsByDate[date].scanStatus &&
  //             (snapshotsByDate[date].scanStatus[s]?.telivyAgentInstalledAt ||
  //               (snapshotsByDate[date].scanStatus[s]?.system.scan_apps_os_version &&
  //                 snapshotsByDate[date].nmapScanStats[s]));

  //           return {
  //             name: completed ? s : `${s} *`,
  //             value: snapshotsByDate[date].nmapScanStats[s]?.cveCounts?.high || 0,
  //             previous: (prevDate && snapshotsByDate[prevDate].nmapScanStats[s]?.cveCounts?.high) || 0,
  //           };
  //         });

  //       const criticalCount = assetsData.reduce((a, b) => a + b.value, 0);
  //       const criticalCountPrev = prevDate
  //         ? Object.values(snapshotsByDate[prevDate].nmapScanStats).reduce((a, b) => a + b.cveCounts?.high || 0, 0)
  //         : 0;

  //       return {
  //         date,
  //         critical: criticalCount,
  //         criticalPrev: criticalCountPrev,
  //         assetsData,
  //         createdAt: snapshotsByDate[date].createdAt,
  //       };
  //     }),
  //     (snapshot) => snapshot.createdAt,
  //   );

  //   return summedByDate
  //     .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
  //       acc.push({
  //         name: snapshot.date,
  //         score: snapshot.critical,
  //         distribution: ([] as any).concat(snapshot.assetsData, [
  //           { name: 'Total', value: snapshot.critical, previous: snapshot.criticalPrev },
  //         ]),
  //       });

  //       return acc;
  //     }, [])
  //     .slice(0, 365);
  // }, [data, selectedAssets, onlyActive]);

  // const chart = useMemo(() => {
  //   return <Graph graph={graph} options={options} events={{}} />;
  // }, [graph, options]);

  return {
    charts,
    // criticalVulnerabilities,
  };
};
