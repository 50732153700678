import { InternalScanType as SecurityScanType } from 'dtos/security-finding';
import React, { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as _LineChart,
  // LineProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  // YAxisProps,
} from 'recharts';
import { CustomGraphLegendContent } from 'templates/SecurityAssessments/components/CustomGraphLegendContent/internal';
import { CustomGraphTick } from 'templates/SecurityAssessments/components/CustomGraphTick/internal';
import { CustomGraphTooltipContent } from 'templates/SecurityAssessments/components/CustomGraphTooltipContent/internal';

// import { formatMillionsValue } from '../Money';
import { ChartContainer, Props as ChartContainerProps } from './ChartContainer';

type ChartValues = {
  [key in SecurityScanType]: number;
};

export interface ChartRecord extends ChartValues {
  date: string;
  OVERALL_SCORE?: number | null;
}

export const SCAN_TYPE_NAME = {
  [SecurityScanType.NETWORK_SECURITY]: 'Network Security',
  [SecurityScanType.DATA_SECURITY]: 'Data Security',
  [SecurityScanType.IDENTITY_ACCESS_MANAGEMENT]: 'Identity and Access Management',
  [SecurityScanType.DARK_WEB_PRESENCE]: 'Dark Web Presence',
  [SecurityScanType.M365_SECURITY]: 'M365 Security',
  [SecurityScanType.APPLICATION_SECURITY]: 'Application Security',
};

export const CHART_OPTION = {
  ...SCAN_TYPE_NAME,
  OVERALL_SCORE: 'Overall Score',
};

type ChartVisibility = {
  [key in SecurityScanType]: boolean;
};

export interface Props extends ChartContainerProps {
  data: ChartRecord[];
  dollars?: boolean;
  skipValues?: Array<number>;
}

// Keep outside of component to avoid re-rendering
// const mockScore = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];

export const HistoryGrades = (props: Props) => {
  const data = props.data;

  const [selectionType, setSelectionType] = useState<boolean>(false);
  const [visibleCharts, setVisibleCharts] = useState<Partial<ChartVisibility>>({
    [SecurityScanType.NETWORK_SECURITY]: true,
    [SecurityScanType.DATA_SECURITY]: true,
    [SecurityScanType.IDENTITY_ACCESS_MANAGEMENT]: true,
    [SecurityScanType.DARK_WEB_PRESENCE]: true,
    [SecurityScanType.M365_SECURITY]: true,
    [SecurityScanType.APPLICATION_SECURITY]: true,
  });

  const handleToggle = (chart: SecurityScanType) => {
    setVisibleCharts((prev) => ({ ...prev, [chart]: !prev[chart] }));
  };

  const handleToggleAll = () => {
    // check if all of the values are true or the selectionType (last selection) was set to true
    if (selectionType || Object.values(visibleCharts).every((value) => value)) {
      setVisibleCharts({
        [SecurityScanType.NETWORK_SECURITY]: false,
        [SecurityScanType.DATA_SECURITY]: false,
        [SecurityScanType.IDENTITY_ACCESS_MANAGEMENT]: false,
        [SecurityScanType.DARK_WEB_PRESENCE]: false,
        [SecurityScanType.M365_SECURITY]: false,
        [SecurityScanType.APPLICATION_SECURITY]: false,
      });
      setSelectionType(false);
    } else {
      setVisibleCharts({
        [SecurityScanType.NETWORK_SECURITY]: true,
        [SecurityScanType.DATA_SECURITY]: true,
        [SecurityScanType.IDENTITY_ACCESS_MANAGEMENT]: true,
        [SecurityScanType.DARK_WEB_PRESENCE]: true,
        [SecurityScanType.M365_SECURITY]: true,
        [SecurityScanType.APPLICATION_SECURITY]: true,
      });
      setSelectionType(true);
    }
  };

  const tickInterval = useMemo(() => {
    if (!props.data) return 0;

    if (props.data.length <= 7) {
      return 0;
    }

    if (props.data.length <= 30) {
      return 6;
    }
    if (props.data.length <= 182) {
      return 29;
    }
    if (props.data.length <= 365) {
      return 181;
    }

    return 364;
  }, [props.data]);

  // const commonLineProps: Omit<LineProps, 'dataKey' | 'ref'> = {
  //   radius: 4,
  //   strokeWidth: 1,
  // };

  // const commonYAxisProps: YAxisProps = {
  //   fontSize: 10,
  //   allowDecimals: false,
  //   tickFormatter: (tick: number) => {
  //     const val = tick / 1000;
  //     let formatted = tick.toLocaleString();

  //     if (tick >= 1000) {
  //       formatted = `${parseFloat(val.toFixed(1))}k`;
  //     }

  //     if (tick >= 1_000_000) {
  //       formatted = formatMillionsValue(tick);
  //     }

  //     return props.dollars ? `$${formatted}` : formatted;
  //   },
  // };

  // const parsedData = useMemo(() => {
  //   if (props.locked) {
  //     // Randomize the data
  //     return props.data.map((d, idx) => ({
  //       ...d,
  //       score: idx % 2 === 0 ? mockScore[idx % 3] : mockScore[(idx + 1) % 3],
  //     }));
  //   } else {
  //     return props.data;
  //   }
  // }, [props.data, props.locked]);

  return (
    <ChartContainer {...props}>
      <ResponsiveContainer width='100%' height='100%' minHeight={400}>
        <_LineChart
          width={900}
          height={400}
          data={data?.sort((a, b) => (a?.date && b?.date && new Date(a.date) > new Date(b.date) ? 1 : -1))}
          margin={{
            top: 5,
            bottom: 5,
            right: 45,
          }}
        >
          <CartesianGrid strokeDasharray='4 2' vertical={false} />
          <XAxis dataKey='date' interval={tickInterval} />
          <YAxis tick={<CustomGraphTick />} ticks={[25, 50, 75, 100]} />
          <Tooltip
            contentStyle={{ borderRadius: 24, border: '1px solid #EDEDED', padding: '16px 20px' }}
            content={<CustomGraphTooltipContent />}
            filterNull={false}
          />
          <Legend
            layout='vertical'
            align='left'
            verticalAlign='top'
            iconType='plainline'
            wrapperStyle={{ borderRadius: 24, padding: 0 }}
            content={
              <CustomGraphLegendContent
                toggleChart={handleToggle}
                toggleAll={handleToggleAll}
                toggleState={selectionType}
              />
            }
          />
          {/*<Line dataKey={'securityOverallScore'} stroke='#3644CA' dot={true} activeDot={true} strokeWidth={4} />*/}
          <Line
            dataKey={SecurityScanType.NETWORK_SECURITY}
            stroke='#000000'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.NETWORK_SECURITY]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.DATA_SECURITY}
            stroke='#516AE6'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.DATA_SECURITY]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.IDENTITY_ACCESS_MANAGEMENT}
            stroke='#18A0FB'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.IDENTITY_ACCESS_MANAGEMENT]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.DARK_WEB_PRESENCE}
            stroke='#ED6660'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.DARK_WEB_PRESENCE]}
            strokeDasharray='10 5'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.M365_SECURITY}
            stroke='#D41CC2'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.M365_SECURITY]}
            strokeDasharray='15 8'
            strokeWidth={2}
          />
          <Line
            dataKey={SecurityScanType.APPLICATION_SECURITY}
            stroke='#A412C5'
            dot={false}
            activeDot={false}
            hide={!visibleCharts?.[SecurityScanType.APPLICATION_SECURITY]}
            strokeDasharray='15 8'
            strokeWidth={2}
          />
        </_LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};
