import { Button, ButtonProps } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { QuotesTable } from 'components/QuotesTable';
import { ApplicationDTO } from 'dtos/application';
import { QuoteDTO, QuoteStatus } from 'dtos/quotes';
import { ToasterVariant, useToasters } from 'hooks/useToasters';
import { useCreateApplicationProposal } from 'queries/useApplications';
import React, { useCallback, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  footer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  disclaimer: {
    marginLeft: theme.spacing(0.5),
    color: COLORS.GREY_3,
  },
  actions: {
    marginLeft: 'auto',
  },
  button: {
    '& + $button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface Props {
  application: ApplicationDTO;
  className?: string;
  buttonProps?: ButtonProps;
}

export const CreateProposalButton: React.FC<Props> = ({ application, className, buttonProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useCreateApplicationProposal(application.id, {
    onSuccess: () => {
      handleToggleModal();
      showToaster('Proposal Created');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handleToggleModal = useCallback(() => {
    // Reset values if closing the modal
    if (isOpen) {
      setSelectedIds([]);
    }
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleRowClick = useCallback(
    (q: QuoteDTO) => {
      if (selectedIds.includes(q.id)) {
        setSelectedIds((el) => el.filter((el) => el !== q.id));
      } else {
        setSelectedIds((el) => [...el, q.id]);
      }
    },
    [selectedIds],
  );

  const handleCreateProposal = useCallback(() => {
    if (selectedIds.length === 0) {
      showToaster({ message: 'Select at least one quote to create a proposal.', variant: ToasterVariant.ERROR });
    } else {
      mutate(selectedIds);
    }
  }, [mutate, selectedIds, showToaster]);

  return (
    <>
      <Button {...buttonProps} className={className} onClick={handleToggleModal}>
        Create Proposal
      </Button>
      <Dialog fullWidth={true} open={isOpen} maxWidth='lg' onClose={handleToggleModal}>
        <div className={classes.root}>
          <h3 className={classes.title}>Create Proposal</h3>
          <QuotesTable
            emptyState={<div>No quotes available</div>}
            bordered
            rounded
            dataFilter={(i) => i.quoteStatus === QuoteStatus.ACTIVE}
            application={application}
            selectedRowsKeys={selectedIds}
            disableIfMissingQuoteLetter
            disableNotSelected={selectedIds.length === 3}
            onRowSelect={handleRowClick}
            onRowClick={handleRowClick}
          />

          <footer className={classes.footer}>
            {selectedIds.length} Selected{' '}
            {selectedIds.length === 3 ? <span className={classes.disclaimer}>(maximum number of quotes)</span> : null}
            <div className={classes.actions}>
              <Button onClick={handleToggleModal} className={classes.button} disabled={isLoading}>
                Close
              </Button>
              <Button
                onClick={handleCreateProposal}
                color='primary'
                variant='contained'
                disabled={isLoading}
                className={classes.button}
              >
                {isLoading ? 'Creating...' : 'Create'}
              </Button>
            </div>
          </footer>
        </div>
      </Dialog>
    </>
  );
};
