import { Button, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { ApplicationDTO } from 'dtos/application';
import { DeclinationDTO } from 'dtos/declinations';
import React, { useCallback, useMemo, useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

import { DeclinationForm } from './DeclinationForm';
import { Documents } from './Documents';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  application: ApplicationDTO;
  existingDeclination?: DeclinationDTO;
  onDeclinationUpdate: (data: DeclinationDTO) => void;
  className?: string;
}

export const DeclinationButton: React.FC<Props> = ({
  application,
  className,
  existingDeclination,
  onDeclinationUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const [quote, setQuote] = useState<DeclinationDTO | null>(existingDeclination || null);
  const [showDocuments, setShowDocuments] = useState(false);

  const handleToggleModal = useCallback(() => {
    setIsOpen(!isOpen);
    setQuote(null);
  }, [isOpen]);

  const handleQuoteData = useCallback(
    (quote) => {
      setQuote(quote);
      setShowDocuments(true);

      if (existingDeclination) {
        onDeclinationUpdate(quote);
      }
    },
    [existingDeclination, onDeclinationUpdate],
  );

  const modalTitle = useMemo(() => {
    if (quote) {
      return 'Documents Upload';
    }

    if (existingDeclination) {
      return 'Update Declination';
    }

    return 'Create Declination';
  }, [quote, existingDeclination]);

  return (
    <>
      <Button className={className} startIcon={<AddIcon />} onClick={handleToggleModal}>
        {existingDeclination ? 'Update Declination' : 'Create Declination'}
      </Button>
      <Dialog fullWidth={true} open={isOpen} maxWidth='md' onClose={handleToggleModal}>
        <div className={classes.root}>
          <h3 className={classes.title}>{modalTitle}</h3>

          {showDocuments && quote ? (
            <Documents quoteId={quote.id} onClose={handleToggleModal} applicationId={application.id} />
          ) : (
            <DeclinationForm
              application={application}
              onCloseRequest={handleToggleModal}
              onDeclinationCreation={handleQuoteData}
              existingDeclination={existingDeclination}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};
