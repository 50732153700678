import {
  AgencyDTO,
  AgencyStatsDTO,
  AgentDTO,
  CreateEditAgentInAgencyDTO,
  CreateUpdateAgencyDTO,
  DeleteAgentInAgencyDTO,
} from 'dtos/agent';

import RequestService from './Request';

class _AgencyService {
  getCurrentAgencyUsers() {
    return RequestService.client.get<AgentDTO[]>(`/agencies/users`).then((response) => response.data);
  }

  getAgencyUsers(id: string) {
    return RequestService.client.get<AgentDTO[]>(`/agencies/${id}/users`).then((response) => response.data);
  }

  getCurrentAgencyStats() {
    return RequestService.client.get<AgencyStatsDTO>(`/agencies/stats`).then((response) => response.data);
  }

  getCurrentSubAgenciesStats() {
    return RequestService.client.get<AgencyStatsDTO[]>(`/agencies/sub/stats`).then((response) => response.data);
  }

  createUpdateAgentInAgency(data: CreateEditAgentInAgencyDTO) {
    return RequestService.client.post<AgentDTO>(`/agencies/users`, data).then((response) => response.data);
  }

  deleteAgentInAgency(id: string, data: DeleteAgentInAgencyDTO) {
    return RequestService.client.post<AgentDTO>(`/agencies/${id}/delete-user`, data).then((response) => response.data);
  }

  createAgency(data: CreateUpdateAgencyDTO) {
    return RequestService.client.post<AgencyDTO>(`/agencies`, data).then((response) => response.data);
  }

  editAgency(id: string, data: CreateUpdateAgencyDTO) {
    return RequestService.client.patch<AgencyDTO>(`/agencies/${id}/update`, data).then((response) => response.data);
  }

  async uploadLogo(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await RequestService.client
      .post<string>(`/agencies/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);
  }
}

const AgencyService = new _AgencyService();

export { AgencyService };
