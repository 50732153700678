import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { COLORS } from 'telivy-theme';

import { NodewareApiKey } from './NodewareApiKey';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(5),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  color: {
    color: COLORS.BLUE_1,
  },
}));

interface Props {
  agent: AgentDTO;
}

export const IntegrationsView: React.FC<Props> = ({ agent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h1 className={classes.title}>Integrations</h1>
      </div>
      <div>
        <p>Fetch data from existing tools into Telivy.</p>
        <h3 className={classes.subtitle}>Nodeware</h3>
        <p>
          Enable Nodeware using your API Key. Then configure every assessment with nodeware customer ID to use Nodeware
          data in Telivy Reports.
        </p>

        <div>
          <NodewareApiKey agent={agent} />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
