import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { EmptyState } from 'components/EmptyState';
import { Money } from 'components/Money';
import { Option, RadioSelect } from 'components/RadioSelect';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { ProposalDTO, ProposalStatus } from 'dtos/proposal';
import { useToasters } from 'hooks/useToasters';
import { ReactComponent as QuoteIcon } from 'images/quote.svg';
import { useApplicationProposals } from 'queries/useApplications';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { DeleteProposalButton } from 'views/agent/views/application-details/views/proposals/DeleteButton';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { CreateProposalButton } from './CreateProposalButton';
import { DocxButton } from './DocxButton';
import { MultiRow } from './MultiRow';
import { PdfButton } from './PdfButton';

const useStyles = makeStyles((theme) => ({
  // Select component
  select: {
    marginBottom: theme.spacing(3),
  },
}));

const columns = (
  onShare: (proposal: ProposalDTO) => void,
  application: ApplicationDTO,
): Column<ProposalDTO, keyof ProposalDTO>[] => [
  {
    title: 'Created',
    width: '17%',
    render: (proposal) => dayjs(proposal.createdAt).format('MM/DD/YYYY'),
  },
  {
    title: 'Quote ID',
    width: '17%',
    render: (proposal) => <MultiRow rows={proposal.quotes.map((q) => q.quoteNumber)} />,
  },
  {
    title: 'Carrier',
    width: '17%',
    render: (proposal) => <MultiRow rows={proposal.quotes.map((q) => q.carrier.displayName)} noto />,
  },
  {
    title: 'Limit',
    width: '17%',
    render: (proposal) => (
      <MultiRow
        rows={proposal.quotes.map((q, idx) => (
          <Money value={q.aggregateLimit} key={idx} />
        ))}
      />
    ),
  },
  {
    title: 'Premium',
    width: '17%',
    render: (proposal) => (
      <MultiRow
        rows={proposal.quotes.map((q, idx) => (
          <Money value={q.premium} key={idx} />
        ))}
      />
    ),
  },
  {
    title: 'Actions',
    width: '15%',
    render: (proposal) => {
      return (
        <>
          <PdfButton proposal={proposal} application={application} />
          <DocxButton proposal={proposal} application={application} style={{ marginLeft: 8 }} />
          <DeleteProposalButton proposal={proposal} style={{ marginLeft: 8 }} />
        </>
      );
    },
  },
];

const FILTERS: Option<ProposalStatus>[] = [
  {
    label: 'Active',
    value: ProposalStatus.ACTIVE,
  },
  {
    label: 'Deactivated',
    value: ProposalStatus.DEACTIVATED,
  },
];

interface Props {
  application: ApplicationDTO;
}

export const ApplicationProposalView = ({ application }: Props) => {
  const classes = useStyles();
  const { data, isLoading } = useApplicationProposals(application.id);
  const [filter, setFilter] = useState<ProposalStatus>(ProposalStatus.ACTIVE);
  const { showToaster } = useToasters();

  const handleShareProposalClick = useCallback(
    (proposal: ProposalDTO) => {
      const link = `${window.location.origin}${insertIdToRoute(
        ROUTES.application.PROPOSAL,
        proposal.applicationId,
      ).replace(':proposalId', proposal.id)}`;
      navigator.clipboard.writeText(link);
      showToaster('Link is copied to clipboard');
    },
    [showToaster],
  );

  const filterOptions = useMemo((): Option<ProposalStatus>[] => {
    return FILTERS.map((f) => ({
      label: f.label,
      value: f.value,
      count: data?.filter((p) => p.status === f.value).length,
    }));
  }, [data]);

  return (
    <ApplicationViewContainer
      title='Proposals'
      actions={
        <>
          <CreateProposalButton application={application} buttonProps={{ startIcon: <AddIcon /> }} />
        </>
      }
    >
      <Helmet>
        <title>Application Proposals</title>
      </Helmet>

      {data && data.length > 0 && (
        <RadioSelect<ProposalStatus>
          options={filterOptions}
          className={classes.select}
          selectedValue={filter}
          onOptionSelect={(opt) => setFilter(opt.value)}
        />
      )}

      <Table<ProposalDTO>
        rowKey={(el) => el.id}
        hideHeadersWhenEmpty
        emptyState={
          filter === ProposalStatus.ACTIVE ? (
            <EmptyState
              icon={<QuoteIcon />}
              title='No Active Proposals'
              actions={
                <CreateProposalButton
                  application={application}
                  buttonProps={{
                    variant: 'contained',
                    color: 'primary',
                  }}
                />
              }
            />
          ) : (
            <EmptyState title='No Deactivated Proposals' icon={<QuoteIcon />} />
          )
        }
        columns={columns(handleShareProposalClick, application)}
        loading={isLoading}
        oddRowsHighlight
        data={data?.filter((p) => p.status === filter)}
      />
    </ApplicationViewContainer>
  );
};
