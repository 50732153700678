import { Button, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { DeepScanDTO } from 'dtos/deep-scan';
import { ReactComponent as AppleLogo } from 'images/apple.svg';
import { ReactComponent as WindowsLogo } from 'images/windows.svg';
import React, { useCallback, useState } from 'react';
import {
  SCAN_BROWSER_PASSWORDS_EXE_URL,
  SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER,
  SCAN_BROWSER_PASSWORDS_ONE_LINER,
  UNINSTALL_SCAN_ONE_LINER,
} from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { CONFIGURATION_STEP } from './configuration';
import { CopyDeepScanIdButton } from './CopyDeepScanIdButton';
import { SectionTemplate } from './SectionTemplate';

const useStyles = makeStyles((theme) => ({
  boxesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  box: {
    flex: 1,
    height: '100%',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  boxTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  boxDescription: {
    fontSize: 14,
    margin: 0,
  },
  boxSubTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  boxButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  boxButtonMac: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  or: {
    fontWeight: 'bold',
  },

  badge: {
    textAlign: 'left',
  },
  select: {
    marginBottom: theme.spacing(2),
  },

  osContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  osItem: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    cursor: 'pointer',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    color: COLORS.GREY_1,
    backgroundColor: COLORS.GREY_6,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'all .2s linear',
    minWidth: theme.spacing(12.5),
    boxSizing: 'border-box',

    '&:not($osActive):hover': {
      backgroundColor: COLORS.GREY_4,
    },
  },
  icon: {
    '& path': {
      fill: '#BFC1D2',
    },
  },
  osActive: {
    backgroundColor: COLORS.BLUE_1,
    color: COLORS.WHITE,
    '& $icon path': {
      fill: COLORS.WHITE,
    },
  },

  downloadBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
  copyButton: {},
  oneLiner: {
    padding: theme.spacing(1),
    backgroundColor: COLORS.GREY_4,
    fontFamily: 'monospace',
  },
}));

enum OS {
  WINDOWS = 'Windows',
  MAC = 'Mac',
}

interface Props {
  deepScan: DeepScanDTO;
  onVerify: () => void;
}

export const ScanBrowserPasswordsSection = ({ deepScan, onVerify }: Props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(OS.WINDOWS);

  const renderContent = useCallback(() => {
    switch (selected) {
      case OS.WINDOWS:
        return (
          <>
            <div className={classes.box}>
              <div className={classes.boxTitle}>Supported Versions:</div>
              <p className={classes.boxDescription}>
                Windows 10 64-bit (Enterprise, Pro, Home, Education)
                <br />
                Windows 11 64-bit (Enterprise, Pro, Home, Education)
                <br />
                Windows Server 2016
                <br />
                Windows Server 2019
                <br />
                Windows Server 2022
              </p>
            </div>

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>Run as Local User Manually:</div>
                <p className={classes.boxDescription}>Run the application on clients and enter the Telivy Key</p>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.boxButton}
                  href={SCAN_BROWSER_PASSWORDS_EXE_URL}
                  target='_blank'
                >
                  Download Application (.exe)
                </Button>

                <SecurityCard.Badge variant='success' className={classes.downloadBox}>
                  Your Telivy Key is {deepScan.id}
                  <CopyDeepScanIdButton deepScanId={deepScan.id} className={classes.copyButton} />
                </SecurityCard.Badge>

                <div className={classes.boxTitle}>OR Run as Administrator via Powershell/RMM:</div>
                <p className={classes.boxDescription}>
                  Run the following command as Administrator on powershell terminal
                  <br />
                  OR via RMM as System User
                </p>
                <p className={classes.oneLiner}>
                  {SCAN_BROWSER_PASSWORDS_ONE_LINER.replace('TELIVY_KEY', deepScan.id)}
                </p>

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    navigator.clipboard.writeText(SCAN_BROWSER_PASSWORDS_ONE_LINER.replace('TELIVY_KEY', deepScan.id));
                  }}
                >
                  Copy Install Command
                </Button>
              </div>
            </div>

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>To Uninstall after Risk Assessment is completed:</div>
                <p className={classes.boxDescription}>
                  Run the following command as Administrator on powershell terminal
                  <br />
                  OR via RMM as System User
                </p>
                <p className={classes.oneLiner}>{UNINSTALL_SCAN_ONE_LINER}</p>

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    navigator.clipboard.writeText(UNINSTALL_SCAN_ONE_LINER);
                  }}
                >
                  Copy Uninstall Command
                </Button>
              </div>
            </div>
          </>
        );
      case OS.MAC:
        return (
          <>
            <div className={classes.box}>
              <div className={classes.boxTitle}>Supported Versions:</div>
              <p className={classes.boxDescription}>
                macOS 10.14 Mojave
                <br />
                macOS 10.15 Catalina
                <br />
                macOS 11 Big Sur
                <br />
                macOS 12 Monterey
                <br />
                macOS 13 Ventura
              </p>
            </div>

            <div className={classes.boxesContainer}>
              <div className={classes.box}>
                <div className={classes.boxTitle}>Run as Local User Manually:</div>
                <p className={classes.boxDescription}>
                  Run the following command on Terminal.
                  <br />
                  Local user password of the client will be asked twice when running.
                </p>
                <p className={classes.oneLiner}>
                  {SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER.replace('TELIVY_KEY', deepScan.id)}
                </p>

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      SCAN_BROWSER_PASSWORDS_MAC_ONE_LINER.replace('TELIVY_KEY', deepScan.id),
                    );
                  }}
                >
                  Copy Scan Command
                </Button>

                {/*<SecurityCard.Badge variant='success' className={classes.downloadBox}>
                  Your Telivy Key is {deepScan.id}
                  <CopyDeepScanIdButton deepScan={deepScan} className={classes.copyButton} />
                </SecurityCard.Badge>*/}
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [classes, selected, deepScan]);

  return (
    <SectionTemplate
      onVerify={onVerify}
      deepScan={deepScan}
      type={CONFIGURATION_STEP.BROWSER_PASSWORDS}
      title='Scan Browser Passwords'
      description='Choose your OS to see the instructions accordingly:'
    >
      <div className={classes.osContainer}>
        {[
          { os: OS.WINDOWS, icon: <WindowsLogo className={classes.icon} /> },
          { os: OS.MAC, icon: <AppleLogo className={classes.icon} /> },
        ].map((l, idx) => (
          <div
            tabIndex={0}
            role='button'
            key={idx}
            className={cx(classes.osItem, l.os === selected && classes.osActive)}
            onClick={() => setSelected(l.os)}
          >
            {l.icon}
            {l.os}
          </div>
        ))}
      </div>

      {renderContent()}
    </SectionTemplate>
  );
};
