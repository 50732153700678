import { makeStyles } from '@material-ui/core';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { useSecurityFindingsByDomain, useSecurityFindingsBySlug } from 'queries/useSecurityFinding';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { RemediationBoardView } from './Board';
import { RemediationListView } from './List';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  targets: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    DEVICES: string;
  };
  remediation: {
    PARENT: string;
    ROOT: string;
    BOARD: string;
    LIST: string;
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
}

export const ApplicationRemediationView = ({ application, sourceRoute }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: findingsByDomains } = useSecurityFindingsByDomain(
    {
      scanId: application.securityScan?.id || '',
    },
    {
      enabled: Boolean(application.securityScan?.id),
    },
  );

  const domains = useMemo(() => findingsByDomains?.map((finding) => finding.domain) ?? [], [findingsByDomains]);

  const { data: findings } = useSecurityFindingsBySlug(
    {
      scanId: application.securityScan?.id || '',
    },
    {
      enabled: Boolean(application.securityScan?.id),
    },
  );

  const viewOptions: Option<ValueOf<SourceRoute['remediation']>>[] = React.useMemo(() => {
    const options = [];

    options.push({
      label: `Board`,
      value: createSecurityRoute(sourceRoute.remediation.BOARD, application.id),
    });

    options.push({
      label: `List`,
      value: createSecurityRoute(sourceRoute.remediation.LIST, application.id),
    });

    return options;
  }, [sourceRoute, application]);

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Tasks - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute['remediation']>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.remediation.BOARD}
          element={<RemediationBoardView data={findings} domains={domains} />}
        />

        <Route
          path={sourceRoute.remediation.LIST}
          element={<RemediationListView data={findings} domains={domains} />}
        />

        <Route index element={<Navigate replace to={sourceRoute.remediation.BOARD} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
