import { Button, DialogContentText } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { useSubmitApplication } from 'queries/useApplications';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSecurityRoute, insertIdToRoute, ROUTES } from 'telivy-constants';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 120,

    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      width: `calc(100% - ${theme.spacing(2)}px)`,
      borderRadius: `${theme.spacing(4.75)}px !important`,
      height: theme.spacing(5.75),
    },
  },
}));

interface Props {
  className?: string;
  applicationId: string;
  isValid: boolean;
  redirectToTheForm: () => void;
  isSecurityApplication: boolean;
}

const CONFIRM_SUBMIT_COPY = (isSecurityApplication?: boolean) => {
  if (isSecurityApplication) {
    return (
      <p>
        Your work is saved. Hit submit to see security scan.
        <br />
        <br />
        Note: The form will be locked after submission.
      </p>
    );
  }

  return (
    <p>
      Your work is saved. Hit submit to send application to broker.
      <br />
      <br />
      Note: The form will be locked after submission.
    </p>
  );
};

export const SubmitApplicationButton: React.FC<Props> = ({
  className,
  applicationId,
  isValid,
  redirectToTheForm,
  isSecurityApplication,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isSuccess, isLoading } = useSubmitApplication(applicationId, {
    onSuccess: () => {
      setIsModalOpen(false);
      if (isSecurityApplication) {
        navigate(createSecurityRoute(ROUTES.application.security.ROOT, applicationId, 'latest'));
      } else {
        navigate(insertIdToRoute(ROUTES.application.QUOTING, applicationId));
      }
    },
  });

  return (
    <>
      <Button
        className={cx(className, classes.button)}
        color='primary'
        variant='contained'
        onClick={() => (isValid ? setIsModalOpen(true) : redirectToTheForm())}
        data-cy='form-submit-button'
      >
        Submit
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Confirm to submit</DialogTitle>
        <DialogContent>
          <DialogContentText>{CONFIRM_SUBMIT_COPY(isSecurityApplication)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
          {!isSuccess && (
            <Button
              color='primary'
              variant='contained'
              disabled={isLoading}
              onClick={() => mutate(isValid)}
              data-cy='form-confirm-submit-button'
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
