import { ChartContainer, Props as ChartContainerProps } from 'components/charts/ChartContainer';
import { LoadingContainer } from 'components/LoadingContainer';
import { ApplicationDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import React from 'react';

import { useChartsEnabled } from './useChartsEnabled';

export const useChartImage = (
  options: ChartContainerProps,
  chartUrl?: string,
  deepScan?: DeepScanDTO,
  application?: ApplicationDTO,
) => {
  const enabled = useChartsEnabled(deepScan, application);

  return (
    <ChartContainer {...options} locked={!enabled}>
      {!chartUrl ? (
        <LoadingContainer />
      ) : (
        <img
          style={{ width: '100%', minHeight: 200, objectFit: 'contain', display: 'block', flex: 1 }}
          src={chartUrl}
          alt={options.title || 'chart'}
        />
      )}
    </ChartContainer>
  );
};
