import { AxiosError } from 'axios';
import { AgentDTO, AgentSettingsDTO, AgentSummaryDTO } from 'dtos/agent';
import ReactGA from 'react-ga4';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { AgentService } from 'services/Agent';

export const useCurrentAgent = (options?: UseQueryOptions<AgentDTO, AxiosError>) =>
  useQuery<AgentDTO, AxiosError>('current-agent', () => AgentService.getCurrentlyLoggedInAgent(), {
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
    onSuccess: (data) => {
      ReactGA.set({ user_email: data.email, user_role: data.role });

      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
  });

export const useCustomDomainAgent = (origin: string, options?: UseQueryOptions<AgentDTO, AxiosError>) =>
  useQuery<AgentDTO, AxiosError>('custom-domain-agent', () => AgentService.getCustomDomainAgent(origin), options);

export const useAgentSettings = (options?: UseQueryOptions<AgentSettingsDTO, AxiosError>) =>
  useQuery<AgentSettingsDTO, AxiosError>('agent-settings', () => AgentService.getSettingsForAgent(), options);

export const useBaseAgent = (id: string, options?: UseQueryOptions<AgentDTO, AxiosError>) =>
  useQuery<AgentDTO, AxiosError>(`agent`, () => AgentService.getBaseAgentById(id), options);

export const useAgentSummaryData = (id: string, options?: UseQueryOptions<AgentSummaryDTO>) =>
  useQuery<AgentSummaryDTO>(['agent-summary', id], () => AgentService.getAgentSummaryData(id), options);

export const useUploadDomainCsv = (id: string, options?: UseMutationOptions<string, AxiosError, File>) => {
  const queryClient = useQueryClient();

  return useMutation('upload-domains-csv', (data: File) => AgentService.uploadDomainsCsv(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['applications']);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }

      // update scanning status after 5s
      setTimeout(() => queryClient.invalidateQueries(['applications']), 5000);
    },
  });
};

export const useDownloadExportCsv = (
  id: string,
  options?: UseMutationOptions<void, AxiosError, { filename?: string; fromDate?: Date | null; uptoDate?: Date | null }>,
) =>
  useMutation(
    'agent-download-export-csv',
    (data: { filename?: string; fromDate?: Date | null; uptoDate?: Date | null }) =>
      AgentService.downloadExportCsv(id, data.filename, data.fromDate, data.uptoDate),
    options,
  );

export const useTrackAgentEvent = (id: string, options?: UseMutationOptions<void, AxiosError, { event: string }>) =>
  useMutation('agent-track-event', (data: { event: string }) => AgentService.trackAgentEvent(id, data.event), options);

export const useResendVerifyEmail = (options?: UseMutationOptions<void, AxiosError, void>) =>
  useMutation('agent-resend-verify-email', () => AgentService.resendVerifyEmail(), options);
