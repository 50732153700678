import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import startCase from 'lodash/startCase';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  status: string;
  fullWidth?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
    width: (props) => (props.fullWidth ? '100%' : 'fit-content'),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: `2px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2)}px`,
    border: `1px solid ${COLORS.WHITE}`,
    color: (props) => {
      switch (props.status) {
        case 'In Progress':
          return COLORS.GREY_1;
        case 'Complete':
          return COLORS.GREEN_1;
        default:
          return COLORS.GREY_1;
      }
    },
    background: (props) => {
      switch (props.status) {
        case 'In Progress':
          return 'rgba(252, 245, 218, 0.8)';
        case 'Complete':
          return COLORS.GREEN_3;
        default:
          return COLORS.GREY_5;
      }
    },
    borderColor: (props) => {
      switch (props.status) {
        case 'In Progress':
          return COLORS.YELLOW_2;
        case 'Complete':
          return COLORS.GREEN_2;
        default:
          return COLORS.GREY_5;
      }
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}));

interface Props extends StyleProps {
  className?: string;
  isAdmin?: boolean;
}

export function ScanningStatusLabel({ status, className, fullWidth, isAdmin }: Props) {
  const classes = useStyles({ status, fullWidth });
  const rootClasses = cx(classes.root, className);

  const getStatusForUser = (status: string, isAdmin?: boolean) => {
    if (isAdmin) return status;

    return status;
  };

  return (
    <span className={rootClasses} role='button' tabIndex={0}>
      {startCase(getStatusForUser(status, isAdmin))}
    </span>
  );
}
