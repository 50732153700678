/* eslint-disable indent */
import { Button, Checkbox, makeStyles } from '@material-ui/core';
import { Badge, BadgeVariant } from 'components/Badge';
import { Modal } from 'components/Modal';
import { Column, Table } from 'components/Table';
import { SecurityFindingDTO, SecurityFindingStatus } from 'dtos/security-finding';
import { capitalize, sortBy } from 'lodash';
import Highlight, { defaultProps as defaultPrismProps } from 'prism-react-renderer';
import nightOwlLight from 'prism-react-renderer/themes/nightOwlLight';
import { useCurrentAgent } from 'queries/useAgent';
import React, { useMemo, useState } from 'react';
import { isAgentAnAdmin } from 'telivy-selectors';
import { isStringJSON } from 'telivy-utils';
import wrap from 'word-wrap';

import { ResolveFindingModal, Type } from '../ResolveFindingModal';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
  },

  checkbox: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

interface Props {
  data: SecurityFindingDTO[];
  isLoading?: boolean;
  onSelect?: (ids: string[]) => void;
  selectedIds?: string[];
}

interface FindingAction {
  finding: SecurityFindingDTO;
  type: Type;
}

const STATUS_TO_VARIANT: { [key in SecurityFindingStatus]: BadgeVariant } = {
  [SecurityFindingStatus.OPEN]: 'red',
  [SecurityFindingStatus.ACCEPTED]: 'greenWhite',
  [SecurityFindingStatus.IN_REVIEW]: 'yellowWhite',
  [SecurityFindingStatus.RESOLVED]: 'blueWhite',
};

export const SecurityFindingsTable = ({ data, isLoading, onSelect, selectedIds }: Props) => {
  const [action, setAction] = useState<FindingAction | null>(null);
  const classes = useStyles();
  const { data: user } = useCurrentAgent();

  const columns: Array<Column<SecurityFindingDTO>> = useMemo(() => {
    const cols: Column<SecurityFindingDTO>[] = [
      {
        title: 'Domain',
        width: '20%',
        render: (v) => v.domain,
      },
      {
        title: 'Finding',
        width: '40%',
        render: (v) => (
          <span dangerouslySetInnerHTML={{ __html: v.formattedValue || v.value }} style={{ wordBreak: 'break-all' }} />
        ),
      },
      {
        title: 'Comment',
        width: '20%',
        render: (v) => <span>{v.comment}</span>,
      },
      {
        title: 'Status',
        width: '5%',
        render: (v) => {
          return <Badge variant={STATUS_TO_VARIANT[v.status]}>{capitalize(v.status.replace('_', ' '))}</Badge>;
        },
      },
      {
        title: 'Dev Logs',
        width: '5%',
        render: (v) => {
          if (!v?.devLogs) {
            return null;
          }

          const log = v?.devLogs;
          const isJson = isStringJSON(log);
          const formattedCode = log
            ? isJson
              ? JSON.stringify(JSON.parse(log), null, 2)
              : wrap(log, {
                  indent: '',
                  width: 80,
                })
            : '';

          return (
            <Modal
              autoWidth
              removePaddingRight
              openButton={
                <Button color='primary' size='small'>
                  Details
                </Button>
              }
              title='Dev Logs'
            >
              {v.devLogs ? (
                <Highlight {...defaultPrismProps} code={formattedCode} language='bash' theme={nightOwlLight}>
                  {({ className, style, tokens, getLineProps, getTokenProps }) => (
                    <pre className={className} style={{ ...style, width: 'fit-content', padding: 16 }}>
                      {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })} key={i}>
                          {line.map((token, key) => (
                            <span {...getTokenProps({ token, key })} key={key} />
                          ))}
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              ) : null}
            </Modal>
          );
        },
      },
    ];

    if (onSelect) {
      cols.push({
        title: 'Actions',
        width: '10%',
        render: (v) => {
          return (
            <div className={classes.buttons}>
              {v.status === SecurityFindingStatus.IN_REVIEW && isAgentAnAdmin(user) && (
                <Button variant='contained' color='primary' onClick={() => setAction({ finding: v, type: 'approve' })}>
                  Approve
                </Button>
              )}

              {v.status === SecurityFindingStatus.IN_REVIEW && isAgentAnAdmin(user) && (
                <Button variant='contained' onClick={() => setAction({ finding: v, type: 'reject' })}>
                  Reject
                </Button>
              )}

              {[SecurityFindingStatus.ACCEPTED].includes(v.status) && (
                <Button variant='contained' color='primary' onClick={() => setAction({ finding: v, type: 'resolve' })}>
                  Resolve
                </Button>
              )}
            </div>
          );
        },
      });
    }

    if (onSelect && data.filter((f) => f.status === SecurityFindingStatus.OPEN).length > 0) {
      cols.unshift({
        title: '',
        width: 20,
        render: (v) => {
          if (v.status !== SecurityFindingStatus.OPEN) {
            return null;
          }

          return (
            <Checkbox
              color='primary'
              checked={(selectedIds || []).includes(v.id)}
              inputProps={{ 'aria-labelledby': `select ${v.id}` }}
              className={classes.checkbox}
              onChange={() => {
                if ((selectedIds || []).includes(v.id)) {
                  onSelect((selectedIds || []).filter((s) => s !== v.id));
                } else {
                  onSelect([...(selectedIds || []), v.id]);
                }
              }}
            />
          );
        },
        renderColumn: () => {
          return (
            <Checkbox
              color='primary'
              checked={(selectedIds || []).length === data.length}
              indeterminate={(selectedIds || []).length > 0 && (selectedIds || []).length < data.length}
              inputProps={{ 'aria-labelledby': 'select all' }}
              className={classes.checkbox}
              onChange={() => {
                if ((selectedIds || []).length === data.length) {
                  onSelect([]);
                } else {
                  onSelect(data.filter((f) => f.status === SecurityFindingStatus.OPEN).map((d) => d.id));
                }
              }}
            />
          );
        },
      });
    }

    return cols;
  }, [classes.buttons, classes.checkbox, data, onSelect, selectedIds, user]);

  return (
    <>
      <Table<SecurityFindingDTO, undefined>
        hideHeadersWhenEmpty
        columns={columns}
        loading={isLoading}
        data={sortBy(data || [], ['formattedValue', 'value', 'domain'])}
        rowKey={(el) => el.id}
      />
      <ResolveFindingModal type={action?.type || 'accept'} finding={action?.finding} onClose={() => setAction(null)} />
    </>
  );
};
