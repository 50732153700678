import { CreateDocumentDTO, DocumentDTO, GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import { ToasterVariant, useToasters } from 'hooks/useToasters';
import { useCallback } from 'react';
import { UseMutateAsyncFunction, UseMutateFunction } from 'react-query';

interface Data<DocumentType> {
  defaultDocumentType: DocumentType;
  fileUploadFn: UseMutateAsyncFunction<GeneratedUploadUrlResponseDTO, unknown, { type: DocumentType; file: File }>;
  createDocumentFn:
    | UseMutateFunction<DocumentDTO<DocumentType>, unknown, CreateDocumentDTO<DocumentType>>
    | ((data: CreateDocumentDTO<DocumentType>) => void);
}

export const useManageDocuments = <T,>({ defaultDocumentType, fileUploadFn, createDocumentFn }: Data<T>) => {
  const { showToaster, toasterErrorHandler, hideToaster } = useToasters();

  const handleUpload = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        try {
          const infoToaster = showToaster({ message: 'Uploading file...', variant: ToasterVariant.INFO });
          const data = await fileUploadFn({
            type: defaultDocumentType,
            file,
          });

          await createDocumentFn({
            documentPath: data.path,
            fileName: file.name,
            documentType: defaultDocumentType,
          });

          showToaster(`Successfully uploaded a document. See the list below.`);
          hideToaster(infoToaster);
        } catch (e) {
          toasterErrorHandler(e);
        }
      }
    },
    [showToaster, fileUploadFn, defaultDocumentType, createDocumentFn, hideToaster, toasterErrorHandler],
  );

  return { handleUpload };
};
