/* eslint-disable indent */
import { makeStyles } from '@material-ui/core';
import { GridLabelProps, RadarSliceTooltipProps, ResponsiveRadar } from '@nivo/radar';
import { Chip, TableTooltip } from '@nivo/tooltip';
import { animated } from '@react-spring/web';
import { ReportData } from 'dtos/deep-scan';
import { INTERNAL_SCAN_TYPE_NAME, InternalScanType } from 'dtos/security-finding';
import { startCase } from 'lodash';
import React, { useMemo } from 'react';
// import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { parseChartValueToGrade } from 'templates/SecurityAssessments/views/SecurityHistory';

interface Props {
  isLoading: boolean;
  reportData?: ReportData;
}

enum Keys {
  SCORE = 'score',
  BENCHMARK = 'benchmark',
}

interface GraphItem {
  key: InternalScanType;
  [Keys.SCORE]?: number;
  [Keys.BENCHMARK]?: number;
}

const useStyles = makeStyles((theme) => ({
  notes: {
    padding: theme.spacing(3),
    fontSize: '10px !important',
    color: 'grey',
  },
}));

export const RiskAssessmentRadarChart = ({ reportData, isLoading }: Props) => {
  const classes = useStyles();

  // const hasBenchmark = !!securityScan?.benchmark?.data?.moduleBenchmarks;
  const hasBenchmark = false;

  const data = useMemo((): GraphItem[] => {
    return [
      {
        key: InternalScanType.NETWORK_SECURITY,
        score: isLoading ? 0 : (reportData?.networkSecurity?.score || 0) * 100,
        benchmark: 0,
      },
      {
        key: InternalScanType.DATA_SECURITY,
        score: isLoading ? 0 : (reportData?.dataSecurity?.score || 0) * 100,
        benchmark: 0,
      },
      {
        key: InternalScanType.APPLICATION_SECURITY,
        score: isLoading ? 0 : (reportData?.applicationSecurity?.score || 0) * 100,
        benchmark: 0,
      },
      {
        key: InternalScanType.IDENTITY_ACCESS_MANAGEMENT,
        score: isLoading ? 0 : (reportData?.identityAccessManagement?.score || 0) * 100,
        benchmark: 0,
      },
      {
        key: InternalScanType.DARK_WEB_PRESENCE,
        score: isLoading ? 0 : (reportData?.securityAwarenessTraining?.score || 0) * 100,
        benchmark: 0,
      },
      // {
      //   key: InternalScanType.M365_SECURITY,
      //   score: isLoading ? 0 : (reportData?.m365Security?.score || 0) * 100,
      //   benchmark: 0,
      // },
    ];
  }, [reportData, isLoading]);

  return (
    <>
      <ResponsiveRadar
        data={data as any}
        keys={hasBenchmark ? [Keys.BENCHMARK, Keys.SCORE] : [Keys.SCORE]}
        indexBy='key'
        maxValue={100}
        gridLabel={LabelComponent()}
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: 'color' }}
        valueFormat={(value: number | null, key: string) =>
          key === Keys.SCORE ? `${parseChartValueToGrade(value)}` : ''
        }
        isInteractive={true}
        colors={hasBenchmark ? ['#6610F226', '#516AE699'] : ['#516AE699']}
        gridLevels={4}
        gridShape='linear'
        gridLabelOffset={32}
        sliceTooltip={RadarSliceTooltipComponent}
        dotSize={4}
        dotColor='#41505e'
        enableDotLabel={true}
        fillOpacity={0.2}
        blendMode='multiply'
        motionConfig='wobbly'
        legends={[
          {
            anchor: 'top-left',
            direction: 'column',
            translateX: -50,
            translateY: -50,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: '#999',
            data: [
              {
                id: Keys.SCORE,
                label: 'Score',
                color: '#516AE699',
              },
              {
                id: Keys.BENCHMARK,
                label: 'Benchmark *',
                color: '#6610F226',
              },
            ],
            symbolSize: 12,
            symbolShape: 'square',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
      <i className={classes.notes}>* Benchmarked against revenue and NAICS code.</i>
    </>
  );
};

const RadarSliceTooltipComponent = ({ index, data }: RadarSliceTooltipProps) => {
  const rows = useMemo(
    () =>
      data
        ?.sort((a, b) => {
          const keysOrder = [Keys.SCORE, Keys.BENCHMARK];
          const keyA = keysOrder.indexOf(a?.id as Keys);
          const keyB = keysOrder.indexOf(b?.id as Keys);
          return keyA - keyB;
        })
        ?.map((datum) => [
          <Chip key={datum?.id} color={datum?.color} />,
          startCase(datum?.id),
          datum?.value ? `${parseChartValueToGrade(datum?.value)} (${Math.round(datum?.value)}%)` : 'N/A',
        ]),
    [data],
  );

  return <TableTooltip title={<strong>{INTERNAL_SCAN_TYPE_NAME[index as InternalScanType]}</strong>} rows={rows} />;
};

const LabelComponent =
  () =>
  ({ id, anchor, animated: animatedProps }: GridLabelProps) => {
    const TEXT = { fontFamily: 'sans-serif', fontSize: 11, fill: 'rgb(51, 51, 51)' };
    const BOLD_TEXT = { ...TEXT, fontWeight: 600 };

    return (
      <animated.g transform={(animatedProps?.transform as any) || undefined}>
        <text dominantBaseline='central' textAnchor={anchor}>
          <tspan style={BOLD_TEXT}>{INTERNAL_SCAN_TYPE_NAME[id as InternalScanType]} </tspan>
        </text>
      </animated.g>
    );
  };
