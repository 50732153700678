import { Button, makeStyles } from '@material-ui/core';
import { AgentDTO } from 'dtos/agent';
import { ReactComponent as CheckGreenIcon } from 'images/confirm.svg';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px`,
  },
  heading: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    maxWidth: 390,
    textAlign: 'center',
    margin: 0,
    lineHeight: `${theme.spacing(3.5)}px`,
  },
  icon: {},
}));

interface Props {
  agent?: AgentDTO;
}

export const ThankYou = ({ agent }: Props) => {
  const classes = useStyles();

  const contact = useMemo(() => {
    if (!agent) {
      return null;
    }

    if (agent?.phone) {
      return (
        <span>
          via phone at <a href={`tel:${agent.phone.replace(' ', '')}`}>{agent?.phone}</a>
        </span>
      );
    }

    if (agent?.email) {
      return (
        <span>
          via email at <a href={agent.email}>{agent.email}</a>
        </span>
      );
    }

    return 'via email or phone';
  }, [agent]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Thank You</title>
      </Helmet>

      <div className={classes.heading} key='heading'>
        <h1 className={classes.title}>
          Thank You
          <CheckGreenIcon className={classes.icon} />
        </h1>

        <p className={classes.description}>
          We will reach out to you shortly to help you get started. If you have any questions, please contact us{' '}
          {contact}.
          <br />
          <br />
          <br />
          <Button
            variant='outlined'
            color='primary'
            href={`https://${agent?.agency?.domain}`}
            // onClick={() => {}}
          >
            Go back and explore {agent?.agency?.name}
          </Button>
        </p>
      </div>
    </div>
  );
};
