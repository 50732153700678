import { makeStyles, Theme } from '@material-ui/core';
import { Badge, BadgeVariant } from 'components/Badge';
import { SecurityGrade } from 'dtos/application';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';

const useStyles = makeStyles<Theme>((theme) => ({
  tile: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(0)}px 0 ${theme.spacing(1.5)}px`,
    borderBottom: `1px solid ${COLORS.GREY_5}`,
    marginBottom: theme.spacing(2),
  },
  tileNoBottomPadding: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
    padding: `${theme.spacing(0)}px 0 ${theme.spacing(0)}px`,
  },
  itemTitleWrapper: {
    ['@media print']: {
      width: '100%',
    },
  },
  title: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },
  description: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
    // marginBottom: theme.spacing(3),
  },
  badge: {
    marginRight: theme.spacing(1),
  },
  bigGrade: {
    ...TYPOGRAPHY.TITLE_3,
    height: theme.spacing(6),
    minWidth: theme.spacing(6),
    width: theme.spacing(6),
    boxSizing: 'border-box',
  },
}));

export const getGradeBadgeVariant = (grade?: SecurityGrade): BadgeVariant => {
  switch (grade) {
    case SecurityGrade.A:
      return 'green';
    case SecurityGrade.B:
      return 'blue';
    case SecurityGrade.C:
      return 'yellow';
    case SecurityGrade.D:
      return 'red';
    case SecurityGrade.E:
      return 'darkRed';
    default:
      return 'grey';
  }
};

export interface Props {
  title: string;
  score?: number;
  scoreText?: string;
  noBottomBorder?: boolean;
}

export const descriptions: { [key: string]: string } = {
  ['Network Security']: 'Exploitable vulnerabilities and network attack vectors',
  ['Application Security']: 'Risky web applications used by employees',
  ['Data Security']: 'PII data risk of unencrypted files on company assets',
  ['Identity and Access Management']: 'Weak and reused password commonly saved in browsers',
  ['Dark Web Presence']: 'Accounts and passwords found in data breaches on the dark web',
};

export const ExecutiveSummaryItem = ({ title, score, noBottomBorder }: Props) => {
  const classes = useStyles();

  return (
    <div className={noBottomBorder ? classes.tileNoBottomPadding : classes.tile}>
      <Badge
        typography={TYPOGRAPHY.LARGE}
        variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
        className={classes.bigGrade}
      >
        {parseScoreToGrade((score || 0) * 100)}
      </Badge>

      <div className={classes.itemTitleWrapper}>
        <div className={classes.title}>{title} </div>
        <div className={classes.description}>{descriptions[title as string]}</div>
      </div>
      {/*<div className={classes.description}>{scoreText}</div>*/}
    </div>
  );
};
