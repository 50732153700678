import { Button, makeStyles } from '@material-ui/core';
import { useExternalScanDisabled, useRiskAssessmentDisabled } from 'hooks/useAccountDisabled';
import { useRiskAssessmentPlusEnabled } from 'hooks/useRiskAssessmentPlusEnabled';
// import { ReactComponent as RecommendedIcon } from 'images/recommended-icon.svg';
import React, { useCallback } from 'react';
import { ASSESSMENT_NAMES, AssessmentType, DEMO_ID, insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  scanTypeWrapper: {
    display: 'flex',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },

  root: {
    flex: 1,
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
    color: COLORS.TEXT,
    backgroundColor: COLORS.GREY_6,
    border: `2px solid ${COLORS.GREY_6}`,
    borderRadius: theme.spacing(),
    transition: 'all 0.3s',

    '&:hover': {
      border: `2px solid ${COLORS.BLUE_1}`,
    },

    '&:after': {
      content: '',
      width: 100,
      height: '90%',
      borderRight: '1px solid #000',
      background: '#000',
    },
  },

  recommendedBadge: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE_1,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(3),
    position: 'absolute',
    top: theme.spacing(-1.5),
    left: theme.spacing(),
  },

  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    padding: `${theme.spacing(1.5)}px 0`,
    borderBottom: `1px solid transparent`,
  },
  button: {
    ...TYPOGRAPHY.SMALL_BOLD,
    border: 'none',
    color: COLORS.BLUE_1,
    textDecoration: 'none',
    transition: 'all 0.3s',
    borderRadius: theme.spacing(0.5),
    padding: `${theme.spacing(0.625)}px ${theme.spacing(1.25)}px`,

    '&:hover': {
      backgroundColor: `${COLORS.BLUE_1}1a`,
    },
  },

  selectButton: {
    padding: `${theme.spacing(1.25)}px 0`,
    marginTop: theme.spacing(4),
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${COLORS.BLUE_2}80`,

    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 'auto',
      paddingBottom: 0,
      height: '100%',
    },
  },
  value: {
    ...TYPOGRAPHY.REGULAR_BOLD,
    color: COLORS.BLACK,
    fontSize: theme.spacing(1.8),
  },
  details: {
    fontSize: theme.spacing(1.8),

    '& ul': {
      paddingLeft: theme.spacing(2.5),
      margin: 0,
      marginTop: theme.spacing(0.5),
    },
  },
  label: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.BLUE_1,
    fontSize: theme.spacing(1.5),
    textTransform: 'uppercase',
    transition: 'all 0.3s',
  },

  title: {
    color: COLORS.BLUE_1,
    ...TYPOGRAPHY.TITLE_3,
    fontSize: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
  },

  footer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  telivyColor: {
    color: COLORS.BLUE_1,
  },

  disclaimer: {
    marginTop: theme.spacing(4),
  },

  disclaimerBadge: {
    border: `1px solid ${COLORS.BLUE_1}`,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },

  demoButton: {
    border: `1px solid ${COLORS.GREY_4}`,
    ...TYPOGRAPHY.REGULAR_MEDIUM,
    color: COLORS.GREY_3,
    textDecoration: 'none',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(1),
    // alignSelf: 'end',

    '&:hover': {
      border: `1px solid ${COLORS.BLUE_2}`,
      color: COLORS.BLUE_1,
    },
  },
}));

interface Props {
  recommended?: boolean;
  onChooseClick: (scanType: AssessmentType) => void;
}

const getSetupTime = (scanType: AssessmentType) => {
  switch (scanType) {
    case AssessmentType.EXTERNAL:
      return 'Under 1 Minute';

    case AssessmentType.RISK:
      return 'Under 1 Hour';

    case AssessmentType.RISK_PLUS:
      return 'Under 3 Hours';

    default:
      break;
  }
};

const getDuration = (scanType: AssessmentType) => {
  switch (scanType) {
    case AssessmentType.EXTERNAL:
      return '10 Minutes';

    case AssessmentType.RISK:
      return '24 Hours';

    case AssessmentType.RISK_PLUS:
      return '30 Days';

    default:
      break;
  }
};

const getDetails = (scanType: AssessmentType, classes: any) => {
  switch (scanType) {
    case AssessmentType.EXTERNAL:
      return (
        <ul>
          <li>Dark Web Monitor</li>
          <li>External Network Vulnerability Scan</li>
          <li>DNS Evaluation</li>
          <li>Website Vulnerability Check</li>
        </ul>
      );

    case AssessmentType.RISK:
      return (
        <ul>
          <li>
            Full <span className={classes.telivyColor}>External Assessment</span>
          </li>
          <li>Internal Vulnerability Analysis</li>
          <li>Sensitive Data Identification</li>
          <li>Browser Credential Sniffer</li>
          <li>Application Risk Monitor</li>
        </ul>
      );

    case AssessmentType.RISK_PLUS:
      return (
        <ul>
          <li>
            Full <span className={classes.telivyColor}>Risk Assessment</span>
          </li>
          <li>Application Login Monitoring</li>
          <li>Phishing Simulation Campaigns</li>
          <li>Premium Technical Support</li>
        </ul>
      );

    default:
      break;
  }
};

export const ScanTypeCards = ({ onChooseClick }: Props) => {
  const classes = useStyles();
  const canChooseRiskPlus = useRiskAssessmentPlusEnabled();
  const externalScanDisabled = useExternalScanDisabled();
  const riskAssessmentDisabled = useRiskAssessmentDisabled();

  const renderCard = useCallback(
    (scanType: AssessmentType, locked?: boolean, demoUrl?: string) => {
      return (
        <div className={classes.root}>
          {/* {recommended && <RecommendedIcon className={classes.recommendedBadge} />} */}
          <div>
            <div className={classes.title}>
              {ASSESSMENT_NAMES[scanType]}
              {demoUrl && (
                <a className={classes.demoButton} href={demoUrl}>
                  See Demo
                </a>
              )}
            </div>
            <div className={classes.item}>
              <div className={classes.value}>{getSetupTime(scanType)}</div>
              <div className={classes.label}>Setup Time</div>
            </div>

            <div className={classes.item}>
              <div className={classes.value}>{getDuration(scanType)}</div>
              <div className={classes.label}>Duration</div>
            </div>

            <div className={classes.item}>
              <div className={classes.label}>Includes:</div>
              <div className={classes.details}>{getDetails(scanType, classes)}</div>
            </div>
          </div>

          {locked ? (
            <div className={classes.disclaimer}>
              <SecurityCard.Badge variant='locked' className={classes.disclaimerBadge}>
                Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a> to upgrade account.
              </SecurityCard.Badge>
            </div>
          ) : (
            <Button
              disabled={locked}
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => onChooseClick(scanType)}
              className={classes.selectButton}
            >
              Select
            </Button>
          )}
        </div>
      );
    },
    [classes, onChooseClick],
  );

  return (
    <div className={classes.scanTypeWrapper}>
      {renderCard(AssessmentType.EXTERNAL, externalScanDisabled)}
      {renderCard(
        AssessmentType.RISK,
        riskAssessmentDisabled,
        `${insertIdToRoute(ROUTES.agent.application.ROOT, DEMO_ID)}`,
      )}
      {canChooseRiskPlus ? renderCard(AssessmentType.RISK_PLUS, !canChooseRiskPlus) : null}
    </div>
  );
};
