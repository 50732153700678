import { CarrierDTO } from 'dtos/carrier';

import RequestService from './Request';

class _CarrierService {
  getAllCarriers() {
    return RequestService.client.get<CarrierDTO[]>(`/carrier`).then((response) => response.data);
  }
}

const CarrierSevice = new _CarrierService();

export { CarrierSevice };
