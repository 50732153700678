import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AxiosError } from 'axios';
import { LinkButton } from 'components/LinkButton';
import { Money } from 'components/Money';
import { DeclinationDTO } from 'dtos/declinations';
import { useToasters } from 'hooks/useToasters';
import { useDeleteDeclination } from 'queries/useDeclinations';
import React, { useCallback } from 'react';

interface Props {
  className?: string;
  declination: DeclinationDTO;
}

export const DeleteDeclinationButton = ({ className, declination }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useDeleteDeclination({
    onSuccess: () => {
      showToaster(`Successfully deleted declination`);
    },
    onError: (error: AxiosError | unknown) => toasterErrorHandler(error, 'Failed to delete declination'),
    onSettled: () => setOpen(false),
  });

  const handleConfirmClick = useCallback(() => {
    mutate(declination.id);
  }, [mutate, declination]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <LinkButton className={className} onClick={handleClickOpen}>
        {isLoading ? 'Deleting...' : 'Delete'}
      </LinkButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you want to delete &quot;{declination.reason}&quot; for&nbsp;
            {declination.carrier.displayName} limit <Money value={declination.requestedAggregateLimit} /> with&nbsp;
            retention <Money value={declination.requestedRetention} />.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color='primary' disabled={isLoading}>
            Confirm and delete declination
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
