import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkIcon from '@material-ui/icons/Link';
import React, { useCallback, useState } from 'react';
import { insertIdToRoute, ROUTES } from 'telivy-constants';

interface Props {
  applicationId: string;
  buttonText?: string;
  className?: string;
}

export const SharePolicyButton: React.FC<Props> = ({ applicationId, buttonText, className }) => {
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const handleShareButtonClick = useCallback(() => {
    const link = `${window.location.origin}${insertIdToRoute(ROUTES.application.POLICY, applicationId)}`;
    navigator.clipboard.writeText(link);
    setLinkModalOpen(true);
  }, [applicationId]);

  return (
    <>
      <Button className={className} startIcon={<LinkIcon />} onClick={handleShareButtonClick}>
        {buttonText || 'Share Purchase Link'}
      </Button>
      <Dialog fullWidth={true} open={linkModalOpen} onClose={() => setLinkModalOpen(false)}>
        <DialogTitle>Link is copied to clipboard</DialogTitle>
        <DialogContent>
          <DialogContentText>Go ahead and share link with your client!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLinkModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
