import { LayoutProps, VerticalLayout } from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

const useStyles = makeStyles(() => ({
  elementsWrapper: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',

      '& .MuiCardHeader-root': {
        padding: 0,
      },
    },

    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
}));

export const _CustomVerticalLayout = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
  direction = 'column',
}: LayoutProps) => {
  const verticalLayout = uischema as VerticalLayout;
  const classes = useStyles();

  if (isEmpty(verticalLayout.elements) || !visible) {
    return null;
  }

  return (
    <Grid container direction={direction} spacing={direction === 'row' ? 2 : 0}>
      {verticalLayout.elements.map((child, index) => (
        <Grid item key={`${path}-${index}`} xs className={classes.elementsWrapper}>
          <ResolvedJsonFormsDispatch
            uischema={child}
            schema={schema}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const CustomVerticalLayout = withJsonFormsLayoutProps(_CustomVerticalLayout);
