import { Step } from 'components/Step';
import { ApplicationWithAgentDTO, UiSectionSchema } from 'dtos/application';
import intersection from 'lodash/intersection';
import React from 'react';

interface Props {
  application: ApplicationWithAgentDTO | undefined;
  isApplicationLocked: boolean;
  currentPageIdx: number | null;
  goToPage: (metadata: UiSectionSchema) => void;
  errorPaths: string[];
  showAllErrors: boolean;
  closeMenu?: () => void;
  seenSectionsIds?: number[];
}

export const ApplicationSidebar = ({
  application,
  isApplicationLocked,
  currentPageIdx,
  errorPaths,
  showAllErrors,
  goToPage,
  closeMenu,
  seenSectionsIds,
}: Props) => {
  return (
    <div>
      {application &&
        application.applicationVersion.uiSchema.sections.map((section, index) => {
          const parsedPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
          const errors = intersection(section.uiSchema.propertiesPaths, parsedPaths);
          const hasErrors = errors.length !== 0;

          return (
            <Step
              key={index}
              idx={index}
              currIndex={currentPageIdx || 0}
              readonly={isApplicationLocked}
              title={section.label}
              isActive={index === currentPageIdx}
              progress={0}
              totalQuestions={2}
              isError={hasErrors}
              hideErrors={isApplicationLocked ? false : !showAllErrors}
              onClick={() => {
                goToPage(section);
                closeMenu && closeMenu();
              }}
              isClient={true}
              hasBeenSeen={seenSectionsIds?.includes(index)}
            />
          );
        })}
    </div>
  );
};
