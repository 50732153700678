import { PolicyDTO } from 'dtos/policy';
import { ProposalWithApplicationDTO } from 'dtos/proposal';

import RequestService from './Request';

class _ProposalService {
  getProposalById(id: string) {
    return RequestService.client.get<ProposalWithApplicationDTO>(`/proposal/${id}`).then((response) => response.data);
  }

  removeProposalById(id: string) {
    return RequestService.client.delete<void>(`/proposal/${id}`).then((response) => response.data);
  }

  chooseProposalQuote(id: string, quoteId: string) {
    return RequestService.client.post<PolicyDTO>(`/proposal/${id}/bind/${quoteId}`).then((response) => response.data);
  }
}

const ProposalService = new _ProposalService();

export { ProposalService };
