import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import { ReactComponent as CheckV2Icon } from 'images/check-v2.svg';
import { ReactComponent as ExplanationMarkIcon } from 'images/explaination-mark.svg';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.WHITE,
    verticalAlign: 'middle',
    lineHeight: `${theme.spacing(3)}px`,
    backgroundColor: ({ isActive, isError, doNotGreyOut, checkV2, hideErrors, readonly, hasBeenSeen }: IconProps) => {
      if (isActive || doNotGreyOut) {
        if (isError) {
          if (!hideErrors) {
            return COLORS.RED_1;
          }

          return COLORS.BLUE_1;
        }

        if (checkV2) {
          return undefined;
        }

        if (readonly) {
          return COLORS.GREEN_1;
        }

        return COLORS.BLUE_1;
      }

      // not active
      if (isError) {
        if (!hideErrors) {
          return COLORS.RED_2;
        }

        return COLORS.GREY_3;
      }

      if (hasBeenSeen || readonly) {
        return COLORS.GREEN_2;
      }

      return COLORS.GREY_3;
    },
  },
  icon: {
    '& path': {
      fill: ({ checkV2, isActive, doNotGreyOut }: IconProps) =>
        checkV2 && (isActive || doNotGreyOut) ? undefined : COLORS.WHITE,
    },
  },
}));

interface IconProps {
  idx: number;
  checkV2?: boolean;
  doNotGreyOut?: boolean;
  isError?: boolean;
  currIndex: number;
  readonly?: boolean;
  hideErrors?: boolean;
  isClient?: boolean;
  isActive?: boolean;
  className?: string;
  hasBeenSeen?: boolean;
}

export const StepIcon = (props: IconProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <StepIconContent className={classes.icon} {...props} />
    </div>
  );
};

const StepIconContent = ({
  idx,
  currIndex,
  isError,
  checkV2,
  // hideErrors,
  hasBeenSeen,
  readonly,
  // isClient,
  className,
}: IconProps) => {
  const CheckIconToUse = useMemo(() => (checkV2 ? CheckV2Icon : CheckIcon), [checkV2]);

  if (readonly) {
    if (isError) {
      // if (!hideErrors) {
      return <ExplanationMarkIcon className={className} />;
      // }
      // return <>{idx + 1}</>;
    }

    return <CheckIconToUse className={className} />;
  }

  if (isError) {
    // if (!hideErrors) {
    return <ExplanationMarkIcon className={className} />;
    // }
    // return <>{idx + 1}</>;
  }

  if (hasBeenSeen && currIndex !== idx) {
    return <CheckIconToUse className={className} />;
  }

  return <>{idx + 1}</>;
};
