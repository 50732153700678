import { withJsonFormsControlProps } from '@jsonforms/react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomControlProps } from 'components/JsonForms/customProps';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import isString from 'lodash/isString';
import React from 'react';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format='####'
      mask={['Y', 'Y', 'Y', 'Y']}
      allowNegative={false}
    />
  );
}

export const _YearInput = (props: CustomControlProps) => {
  const classes = useStyles();
  const disabled = !props.enabled;

  if (!props.visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <TextField
        error={!!props.errors}
        disabled={disabled}
        required={props.required}
        label={
          <JsonFormsLabelDescription
            label={isString(props.label) ? props.label : props.label.default}
            description={props.schema.description}
          />
        }
        value={props.data || undefined}
        onChange={(e) => {
          props.handleChange(props.path, parseInt(e.target.value, 10) ? parseInt(e.target.value, 10) : undefined);
        }}
        className={classes.input}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        placeholder={props.uischema.placeholder}
        InputLabelProps={{ shrink: false }}
      />

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const YearInput = withJsonFormsControlProps(_YearInput);
