import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React, { forwardRef } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StylesProps {
  white?: boolean;
  color?: ValueOf<typeof COLORS>;
  typography?: ValueOf<typeof TYPOGRAPHY>;
  iconPosition?: 'left' | 'right' | 'top' | 'bottom';
  noUnderline?: boolean;
  bordered?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => {
  return {
    root: (props) => {
      return {
        ...(props.typography || TYPOGRAPHY.EXTRA_SMALL_REGULAR),
        color: props.color || COLORS.BLUE_1,
        textDecoration: props.noUnderline ? 'none' : 'underline',
        background: 'none',
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        border: 'none',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        ...(props.iconPosition === 'left' && { flexDirection: 'row' }),
        ...(props.iconPosition === 'right' && { flexDirection: 'row-reverse' }),
        ...(props.iconPosition === 'top' && { flexDirection: 'column' }),
        ...(props.iconPosition === 'bottom' && { flexDirection: 'column-reverse' }),
        ...(props.bordered && {
          border: `1px solid ${COLORS.GREY_5}`,
          borderRadius: theme.spacing(1),
        }),
      };
    },
    iconContainer: {
      display: 'flex',
      marginRight: theme.spacing(0.5),
    },
    content: {
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  };
});

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
  StylesProps & {
    icon?: React.ReactNode;
    contentClassName?: string;
  };

export const LinkButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      contentClassName,
      children,
      color,
      icon,
      iconPosition = 'left',
      typography,
      noUnderline,
      bordered,
      ...restProps
    },
    ref,
  ) => {
    const classes = useStyles({ color, typography, iconPosition, noUnderline, bordered });

    return (
      <button className={cx(classes.root, className)} ref={ref} {...restProps}>
        {icon ? <div className={classes.iconContainer}>{icon}</div> : null}
        <div className={cx(classes.content, contentClassName)}>{children}</div>
      </button>
    );
  },
);
