import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';
import { LeadMagnetView } from 'views/agent/views/account/views/lead-magnet';

import { AccountsView } from './views/accounts';
import { ManageView } from './views/manage';
import { UsersView } from './views/users';

export interface Company {
  name: string;
  numberOfFindings: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
  },
  header: {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
    justifyContent: 'start',

    textDecoration: 'none',
    color: COLORS.BLACK,
    transition: 'all 0.3s',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    // boxShadow: '0px 1px 0.1px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: COLORS.GREY_5,
    },
  },
  menu: {
    width: 270,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  item: {
    // fontWeight: 'bold',
    textDecoration: 'none',
    color: COLORS.BLACK,
    transition: 'all 0.3s',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    // boxShadow: '0px 1px 0.1px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: COLORS.GREY_5,
    },
  },
  activeItem: {
    color: COLORS.BLUE_1,
    backgroundColor: COLORS.GREY_6,
  },
  content: {
    flexGrow: 1,
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AdminView = ({ agent }: Props) => {
  const classes = useStyles();

  const isGlobalAdmin = agent.role === 'agency_admin' && agent.agency?.isMasterAgency;

  if (!isGlobalAdmin) {
    return (
      <SecurityCard.Badge variant='locked'>
        You do not have permission to view this page. Contact{' '}
        <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
      </SecurityCard.Badge>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Admin - {agent?.agency?.name}</title>
      </Helmet>

      <div className={classes.menu}>
        <h3 className={classes.header}>Global Admin</h3>
        <div>
          <div className={classes.list}>
            {[
              {
                label: 'Home',
                href: ROUTES.agent.admin.MANAGE,
              },
              {
                label: 'Accounts',
                href: ROUTES.agent.admin.ACCOUNTS,
              },
              {
                label: 'Users',
                href: ROUTES.agent.admin.USERS,
              },
              // {
              //   label: 'Lead Magnet',
              //   href: ROUTES.agent.admin.LEAD_MAGNET,
              // },
            ].map((el, idx) => (
              <Link
                to={el.href}
                key={idx}
                className={cx(classes.item, el.href === location.pathname && classes.activeItem)}
              >
                {el.label}
              </Link>
            ))}
            {/*<Button variant='text' color='primary' className={classes.button}>
              Log Out
            </Button>*/}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <Routes>
          <Route index element={() => <Navigate replace to={ROUTES.agent.admin.MANAGE} />} />
          <Route path={ROUTES.agent.admin.MANAGE} element={<ManageView agent={agent} />} />
          <Route path={ROUTES.agent.admin.USERS} element={<UsersView agent={agent} />} />
          <Route path={ROUTES.agent.admin.ACCOUNTS} element={<AccountsView agent={agent} />} />
          <Route path={ROUTES.agent.admin.LEAD_MAGNET} element={<LeadMagnetView agent={agent} />} />
        </Routes>
      </div>
    </div>
  );
};
