import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Badge } from 'components/Badge';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { Placeholder } from 'components/Placeholder';
import { Column, Table } from 'components/Table';
import { VerifyEmail } from 'components/VerifyEmail';
import { AgentDTO } from 'dtos/agent';
import { Finding, FindingSeverity, SecurityGrade } from 'dtos/application';
import { countBy, sortBy, startCase } from 'lodash';
import { useAgentSummaryData } from 'queries/useAgent';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { getGradeBadgeVariant } from 'templates/SecurityAssessments/components/ExternalScanItem';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';
import { getSeverityBadgeVariant } from 'templates/SecurityAssessments/helpers';

import { CompanyFindings } from './CompanyFindings';

export interface Company {
  name: string;
  numberOfFindings: number;
}

interface SummaryFinding extends Partial<Finding> {
  numberOfDomains: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },
  header: {
    padding: `0 ${theme.spacing()}`,
  },
  headerText: {
    ...TYPOGRAPHY.LARGE,
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(1)}px 0`,
  },

  container: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  grid2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  gap: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },

  summaryWrapper: {
    ...TYPOGRAPHY.REGULAR_MEDIUM,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
  },
  summaryBadge: {
    ...TYPOGRAPHY.TITLE_3,
    padding: `0 ${theme.spacing(1.5)}`,
    boxSizing: 'border-box',
    minWidth: theme.spacing(6),
    height: theme.spacing(6),
    minHeight: theme.spacing(6),
  },

  ratingContainer: {
    justifyContent: 'space-around',
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  ratingBadge: {
    width: '100%',
    padding: 0,
    borderRadius: theme.spacing(1.5),
  },
  ratingBadgeContent: {
    padding: `0 ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    ...TYPOGRAPHY.TITLE,
  },
  ratingGrade: {},
  ratingText: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    lineHeight: 1,
    color: COLORS.GREY_1,
    '& span': {
      fontWeight: 900,
    },
  },

  companyFindingsWrapper: {
    width: 'calc(100% - 1em)',
    overflow: 'auto',
    maxHeight: theme.spacing(23),
    paddingRight: theme.spacing(1.25),

    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacing(30),
    },
  },

  table: {
    '& tbody, & thead': {
      '& tr': {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
      },
    },
    '& tbody': {
      display: 'block',
      overflow: 'auto',
      height: theme.spacing(30),
      maxHeight: theme.spacing(30),
    },
  },
}));

const getAffectedColor = (value: number): ValueOf<typeof COLORS> => {
  if (value >= 67) {
    return COLORS.RED_1;
  }
  if (value >= 34) {
    return COLORS.YELLOW_1;
  }
  if (value >= 0) {
    return COLORS.GREEN_1;
  }

  return COLORS.GREY_3;
};

interface Props {
  agent: AgentDTO;
}

export const AgentSummaryView = ({ agent }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const options = {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  };

  const {
    data: summaryData,
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
  } = useAgentSummaryData(agent.id, options);

  // TODO: where should onRowClick take you?
  // const onFindingClick = (finding: SummaryFinding) =>
  //   navigate(insertIdToRoute(ROUTES.agent.application.security.FINDINGS, 'applicationId').replace('/:slug?', ''));
  // TODO company.id === application.id
  const onCompanyClick = (company: Company) => navigate(`${ROUTES.agent.DASHBOARD}?q=${company.name}`);

  const mostCommonFindingsColumns: Array<Column<SummaryFinding, keyof SummaryFinding>> = useMemo(() => {
    if (!summaryData) return [];

    return [
      {
        title: 'Finding',
        render: (finding) => finding.name,
      },
      {
        title: '% of Domains Affected',
        render: (finding) => {
          const value = Math.floor(((finding.numberOfDomains | 0) / summaryData.summary.domains) * 100);
          return <span style={{ color: getAffectedColor(value), width: '100%', textAlign: 'right' }}>{value}%</span>;
        },
        renderColumn: (column) => <span style={{ width: '100%', textAlign: 'right' }}>{column.title}</span>,
      },
    ];
  }, [summaryData]);

  const overallScoresByDomain = summaryData?.overallScoresByDomain || {};
  const numberOfDomainsByFindings = summaryData?.domainsByFindings.length || 0;
  const numberOfDomainsByGrade = countBy(summaryData?.domainsByFindings, (f) =>
    parseScoreToGrade(overallScoresByDomain[f.name]),
  );
  const getRatingDistribution = (grade: SecurityGrade) =>
    numberOfDomainsByFindings > 0
      ? `${Math.round(((numberOfDomainsByGrade[grade] || 0) / numberOfDomainsByFindings) * 100)}%`
      : '0%';

  if (isLoadingSummary) {
    return <LoadingContainer />;
  }

  if (isErrorSummary) {
    return <NotFoundContainer />;
  }

  if (agent && !agent.isEmailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{isSecurityAgency(agent) ? 'Summary' : 'Security Summary'}</title>
      </Helmet>

      <header className={classes.header}>
        <h1 className={classes.headerText}>Summary</h1>
      </header>

      <div className={classes.container}>
        <SecurityCard.Container>
          <SecurityCard.Header title='Account Summary' />
          <SecurityCard.Content gap={theme.spacing(2.5)}>
            <Placeholder isActive={false}>
              <div className={classes.summaryWrapper}>
                <Badge variant='black' className={classes.summaryBadge}>
                  {summaryData?.summary.companies || 0}
                </Badge>
                Total Companies
              </div>
            </Placeholder>
            <Placeholder isActive={false}>
              <div className={classes.summaryWrapper}>
                <Badge variant='blue' className={classes.summaryBadge}>
                  {summaryData?.summary.domains || 0}
                </Badge>
                Total Domains
              </div>
            </Placeholder>
            <Placeholder isActive={false}>
              <div className={classes.summaryWrapper}>
                <Badge variant='blue' className={classes.summaryBadge}>
                  {summaryData?.summary.findings || 0}
                </Badge>
                Total Findings
              </div>
            </Placeholder>
          </SecurityCard.Content>
        </SecurityCard.Container>

        <div className={classes.grid2}>
          <SecurityCard.Container>
            <SecurityCard.Header title='Rating Distribution' />
            <SecurityCard.Content gap={theme.spacing(2.5)} className={classes.ratingContainer}>
              <Placeholder isActive={false} className={classes.ratingWrapper}>
                <>
                  <Badge variant={getGradeBadgeVariant(SecurityGrade.A)} className={classes.ratingBadge}>
                    <div className={classes.ratingBadgeContent}>
                      {SecurityGrade.A}
                      <div className={classes.ratingText}>
                        <span>{getRatingDistribution(SecurityGrade.A)}</span>
                        <br />
                        companies
                      </div>
                    </div>
                  </Badge>
                  <Badge variant={getGradeBadgeVariant(SecurityGrade.B)} className={classes.ratingBadge}>
                    <div className={classes.ratingBadgeContent}>
                      {SecurityGrade.B}
                      <div className={classes.ratingText}>
                        <span>{getRatingDistribution(SecurityGrade.B)}</span>
                        <br />
                        companies
                      </div>
                    </div>
                  </Badge>
                  <Badge variant={getGradeBadgeVariant(SecurityGrade.C)} className={classes.ratingBadge}>
                    <div className={classes.ratingBadgeContent}>
                      {SecurityGrade.C}
                      <div className={classes.ratingText}>
                        <span>{getRatingDistribution(SecurityGrade.C)}</span>
                        <br />
                        companies
                      </div>
                    </div>
                  </Badge>
                  <Badge variant={getGradeBadgeVariant(SecurityGrade.D)} className={classes.ratingBadge}>
                    <div className={classes.ratingBadgeContent}>
                      {SecurityGrade.D}
                      <div className={classes.ratingText}>
                        <span>{getRatingDistribution(SecurityGrade.D)}</span>
                        <br />
                        companies
                      </div>
                    </div>
                  </Badge>
                </>
              </Placeholder>
            </SecurityCard.Content>
          </SecurityCard.Container>

          <SecurityCard.Container>
            <SecurityCard.Header title='Companies by Findings' />
            <SecurityCard.Content gap={theme.spacing(2.5)}>
              <Placeholder isActive={false} className={classes.gap}>
                <CompanyFindings
                  companies={sortBy(summaryData?.domainsByFindings, 'numberOfFindings').reverse()}
                  overallScoresByDomain={overallScoresByDomain}
                  onClick={onCompanyClick}
                  className={classes.companyFindingsWrapper}
                />
              </Placeholder>
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>

        <SecurityCard.Container>
          <SecurityCard.Header title='Most Common Issues' />
          <SecurityCard.Content gap={theme.spacing(2.5)}>
            <Table<SummaryFinding>
              hideHeadersWhenEmpty
              columns={mostCommonFindingsColumns}
              data={sortBy(summaryData?.findingsByDomains, 'numberOfDomains').reverse()}
              rowKey={(el) => el.name || ''}
              className={classes.table}
            />
          </SecurityCard.Content>
        </SecurityCard.Container>

        <SecurityCard.Container>
          <SecurityCard.Header title='Most Critical Issues' />
          <SecurityCard.Content gap={theme.spacing(2.5)}>
            <Table<SummaryFinding>
              hideHeadersWhenEmpty
              columns={mostCriticalFindingsColumns}
              data={sortBySeverityAndNumber(summaryData?.findingsByDomains || [])}
              rowKey={(el) => el.name || ''}
              className={classes.table}
            />
          </SecurityCard.Content>
        </SecurityCard.Container>
      </div>
    </div>
  );
};

const mostCriticalFindingsColumns: Array<Column<SummaryFinding, keyof SummaryFinding>> = [
  {
    title: 'Severity',
    render: (finding) => (
      <Badge bordered variant={getSeverityBadgeVariant(finding.severity)} style={{ width: 80, minWidth: 80 }}>
        {startCase(finding.severity)}
      </Badge>
    ),
  },
  {
    title: 'Finding',
    render: (finding) => finding.name,
  },
  {
    title: 'Domains Affected',
    render: (finding) => <span style={{ width: '100%', textAlign: 'right' }}>{finding.numberOfDomains}</span>,
    renderColumn: (column) => <span style={{ width: '100%', textAlign: 'right' }}>{column.title}</span>,
  },
];

const sortBySeverityAndNumber = (findings: SummaryFinding[]) => {
  const severityArray = [FindingSeverity.High, FindingSeverity.Medium, FindingSeverity.Low];

  return findings.sort((a, b) => {
    if (a.severity === b.severity) {
      return b.numberOfDomains - a.numberOfDomains;
    } else {
      const firstPrio = a.severity ? severityArray.indexOf(a.severity) : severityArray.length;
      const secPrio = b.severity ? severityArray.indexOf(b.severity) : severityArray.length;
      return firstPrio - secPrio;
    }
  });
};
