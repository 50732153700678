import { makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Badge } from 'components/Badge';
import { EmptyState } from 'components/EmptyState';
import { Table } from 'components/Table';
import { Finding, FindingSeverity } from 'dtos/application';
import startCase from 'lodash/startCase';
import React from 'react';
import { COLORS } from 'telivy-theme';

import { getSeverityBadgeVariant } from '../../helpers';
import { ProgressBar, ProgressBarVariant } from './ProgressBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 300,
  },
  badge: {
    width: theme.spacing(10),
    minWidth: theme.spacing(10),
    boxSizing: 'border-box',
  },

  emptyState: {
    padding: `${theme.spacing(4)}px 0`,
    marginTop: 'auto',
    marginBottom: 'auto',
  },

  emptyStateIcon: {
    color: COLORS.GREEN_1,
    height: 40,
    width: 40,
  },
}));

enum ActionItemAffection {
  Insurability = 'Insurability',
  Security = 'Security',
}

export interface ActionItem extends Pick<Finding, 'id' | 'name' | 'severity' | 'eligibility'> {
  // insuranceElegibility: number; // 0 - 100 (0 - not eligible, 100 - fully eligible) // if we don't want to use finding severity
  affects?: Array<ActionItemAffection>;
}

const getSeverityValue = (finding: ActionItem): number => {
  return Math.ceil(Math.max(5, finding.eligibility || 0));
};

const getSeverity = (finding: ActionItem): FindingSeverity => {
  const severityValue = getSeverityValue(finding);
  if (severityValue >= 75) {
    return FindingSeverity.High;
  } else if (severityValue >= 50) {
    return FindingSeverity.Medium;
  } else {
    return FindingSeverity.Low;
  }
};

const getSeverityProgressBarVariant = (finding: ActionItem): ProgressBarVariant => {
  const severityValue = getSeverityValue(finding);
  if (severityValue >= 75) {
    return 'red';
  } else if (severityValue >= 50) {
    return 'yellow';
  } else if (severityValue >= 25) {
    return 'blue';
  } else {
    return 'grey';
  }
};

const columns = [
  {
    title: 'Finding',
    width: '45%',
    render: (rowData: ActionItem) => <span>{rowData?.name}</span>,
  },
  {
    title: 'Insurance Eligibility',
    width: '20%',
    render: (rowData: ActionItem) => {
      return (
        <ProgressBar
          label={`${getSeverityValue(rowData)}%`}
          progress={getSeverityValue(rowData)}
          customVariant={getSeverityProgressBarVariant(rowData)}
        />
      );
    },
  },
  {
    title: 'Severity',
    width: '35%',
    render: (rowData: ActionItem) => (
      <Badge bordered variant={getSeverityBadgeVariant(getSeverity(rowData))} style={{ minWidth: 80, width: 80 }}>
        {startCase(getSeverity(rowData))}
      </Badge>
    ),
  },
  // {
  //   title: 'Affects',
  //   width: '35%',
  //   render: (rowData: ActionItem) => (
  //     <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
  //       {rowData?.affects?.map((affect) => (
  //         <Badge variant={'blue'} key={affect}>
  //           {startCase(affect)}
  //         </Badge>
  //       ))}
  //     </div>
  //   ),
  // },
];

interface Props {
  items: ActionItem[];
  handleGoToFinding: (findingId?: string) => void;
}

export const ActionItems = ({ items }: Props) => {
  const classes = useStyles();

  const SEVERITY_ORDER = [FindingSeverity.High, FindingSeverity.Medium, FindingSeverity.Low];

  const tableData = (items || []).sort((a, b) => {
    const sva = getSeverityValue(a),
      svb = getSeverityValue(b);
    return svb !== sva ? svb - sva : SEVERITY_ORDER.indexOf(a?.severity) - SEVERITY_ORDER.indexOf(b?.severity);
  });

  return (
    <div className={classes.root}>
      <Table<ActionItem>
        hideHeadersWhenEmpty
        columns={columns}
        // loading={isLoading}
        data={tableData}
        // onRowClick={(item) => handleGoToFinding(item.id)}
        rowKey={(el) => el.id}
        stickyHeader
        stickyHeaderBackgroundColor={COLORS.BLUE_4}
        emptyState={
          <EmptyState
            icon={<CheckCircleOutlineIcon className={classes.emptyStateIcon} />}
            title='Great job!'
            text='No action items at this time.'
            className={classes.emptyState}
          />
        }
      />
    </div>
  );
};
