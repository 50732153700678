import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(14),
    borderRadius: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  header: {
    ...TYPOGRAPHY.LARGE,
  },
  text: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    margin: 0,
    marginTop: theme.spacing(2),
    maxWidth: 300,
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  title: string;
  text?: string | React.ReactNode;
  actions?: JSX.Element;
  icon?: JSX.Element;
  className?: string;
}

export const EmptyState = ({ text, title, actions, icon, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.container}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <div className={classes.header}>{title}</div>
        {text && <p className={classes.text}>{text}</p>}
        {actions && <div className={classes.actions}>{actions}</div>}
      </div>
    </div>
  );
};
