import { AxiosError } from 'axios';
import { CompaniesQuery, CompaniesResponse, CompanyDTO, EditCompanyDTO } from 'dtos/company';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { UseMutationOptions } from 'react-query/types/react/types';
import { CompaniesService } from 'services/Company';

export const useCompanies = (query: CompaniesQuery) => {
  return useQuery<CompaniesResponse>(['companies', query], async () => {
    return await CompaniesService.getAllCompanies(query);
  });
};

export const useCompany = (id: string, options?: UseQueryOptions<CompanyDTO, AxiosError>) =>
  useQuery<CompanyDTO, AxiosError>(['company-data', id], () => CompaniesService.getCompany(id), options);

export const useEditCompany = (id: string, options?: UseMutationOptions<CompanyDTO, AxiosError, EditCompanyDTO>) => {
  const queryClient = useQueryClient();

  return useMutation('edit-company', (data: EditCompanyDTO) => CompaniesService.updateCompany(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['companies']);
      await queryClient.refetchQueries(['company-data', id]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
