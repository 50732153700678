import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { LinkButton } from 'components/LinkButton';
import { ReactComponent as BlueInfoIcon } from 'images/blue-info-icon.svg';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  gap?: number;
  padding?: number | string;
  centered?: boolean;
  maxHeight?: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(12),
    maxHeight: ({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'auto'),
    overflow: 'auto',
    flex: 1,
    border: `2px solid ${COLORS.GREY_5}`,
    borderRadius: theme.spacing(1),
    background: COLORS.BLUE_4,
  },

  content: ({ gap, padding, centered }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: gap ? gap : theme.spacing(4),
    padding: padding ? padding : theme.spacing(3),
    positon: 'relative',
    flex: '1',

    ...(centered && {
      alignItems: 'center',
    }),

    '& p': {
      margin: 0,
    },

    '& a': {
      ...TYPOGRAPHY.SMALL_REGULAR,
      color: COLORS.GREY_2,
      textDecoration: 'underline',
    },
  }),

  comingSoon: {
    opacity: 0.4,
  },

  isNotSubmittedWrapper: {
    userSelect: 'none',
    pointerEvents: 'none',
    filter: 'blur(12px)',
  },
  isNotSubmitted: {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
}));

export interface Props extends StyleProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  // comingSoon?: boolean;
  isNotSubmitted?: boolean;
  handleGoToForm?: () => void;
  // financial risk calculator props
  showFinancialCalculator?: boolean;
  isFinancialRiskValid?: boolean;
  setIsFinancialRiskModalOpen?: (isOpen: boolean) => void;
}

export const SecurityCardContent = ({
  children,
  gap,
  padding,
  centered,
  maxHeight,
  className,
  // comingSoon,
  isNotSubmitted,
  handleGoToForm,
}: Props) => {
  const classes = useStyles({ gap, padding, centered, maxHeight });

  return (
    <div className={classes.root}>
      <div
        className={cx(
          classes.content,
          // comingSoon && classes.comingSoon,
          isNotSubmitted && classes.isNotSubmittedWrapper,
          className,
        )}
      >
        {children || 'No Data'}
      </div>

      {isNotSubmitted && (
        <div className={classes.isNotSubmitted}>
          <BlueInfoIcon />
          <LinkButton
            style={{
              ...TYPOGRAPHY.REGULAR_BOLD,
              margin: '0 auto',
            }}
            onClick={() => handleGoToForm && handleGoToForm()}
            role='button'
            tabIndex={0}
          >
            Fill the questionnaire
          </LinkButton>
          <div style={{ ...TYPOGRAPHY.REGULAR_REGULAR }}>to view this section</div>
        </div>
      )}
    </div>
  );
};
