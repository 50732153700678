import { CreateDocumentDTO, DocumentDTO } from 'dtos/_common';
import { ApplicationDTO } from 'dtos/application';
import { CarrierDTO } from 'dtos/carrier';

export enum QuoteStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  BOUND = 'bound',
}

export enum CreationType {
  MANUAL = 'manual',
  API = 'api',
}

export enum CarrierProduct {
  ADMITTED = 'admitted',
  SURPLUS = 'surplus',
}

export enum QuoteDocumentType {
  QUOTE_LETTER = 'quote_letter',
  SECURITY_REPORT = 'security_report',
  PREFILLED_APP = 'prefilled_app',
  OTHER = 'other',
}

export type QuoteDocumentDTO = DocumentDTO<QuoteDocumentType>;
export type CreateQuoteDocumentDTO = CreateDocumentDTO<QuoteDocumentType>;

export interface QuoteLetterCheckResultDTO {
  carrierId: string;
  quoteNumber?: string;
  product?: CarrierProduct;
  limit?: number;
  retention?: number;
  premium?: number;
  annualPremium?: number;
  effectiveDate?: string;
  expirationDate?: string;
  sublimits?: CyberQuoteSublimits;
}

export enum PendingStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
}

export interface QuoteInReviewDTO {
  id: string;
  serializedApplicationInput: Record<string, unknown>;
  requestedEffectiveDate: string;
  requestedAggregateLimit: number;
  requestedRetention: number;
  pendingStatus: PendingStatus;
  createdAt: Date;
  application: ApplicationDTO;
  carrier: CarrierDTO;
}

export interface QuoteDTO {
  id: string;
  quoteNumber: string;
  quoteStatus: QuoteStatus;
  premium: number;
  effectiveDate: string;
  carrierProduct: CarrierProduct;
  expirationDate: string;
  aggregateLimit: number;
  annualPolicyCost: number;
  surplusLinesCost?: string | null;
  retention: number;
  rawQuote: Record<string, unknown>;
  serializedApplicationInput: Record<string, unknown>;
  creationType: CreationType;
  createdAt: Date;
  updatedAt: Date;
  carrier: CarrierDTO;
  documents: QuoteDocumentDTO[];
  applicationId: string;
  sublimits?: CyberQuoteSublimits;
  recommended?: boolean; // TODO: check after implementation on backend
  score: number;
}

export interface CyberQuoteSublimits {
  // Third Party Liability for Network Breach
  networkAndInformationSecurityLiability?: number;
  regulatoryDefenseAndPenalties?: number;
  multimediaContentLiability?: number;
  pciFinesAndAssessments?: number;

  // First Party Sublimits
  breachResponseCostsAndServices?: number;
  forensicAndLegalExpenses?: number;
  crisisManagementAndPublicRelations?: number;
  reputationalHarmLoss?: number;
  creditIdentityMonitoringServices?: number;
  cyberExtortionExpensesAndFees?: number;
  ransomPayments?: number;
  businessInterruptionContingent3rdPartyComputerSystems?: number;
  businessInterruptionDirectSystemsBreach?: number;
  businessInterruptionDirectSystemFailure?: number;
  digitalAssetRestoration?: number;
  fundsTransferFraudSystemsBreach?: number;
  fundsTransferFraudSocialEngineering?: number;
  reverseSocialEngineeringInvoiceManipulation?: number;
  hardwareReplacementCostsBricking?: number;
  computerSystemsInstallationServicesCosts?: number;
  digitalServicesFraudCryptojacking?: number;
  utilitiesExpenseDueToCryptojacking?: number;
}

export interface DocumentSublimits {
  // Third Party Liability for Network Breach
  networkAndInformationSecurityLiability: string;
  regulatoryDefenseAndPenalties: string;
  multimediaContentLiability: string;
  pciFinesAndAssessments: string;

  // First Party Sublimits
  breachResponseCostsAndServices: string;
  forensicAndLegalExpenses: string;
  crisisManagementAndPublicRelations: string;
  reputationalHarmLoss: string;
  creditIdentityMonitoringServices: string;
  cyberExtortionExpensesAndFees: string;
  ransomPayments: string;
  businessInterruptionContingent3rdPartyComputerSystems: string;
  businessInterruptionDirectSystemsBreach: string;
  businessInterruptionDirectSystemFailure: string;
  digitalAssetRestoration: string;
  fundsTransferFraudSystemsBreach: string;
  fundsTransferFraudSocialEngineering: string;
  reverseSocialEngineeringInvoiceManipulation: string;
  hardwareReplacementCostsBricking: string;
  computerSystemsInstallationServicesCosts: string;
  digitalServicesFraudCryptojacking: string;
  utilitiesExpenseDueToCryptojacking: string;
}

export interface TermsDTO {
  limit: number;
  retention: number;
}

export interface ScheduleApiQuotingDTO {
  carrierId?: string;
  terms?: TermsDTO;
}

export interface CreateManualQuoteDTO {
  quoteNumber: string;
  premium: number;
  effectiveDate: string;
  expirationDate: string;
  aggregateLimit: number;
  annualPolicyCost: number;
  surplusLinesCost?: string | null;
  retention: number;
  carrierProduct: CarrierProduct;
  carrierId: string;
  quoteStatus: QuoteStatus;
}

export interface UpdateManualQuoteDTO {
  quoteStatus?: QuoteStatus;
  quoteNumber?: string;
  premium?: number;
  effectiveDate?: string;
  annualPolicyCost?: number;
  surplusLinesCost?: string | null;
  expirationDate?: string | null;
  aggregateLimit?: number;
  retention?: number;
  carrierProduct?: CarrierProduct | null;
  carrierId?: string;
}
