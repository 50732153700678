import { ReactComponent as SaluswallLogo } from 'images/saluswall-logo.svg';
import { ReactComponent as TelivyLogo } from 'images/telivy-logo.svg';
import React from 'react';
import { isUrlSaluswall } from 'telivy-selectors';

interface Props {
  className?: string;
}

export const CompanyLogo = ({ className }: Props) => {
  if (isUrlSaluswall) {
    return <SaluswallLogo className={className} />;
  }

  return <TelivyLogo className={className} />;
};
