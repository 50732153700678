import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import cx from 'classnames';
import { ClientStatus } from 'dtos/company';
import startCase from 'lodash/startCase';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  clientStatus: ClientStatus | null;
  fullWidth?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
    width: (props) => (props.fullWidth ? '100%' : 'fit-content'),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: `2px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2)}px`,
    border: `1px solid ${COLORS.WHITE}`,
    color: (props) => {
      switch (props.clientStatus) {
        case ClientStatus.CLIENT:
          return COLORS.GREEN_1;
        case ClientStatus.SUSPECT:
          return COLORS.WHITE;
        case ClientStatus.LEAD:
          return COLORS.YELLOW_1;
        default:
          return COLORS.GREY_1;
      }
    },
    background: (props) => {
      switch (props.clientStatus) {
        case ClientStatus.CLIENT:
          return COLORS.GREEN_3;
        case ClientStatus.SUSPECT:
          return COLORS.BLUE_1;
        case ClientStatus.LEAD:
          return 'rgba(252, 245, 218, 0.8)';
        default:
          return COLORS.GREY_5;
      }
    },
    borderColor: (props) => {
      switch (props.clientStatus) {
        case ClientStatus.CLIENT:
          return COLORS.GREEN_2;
        case ClientStatus.SUSPECT:
          return COLORS.BLUE_2;
        case ClientStatus.LEAD:
          return COLORS.YELLOW_2;
        default:
          return COLORS.GREY_5;
      }
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}));

interface Props extends StyleProps {
  className?: string;
  onClick?: () => void;
  buttonRef?: React.RefObject<HTMLButtonElement> | null;
}

function getUserStatus(clientStatus: string) {
  switch (clientStatus) {
    case ClientStatus.LEAD:
      return 'Prospect';
    default:
      return startCase(clientStatus);
  }
}

export function ClientStatusLabel({ clientStatus, className, onClick, buttonRef, fullWidth }: Props) {
  const classes = useStyles({ clientStatus, fullWidth });
  const rootClasses = cx(classes.root, className, onClick && classes.clickable);

  return (
    <span className={rootClasses} onClick={onClick} role='button' tabIndex={0} ref={buttonRef}>
      {clientStatus ? getUserStatus(clientStatus) : 'N/A'} {onClick && <ArrowDropDownRoundedIcon fontSize='small' />}
    </span>
  );
}
