import { RiskScatter, RiskScatterDataPoint } from 'components/charts/RiskScatter';
import { RiskAnalysisItem } from 'dtos/deep-scan';
import React, { useMemo } from 'react';

export const useDataRiskByVulnerabilityChart = (enableCharts: boolean, data?: RiskAnalysisItem[]) => {
  const dataRiskByVulnerability = useMemo((): RiskScatterDataPoint[] => {
    return (data || [])
      .map((row) => ({
        risk: row.vulnerabilityScore || null,
        dataRisk: row.dataRisk || 0,
        name: row.hostname,
      }))
      .filter((row) => row.risk !== null && row.dataRisk !== 0);
  }, [data]);

  const chart = useMemo(() => {
    return (
      <RiskScatter
        locked={!enableCharts}
        id='risk-by-vulnerability'
        title='Data Risk by Vulnerability'
        data={dataRiskByVulnerability}
      />
    );
  }, [enableCharts, dataRiskByVulnerability]);

  return {
    chart,
    dataRiskByVulnerability,
  };
};
