import { makeStyles, Popover } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
  },
  label: {
    ...TYPOGRAPHY.SMALL_REGULAR,
  },
  content: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },
}));

interface Props {
  label: string | React.ReactNode;
  content: string;
}

export const MouseOverPopover = ({ label, content }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isSimpleLabel = typeof label === 'string';
  return (
    <div>
      {isSimpleLabel ? (
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.label}
        >
          {label}
        </Typography>
      ) : (
        <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          {label}
        </div>
      )}
      <Popover
        id='mouse-over-popover'
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.content}>{content}</Typography>
      </Popover>
    </div>
  );
};
