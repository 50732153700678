import { ApplicationQuickChartsDTO, ApplicationQuickChartsUrlsDTO } from 'dtos/charts';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { ChartsService } from 'services/Charts';

export const useApplicationSecurityCharts = (
  applicationId: string,
  options?: UseQueryOptions<ApplicationQuickChartsDTO>,
) =>
  useQuery<ApplicationQuickChartsDTO>(
    ['application-security-charts', applicationId],
    () => ChartsService.getSecurityCharts(applicationId),
    {
      staleTime: 1000 * 60,
      ...options,
    },
  );

export const useApplicationSecurityChartsUrls = (
  applicationId: string,
  options?: UseQueryOptions<ApplicationQuickChartsUrlsDTO>,
) =>
  useQuery<ApplicationQuickChartsUrlsDTO>(
    ['application-security-charts-urls', applicationId],
    () => ChartsService.getSecurityChartsUrls(applicationId),
    {
      staleTime: 1000 * 60,
      ...options,
    },
  );

export const useApplicationExternalSurfaceExposure = (
  applicationId: string,
  scanId: string,
  options?: UseQueryOptions<Buffer>,
) =>
  useQuery<Buffer>(
    ['application-documents', applicationId, scanId],
    () => ChartsService.getExternalExposure(applicationId, scanId),
    {
      staleTime: 1000 * 60,
      ...options,
    },
  );
