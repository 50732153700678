import { Badge, makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { SecurityFindingBySlugDTO, SecuritySeverity } from 'dtos/security-finding';
import { ReactComponent as ExplanationIcon } from 'images/explaination-mark-grey.svg';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  color: ValueOf<typeof COLORS>;
  backgroundColor: ValueOf<typeof COLORS>;
  typography: ValueOf<typeof TYPOGRAPHY>;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    padding: `${theme.spacing()}px 0`,
  },
  findingsRoot: {
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
  },
  findingsRootFaded: {
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    opacity: '0.35',
  },
  dashedSpacer: {
    flexGrow: 1,
    borderBottom: `1px dashed ${COLORS.GREY_3}`,
    margin: `${theme.spacing(1.25)}px ${theme.spacing()}px auto ${theme.spacing()}px`,
    minWidth: theme.spacing(4),
  },
  text: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    marginRight: theme.spacing(0.5),

    '&, &:visited': {
      color: COLORS.GREY_1,
    },
  },
  textRight: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    marginRight: theme.spacing(0.5),
    textAlign: 'right',
  },
  findingLink: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    cursor: 'pointer',
    textDecoration: 'underline',
    transition: 'all 0.3s',

    '&:hover': {
      color: COLORS.BLUE_1,
    },
  },
  badge: ({ backgroundColor, color, typography }) => ({
    ...typography,
    backgroundColor: backgroundColor,
    color: color,
    display: 'flex',
    textTransform: 'uppercase',
    alignItems: 'center',
    borderRadius: theme.spacing(0.625),
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
  }),
  icon: {
    marginRight: theme.spacing(0.5),
  },
  badgeCompare: {
    marginLeft: theme.spacing(3),
    marginTop: `-${theme.spacing(0.25)}px`,
  },
}));

interface Props {
  impact: SecuritySeverity;
  findings: SecurityFindingBySlugDTO[];
  previousFindings?: SecurityFindingBySlugDTO[];
  title?: string;
  getFindingUrl: (finding: SecurityFindingBySlugDTO) => string;
}

interface ImpactData {
  color: string;
  background: string;
  text: string;
}

export const SecurityFindings = ({ impact, findings, previousFindings, title, getFindingUrl }: Props) => {
  const impactData = useMemo((): ImpactData => {
    if (impact === SecuritySeverity.HIGH) {
      return {
        color: COLORS.RED_1,
        background: COLORS.RED_3,
        text: title || 'High Severity',
      };
    } else if (impact === SecuritySeverity.MEDIUM) {
      return {
        color: COLORS.YELLOW_1,
        background: COLORS.YELLOW_3,
        text: title || 'Medium Severity',
      };
    } else if (impact === SecuritySeverity.INFO) {
      return {
        color: '#232830',
        background: '#E8E8E8',
        text: title || 'Informational Signals',
      };
    }

    return {
      color: COLORS.BLUE_1,
      background: COLORS.BLUE_3,
      text: title || 'Low Severity',
    };
  }, [impact, title]);

  const classes = useStyles({
    backgroundColor: impactData.background,
    color: impactData.color,
    typography: TYPOGRAPHY.SMALL_BOLD,
  });

  findings.sort((a, b) => b.count - a.count);

  return (
    <div className={classes.root}>
      <div className={classes.badge}>
        {impact === SecuritySeverity.INFO && <ExplanationIcon className={classes.icon} />}
        {impactData.text}
      </div>
      {findings.map((item, index) => {
        const previousFinding = previousFindings?.find((f) => f.slug === item.slug);

        return (
          <div className={item.count > 0 ? classes.findingsRoot : classes.findingsRootFaded} key={index}>
            <FindingLink
              url={item.count > 0 ? getFindingUrl(item) : undefined}
              className={cx(classes.text, item.count > 0 && classes.findingLink)}
            >
              <>
                {item.name}
                {(previousFindings?.length || 0) > 0 && !previousFinding && item.count > 0 && (
                  <Badge badgeContent='New' color={'error'} className={classes.badgeCompare}></Badge>
                )}
                {previousFinding && previousFinding.count > 0 && item.count === 0 && (
                  <Badge badgeContent='Fixed' color={'primary'} className={classes.badgeCompare}></Badge>
                )}
              </>
            </FindingLink>
            <div className={classes.dashedSpacer}></div>
            <div className={classes.textRight}>{item.count}</div>
          </div>
        );
      })}
    </div>
  );
};

interface FindingLinkProps {
  children: React.ReactNode;
  url?: string;
  className?: string;
}

const FindingLink = ({ children, url, className }: FindingLinkProps) => {
  if (url) {
    return (
      <Link to={url} className={className}>
        {children}
      </Link>
    );
  }
  return <div className={className}>{children}</div>;
};
