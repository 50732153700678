import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { Step } from 'components/Step';
import { ApplicationStatus, ApplicationWithAgentDTO } from 'dtos/application';
import { CompanyDTO } from 'dtos/company';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SUPPORT_URL } from 'telivy-constants';
import { isNodewareEnabled, isScanApplication } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { ConfigurePhishingSection } from './ConfigurePhishingSection';
import { ConfigureSoftwares } from './ConfigureSoftwares';
import { ConnectNodeware } from './ConnectNodeware';
import { InstallAgentsSection } from './InstallAgentsSection';
import { InstallSaasAlertsSection } from './InstallSaasAlerts';
import { ScanBrowserPasswordsSection } from './ScanBrowserPasswordsSection';
import { UninstallAgentsSection } from './UninstallAgentsSection';
import { UploadEmployeeListSection } from './UploadEmployeeListSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(6),
  },
  sidebar: {
    width: 270,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  content: {
    flex: 1,
    paddingRight: theme.spacing(3),
    maxWidth: 800,
  },
  steps: {},

  greyText: {
    color: COLORS.GREY_1,
  },
  redText: {
    color: COLORS.RED_1,
  },
  greenText: {
    color: COLORS.GREEN_1,
  },

  message: {
    marginBottom: theme.spacing(2),
    justifyContent: 'start',
  },
  sectionMenu: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  company?: CompanyDTO;
  setSecurityOverallScore: (score: number | null) => void;
}

export enum CONFIGURATION_STEP {
  INSTALLATION = 'installation',
  NODEWARE = 'nodeware',
  BROWSER_PASSWORDS = 'browser-passwords',
  EMPLOYEES_LIST = 'employees-list',
  PHISHING = 'phishing',
  SAAS_ALERTS = 'saas-alerts',
  CLOUD_SOFTWARES = 'cloud-softwares',
  UNINSTALLATION = 'uninstallation',
}

interface StepConfiguration {
  type: CONFIGURATION_STEP;
  completed: boolean;
  label: string;
  locked?: boolean;
}

export const ApplicationConfigurationView = ({ application, company, setSecurityOverallScore }: Props) => {
  const classes = useStyles();
  const { data: agent } = useCurrentAgent();

  const deepScanId = application.deepScanId || '';
  const { data: applicationData } = useApplicationSecurityScan(application.id, 'latest');
  const { data, isLoading, error } = useDeepScan(deepScanId, {
    enabled: Boolean(deepScanId),
    retry: 1,
  });
  const [step, setStep] = useState(CONFIGURATION_STEP.INSTALLATION);

  useEffect(() => {
    if (applicationData) {
      setSecurityOverallScore(((applicationData.securityScore || 0) * 100) / 5);
    }
  }, [applicationData, setSecurityOverallScore]);

  const handleNextStep = useCallback(() => {
    if (!data?.isLightScan) {
      switch (step) {
        case CONFIGURATION_STEP.INSTALLATION:
          //   setStep(CONFIGURATION_STEP.BROWSER_PASSWORDS);
          //   break;
          // case CONFIGURATION_STEP.BROWSER_PASSWORDS:
          setStep(CONFIGURATION_STEP.EMPLOYEES_LIST);
          break;
        case CONFIGURATION_STEP.EMPLOYEES_LIST:
          setStep(CONFIGURATION_STEP.PHISHING);
          break;
        case CONFIGURATION_STEP.PHISHING:
          setStep(CONFIGURATION_STEP.SAAS_ALERTS);
          break;
        case CONFIGURATION_STEP.SAAS_ALERTS:
          setStep(CONFIGURATION_STEP.CLOUD_SOFTWARES);
          break;
        case CONFIGURATION_STEP.CLOUD_SOFTWARES:
          setStep(CONFIGURATION_STEP.UNINSTALLATION);
          break;
      }
    }
  }, [step, setStep, data]);

  const steps = useMemo(() => {
    const getStarted: StepConfiguration[] = [
      {
        type: CONFIGURATION_STEP.INSTALLATION,
        completed:
          data?.installedAgents ||
          [
            ApplicationStatus.IN_PROGRESS,
            ApplicationStatus.REPORT_REQUESTED,
            ApplicationStatus.REPORT_COMPLETED,
            ApplicationStatus.RESCAN_ALL,
            ApplicationStatus.UNINSTALL_ALL,
          ].includes(application.applicationStatus) ||
          false,
        label: 'Deploy Telivy',
      },
      // {
      //   type: CONFIGURATION_STEP.BROWSER_PASSWORDS,
      //   completed: data?.scannedBrowserPasswords || false,
      //   label: 'Scan Browser Passwords',
      // },
    ];

    if (isNodewareEnabled(agent)) {
      getStarted.push({
        type: CONFIGURATION_STEP.NODEWARE,
        completed: !!data?.nodewareId,
        label: 'Connect Nodeware',
      });
    }

    const recommended: StepConfiguration[] = [];

    const steps: StepConfiguration[] = [];

    if (!data?.isLightScan) {
      steps.push({
        type: CONFIGURATION_STEP.PHISHING,
        completed: data?.configuredPhishing || false,
        label: 'Configure Phishing',
      });

      if (data?.saasAlertLink) {
        steps.push({
          type: CONFIGURATION_STEP.SAAS_ALERTS,
          completed: data?.completedSaasAlerts || false,
          label: 'Configure SaaS Applications',
        });
      }
    }

    recommended.push({
      type: CONFIGURATION_STEP.CLOUD_SOFTWARES,
      completed: data?.m365Connected || data?.gwsConnected || false,
      label: 'Connect Cloud',
    });

    recommended.push({
      type: CONFIGURATION_STEP.EMPLOYEES_LIST,
      completed: data?.uploadedEmployeeList || false,
      label: data?.isLightScan ? 'Dark Web Email List' : 'Upload Employee List',
    });

    steps.push({
      type: CONFIGURATION_STEP.UNINSTALLATION,
      completed: data?.installedAgents || false,
      label: 'Remove Telivy',
    });

    return [
      {
        title: 'Get Started',
        steps: getStarted,
      },
      {
        title: 'Recommended Steps',
        steps: recommended,
      },
      {
        title: 'Optional Steps',
        steps,
      },
    ];
  }, [data, application, agent]);

  const section = useMemo(() => {
    if (!data) {
      return null;
    }

    const map = {
      [CONFIGURATION_STEP.INSTALLATION]: (
        <InstallAgentsSection onVerify={handleNextStep} deepScan={data} application={application} company={company} />
      ),
      [CONFIGURATION_STEP.NODEWARE]: (
        <ConnectNodeware onVerify={handleNextStep} application={application} deepScan={data} />
      ),
      [CONFIGURATION_STEP.BROWSER_PASSWORDS]: <ScanBrowserPasswordsSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.EMPLOYEES_LIST]: <UploadEmployeeListSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.PHISHING]: <ConfigurePhishingSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.SAAS_ALERTS]: <InstallSaasAlertsSection onVerify={handleNextStep} deepScan={data} />,
      [CONFIGURATION_STEP.CLOUD_SOFTWARES]: (
        <ConfigureSoftwares onVerify={handleNextStep} deepScan={data} application={application} />
      ),
      [CONFIGURATION_STEP.UNINSTALLATION]: (
        <UninstallAgentsSection onVerify={handleNextStep} deepScan={data} application={application} />
      ),
    };

    return map[step];
  }, [data, handleNextStep, application, step, company]);

  if (error?.response?.status === 403) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Forbidden'
          text={<>You do not have access to view this page.</>}
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  if (!isScanApplication(application) || (!data && !isLoading)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Setup - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.root}>
        <div className={classes.sidebar}>
          <div className={classes.steps}>
            {steps.map((sectionData) => {
              return (
                <div className={classes.sectionMenu} key={sectionData.title}>
                  <div className={classes.sectionTitle}>{sectionData.title}</div>
                  {sectionData.steps.map((stepData, index) => {
                    if (stepData.type == CONFIGURATION_STEP.UNINSTALLATION) {
                      return (
                        <Step
                          doNotGreyOut
                          progress={0}
                          currIndex={3}
                          readonly
                          checkV2
                          key={index}
                          isError={true}
                          hideErrors
                          descriptionClassName={classes.greyText}
                          idx={4}
                          title='Remove Telivy'
                          isActive={CONFIGURATION_STEP.UNINSTALLATION === step}
                          description='After Risk Assessment'
                          onClick={() => {
                            setStep(CONFIGURATION_STEP.UNINSTALLATION);
                          }}
                          totalQuestions={3}
                        />
                      );
                    }

                    return (
                      <Step
                        totalQuestions={3}
                        currIndex={3}
                        progress={0}
                        doNotGreyOut
                        readonly
                        checkV2
                        key={index}
                        idx={index}
                        title={stepData.label}
                        locked={stepData.locked}
                        isActive={stepData.type === step}
                        onClick={() => setStep(stepData.type)}
                        isError={data ? !stepData.completed : undefined}
                        description={stepData.completed ? 'Complete' : 'Incomplete'}
                        descriptionClassName={stepData.completed ? classes.greenText : classes.redText}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <main className={classes.content}>
          <SecurityCard.Badge variant='info' className={classes.message}>
            Please refer to our{' '}
            <a href={SUPPORT_URL} target='_blank' rel='noreferrer'>
              support center
            </a>{' '}
            for help. Reach out to{' '}
            <a href={`mailto:support@telivy.com?subject=Question about ${applicationData?.domain}`}>
              support@telivy.com
            </a>{' '}
            if you still have questions
          </SecurityCard.Badge>
          {section}
        </main>
      </div>
    </ApplicationViewContainer>
  );
};
