import { Button } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { useToasters } from 'hooks/useToasters';
import { useResendVerifyEmail } from 'queries/useAgent';
import React, { useCallback } from 'react';

export const VerifyEmail = () => {
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useResendVerifyEmail({
    onSuccess: () => {
      showToaster('Verification email resent successfully');
    },
    onError: (error) => {
      toasterErrorHandler(error, 'Failed to resend verification email');
    },
  });

  const handleClick = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <EmptyState
      title='Access Denied'
      text={
        <>
          Please check your inbox to verify your email.
          <br />
          <br />
          <Button disabled={isLoading} variant='outlined' onClick={handleClick}>
            Resend verification email
          </Button>
        </>
      }
      icon={<LockOutlinedIcon />}
    />
  );
};
