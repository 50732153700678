import { makeStyles } from '@material-ui/core';
import { ReactComponent as CheckGreenIcon } from 'images/confirm.svg';
import React from 'react';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px`,
  },
  heading: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    maxWidth: 390,
    textAlign: 'center',
    margin: 0,
    lineHeight: `${theme.spacing(3.5)}px`,
  },
  icon: {},
}));

export const ApplicationThankYouM365View = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Thank You</title>
      </Helmet>

      <div className={classes.heading} key='heading'>
        <h1 className={classes.title}>
          Thank You
          <CheckGreenIcon className={classes.icon} />
        </h1>

        <p className={classes.description}>
          Thank you for connecting your M365 account with Telivy. This page can now be closed.
        </p>
      </div>
    </div>
  );
};
