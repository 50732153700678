import { CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

interface StyleProps {
  fullScreen?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: ({ fullScreen }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    ...(fullScreen && {
      height: '100vh',
      width: '100vw',
    }),
  }),
}));

interface Props extends StyleProps {}

export const LoadingContainer = ({ fullScreen }: Props) => {
  const classes = useStyles({ fullScreen });

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};
