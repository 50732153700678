import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { ApplicationDTO } from 'dtos/application';
import { PolicyDTO } from 'dtos/policy';
import { QuoteDTO } from 'dtos/quotes';
import { useToasters } from 'hooks/useToasters';
import { useCreatePolicy } from 'queries/usePolicy';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  text: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  hasActivePolicy: boolean;
  application: ApplicationDTO;
  quote: QuoteDTO;
  onPolicyCreate?: (data: PolicyDTO) => void;
  className?: string;
}

export const CreatePolicyButton: React.FC<Props> = ({ className, quote, application, hasActivePolicy }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useCreatePolicy({
    onSuccess: () => {
      handlePolicyPageNavigation();
      showToaster('Policy Created');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const handlePolicyPageNavigation = () => {
    navigate(insertIdToRoute(ROUTES.agent.application.POLICIES, application.id));
  };

  const handleCreate = useCallback(() => {
    mutate({
      applicationId: application.id,
      quoteId: quote.id,
      limit: quote.aggregateLimit,
      premium: quote.premium,
      retention: quote.retention,
    });
  }, [mutate, application, quote]);

  return (
    <>
      <Button className={className} onClick={() => setOpen(true)} variant='contained'>
        Create Policy
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseRoundedIcon />
          </IconButton>
          <h3 className={classes.title}>Create Policy</h3>
          <p className={classes.text}>
            {hasActivePolicy ? (
              'Looks like you already have an active policy. Only one policy may be active at a time. To create a new policy, cancel the active one.'
            ) : (
              <>
                Create a policy from Quote <b>#{quote.quoteNumber}</b>. Only one quote may be selected for a policy at a
                time. To select a different quote, simply cancel the active policy.
              </>
            )}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {hasActivePolicy ? (
            <Button onClick={handlePolicyPageNavigation}>Go to Policy Page</Button>
          ) : (
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} onClick={handleCreate}>
              {isLoading ? 'Creating...' : 'Create Policy'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
