import { Button, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DeclinationDocumentDTO } from 'dtos/declinations';
import { useToasters } from 'hooks/useToasters';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import { useDeleteDeclinationDocument } from 'queries/useDeclinations';
import React, { useCallback } from 'react';

interface Props {
  className?: string;
  quoteId: string;
  document: DeclinationDocumentDTO;
}

export const DeleteDocumentButton = ({ className, quoteId, document }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useDeleteDeclinationDocument(quoteId, {
    onSuccess: () => {
      showToaster(`Successfully deleted "${document.fileName}"`);
    },
    onError: (error) => {
      toasterErrorHandler(error, 'Failed to delete document');
    },
  });

  const handleConfirmClick = useCallback(() => {
    mutate(document.id);
  }, [mutate, document]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton className={className} onClick={handleClickOpen}>
        <TrashIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Please confirm that you want to delete &quot;{document.fileName}&quot;.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color='primary' disabled={isLoading}>
            Confirm and delete document
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
