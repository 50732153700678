import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import cx from 'classnames';
// import { SortOrder } from 'components/Table';
import { SingleFilter } from 'components/SingleFilter';
import { AlertCategory } from 'dtos/alert';
// import { SortOrder } from 'dtos/application';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { ReactComponent as SearchIcon } from 'images/search.svg';
import { pickBy } from 'lodash';
import intersection from 'lodash/intersection';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';
import { NewFilterButton } from 'views/agent/views/dashboard/NewFilterButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemsContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
  },
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 'auto 15px auto 0',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  preferences: {
    marginLeft: theme.spacing(1),
  },

  inputContainer: {
    position: 'relative',
    display: 'flex',
  },
  searchIcon: {
    position: 'absolute',
    left: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchInput: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    border: `1px solid ${COLORS.GREY_4}`,
    background: COLORS.WHITE,
    borderRadius: theme.spacing(1),
    width: 270,
    '&:focus': {
      borderColor: COLORS.BLUE_1,
    },
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: COLORS.GREY_3,
    },
  },
  filtersContainer: {
    position: 'relative',
    display: 'flex',
    gap: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  clearIcon: {
    display: 'flex',
    border: 'none',
    margin: 0,
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    background: 'none',
    transform: 'translateY(-50%)',
  },
  monitoringFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  monitoringForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },
}));

export interface FilterParams {
  unread?: boolean | null;
  alertCategory?: AlertCategory | null;
  creationDateDays?: number;
}

export interface QueryParams extends FilterParams {
  q?: string;
  page?: number;
}

export type AvailableFilter<T> = {
  [Property in keyof T]: {
    label: string;
    icon?: JSX.Element;
    options: Array<{
      label: string;
      value: T[Property];
    }>;
  };
};

interface Props<Params> {
  title: string;
  className?: string;
  onFilterChange: (paramsToUpdate: Partial<Params>) => void;
  currentFilters: Params;
  filterList: AvailableFilter<Params>;
  applicationIds: string[];
  onBulkAction?: () => void;
  selectedLimit: keyof typeof LIMITS;
  setSelectedLimit: (limit: keyof typeof LIMITS) => void;
  applicationId?: string;
}

export function Filters({
  title,
  className,
  currentFilters,
  onFilterChange,
  filterList,
  selectedLimit,
  setSelectedLimit,
  applicationId,
}: Props<QueryParams>) {
  const classes = useStyles();
  const navigate = useNavigate();

  // currentFilter without falsy values
  const currentFiltersReduced = pickBy(currentFilters);
  // all filter names as an array of strings
  const filterListKeys = Object.keys(filterList) as Array<keyof AvailableFilter<QueryParams>>;
  // currentFilters names as an array of strings
  const filterKeys = intersection(
    Object.keys(currentFiltersReduced) as Array<keyof Partial<QueryParams>>,
    filterListKeys,
  );
  // currentFilters as an array of objects
  const filtersFormatted = filterKeys.map((filter) => ({
    name: filter,
    value: currentFiltersReduced[filter] || undefined,
  }));

  // not yet used filters - filters available to add
  const availableFilters = filterListKeys.filter((filter) => !filterKeys.includes(filter));

  const handleViewPreference = useCallback(
    () => navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.alerts.ROOT}/${ROUTES.agent.alerts.POLICIES}`),
    [navigate],
  );

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.itemsContainer}>
        <Typography variant='h2' className={classes.title}>
          {title}
        </Typography>
        <div className={classes.inputContainer}>
          <SearchIcon className={classes.searchIcon} />
          <input
            placeholder='Search alert...'
            value={currentFilters.q}
            onChange={(e) => onFilterChange({ q: e.target.value })}
            className={classes.searchInput}
          />
          {currentFilters.q && currentFilters.q.length > 0 && (
            <button className={classes.clearIcon} onClick={() => onFilterChange({ q: '' })}>
              <CloseIcon />
            </button>
          )}
        </div>
        <div className={classes.filtersContainer}>
          {(filtersFormatted || []).map((filter, idx: number) => (
            <NewFilterButton<QueryParams>
              key={idx}
              currentFilter={filter}
              onFilterChange={(paramsToUpdate) => onFilterChange(paramsToUpdate)}
              filterList={filterList}
            />
          ))}
          {filtersFormatted?.length < filterListKeys.length && (
            <NewFilterButton<QueryParams>
              currentFilter={{ name: undefined, value: undefined }}
              onFilterChange={(paramsToUpdate) => onFilterChange(paramsToUpdate)}
              availableFilters={availableFilters}
              filterList={filterList}
            />
          )}
        </div>
        <div className={classes.monitoringFormContainer}>
          <SingleFilter
            type='radio'
            buttonText={selectedLimit}
            defaultSelectedOptions={[selectedLimit]}
            popperText='Select Period'
            showDropdownIcon
            options={Object.keys(LIMITS)}
            setSelectedOptions={(a) => {
              setSelectedLimit(a[0]);
            }}
          />
          {!applicationId && (
            <Button
              variant='outlined'
              color='primary'
              startIcon={<TuneRoundedIcon />}
              onClick={() => handleViewPreference()}
              className={classes.preferences}
            >
              Alert Policies
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
