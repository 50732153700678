import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { COLORS } from 'telivy-theme';

import { AgenciesTable } from './AgenciesTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  stats: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  stat: {
    maxWidth: '50%',
    minWidth: 300,
  },
  color: {
    color: COLORS.BLUE_1,
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AccountsView: React.FC<Props> = ({ agent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AgenciesTable agent={agent} />
    </div>
  );
};
