import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  roundedButton?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    [theme.breakpoints.down('sm')]: {
      background: COLORS.WHITE,
      zIndex: 2,
      margin: 0,
      position: 'fixed',
      left: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      width: '100%',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      boxSizing: 'border-box',
      boxShadow: '0px 2px 4px rgba(35, 40, 48, 0.16), 0px 0px 1px rgba(35, 40, 48, 0.5)',

      '& button': {
        ...TYPOGRAPHY.SMALL_BOLD,
        width: '100%',
        maxWidth: theme.spacing(42),
        padding: `${theme.spacing(1.25)}px 0`,
        ...(props.roundedButton && {
          borderRadius: `${theme.spacing(4.75)}px !important`,
        }),
      },
    },
  }),
}));

interface Props extends StyleProps {
  className?: string;
}

export const BottomActionsNavigation: React.FC<Props> = ({ children, className, roundedButton }) => {
  const classes = useStyles({ roundedButton });

  return <div className={cx(classes.root, className)}>{children}</div>;
};
