import { makeStyles } from '@material-ui/core/styles';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { AgentDTO } from 'dtos/agent';
import {
  ApplicationDocumentDTO,
  ApplicationDocumentType,
  ApplicationDTO,
  ApplicationWithAgentDTO,
} from 'dtos/application';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { isAgentAnAdmin } from 'telivy-selectors';
import { TYPOGRAPHY } from 'telivy-theme';

import { DocumentActions } from './DocumentActions';

const generateColumns = (app: ApplicationDTO, agent: AgentDTO, reports?: boolean): Column<ApplicationDocumentDTO>[] => {
  const cols: Column<ApplicationDocumentDTO>[] = [
    {
      title: 'Type',
      render: (data) => {
        const isReportsAndReportType = data.documentType.toLowerCase().includes('report') && reports;
        const shouldAddRawData = isReportsAndReportType && !data.documentType.toLowerCase().includes('telivy');

        return (
          <span>
            {shouldAddRawData && ` (Raw Data) `}
            {DOCUMENT_TYPE_HUMANIZED_NAME[data.documentType]}
          </span>
        );
      },
    },
  ];

  if (isAgentAnAdmin(agent)) {
    cols.push({
      title: 'Created At',
      render: (data) => <span>{data.createdAt ? dayjs(data.createdAt).format('MMM DD YYYY hh:mm A') : '-'}</span>,
    });
  }

  cols.push({
    title: 'Actions',
    render: (data) => <DocumentActions document={data} application={app} />,
  });

  return cols;
};

const useStyles = makeStyles(() => ({
  emptyContainer: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface Props {
  documents?: ApplicationDocumentDTO[];
  isLoading?: boolean;
  application: ApplicationWithAgentDTO;
  agent: AgentDTO;
  reports?: boolean;
}

export const ApplicationDocumentsTable = ({ isLoading, documents, application, reports, agent }: Props) => {
  const classes = useStyles();

  const columns = useMemo(() => generateColumns(application, agent, reports), [agent, application, reports]);
  const sortedData = useMemo(() => {
    if (!reports) {
      return documents;
    }

    // Ensure that the complete report is always first
    return sortBy(documents, (el) => {
      if (el.documentType === ApplicationDocumentType.TELIVY_COMPLETE_REPORT) {
        return 9999999999999;
      }

      return +new Date(el.createdAt);
    }).reverse();
  }, [documents, reports]);

  return (
    <Table
      bordered
      rowContentCentered
      columns={columns}
      loading={isLoading}
      data={sortedData}
      rowKey={(el) => el.id}
      emptyState={
        <div className={classes.emptyContainer}>
          Looks like there aren&apos;t any {reports ? 'Reports' : 'Documents'} yet.
        </div>
      }
    />
  );
};
