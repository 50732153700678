import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditIcon from '@material-ui/icons/Edit';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { ApplicationDTO } from 'dtos/application';
import { PolicyDTO } from 'dtos/policy';
import { useToasters } from 'hooks/useToasters';
import { useUpdatePolicy } from 'queries/usePolicy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.LARGE,
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  input: {
    width: 350,
  },
}));

interface Props {
  application: ApplicationDTO;
  policy: PolicyDTO;
  className?: string;
}

export const EditPolicyButton: React.FC<Props> = ({ className, policy, application }) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const [text, setText] = useState('');
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const isValid = text.length;
  const classes = useStyles();

  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useUpdatePolicy(application.id, policy.id, {
    onSuccess: () => {
      handleClose();
      showToaster('Policy has been updated');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  useEffect(() => {
    setText(policy.policyNumber || '');
  }, [open, policy.policyNumber]);

  const handleUpdate = useCallback(() => {
    setShowValidation(true);

    if (isValid) {
      mutate({
        policyNumber: text,
      });
    }
  }, [isValid, mutate, text]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const errorMessage = useMemo(() => {
    if (!text) {
      return 'Policy number cannot be blank.';
    }

    return null;
  }, [text]);

  return (
    <>
      <Button
        startIcon={<EditIcon />}
        className={className}
        onClick={() => setOpen(true)}
        variant='contained'
        color='primary'
      >
        Edit Policy Number
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseRoundedIcon />
          </IconButton>
          <h3 className={classes.title}>Edit Policy Number</h3>
          <CustomTextFieldShrink
            value={text}
            className={classes.input}
            onChange={handleChange}
            label='Policy Number'
            required
            error={showValidation ? !isValid : false}
            helperText={showValidation ? errorMessage : null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type='submit' color='primary' variant='contained' disabled={isLoading} onClick={handleUpdate}>
            {isLoading ? 'Updating...' : 'Done'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
