import { Button, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DocumentDTO } from 'dtos/_common';
import { ReactComponent as TrashIcon } from 'images/trash.svg';
import React, { useCallback } from 'react';

interface Props<Document> {
  className?: string;
  onDelete: (doc: Document) => void;
  isLoading: boolean;
  document: Document;
}

export const DeleteDocumentButton = <Type, Document extends DocumentDTO<Type>>({
  className,
  document,
  onDelete,
  isLoading,
}: Props<Document>) => {
  const [open, setOpen] = React.useState(false);

  const handleConfirmClick = useCallback(() => {
    onDelete(document);
  }, [document, onDelete]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton className={className} onClick={handleClickOpen}>
        <TrashIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Please confirm that you want to delete &quot;{document.fileName}&quot;.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color='primary' disabled={isLoading}>
            Confirm and delete document
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
