import { EmptyState } from 'components/EmptyState';
import { Money } from 'components/Money';
import { CarrierColumn } from 'components/QuotesTable/CarrierColumn';
import { Column, Table } from 'components/Table';
import { PolicyDTO } from 'dtos/policy';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

interface Props {
  data?: PolicyDTO[];
  isLoading?: boolean;
  onPolicySelect: (item: PolicyDTO) => void;
  selectedRowsKeys: string[];
  selectedRowClass: string;
}

const columns: Array<Column<PolicyDTO, keyof PolicyDTO>> = [
  {
    title: 'Policy #',
    width: '20%',
    render: (policy) => policy.policyNumber || '-',
  },
  {
    title: 'Quote',
    width: '35%',
    render: (policy) => (
      <div>
        <div style={{ ...TYPOGRAPHY.MONO_MEDIUM_SMALL }}>{policy.quote.quoteNumber}</div>
        <CarrierColumn carrier={policy.quote.carrier} />
      </div>
    ),
  },
  {
    title: 'Limit',
    width: '15%',
    render: (policy) => <Money value={policy.limit} />,
  },
  {
    title: 'Premium',
    width: '15%',
    render: (policy) => <Money value={policy.premium} />,
  },
  {
    title: 'Retention',
    width: '15%',
    render: (policy) => <Money value={policy.retention} />,
  },
];

export const CancelPoliciesTable = ({ data, isLoading, onPolicySelect, selectedRowClass, selectedRowsKeys }: Props) => {
  if (!isLoading && data && data.length === 0) {
    return <EmptyState title='No Cancelled Policies' />;
  }

  return (
    <Table<PolicyDTO>
      hideHeadersWhenEmpty
      columns={columns}
      loading={isLoading}
      data={data}
      selectedRowsKeys={selectedRowsKeys}
      selectedRowClass={selectedRowClass}
      onRowClick={(policy) => onPolicySelect(policy)}
      rowKey={(el) => el.id}
    />
  );
};
