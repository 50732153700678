import { CreateApplicationPublicDTO, VerifyCaptchaDTO } from 'dtos/public';

import RequestService from './Request';

class _PublicService {
  createPublicApplications(data: CreateApplicationPublicDTO): Promise<string> {
    return RequestService.client.post<string>('/applications/public', data).then((response) => response.data);
  }

  verifyCaptcha(data: VerifyCaptchaDTO): Promise<{ success: boolean }> {
    return RequestService.client
      .post<{ success: boolean }>('/applications/recaptcha/verify', data)
      .then((response) => response.data);
  }
}

const PublicService = new _PublicService();

export { PublicService };
