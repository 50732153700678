import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles<Theme, { skipPadding?: boolean }>((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    border: `1px solid ${COLORS.GREY_5}`,
    padding: (p) => (p.skipPadding ? 0 : theme.spacing(2)),
    backgroundColor: `rgba(#F3F7F9, 0.5)`,
  },
  title: {
    ...TYPOGRAPHY.SMALL_BOLD,
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  blur: {
    filter: 'blur(6px)',
  },
  chartsContainer: {
    display: 'flex',
  },
  disclaimer: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_3,
    position: 'absolute',
    top: '50%',
    left: '50%',
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'all',
  },
}));

export interface Props {
  title?: string;
  id?: string;
  skipPadding?: boolean;
  children?: React.ReactNode;
  className?: string;
  chartContainerClassName?: string;
  actions?: React.ReactNode;
  locked?: boolean;
}

export const ChartContainer = ({
  title,
  children,
  className,
  actions,
  id,
  locked,
  chartContainerClassName,
  skipPadding,
}: Props) => {
  const classes = useStyles({ skipPadding });

  return (
    <div className={cx(classes.root, chartContainerClassName)} id={id}>
      {title ? (
        <div className={classes.title}>
          <div>{title}</div>
          {actions && <div>{actions}</div>}
        </div>
      ) : null}
      <div className={cx(classes.chartsContainer, className, locked && classes.blur)}>{children}</div>
      {locked ? (
        <div className={classes.disclaimer}>
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        </div>
      ) : null}
    </div>
  );
};
