import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { LinkButton } from 'components/LinkButton';
import { ProposalDTO } from 'dtos/proposal';
import { useDeleteProposal } from 'queries/useProposal';
import React, { CSSProperties, useCallback, useState } from 'react';
import { COLORS } from 'telivy-theme';

interface Props {
  proposal: ProposalDTO;
  style?: CSSProperties;
}

export const DeleteProposalButton = ({ proposal, style }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate } = useDeleteProposal(proposal.applicationId, proposal.id, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const handleClick = useCallback(() => {
    mutate();
    setIsModalOpen(true);
  }, [mutate]);

  return (
    <>
      <LinkButton onClick={handleClick} style={{ ...style, color: COLORS.RED_1 }}>
        Delete
      </LinkButton>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Deleting proposal...</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};
