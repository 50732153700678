import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { LinkButton } from 'components/LinkButton';
import { DocumentDTO } from 'dtos/_common';
import startCase from 'lodash/startCase';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { DeleteDocumentButton } from './DeleteDocumnetButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 45,
    border: `1px solid ${COLORS.GREY_4}`,
    display: 'flex',
    '& + $root': {
      borderTop: 'none',
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },

    '&:last-of-type': {
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(1 / 4)}px  ${theme.spacing(2)}px`,
    paddingRight: theme.spacing(0.5),
  },
  select: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    width: 200,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${COLORS.GREY_4}`,
  },
  label: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
    color: COLORS.RED_1,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    border: 0,
    background: 0,
  },
  fileSelectButton: {
    marginLeft: theme.spacing(-1),
  },
}));

interface Props<Type, Document extends DocumentDTO<Type>> {
  document: Document;
  onSelectType: (type: Type) => void;
  isDeleting: boolean;
  isUpdating: boolean;
  onRequestDownload?: () => void;
  onDelete: () => void;
  types: Type[];
  selectClassName?: string;
}

export const EditableDocumentRow = <Type extends string, Document extends DocumentDTO<Type>>({
  document,
  selectClassName,
  onRequestDownload,
  onSelectType,
  isDeleting,
  isUpdating,
  onDelete,
  types,
}: Props<Type, Document>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Select
        className={cx(classes.select, selectClassName)}
        disabled={isUpdating}
        value={document.documentType}
        onChange={(el) => {
          onSelectType(el.target.value as Type);
        }}
      >
        {types.map((type) => (
          <MenuItem key={type} value={type}>
            {startCase(type)}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.content}>
        <div className={classes.label}>
          {onRequestDownload ? (
            <LinkButton onClick={onRequestDownload}>{document.fileName}</LinkButton>
          ) : (
            document.fileName
          )}
        </div>
        <div className={classes.actions}>
          <DeleteDocumentButton
            document={document}
            className={classes.button}
            isLoading={isDeleting}
            onDelete={onDelete}
          />
        </div>
      </div>
    </div>
  );
};
