import { ControlProps } from '@jsonforms/core';
import { useJsonForms, withJsonFormsControlProps } from '@jsonforms/react';
import { _Autocomplete, Options } from 'components/JsonForms/Autocomplete';
import { startsWith } from 'lodash';
import { useNaicsCodes } from 'queries/useApplications';
import React, { useMemo } from 'react';

const _NaicsAutocomplete: React.FC<ControlProps> = (props) => {
  const { data, isLoading } = useNaicsCodes();
  const context = useJsonForms();
  const { options: config } = props.uischema;

  const options: Options = useMemo(() => {
    if (!data) {
      return [];
    }

    // By default, return all the values
    let filteredOptions = data;

    const naicsValue = context.core?.data[config?.naicsField];
    const startWith = (naicsValue || '').match(/\d+/gi);

    // Handling a case with simple digit
    if (startWith && startWith.length === 1) {
      filteredOptions = data.filter((o) => startsWith(o, startWith[0]));
    }

    // Handling a case with a range
    if (startWith && startWith.length === 2) {
      const startingNumber = parseInt(startWith[0]);
      const endingNumber = parseInt(startWith[1]);
      const numbers: number[] = [];

      for (let i = startingNumber; i <= endingNumber; i++) {
        numbers.push(i);
      }

      filteredOptions = data.filter((o) => numbers.some((num) => startsWith(o, `${num}`)));
    }

    return filteredOptions.map((e) => ({ title: e, const: e }));
  }, [config?.naicsField, context.core?.data, data]);

  if (!props.visible) {
    return null;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <_Autocomplete
      {...props}
      schema={{
        ...props.schema,
        oneOf: options,
      }}
    />
  );
};

export const NaicsAutocomplete = withJsonFormsControlProps(_NaicsAutocomplete);
