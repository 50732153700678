import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import * as rax from 'retry-axios';
import { loadAuthTokens } from 'services/auth0-tokens';
import { API_URL } from 'telivy-constants';

// TODO: Handle re-authentication
export class RequestService {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
    });

    this.setRetry();
    this.setAuthenticationInterceptor();
  }

  setRetry() {
    rax.attach(this.client);
  }

  setAuthenticationInterceptor() {
    this.client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const tokens = loadAuthTokens();

      // Prevent from extra headers to Google APIs resulting in CORS issues
      if (config.url && config.url.includes('googleapis')) {
        return config;
      }

      if (tokens.accessToken) {
        // config.headers = { ...config.headers } as AxiosRequestHeaders;
        config.headers.set('Authorization', 'Bearer ' + tokens.accessToken);
      }

      if (tokens.adminToken) {
        // config.headers = { ...config.headers } as AxiosRequestHeaders;
        config.headers.set('Authorization-Admin', 'Bearer ' + tokens.adminToken);
      }

      return config;
    });
  }

  // https://github.com/googleapis/nodejs-storage/issues/347
  async uploadFile(file: File, data: GeneratedUploadUrlResponseDTO) {
    return new Promise((res, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', data.url);
      xhr.onload = () => {
        res(data.path);
      };
      xhr.onerror = (err) => {
        reject(err);
      };
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.send(file);
    });
  }
}

export default new RequestService();
