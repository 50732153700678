import { makeStyles } from '@material-ui/core/styles';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
// import { ApplicationWithAgentDTO } from 'dtos/application';
import { ApplicationsRiskReport } from 'dtos/deep-scan';
import sortBy from 'lodash/sortBy';
// import { useDeepScanParsedReports } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
// import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ScoreBox, ScoreRanking } from 'templates/SecurityAssessments/components/ScoreBox';

const useStyles = makeStyles(() => ({
  root: {},
  table: {
    width: '100%',
  },
}));

interface Props {
  applicationRiskReports?: ApplicationsRiskReport[] | null;
}

const PER_PAGE = 500;
export const Browser = ({ applicationRiskReports }: Props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sorter, setSorter] = useState<Sorter<keyof ApplicationsRiskReport> | undefined>({
    key: 'visits',
    order: SortOrder.DESC,
  });

  const columns = useMemo((): Column<ApplicationsRiskReport, keyof ApplicationsRiskReport>[] => {
    return [
      {
        title: 'Application',
        sortKey: 'application',
        render: (row) => row.application,
      },
      {
        title: 'Category',
        sortKey: 'category',
        render: (row) => row.category,
      },
      // {
      //   title: 'Days Active',
      //   sortKey: 'daysActive',
      //   render: (row) => row.daysActive,
      // },
      {
        title: 'Total Visits',
        sortKey: 'visits',
        render: (row) => row.visits,
      },
      {
        title: 'Risk',
        sortKey: 'risk',
        render: (row: ApplicationsRiskReport) => <ScoreBox ranking={row.risk as ScoreRanking} />,
      },
      {
        title: 'Users',
        sortKey: 'users',
        render: (row) => row.users,
      },
    ];
  }, []);

  const sortedData = useMemo(() => {
    if (sorter) {
      const data = sortBy(applicationRiskReports, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(applicationRiskReports, (d) => d.visits).reverse();
  }, [applicationRiskReports, sorter]);

  return (
    <Table<ApplicationsRiskReport, keyof ApplicationsRiskReport>
      columns={columns}
      sorter={sorter}
      rowContentCentered
      onChange={(newPage, sorting) => {
        if (newPage?.page !== undefined) {
          setPage(newPage.page);
        }

        setSorter(sorting);
      }}
      className={classes.table}
      rowKey={(row) => row.application}
      data={sortedData.slice(page * PER_PAGE, (page + 1) * PER_PAGE)}
      loading={!applicationRiskReports}
      pagination={
        !applicationRiskReports ? undefined : { page, perPage: PER_PAGE, total: applicationRiskReports?.length || 0 }
      }
    />
  );
};
