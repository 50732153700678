import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Modal } from 'components/Modal';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { DataRiskDistributionDTO, DeepScanDTO, PII_TYPE_INFOS, PII_TYPE_LABELS } from 'dtos/deep-scan';
import sortBy from 'lodash/sortBy';
// import uniqBy from 'lodash/uniqBy';
import React, { useMemo, useState } from 'react';
import { COLORS } from 'telivy-theme';
import { DataRiskDetailsModal } from 'templates/SecurityAssessments/components/DataRiskDetailsModal';

const useStyles = makeStyles((theme) => ({
  root: {},

  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
  actionButton: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginRight: theme.spacing(0.5),
  },
  table: {
    width: '100%',
  },
}));

interface Props {
  totalDataRiskDistribution?: DataRiskDistributionDTO[];
  deepScan: DeepScanDTO | undefined;
  isLoadingDataRisk?: boolean;
}

export const InventoryData = ({ deepScan, totalDataRiskDistribution, isLoadingDataRisk }: Props) => {
  const classes = useStyles();

  const [selectedPiiType, setSelectedPiiType] = useState<string | undefined>(undefined);
  const [datRiskDistributionSorter, setDatRiskDistributionSorter] = useState<
    Sorter<keyof DataRiskDistributionDTO> | undefined
  >({
    key: 'totalRisk',
    order: SortOrder.DESC,
  });

  const handleClose = () => {
    setSelectedPiiType(undefined);
  };

  const dataRiskDistributionColumns = useMemo((): Column<DataRiskDistributionDTO, keyof DataRiskDistributionDTO>[] => {
    return [
      {
        title: 'PII Type',
        sortKey: 'piiType',
        render: (row) => {
          const piiTypeLabel = PII_TYPE_LABELS[deepScan?.piiConfig?.country || 'US'][row.piiType] || row.piiType;
          const piiTypeInfo = PII_TYPE_INFOS['US'] && PII_TYPE_INFOS['US'][row.piiType];

          if (!piiTypeInfo) return piiTypeLabel;

          return (
            <span>
              {piiTypeLabel}
              <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title={piiTypeLabel}>
                <p>{piiTypeInfo}</p>
              </Modal>
            </span>
          );
        },
      },
      {
        title: `Risk per match (in ${deepScan?.piiConfig?.currency})`,
        // sortKey: 'piiType',
        render: (row) =>
          `${deepScan?.piiConfig?.currency}${((deepScan?.piiConfig?.costs || {})[row.piiType] || 0).toLocaleString()}`,
      },
      {
        title: `Total PII Risk (in ${deepScan?.piiConfig?.currency})`,
        sortKey: 'totalRisk',
        render: (row) => `${deepScan?.piiConfig?.currency}${(row.totalRisk || 0).toLocaleString()}`,
      },
      {
        title: 'Total PII Matches',
        sortKey: 'matchesCount',
        render: (row) => (row.matchesCount || 0).toLocaleString(),
      },
      {
        title: 'Sensitive Files',
        sortKey: 'sensitiveFiles',
        render: (row) => (row.sensitiveFiles || 0).toLocaleString(),
      },
      {
        title: 'Action',
        render: (row) => (
          <Button className={classes.actionButton} onClick={() => setSelectedPiiType(row.piiType)}>
            Details
          </Button>
        ),
      },
    ];
  }, [classes, deepScan]);

  const sortedTotalDataRiskDistribution = useMemo(() => {
    if (datRiskDistributionSorter) {
      const data = sortBy(totalDataRiskDistribution || [], datRiskDistributionSorter.key);

      if (datRiskDistributionSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(totalDataRiskDistribution || [], (d) => d.totalRisk).reverse();
  }, [totalDataRiskDistribution, datRiskDistributionSorter]);

  return (
    <>
      <Table<DataRiskDistributionDTO, keyof DataRiskDistributionDTO>
        columns={dataRiskDistributionColumns}
        sorter={datRiskDistributionSorter}
        onChange={(pagination, sorter) => {
          setDatRiskDistributionSorter(sorter);
        }}
        rowContentCentered
        className={classes.table}
        rowKey={(row) => `${row.piiType}`}
        data={sortedTotalDataRiskDistribution}
        loading={isLoadingDataRisk}
      />

      <DataRiskDetailsModal
        deepScan={deepScan}
        className={classes.actionButton}
        piiType={selectedPiiType}
        isOpen={Boolean(selectedPiiType)}
        handleClose={handleClose}
      />
    </>
  );
};
