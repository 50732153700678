import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { useAgentLogo } from 'hooks/useAgentLogo';
import { useApplication } from 'queries/useApplications';
import React from 'react';

interface StyleProps {
  logoSize?: number;
}

const useStyles = makeStyles<Theme, StyleProps>({
  logo: (props) => ({
    width: 'auto',
    'img&': {
      height: props.logoSize ? props.logoSize : 60,
    },
    'svg&': {
      height: 30,
    },
  }),
});

interface Props {
  applicationId: string;
  className?: string;
}

export const AgentLogo = ({ applicationId, logoSize, className }: Props & StyleProps) => {
  const classes = useStyles({ logoSize });
  const { data } = useApplication(applicationId, {
    enabled: !!applicationId,
  });
  const { renderAgentLogo } = useAgentLogo(data, logoSize);

  if (!applicationId) {
    return <></>;
  }

  return <>{renderAgentLogo(cx(classes.logo, className && className))}</>;
};
