/* eslint-disable indent */
import { RadialBarCustomLayerProps, ResponsiveRadialBar, svgDefaultProps } from '@nivo/radial-bar';
import React, { ReactNode } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface Props {
  total: number;
  items: number;
  label: string;
  title: string;
  learnMoreSection: ReactNode;
}

export const InsurabilityScoreCircularProgress = ({ total, items, label, title, learnMoreSection }: Props) => {
  const data = [
    {
      id: label,
      color: COLORS.BLUE_1,
      data: [
        {
          x: title,
          y: items,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
      }}
    >
      <span style={{ ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM, textAlign: 'center' }}>{title}</span>
      <div style={{ position: 'relative', height: 75, width: '100%', marginTop: 8 }}>
        <div
          style={{
            width: '100%',
            height: 75,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <ResponsiveRadialBar
            maxValue={total}
            data={data}
            startAngle={-45}
            innerRadius={0.8}
            padding={0}
            cornerRadius={20}
            // margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            borderColor={{
              from: 'color',
            }}
            colors={COLORS.BLUE_1}
            tracksColor={COLORS.BLUE_1 + '40'}
            enableRadialGrid={false}
            enableCircularGrid={false}
            radialAxisStart={null}
            // circularAxisOuter={null}
            legends={[]}
            isInteractive={false}
            circularAxisOuter={{
              tickComponent: () => null,
            }}
            layers={[...svgDefaultProps.layers, CustomLayer({ maxValue: total, label })]}
          />
        </div>
      </div>
      {learnMoreSection}
    </div>
  );
};

const CustomLayer =
  ({ maxValue, label }: { maxValue: number; label: string }) =>
  ({ center, bars }: RadialBarCustomLayerProps) => {
    return (
      <g>
        <g transform={`translate(${center[0]}, ${center[1]})`}>
          <text
            textAnchor='middle'
            dominantBaseline='central'
            y={-8}
            style={{
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            {bars?.[0]?.value}
          </text>
          <text textAnchor='middle' dominantBaseline='central' y={8} style={{ fontSize: 12 }}>
            {label}
          </text>
        </g>
        <g transform={`translate(${center[0] - 46}, ${center[1]})`}>
          <text
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontSize: 12,
              fontWeight: 700,
            }}
          >
            {maxValue}
          </text>
        </g>
      </g>
    );
  };
