import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AxiosError } from 'axios';
import { QuoteDTO } from 'dtos/quotes';
import { useToasters } from 'hooks/useToasters';
import { useDeleteQuote } from 'queries/useQuote';
import React, { useCallback } from 'react';

interface Props {
  className?: string;
  quote: QuoteDTO;
  onQuoteDelete: () => void;
}

export const DeleteQuoteButton = ({ className, quote, onQuoteDelete }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { mutate, isLoading } = useDeleteQuote({
    onSuccess: () => {
      showToaster(`Successfully deleted "${quote.quoteNumber}"`);
      onQuoteDelete();
      setOpen(false);
    },
    onError: (error: AxiosError | unknown) => {
      toasterErrorHandler(error, 'Failed to delete quote');
      setOpen(false);
    },
  });

  const handleConfirmClick = useCallback(() => {
    mutate(quote.id);
  }, [mutate, quote]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button className={className} onClick={handleClickOpen} variant='contained'>
        {isLoading ? 'Deleting...' : 'Delete Quote'}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>Please confirm that you want to delete &quot;{quote.quoteNumber}&quot;.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color='primary' disabled={isLoading}>
            Confirm and delete quote
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
