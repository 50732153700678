import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.BLUE_3}`,
    padding: theme.spacing(2),
    position: 'relative',
    fontSize: 12,
    borderRadius: 12,
  },
  title: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginBottom: theme.spacing(3),
  },
  spacer: {
    height: 1,
    width: '100%',
    marginBottom: theme.spacing(1.5),
    backgroundColor: COLORS.GREY_3,
    border: 'none',
  },
  description: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
    '& a': {
      color: COLORS.BLUE_1,
    },
  },
  link: {
    display: 'block',
    marginTop: theme.spacing(1.5),
    color: COLORS.BLUE_1,
    textDecoration: 'underline',
  },
  image: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface Props {
  title: string;
  description: string;
  onLearnMoreClick?: () => void;
}

export const HelperBox = (props: Props): JSX.Element => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.title}>{props.title}</div>
      <hr className={classes.spacer} />
      <div className={classes.description} dangerouslySetInnerHTML={{ __html: props.description }} />
      {props.onLearnMoreClick && <span className={classes.link}>Learn More.</span>}
    </div>
  );
};
