import { CompanyLogo } from 'components/CompanyLogo';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React, { useCallback, useMemo, useState } from 'react';

export const generateClearbitLogoUrl = (domain: string, size = 180) => `//logo.clearbit.com/${domain}?size=${size}`;

const extractDomainFromEmail = (email: string): null | string => {
  const match = email.match(/@(.+)/);

  if (match) {
    return match[1];
  }

  return match;
};

export const useAgentLogo = (data?: ApplicationWithAgentDTO, logoSize?: number) => {
  const [failedToFetchLogo, setFailedToFetchLogo] = useState<boolean>(false);
  const logo = useMemo(() => {
    if (data) {
      if (data.agent.agency && data.agent.agency.logoUrl) {
        return {
          url: data.agent.agency.logoUrl,
          isGenerated: false,
        };
      }

      const domain = extractDomainFromEmail(data.agent.email);
      if ((domain && domain === 'telivy.com') || !domain) {
        return setFailedToFetchLogo(true);
      }

      if (domain) {
        return {
          url: generateClearbitLogoUrl(domain, logoSize),
          isGenerated: true,
        };
      }
    }
  }, [data, logoSize]);

  const renderAgentLogo = useCallback(
    (className?: string) => {
      // Show Telivy logo if we failed to fetch the logo
      if (failedToFetchLogo) {
        return <CompanyLogo className={className} />;
      }

      // Do not show anything if we are still loading agent information
      if (!logo) {
        return null;
      }

      // Show the logo if we have it
      return <img src={logo?.url} alt='Agent Logo' className={className} onError={() => setFailedToFetchLogo(true)} />;
    },
    [failedToFetchLogo, logo],
  );

  return {
    renderAgentLogo,
    logo,
  };
};
