import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useToasters } from 'hooks/useToasters';
import { useMarkApplicationNotSubmitted, useMarkApplicationSubmitted } from 'queries/useApplications';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createSecurityRoute, ROUTES } from 'telivy-constants';
import { isSecurityApplication } from 'telivy-selectors';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  applicationId: string;
  className?: string;
  handleSave: () => void;
  isSaving?: boolean;
  shouldSave?: boolean;
  isSubmitted?: boolean;
  isSecurity?: boolean;
  isFormValid?: boolean;
  onValidationError: () => void;
}

export const MarkSubmittedButton: React.FC<Props> = ({
  applicationId,
  className,
  handleSave,
  isSaving,
  shouldSave,
  isSubmitted,
  isSecurity,
  isFormValid,
  onValidationError,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const navigate = useNavigate();

  const { mutate, isLoading } = (isSubmitted ? useMarkApplicationNotSubmitted : useMarkApplicationSubmitted)(
    applicationId,
    {
      onSuccess: (data) => {
        showToaster(`Successfully marked application as ${isSubmitted ? 'not' : ''} submitted`);
        setIsModalOpen(false);

        if (isSecurityApplication(data)) {
          navigate(createSecurityRoute(ROUTES.agent.application.security.ROOT, data.id, 'latest'));
        }
      },
    },
  );

  const openModalSaveChanges = () => {
    if (shouldSave) handleSave();
    setIsModalOpen(true);
  };

  const handleModalOpen = () => {
    if (!isFormValid) {
      toasterErrorHandler('Validation error', 'All required fields must be filled out');
      onValidationError();
    } else {
      openModalSaveChanges();
    }
  };

  const BUTTON_SUBMIT_TEXT = isSecurity ? 'Submit' : 'Submit to Quote';
  const BUTTON_SUBMITTED_TEXT = isSecurity ? 'Undo Submitted' : 'Undo Quoted';
  const MODAL_TEXT = (() => {
    if (isSubmitted) {
      return isSecurity ? 'Assessment will be marked as not submitted' : 'Application will be marked as not submitted';
    } else {
      return isSecurity
        ? 'Assessment will be submitted for generating Security Report.'
        : 'Application will be sent to the Telivy team for quoting.';
    }
  })();
  const MODAL_TITLE = (() => {
    if (isSubmitted) {
      return 'Confirm marking not submitted';
    } else {
      return isSecurity ? 'Confirm submitting the assessment' : 'Confirm submitting the application';
    }
  })();

  return (
    <>
      <Button
        className={className}
        onClick={handleModalOpen}
        variant={isSubmitted ? 'text' : 'contained'}
        color='primary'
        // disabled={isSubmitted}
      >
        {isSubmitted ? BUTTON_SUBMITTED_TEXT : BUTTON_SUBMIT_TEXT}
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            {MODAL_TITLE}
          </Typography>
          <Typography variant='body1'>{MODAL_TEXT}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button disabled={isSaving || isLoading} onClick={() => mutate()}>
            {isSaving ? 'Saving...' : isLoading ? (isSubmitted ? 'Saving...' : 'Submitting...') : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
