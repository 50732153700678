import { Badge } from 'components/Badge';
import { Column, Table } from 'components/Table';
import { SecurityFindingBySlugDTO, SecuritySeverity } from 'dtos/security-finding';
import { startCase } from 'lodash';
import React, { useMemo } from 'react';

import { getSeverityBadgeVariant } from '../../helpers';
import { SCAN_TYPE_NAME } from '../../views/SecurityExternalScan';

export const sortBySeverityAndCount = (a: SecurityFindingBySlugDTO, b: SecurityFindingBySlugDTO) => {
  const severityOrder = [SecuritySeverity.HIGH, SecuritySeverity.MEDIUM, SecuritySeverity.LOW, SecuritySeverity.INFO];
  const severityA = severityOrder.indexOf(a.severity);
  const severityB = severityOrder.indexOf(b.severity);
  if (severityA === severityB) {
    return b.count - a.count;
  }

  return severityA - severityB;
};

interface Props {
  data?: SecurityFindingBySlugDTO[];
  showFactor?: boolean;
  onRowClick: (finding: SecurityFindingBySlugDTO) => void;
}

export const FindingsTable = ({ data, onRowClick, showFactor }: Props) => {
  const columns = useMemo((): Array<Column<SecurityFindingBySlugDTO, keyof SecurityFindingBySlugDTO>> => {
    const columns: Array<Column<SecurityFindingBySlugDTO, keyof SecurityFindingBySlugDTO>> = [
      {
        title: 'Severity',
        width: '20%',
        render: (finding) => (
          <Badge bordered variant={getSeverityBadgeVariant(finding.severity)} style={{ minWidth: 80, width: 80 }}>
            {startCase(finding.severity)}
          </Badge>
        ),
      },
      {
        title: 'Finding',
        width: '40%',
        render: (finding) => {
          return finding.name;
        },
      },
    ];

    if (showFactor) {
      columns.push({
        title: 'Factor',
        width: '20%',
        render: (finding) => SCAN_TYPE_NAME[finding.scanType],
      });
    }

    columns.push({
      title: 'Findings',
      width: '20%',
      render: (finding) => finding.count,
    });

    return columns;
  }, [showFactor]);

  return (
    <Table<SecurityFindingBySlugDTO>
      hideHeadersWhenEmpty
      columns={columns}
      data={data?.filter((f) => f.count > 0)?.sort(sortBySeverityAndCount) || []}
      onRowClick={onRowClick}
      rowKey={(el) => el.slug}
    />
  );
};
