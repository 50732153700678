import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { CustomTextField } from 'components/CustomTextField';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { Modal } from 'components/Modal';
// import { RadioSelect } from 'components/RadioSelect';
import {
  ANTIVIRUS_OPTIONS,
  CLIENT_CATEGORIES,
  ClientCategory,
  ClientStatus,
  CompanyDTO,
  EditCompanyDTO,
} from 'dtos/company';
import { useToasters } from 'hooks/useToasters';
import { useEditCompany } from 'queries/useCompanies';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {},
  radioSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioSelectVertical: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
  button: {
    display: 'block',
    minWidth: 200,
    marginLeft: 'auto',
    marginTop: theme.spacing(4),
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: '12px',
      color: '#232830',
      fontWeight: 500,
    },
  },
  edit: {
    paddingTop: theme.spacing(0.025),
    paddingBottom: theme.spacing(0.025),
  },
  invite: {},
}));

interface Props {
  company: CompanyDTO;
  onClose?: () => void;
}

export const EditCompany = ({ company, onClose }: Props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useEditCompany(company.id, {
    onSuccess: () => {
      showToaster(`Successfully updated company`);
      onClose && onClose();
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const { handleSubmit, control, watch } = useForm<EditCompanyDTO>({
    defaultValues: {
      // orgName: company.orgName || '',
      // domain: company.domain || '',
      clientStatus: company.clientStatus,
      clientCategory: company.clientCategory,
      antiVirus: company.antiVirus,
    },
  });

  const selectAntiVirus = watch('antiVirus');

  const onSubmit = (data: EditCompanyDTO) => {
    mutate({
      // orgName: data.orgName,
      // domain: data.domain,
      clientStatus: data.clientStatus,
      clientCategory: data.clientCategory,
      antiVirus: selectAntiVirus == 'Other' ? data.antiVirusOther : data.antiVirus,
    });
  };

  return (
    <>
      <Modal
        title='Edit Company'
        isOpen={!!company}
        onClose={() => onClose && onClose()}
        openButton={
          <Button variant='outlined' color='default' className={classes.edit}>
            Edit
          </Button>
        }
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='clientStatus'
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id='select-client-status'>Company Status</InputLabel>
                  <Select {...field} labelId='select-client-status' label='Company Status' value={field.value}>
                    {[
                      { label: 'Prospect', value: ClientStatus.LEAD },
                      { label: 'Client', value: ClientStatus.CLIENT },
                    ].map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name='antiVirus'
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id='select-anti-virus'>Anti Virus</InputLabel>
                  <Select {...field} labelId='select-anti-virus' label='Anti Virus' value={field.value}>
                    {ANTIVIRUS_OPTIONS.map((av) => (
                      <MenuItem key={av} value={av}>
                        {av}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {selectAntiVirus == 'Other' && (
              <Controller
                name='antiVirusOther'
                control={control}
                defaultValue={null}
                render={({ field }) => <CustomTextFieldShrink {...field} label={'Please specify Anti Virus'} />}
              />
            )}
            <Controller
              name='clientCategory'
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id='select-client-category'>Company Category</InputLabel>
                  <Select {...field} labelId='select-client-category' label='Company Category' value={field.value}>
                    {Object.keys(CLIENT_CATEGORIES)
                      .map((c) => {
                        return {
                          label: CLIENT_CATEGORIES[c as any as ClientCategory],
                          value: c,
                        };
                      })
                      .map((category) => (
                        <MenuItem key={category.value} value={category.value}>
                          {category.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} className={classes.button}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};
