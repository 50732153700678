import { SingleFilter } from 'components/SingleFilter';
import { DeepScanTargetLinkedUserDTO, RiskAnalysisItem } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import uniq from 'lodash/uniq';
import { useUpdateDeepScanTargetLinkedUsers } from 'queries/useDeepScan';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  deepScanId: string;
  deepScanTarget: RiskAnalysisItem;
  allUsers: DeepScanTargetLinkedUserDTO[];
  className?: string;
}

export const LinkedUsersModal = ({ deepScanId, deepScanTarget, allUsers }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { showToaster, toasterErrorHandler } = useToasters();

  useEffect(() => {
    if (deepScanTarget.linkedUsers) {
      setSelectedUsers((deepScanTarget.linkedUsers || []).map((u) => u.mail));
    }
  }, [deepScanTarget]);

  const { mutate, isLoading } = useUpdateDeepScanTargetLinkedUsers(deepScanId, deepScanTarget.id, {
    onSuccess: () => {
      showToaster('Users Updated');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const onSubmit = useCallback(
    (linkedUsers: string[]) => {
      mutate(allUsers.filter((u) => linkedUsers.indexOf(u.mail) > -1));
    },
    [mutate, allUsers],
  );

  const userValues = useMemo(() => {
    return uniq(allUsers.map((u) => u.mail).sort());
  }, [allUsers]);

  const selectedUserValues = useMemo(() => {
    return (deepScanTarget.linkedUsers || []).map((u) => u.mail?.split('@')[0]);
  }, [deepScanTarget]);

  if (allUsers.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <SingleFilter
        buttonText={`${selectedUserValues.length > 0 ? `${selectedUserValues.join(', ')}` : 'Link Users'}`}
        defaultSelectedOptions={selectedUsers}
        popperText='Select Users:'
        showDropdownIcon
        options={userValues}
        setSelectedOptions={(d) => {
          onSubmit(d);
        }}
        disabled={isLoading}
        startIcon={<></>}
      />
    </>
  );
};
