import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LinkButton } from 'components/LinkButton';
import { AgentDTO } from 'dtos/agent';
import { useDownloadExportCsv } from 'queries/useAgent';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  linkButton: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'inline',
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  buttonRight: {
    marginRight: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
  },
}));

interface Props {
  agent: AgentDTO;
}

interface Form {
  fromDate?: Date | null;
  uptoDate?: Date | null;
}

const FORM_ID = 'export-external-scans-csv-form';

export const ExportCsvButton = ({ agent }: Props) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isLoading } = useDownloadExportCsv(agent.id, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const { handleSubmit, control } = useForm<Form>({
    defaultValues: {},
  });

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onSubmit = (data: Form) => {
    mutate({
      fromDate: data.fromDate,
      uptoDate: data.uptoDate,
    });
  };

  return (
    <>
      <LinkButton className={classes.linkButton} onClick={handleClick}>
        Export External Scans (CSV)
      </LinkButton>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Export External Scans (CSV)</DialogTitle>
        <DialogContent>
          <Typography>Select Date Range (optional)</Typography>
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              <FormControlLabel
                label=''
                control={
                  <Controller
                    name='fromDate'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Start Date'
                        type='date'
                        defaultValue={null}
                        value={field.value || null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                }
              />

              <FormControlLabel
                label=''
                control={
                  <Controller
                    name='uptoDate'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='End Date'
                        type='date'
                        defaultValue={null}
                        value={field.value || null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                }
              />
            </section>
          </form>
        </DialogContent>
        <DialogActions>
          <div className={classes.marginLeftAuto}>
            <Button onClick={() => setIsModalOpen(false)} className={classes.buttonRight}>
              Go back
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} form={FORM_ID}>
              {isLoading ? 'Downloading...' : 'Download'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
