import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { Modal } from 'components/Modal';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    fontSize: 15,
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(-0.25)}px ${theme.spacing(0.5)}px`,
  },
}));

interface Props {
  label: string;
  description?: string;
}

export const JsonFormsLabelDescription = ({ label, description }: Props) => {
  const classes = useStyles();

  return (
    <>
      {label}
      {description && (
        <Modal openButton={<HelpOutlineRoundedIcon className={classes.helpIcon} />} title={label}>
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Modal>
      )}
    </>
  );
};
