import { Money } from 'components/Money';
import { Column, Props as TableProps, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { QuoteDocumentType, QuoteDTO } from 'dtos/quotes';
import { useApplicationQuotes } from 'queries/useApplications';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

import { CarrierColumn } from './CarrierColumn';

const columns: Array<Column<QuoteDTO, keyof QuoteDTO>> = [
  {
    title: 'Quote Name',
    width: '30%',
    render: (quote) => (
      <div>
        <div style={{ ...TYPOGRAPHY.MONO_MEDIUM_SMALL }}>{quote.quoteNumber}</div>
        <CarrierColumn carrier={quote.carrier} />
      </div>
    ),
  },
  {
    title: 'Limit',
    width: '10%',
    render: (quote) => <Money value={quote.aggregateLimit} />,
  },
  {
    title: 'Retention',
    width: '10%',
    render: (quote) => <Money value={quote.retention} />,
  },
  {
    title: 'Premium',
    width: '10%',
    render: (quote) => <Money value={quote.premium} />,
  },
  {
    title: 'Annual Cost',
    width: '10%',
    render: (quote) => <Money value={quote.annualPolicyCost} />,
  },
  {
    title: 'Created At',
    width: '10%',
    render: (quote) => dayjs(quote.createdAt).format('M/D/YYYY'),
  },
  {
    title: 'Expiration Date',
    width: '10%',
    render: (quote) => dayjs(quote.expirationDate).format('M/D/YYYY'),
  },
  {
    title: 'Telivy Score',
    width: '10%',
    render: (quote) => Number(quote.score).toFixed(1),
  },
];

interface Props extends Omit<TableProps<QuoteDTO, keyof QuoteDTO>, 'data' | 'loading' | 'rowKey' | 'columns'> {
  application: ApplicationDTO;
  dataFilter?: (item: QuoteDTO) => boolean;
  disableIfMissingQuoteLetter?: boolean;
  emptyState: JSX.Element;
}

export const QuotesTable = ({
  dataFilter,
  application,
  disableIfMissingQuoteLetter,
  emptyState,
  ...restProps
}: Props) => {
  const { data, isLoading } = useApplicationQuotes(application.id);
  const disabledRowsKeys = disableIfMissingQuoteLetter
    ? data?.filter((q) => !q.documents.find((d) => d.documentType === QuoteDocumentType.QUOTE_LETTER)).map((q) => q.id)
    : [];

  return (
    <Table<QuoteDTO, keyof QuoteDTO>
      {...restProps}
      emptyState={emptyState}
      columns={columns}
      disabledRowsKeys={disabledRowsKeys}
      rowKey={(q) => q.id}
      data={(dataFilter ? data?.filter(dataFilter) : data)?.sort((a: QuoteDTO, b: QuoteDTO) => b.score - a.score)}
      loading={isLoading}
    />
  );
};
