import { AgencyDTO } from 'dtos/agent';
import { ApplicationDTO, SortOrder } from 'dtos/application';

export enum ClientCategory {
  BREAKFIX = 'breakfix',
  IT_ONLY = 'it_only',
  SECURITY_ONLY = 'security_only',
  IT_AND_SECURITY = 'it_and_security',
}

export const CLIENT_CATEGORIES: { [key in ClientCategory]: string } = {
  [ClientCategory.BREAKFIX]: 'Breakfix',
  [ClientCategory.IT_ONLY]: 'IT Only',
  [ClientCategory.SECURITY_ONLY]: 'Security Only',
  [ClientCategory.IT_AND_SECURITY]: 'IT & Security',
};

export enum ClientStatus {
  SUSPECT = 'suspect',
  LEAD = 'lead',
  CLIENT = 'client',
}

export interface CompanyDTO {
  id: string;
  applications: ApplicationDTO[];
  createdAt: Date;
  updatedAt: Date;
  orgName: string;
  domain: string;
  antiVirus: string | null;
  clientStatus: ClientStatus | null;
  clientCategory: ClientCategory | null;
  agency: AgencyDTO | null;
  agencyId: string;
}

export enum CompanySortBy {
  ORG_NAME = 'orgName',
  DOMAIN = 'domain',
  CLIENT_STATUS = 'clientStatus',
  CLIENT_CATEGORY = 'clientCategory',
  ANTI_VIRUS = 'antiVirus',
  CREATED_AT = 'createdAt',
}

export interface CompaniesQuery {
  q?: string;
  sortBy?: CompanySortBy;
  sortOrder?: SortOrder;
  clientStatus?: ClientStatus;
  clientCategory?: ClientCategory;
  offset?: number;
  limit?: number;
  creationDateDays?: number;
}

export interface CompaniesResponse {
  message: string;
  companies: CompanyDTO[];
  companiesCount: number;
}

export interface EditCompanyDTO {
  antiVirus?: string | null;
  antiVirusOther?: string | null;
  clientStatus: ClientStatus | null;
  clientCategory: ClientCategory | null;
}

export const ANTIVIRUS_OPTIONS = [
  'Microsoft Defender',
  'Datto AV',
  'Todyl',
  'Deep Instinct',
  'Threatlocker',
  'Sentinel One',
  'Webroot',
  'ESET',
  'Other',
];
