import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import cx from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { ParsedPiiTarget } from 'dtos/deep-scan';
import { useDeviceByRiskChart } from 'hooks/charts/useDeviceByRiskChart';
import { useChartImage } from 'hooks/useChartImage';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanTargets } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { ApplicationViewContainer } from 'views/agent/views/application-details/views/ApplicationViewContainer';

import { GeneratePiiToolsBundle } from '../../components/GeneratePiiToolsBundle';
import { MouseOverPopover } from '../../components/MouseOverPopover';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },
  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },
  red: {
    color: COLORS.RED_1,
  },

  chart: {
    marginBottom: theme.spacing(2),
    height: 400,
  },
}));

enum RiskBy {
  PII = 'pii',
  FILES_SCANNED = 'filesScanned',
}

interface Props {
  application: ApplicationWithAgentDTO;
}

export const DeepScanDataSecurityPiiTools = ({ application }: Props) => {
  const classes = useStyles();
  const [riskBy, setRiskBy] = React.useState<RiskBy>(RiskBy.PII);
  const deepScanId = application.deepScanId || '';
  const { data, isLoading } = useDeepScanTargets(deepScanId, { enabled: Boolean(deepScanId) });
  const [sorter, setSorter] = useState<Sorter<keyof ParsedPiiTarget> | undefined>({
    key: 'riskValuation',
    order: SortOrder.DESC,
  });
  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);
  const { devices } = useDeviceByRiskChart(enableCharts, data, undefined, classes.chart);
  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  const devicesFilesScannedChart = useChartImage(
    {
      className: classes.chart,
      title: 'Device risk by:',
      actions: (
        <Select
          value={riskBy}
          onChange={(el) => {
            setRiskBy(el.target.value as RiskBy);
          }}
        >
          <MenuItem key={RiskBy.PII} value={RiskBy.PII}>
            PII Risk ($)
          </MenuItem>
          <MenuItem key={RiskBy.FILES_SCANNED} value={RiskBy.FILES_SCANNED}>
            Files Scanned
          </MenuItem>
        </Select>
      ),
    },
    riskBy === RiskBy.FILES_SCANNED ? charts?.devicesFilesScanned : charts?.devicesRiskValuation,
    deepScan,
    application,
  );

  const totals = useMemo(() => {
    return devices.reduce(
      (acc, target) => {
        return {
          totalRiskValuation: acc.totalRiskValuation + target.riskValuation,
          totalSensitive: acc.totalSensitive + target.sensitive,
          totalFilesScanned: acc.totalFilesScanned + target.files,
        };
      },
      { totalRiskValuation: 0, totalSensitive: 0, totalFilesScanned: 0 },
    );
  }, [devices]);

  const columns = useMemo((): Column<ParsedPiiTarget, keyof ParsedPiiTarget>[] => {
    return [
      {
        title: 'Host name',
        sortKey: 'hostname',
        render: (row) => {
          let ranking: ScoreRanking = 'Medium';
          let status = 'Pending';

          if (row.piiToolsScanStatus) {
            if (row.piiToolsScanStatus === 'FAILED') {
              ranking = 'High';
            } else if (row.piiToolsScanStatus === 'FINISHED') {
              ranking = 'Low';
            }

            status = startCase(row.piiToolsScanStatus);
          }

          if (!row.piiToolsScanId) {
            ranking = 'None';
            status = 'Missing setup';
          }

          return (
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <MouseOverPopover
                label={<ScoreBox ranking={ranking} label={status} />}
                content={row.piiToolsScanStatusMessage || 'No message yet'}
              />
              {row.hostname}
            </div>
          );
        },
      },
      {
        title: 'PII Risk (in $)',
        sortKey: 'riskValuation',
        render: (row) => `$${row.riskValuation.toLocaleString()}`,
      },
      {
        title: 'Files scanned',
        sortKey: 'files',
        render: (row) => row.files.toLocaleString(),
      },
      {
        title: 'Sensitive files',
        sortKey: 'sensitive',
        render: (row) => row.sensitive.toLocaleString(),
      },
    ];
  }, []);

  const sortedDevices = useMemo(() => {
    if (sorter) {
      const data = sortBy(devices, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(devices, (device) => device.riskValuation).reverse();
  }, [devices, sorter]);

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (!devices) {
    return (
      <ApplicationViewContainer>
        <EmptyState title='No data available yet' icon={<SignalWifiOffIcon />} />
      </ApplicationViewContainer>
    );
  }

  return (
    <div>
      <div className={classes.section}>
        <div className={classes.title}>
          <div>Data Liability (PII Tools) (Admin view only)</div>
          <GeneratePiiToolsBundle application={application} />
        </div>
        <div className={classes.summary} id='summary'>
          <div className={classes.item}>
            <div className={classes.number}>{data?.length || 0}</div>
            <div className={classes.description}>Total Assets</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>${totals.totalRiskValuation?.toLocaleString()}</div>
            <div className={classes.description}>Total Data Risk</div>
          </div>
          <div className={classes.item}>
            <div className={classes.number}>{totals.totalFilesScanned?.toLocaleString()}</div>
            <div className={classes.description}>Total Files Scanned</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>{totals.totalSensitive?.toLocaleString()}</div>
            <div className={classes.description}>Sensitive Files</div>
          </div>
        </div>
      </div>

      {devicesFilesScannedChart}

      <div className={classes.section}>
        <div className={classes.title}>
          Devices Risk
          <span className={classes.findings}> ({sortedDevices.length} devices)</span>
        </div>
        <div className={classes.boxBorder}>
          <Table<ParsedPiiTarget, keyof ParsedPiiTarget>
            columns={columns}
            sorter={sorter}
            onChange={(pagination, sorter) => {
              setSorter(sorter);
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.hostname}`}
            data={sortedDevices}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
