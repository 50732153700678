import 'leaflet/dist/leaflet.css';

import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { CircleMarker, MapContainer, TileLayer, Tooltip } from 'react-leaflet';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export interface CityData {
  city: string;
  lat: number;
  lng: number;
  value: number;
  fill: string;
}

interface Props<T extends CityData> {
  width: number | string;
  height: number | string;
  tooltipContent?: (data: T) => React.ReactNode;
  cities: T[];
  id?: string;
}

export function MapChart<T extends CityData>({ cities, width, height, id, tooltipContent }: Props<T>) {
  const classes = useStyles();

  const minMaxes = useMemo(() => {
    const minLat = Math.min(...cities.map((o) => o.lat));
    const maxLat = Math.max(...cities.map((o) => o.lat));
    const minLong = Math.min(...cities.map((o) => o.lng));
    const maxLong = Math.max(...cities.map((o) => o.lng));
    const maxVal = Math.max(...cities.map((o) => o.value));
    const minVal = Math.min(...cities.map((o) => o.value));
    return { minLat, maxLat, minLong, maxLong, minVal, maxVal };
  }, [cities]);

  const centerLat = (minMaxes.minLat + minMaxes.maxLat) / 2;
  // const distanceLat = Math.max(minMaxes.maxLat - minMaxes.minLat, 10);
  // const bufferLat = distanceLat * 0.5;
  const centerLong = (minMaxes.minLong + minMaxes.maxLong) / 2;
  // const distanceLong = Math.max(minMaxes.maxLong - minMaxes.minLong, 10);
  // const bufferLong = distanceLong * 0.5;

  const MAX_OPACITY = 0.99;
  const MIN_OPACITY = 0.5;
  const computeOpacity = useCallback(
    (value: number) => {
      if (minMaxes.maxVal - minMaxes.minVal === 0) return MAX_OPACITY;

      return (
        MIN_OPACITY + ((MAX_OPACITY - MIN_OPACITY) * (value - minMaxes.minVal)) / (minMaxes.maxVal - minMaxes.minVal)
      );
    },
    [minMaxes.maxVal, minMaxes.minVal],
  );

  const MAX_RADIUS = 20;
  const MIN_RADIUS = 6;
  const computeRadius = useCallback(
    (value: number) => {
      if (minMaxes.maxVal - minMaxes.minVal === 0) return MAX_RADIUS;

      return MIN_RADIUS + ((MAX_RADIUS - MIN_RADIUS) * (value - minMaxes.minVal)) / (minMaxes.maxVal - minMaxes.minVal);
    },
    [minMaxes.maxVal, minMaxes.minVal],
  );

  return (
    <MapContainer
      id={id}
      // zoom={1.2}
      center={[centerLat, centerLong]}
      bounds={[
        [minMaxes.minLat + 20, minMaxes.minLong + 20],
        [minMaxes.maxLat - 20, minMaxes.maxLong - 20],
      ]}
      scrollWheelZoom={false}
      className={classes.root}
      style={{ width, height }}
    >
      <TileLayer url='http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />

      {cities.map((city, k) => {
        const radius = computeRadius(city.value);
        const offsetX = radius + 2;

        return (
          <CircleMarker
            key={k}
            center={[city.lat, city.lng]}
            radius={radius}
            fillColor={city.fill}
            fillOpacity={computeOpacity(city.value)}
            stroke={false}
          >
            <Tooltip direction='right' offset={[offsetX, -2]} opacity={1}>
              {tooltipContent ? tooltipContent(city) : <span>{`${city.city}. Attempts: ${city.value}`}</span>}
            </Tooltip>
          </CircleMarker>
        );
      })}
    </MapContainer>
  );
}
