import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDeclinationDocuments } from 'queries/useDeclinations';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

import { DocumentRow } from './DocumentRow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  list: {},

  // Actions
  button: {
    marginTop: theme.spacing(2),
  },
  footer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    marginLeft: 'auto',
  },
}));

interface Props {
  quoteId: string;
  applicationId: string;
  onClose: () => void;
}

export const Documents = ({ quoteId, onClose, applicationId }: Props) => {
  const classes = useStyles();
  const { data } = useDeclinationDocuments(applicationId, quoteId);

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        {data?.map((doc, idx) => (
          <DocumentRow quoteId={quoteId} document={doc} key={idx} />
        ))}

        {/* Upload quote form */}
        <DocumentRow quoteId={quoteId} />
      </div>

      <footer className={classes.footer}>
        <div className={classes.actions}>
          <Button onClick={onClose} className={classes.button}>
            Close
          </Button>
        </div>
      </footer>
    </div>
  );
};
