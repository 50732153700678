import { RiskBar } from 'components/charts/RiskBar';
import { AlertDTO } from 'dtos/alert';
// import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo } from 'react';

export const useAlertsHistoryChart = (enableCharts: boolean, data?: AlertDTO[], classname?: string) => {
  const alertCounts = useMemo(() => {
    const alertsByDate = (data || []).reduce<Record<string, AlertDTO[]>>((acc, alert) => {
      const date = moment(alert.createdAt).format('Do MMMM YYYY');
      acc[date] ||= [];
      acc[date].push(alert);

      return acc;
    }, {});

    // const countByDate = sortBy(
    //   Object.keys(alertsByDate).map((date) => {
    //     const count = alertsByDate[date].length;

    //     return {
    //       date,
    //       count,
    //       createdAt: alertsByDate[date][0].createdAt,
    //     };
    //   }),
    //   (alert) => alert.createdAt,
    // );

    const allDates = (data || []).map((a) => a.createdAt).sort();

    if (allDates.length === 0) return [];

    let startDate = moment(allDates[0]);
    const endDate = moment(allDates[allDates.length - 1]);

    const countByDate = [];
    // let previous = 0;

    while (startDate.valueOf() <= endDate.valueOf()) {
      const formattedDate = startDate.format('Do MMMM YYYY');
      const value = (alertsByDate[formattedDate] || []).length;

      countByDate.push({
        name: formattedDate,
        score: (alertsByDate[formattedDate] || []).length,
        // high: value,
        distribution: [{ name: 'Total', value, previous: value }],
      });

      startDate = startDate.add(1, 'days');
      // previous = value;
    }

    return countByDate;
  }, [data]);

  const chart = useMemo(() => {
    if (alertCounts.length === 0) return;

    return (
      <RiskBar
        locked={!enableCharts}
        id='monitoring-m365-security'
        className={classname}
        data={alertCounts}
        showTooltip
        title='Alerts History'
      />
    );
  }, [classname, enableCharts, alertCounts]);

  return {
    chart,
    alertCounts,
  };
};
