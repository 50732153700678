import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { DeepTargetStatus } from 'dtos/deep-scan';
import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles<Theme>(() => ({
  root: {
    ...TYPOGRAPHY.SMALL_BOLD,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },

  green: {
    color: COLORS.GREEN_1,
    '& $dot': {
      backgroundColor: COLORS.GREEN_1,
    },
  },
  red: {
    color: COLORS.RED_1,
    '& $dot': {
      backgroundColor: COLORS.RED_1,
    },
  },
  yellow: {
    color: COLORS.YELLOW_1,
    '& $dot': {
      backgroundColor: COLORS.YELLOW_1,
    },
  },
  grey: {
    color: '#989898',
    '& $dot': {
      backgroundColor: '#989898',
    },
  },
}));

interface Props {
  status: DeepTargetStatus;
  children?: React.ReactNode;
}

export const TargetStatus = ({ status, children }: Props) => {
  const content = status
    .split('-')
    .map((s) => capitalize(s))
    .join(' ');
  const classes = useStyles();

  const rootColor = useMemo(() => {
    switch (status) {
      case 'install-success':
      case 'uninstall-success':
      case 'rescan-success':
      case 'install-partial-done':
        return classes.green;
      case 'install-error':
      case 'already-installed':
      case 'install-not-supported':
      case 'uninstall-error':
      case 'install-offline':
        return classes.red;
      case 'install-in-progress':
      case 'install-pending':
      case 'uninstall-in-progress':
      case 'uninstall-scheduled':
      case 'rescan-scheduled':
      case 'rescan-in-progress':
      case 'install-partial':
        return classes.yellow;
      default:
        return classes.grey;
    }
  }, [classes, status]);

  return (
    <div className={cx(classes.root, rootColor)}>
      <div className={classes.dot} />
      {children || content}
    </div>
  );
};
