// import { makeStyles } from '@material-ui/core/styles';
// import DeleteSweepRoundedIcon from '@material-ui/icons/DeleteSweepRounded';
import { SingleFilter } from 'components/SingleFilter';
import { ApplicationDTO, SecurityScanDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import uniq from 'lodash/uniq';
import { useEditApplication } from 'queries/useApplications';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import { TYPOGRAPHY } from 'telivy-theme';

// const useStyles = makeStyles((theme) => ({
//   title: {
//     ...TYPOGRAPHY.LARGE,
//     margin: 0,
//     marginBottom: theme.spacing(3),
//   },
//   text: {
//     margin: 0,
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },

//   select: {
//     marginBottom: theme.spacing(1),
//   },
// }));

interface Props {
  application: ApplicationDTO;
  securityScan: SecurityScanDTO;
  className?: string;
}

export const RemoveTargetsModal = ({ application, securityScan }: Props) => {
  const [selectedTargets, setSelectedTargets] = useState<string[]>([]);
  const { showToaster, toasterErrorHandler } = useToasters();

  useEffect(() => {
    if (application.applicationResponse) {
      setSelectedTargets(application.applicationResponse.excluded_targets || []);
    }
  }, [application]);

  const { mutate, isLoading } = useEditApplication(application.id, {
    onSuccess: () => {
      showToaster('Targets Updated');
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const onSubmit = useCallback(
    (excluded_targets: string[]) => {
      mutate({
        applicationResponse: {
          ...application.applicationResponse,
          excluded_targets,
        },
      });
    },
    [mutate, application],
  );

  const targetValues = useMemo(() => {
    return uniq(
      ([] as string[])
        .concat(
          (securityScan?.subDomainScans || []).map((s) => s.domain),
          application.applicationResponse.excluded_targets || [],
        )
        .sort(),
    );
  }, [securityScan, application]);

  if (targetValues.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <SingleFilter
        buttonText={`Remove Targets ${selectedTargets.length > 0 ? `(${selectedTargets.length})` : ''}`}
        defaultSelectedOptions={selectedTargets}
        popperText='Select Targets to Remove:'
        showDropdownIcon
        options={targetValues}
        setSelectedOptions={(d) => {
          onSubmit(d);
        }}
        disabled={isLoading}
        startIcon={<></>}
      />
    </>
  );
};
