import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AgentDTO } from 'dtos/agent';
import { ApplicationDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { ReactComponent as ConfirmIcon } from 'images/confirm.svg';
import { ReactComponent as StarIcon } from 'images/star.svg';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { isScanApplication, isSecurityAgency, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  title: {
    ...TYPOGRAPHY.TITLE_3,
    margin: 0,
  },

  box: {
    position: 'relative',
    padding: theme.spacing(3),
    textAlign: 'center',
    border: `1px solid ${COLORS.GREY_5}`,
    borderRadius: 6,
  },
  star: {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.GREY_5}`,
    borderRadius: '50%',
    position: 'absolute',
    left: '50%',
    top: -18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    transform: 'translateX(-50%)',
  },

  name: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_1,
    margin: 0,
    marginBottom: theme.spacing(0.5),
  },
  address: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
    margin: 0,
  },

  right: {
    marginLeft: 'auto !important', // override mui
  },

  badge: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
}));

interface Props {
  application: ApplicationDTO;
  agent?: AgentDTO;
  onClose: () => void;
}

export const ApplicationCreated = ({ agent, application, onClose }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { showToaster } = useToasters();

  const handleEditApplication = useCallback(() => {
    onClose();
    navigate(insertIdToRoute(ROUTES.agent.application.ROOT, application.id));
  }, [application.id, navigate, onClose]);

  const handleDoneApplication = useCallback(() => {
    onClose();
    if (isSecurityApplication(application)) {
      navigate(insertIdToRoute(ROUTES.agent.application.ROOT, application.id));
    }
  }, [application, navigate, onClose]);

  const handleCopyLink = useCallback(async () => {
    if (application) {
      const link = `${window.location.origin}${insertIdToRoute(ROUTES.application.FORM, application.id)}/general`;
      await navigator.clipboard.writeText(link);
      showToaster('Link is copied to clipboard');
      onClose();
    }
  }, [application, showToaster, onClose]);

  const { organization_name, organization_address } = application.applicationResponse;

  const modalTitle = useMemo(() => {
    if (isSecurityAgency(agent)) {
      if (application.isDeepScan) {
        return 'Risk Assessment Plus';
      } else if (application.isLightScan) {
        return 'Risk Assessment';
      } else {
        return 'External Assessment';
      }
    } else {
      return 'Application';
    }
  }, [application, agent]);

  return (
    <>
      <DialogContent key='content'>
        <div className={classes.header}>
          <ConfirmIcon />
          <h2 className={classes.title}>{modalTitle}</h2>
        </div>
        <div className={classes.box}>
          <div className={classes.star}>
            <StarIcon />
          </div>
          <h3 className={classes.name}>{organization_name}</h3>
          {organization_address && (
            <p className={classes.address}>
              {`${organization_address.street} ${organization_address.street2 || ''}, ${organization_address.city}`},{' '}
              {organization_address.state}
            </p>
          )}
        </div>

        {isScanApplication(application) && (
          <SecurityCard.Badge variant='info' className={classes.badge}>
            Thank you for creating the assessment. We will reach out to you via email in a couple of hours once the
            configuration instructions are ready.
          </SecurityCard.Badge>
        )}
      </DialogContent>
      <DialogActions key='actions'>
        {!isScanApplication(application) && (
          <Button variant='outlined' onClick={handleEditApplication}>
            {isSecurityAgency(agent) ? 'Edit Assessment' : 'Edit Application'}
          </Button>
        )}
        {!isScanApplication(application) && (
          <Button variant='outlined' onClick={handleCopyLink}>
            Copy Link
          </Button>
        )}
        <Button color='primary' variant='contained' onClick={handleDoneApplication} className={classes.right}>
          Done
        </Button>
      </DialogActions>
    </>
  );
};
