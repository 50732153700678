import isEmpty from 'lodash/isEmpty';

export const isObjectEmpty = (obj: any): boolean => {
  if (isEmpty(obj)) return true;

  if (obj instanceof Object) {
    return Object.values(obj)
      .map((value) => {
        if (isEmpty(value)) return true;
        if (value instanceof Object) return isObjectEmpty(value);
        return false;
      })
      .every((val) => val);
  }

  return false;
};

export const isStringJSON = (text?: string | null) => {
  if (!text || typeof text !== 'string') {
    return false;
  }

  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
};
