import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { COLORS } from 'telivy-theme';

interface StyleProps {
  gap?: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    background: COLORS.WHITE,
    // minWidth: 'fit-content',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',

    ['@media print']: {
      pageBreakInside: 'avoid',
      display: 'block',
      marginBottom: theme.spacing(2.5),
    },
  },
}));

interface Props extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const SecurityCardContainer = ({ children, gap, className }: Props) => {
  const classes = useStyles({ gap });

  return <div className={cx(classes.root, className)}>{children}</div>;
};
