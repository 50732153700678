import { makeStyles } from '@material-ui/core/styles';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Table } from 'components/Table';
import { ApplicationRawDnbDTO, ApplicationWithAgentDTO, ParsedDnbDunsData } from 'dtos/application';
import { startCase } from 'lodash';
import { useApplicationDnbResults } from 'queries/useApplications';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {},
  toolsWrapper: {},
}));

interface TableRow {
  field: string;
  value?: string;
}

const columns: Column<TableRow, keyof TableRow>[] = [
  {
    title: 'Field',
    width: '40%',
    render: (record) => record?.field || '-',
  },
  {
    title: 'Value',
    width: '60%',
    render: (record) => record?.value || '-',
  },
];

const tableData = (dnbData: ApplicationRawDnbDTO): TableRow[] => {
  const rows: TableRow[] = [];

  if (dnbData && dnbData?.parsedData) {
    const dnbMatch: ParsedDnbDunsData = dnbData.parsedData;
    const fields: (keyof ParsedDnbDunsData)[] = [
      'companyName',
      'street',
      'city',
      'state',
      'zipCode',
      'yearEstablished',
      'employeeCount',
      'annualRevenue',
      'primaryOperations',
      'primaryOperationsLong',
    ];
    for (const key of fields) {
      rows.push({
        field: startCase(key),
        value: dnbMatch[key] as string,
      });
    }
  }

  return rows;
};

interface Props {
  application: ApplicationWithAgentDTO;
  applicationId: string;
}

export const SecurityCompanyInfo = ({ applicationId }: Props) => {
  const classes = useStyles();
  const { data, isLoading } = useApplicationDnbResults(applicationId);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      {data ? (
        <>
          <h1>Company Information</h1>
          <div className={classes.toolsWrapper}>
            <Table<TableRow> columns={columns} data={tableData(data)} rowKey={(el) => el.field} semiBordered />
          </div>
        </>
      ) : (
        <EmptyState
          title='Company Information not found'
          text="Looks like we couldn't find any information for this company."
          icon={<PageviewRoundedIcon />}
        />
      )}
    </div>
  );
};
