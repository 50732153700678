import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { AxiosError } from 'axios';
import cx from 'classnames';
import { DollarInput, Props as DollarInputProps } from 'components/DollarInput';
import { FilePicker } from 'components/FilePicker';
import { RadioSelect } from 'components/RadioSelect';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { CarrierProduct, CyberQuoteSublimits, QuoteDTO, QuoteLetterCheckResultDTO, QuoteStatus } from 'dtos/quotes';
import { useToasters } from 'hooks/useToasters';
import { chunk, startCase } from 'lodash';
import { useCarriers } from 'queries/useCarriers';
import { useCheckQuoteLetter, useCreateManualQuote, useUpdateQuote } from 'queries/useQuote';
import React, { useCallback, useState } from 'react';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { QUOTE_SUBLIMITS_MAP } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const useStyles = makeStyles((theme) => ({
  footer: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },

  actions: {
    marginLeft: 'auto',
  },
  button: {
    '& + $button': {
      marginLeft: theme.spacing(1),
    },
  },
  input: {
    flex: 1,
    marginTop: 0,
    '& .MuiInputBase-root': {
      borderRadius: 0,
    },
  },
  row: {
    display: 'flex',

    '& + $row': {
      marginTop: -16,

      '& .MuiInputBase-root': {
        borderTop: 0,
      },
    },

    // Select the first row...
    '&:nth-child(2)': {
      '& $input': {
        '&:first-of-type': {
          '& .MuiInputBase-root': {
            borderTopLeftRadius: theme.spacing(1),
          },
        },

        '&:last-of-type': {
          '& .MuiInputBase-root': {
            borderTopRightRadius: theme.spacing(1),
          },
        },
      },
    },

    '&:last-of-type': {
      '& $input': {
        '&:first-of-type': {
          '& .MuiInputBase-root': {
            borderBottomLeftRadius: theme.spacing(1),
          },
        },

        '&:last-of-type': {
          '& .MuiInputBase-root': {
            borderBottomRightRadius: theme.spacing(1),
          },
        },
      },
    },
  },
  inputNoBorderLeft: {
    '& .MuiInputBase-root': {
      borderLeft: 0,
    },
  },
  inputNoBorderRight: {
    '& .MuiInputBase-root': {
      borderRight: 0,
    },
  },
  label: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.GREY_2,
    marginBottom: theme.spacing(1),
  },

  section: {
    marginBottom: theme.spacing(3),
  },
}));

interface QuoteForm {
  carrierId: string;
  aggregateLimit: number;
  retention: number;
  premium: number;
  annualPolicyCost: number;
  surplusLinesCost?: string | null;
  effectiveDate: string;
  expirationDate: string;
  quoteNumber: string;
  quoteStatus: QuoteStatus;
  carrierProduct: CarrierProduct;
  sublimits: CyberQuoteSublimits;
}

interface Form {
  quotes: QuoteForm[];
}

interface Props {
  uploadFlow?: boolean;
  application: ApplicationDTO;
  existingQuote?: QuoteDTO;
  onCloseRequest: () => void;
  onQuoteCreation: (quote: QuoteDTO[], quoteLetter?: File | null) => void;
}

const DEFAULT_COVERAGES: CyberQuoteSublimits = {
  networkAndInformationSecurityLiability: 0,
  regulatoryDefenseAndPenalties: 0,
  multimediaContentLiability: 0,
  pciFinesAndAssessments: 0,
  breachResponseCostsAndServices: 0,
  forensicAndLegalExpenses: 0,
  crisisManagementAndPublicRelations: 0,
  reputationalHarmLoss: 0,
  creditIdentityMonitoringServices: 0,
  cyberExtortionExpensesAndFees: 0,
  ransomPayments: 0,
  businessInterruptionContingent3rdPartyComputerSystems: 0,
  businessInterruptionDirectSystemsBreach: 0,
  businessInterruptionDirectSystemFailure: 0,
  digitalAssetRestoration: 0,
  fundsTransferFraudSystemsBreach: 0,
  fundsTransferFraudSocialEngineering: 0,
  reverseSocialEngineeringInvoiceManipulation: 0,
  hardwareReplacementCostsBricking: 0,
  computerSystemsInstallationServicesCosts: 0,
  digitalServicesFraudCryptojacking: 0,
  utilitiesExpenseDueToCryptojacking: 0,
};

export const mapQuoteToDefaultValues = (quote?: QuoteDTO): QuoteForm => ({
  carrierId: quote ? quote.carrier.id : '',
  quoteNumber: quote ? quote.quoteNumber : '',
  carrierProduct: quote ? quote.carrierProduct : CarrierProduct.ADMITTED,
  aggregateLimit: quote ? quote.aggregateLimit : 0,
  retention: quote ? quote.retention : 0,
  premium: quote ? quote.premium : 0,
  annualPolicyCost: quote ? quote.annualPolicyCost : 0,
  surplusLinesCost: quote ? quote.surplusLinesCost : null,
  effectiveDate: quote ? quote.effectiveDate : dayjs().add(1, 'day').format('YYYY-MM-DD'),
  expirationDate: quote ? quote.expirationDate : dayjs().add(1, 'day').add(1, 'year').format('YYYY-MM-DD'),
  quoteStatus: quote ? quote.quoteStatus : QuoteStatus.ACTIVE,
  sublimits: quote && quote.sublimits ? quote.sublimits : DEFAULT_COVERAGES,
});

export const QuoteForm: React.FC<Props> = ({
  application,
  onQuoteCreation,
  onCloseRequest,
  existingQuote,
  uploadFlow,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedQuoteIdx, setSelectedQuoteIdx] = useState(0);
  const { showToaster, toasterErrorHandler } = useToasters();
  const { data: carriers, isLoading: isLoadingCarriers } = useCarriers();
  const options = {
    onSuccess: (quote: QuoteDTO[]) => {
      onQuoteCreation(quote, selectedFile);
      showToaster('Quote created');
    },
    onError: (e: AxiosError | unknown) => toasterErrorHandler(e),
  };

  const updateQuoteMutation = useUpdateQuote(options);
  const createQuoteMutation = useCreateManualQuote(application.id, options);
  const isLoading = updateQuoteMutation.isLoading || createQuoteMutation.isLoading;

  const { handleSubmit, control, setValue, watch, reset } = useForm<Form>({
    defaultValues: {
      quotes: [mapQuoteToDefaultValues(existingQuote)],
    },
  });

  const {
    mutate: checkQuoteLetter,
    isLoading: isUploading,
    data: uploadedQuoteLetterQuotes,
    reset: resetUploadedQuoteLetterQuotes,
  } = useCheckQuoteLetter({
    onSuccess: (data: QuoteLetterCheckResultDTO[]) => {
      setSelectedQuoteIdx(0);
      setValue('quotes', []);

      for (let idx = 0; idx < data.length; idx++) {
        setValue(`quotes.${idx}.quoteStatus`, QuoteStatus.ACTIVE);
        setValue(`quotes.${idx}.carrierId`, data[idx].carrierId);
        setValue(`quotes.${idx}.quoteNumber`, data[idx].quoteNumber || '');
        setValue(`quotes.${idx}.carrierProduct`, data[idx].product || CarrierProduct.ADMITTED);
        setValue(`quotes.${idx}.aggregateLimit`, data[idx].limit || 0);
        setValue(`quotes.${idx}.retention`, data[idx].retention || 0);
        setValue(`quotes.${idx}.premium`, data[idx].premium || 0);
        setValue(`quotes.${idx}.sublimits`, data[idx].sublimits || {});
        setValue(`quotes.${idx}.annualPolicyCost`, data[idx].annualPremium || data[idx].premium || 0);
        setValue(`quotes.${idx}.effectiveDate`, data[idx].effectiveDate || dayjs().add(1, 'day').format('YYYY-MM-DD'));
        setValue(
          `quotes.${idx}.expirationDate`,
          data[idx].expirationDate || dayjs().add(1, 'day').add(1, 'year').format('YYYY-MM-DD'),
        );
      }
    },
    onError: (error: AxiosError | unknown) => {
      setSelectedFile(null);
      resetUploadedQuoteLetterQuotes();

      toasterErrorHandler(error);
    },
  });

  const handleFileChange = useCallback(
    (file: File) => {
      setSelectedFile(file);
      checkQuoteLetter(file);
    },
    [checkQuoteLetter],
  );

  const handleCreateQuote = useCallback(
    (data: Form) => {
      if (existingQuote) {
        updateQuoteMutation.mutate(
          data.quotes.map((quote) => ({
            ...quote,
            id: existingQuote.id,
            effectiveDate: dayjs(quote.effectiveDate).format('YYYY-MM-DD'),
            expirationDate: dayjs(quote.expirationDate).format('YYYY-MM-DD'),
          })),
        );
      } else {
        createQuoteMutation.mutate(
          data.quotes.map((quote) => ({
            ...quote,
            effectiveDate: dayjs(quote.effectiveDate).format('YYYY-MM-DD'),
            expirationDate: dayjs(quote.expirationDate).format('YYYY-MM-DD'),
            quoteLetter: selectedFile || undefined,
          })),
        );
      }
    },
    [existingQuote, updateQuoteMutation, createQuoteMutation, selectedFile],
  );

  const renderInput = useCallback(
    (name: FieldPath<Form>, label: string, type: string, overrides?: Partial<TextFieldProps>) => (
      <Controller
        name={name}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <TextField {...field} label={label} type={type} required className={classes.input} {...overrides} />
        )}
      />
    ),
    [control, classes.input],
  );

  const renderDollarInput = useCallback(
    (name: FieldPath<Form>, label: string, overrides?: Partial<DollarInputProps>) => (
      <Controller
        name={name}
        key={name}
        control={control}
        defaultValue=''
        render={({ field }) => (
          <DollarInput
            required
            value={parseFloat(field.value as string)}
            onChange={field.onChange}
            label={label}
            className={classes.input}
            {...overrides}
          />
        )}
      />
    ),
    [control, classes.input],
  );

  const shouldShowSections = !uploadFlow || (uploadFlow && uploadedQuoteLetterQuotes);
  const quotes = watch('quotes');

  return (
    <>
      {uploadFlow && (
        <section className={classes.section}>
          <div className={classes.label}>Upload Quote Letter</div>
          <FilePicker
            inputProps={{
              accept: 'application/pdf',
            }}
            onReset={() => {
              resetUploadedQuoteLetterQuotes();
              setSelectedFile(null);
              setSelectedQuoteIdx(0);
              reset();
            }}
            isLoading={isUploading}
            onChange={handleFileChange}
            label='Upload Quote Letter *'
            selectedFile={selectedFile}
          />
        </section>
      )}

      {quotes.length > 1 && (
        <section className={classes.section}>
          <div className={classes.label}>Quotes</div>
          <div className={classes.row}>
            <RadioSelect
              options={quotes.map((q, idx) => ({ label: `Quote ${idx + 1}`, value: idx }))}
              selectedValue={selectedQuoteIdx}
              onOptionSelect={(opt) => {
                // FIXME: this is a hack to force the form to re-render
                setLoading(true);
                sleep(0).then(() => {
                  setSelectedQuoteIdx(opt.value);
                  setLoading(false);
                });
              }}
            />
          </div>
        </section>
      )}

      {shouldShowSections && !loading && (
        <>
          <section className={classes.section}>
            <div className={classes.label}>Carrier Information</div>
            <div className={classes.row}>
              <FormControl className={classes.input}>
                <InputLabel id='carrier-select' required>
                  Carrier
                </InputLabel>
                <Select
                  disabled={isLoadingCarriers}
                  labelId='carrier-select'
                  value={quotes[selectedQuoteIdx].carrierId}
                  onChange={(el) => setValue(`quotes.${selectedQuoteIdx}.carrierId`, el.target.value as string)}
                >
                  {carriers?.map((carrier) => (
                    <MenuItem key={carrier.id} value={carrier.id}>
                      {carrier.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {renderInput(`quotes.${selectedQuoteIdx}.quoteNumber`, 'Quote Number', 'text', {
                className: cx(classes.input, classes.inputNoBorderLeft, classes.inputNoBorderRight),
              })}
              <FormControl className={classes.input}>
                <InputLabel id='carrier-select' required>
                  Product
                </InputLabel>
                <Select
                  disabled={isLoadingCarriers}
                  labelId='carrier-select'
                  value={quotes[selectedQuoteIdx].carrierProduct}
                  onChange={(el) =>
                    setValue(`quotes.${selectedQuoteIdx}.carrierProduct`, el.target.value as CarrierProduct)
                  }
                >
                  {Object.values(CarrierProduct)?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {startCase(type)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.label}>Details</div>
            <div className={classes.row}>
              {renderDollarInput(`quotes.${selectedQuoteIdx}.aggregateLimit`, 'Limit')}
              {renderDollarInput(`quotes.${selectedQuoteIdx}.retention`, 'Retention', {
                className: cx(classes.input, classes.inputNoBorderLeft),
              })}
              {renderDollarInput(`quotes.${selectedQuoteIdx}.premium`, 'Premium', {
                className: cx(classes.input, classes.inputNoBorderLeft),
              })}
            </div>
            <div className={classes.row}>
              {renderInput(`quotes.${selectedQuoteIdx}.surplusLinesCost`, 'Surplus Lines Tax & Fees', 'text', {
                required: quotes[selectedQuoteIdx].carrierProduct === CarrierProduct.SURPLUS,
              })}
              {renderDollarInput(`quotes.${selectedQuoteIdx}.annualPolicyCost`, 'Annual Policy Cost', {
                className: cx(classes.input, classes.inputNoBorderLeft),
              })}
            </div>
            <div className={cx(classes.row)}>
              {renderInput(`quotes.${selectedQuoteIdx}.effectiveDate`, 'Effective Date', 'date')}
              {renderInput(`quotes.${selectedQuoteIdx}.expirationDate`, 'Expiration Date', 'date', {
                className: cx(classes.input, classes.inputNoBorderLeft),
              })}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.label}>Sublimits</div>
            {chunk(
              Object.keys(QUOTE_SUBLIMITS_MAP).map((covKey, idx) => {
                const title = QUOTE_SUBLIMITS_MAP[covKey as keyof typeof QUOTE_SUBLIMITS_MAP]?.title || 'Unknown';
                const key = `quotes.${selectedQuoteIdx}.sublimits.${covKey}`;

                // Work smart not hard – it automatically renders the right input based on the key
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return renderDollarInput(key, title, {
                  required: false,
                  ...(idx % 2 === 1 && { className: cx(classes.input, classes.inputNoBorderLeft) }),
                });
              }),
              2,
            ).map((el, idx) => (
              <div className={classes.row} key={idx}>
                {el}
              </div>
            ))}
          </section>
        </>
      )}

      <footer className={classes.footer}>
        <div className={classes.actions}>
          <Button onClick={onCloseRequest} className={classes.button} disabled={isLoading}>
            Close
          </Button>
          {shouldShowSections && (
            <Button
              onClick={handleSubmit(handleCreateQuote)}
              color='primary'
              variant='contained'
              disabled={isLoading}
              className={classes.button}
            >
              {isLoading ? 'Loading...' : existingQuote ? 'Update Quote' : 'Create Quote'}
            </Button>
          )}
        </div>
      </footer>
    </>
  );
};
