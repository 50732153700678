import { DomainTechStackDTO } from 'dtos/domain-tech-stack';

import RequestService from './Request';

class _DomainTechStackService {
  getDomainTechStack(domain: string, force?: boolean) {
    return RequestService.client
      .get<DomainTechStackDTO>(`/domain-tech-stack/tech-stack`, {
        params: {
          domain,
          force,
        },
      })
      .then((response) => response.data);
  }
}

const DomainTechStackService = new _DomainTechStackService();

export { DomainTechStackService };
