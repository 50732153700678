import { makeStyles } from '@material-ui/core';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { CompanyDTO } from 'dtos/company';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan } from 'queries/useDeepScan';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';
import { isNodewareEnabled } from 'telivy-selectors';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
// import { ConfigurePhishingSection } from './ConfigurePhishingSection';
import { ConfigureSoftwares } from './ConfigureSoftwares';
// import { ConnectNodeware } from './ConnectNodeware';
import { InstallAgentsSection } from './InstallAgentsSection';
// import { InstallSaasAlertsSection } from './InstallSaasAlerts';
import { ApplicationSetupOptionalView } from './optional';
// import { ScanBrowserPasswordsSection } from './ScanBrowserPasswordsSection';
// import { UninstallAgentsSection } from './UninstallAgentsSection';
// import { UploadEmployeeListSection } from './UploadEmployeeListSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  targets: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    DEVICES: string;
  };
  company: {
    PARENT: string;
    ROOT: string;
    DETAILS: string;
    FORM: string;
  };
  setup: {
    PARENT: string;
    ROOT: string;
    DEPLOY: string;
    CLOUD: string;
    optional: {
      PARENT: string;
      ROOT: string;
      EMAIL_LIST: string;
      NODEWARE: string;
      REMOVE: string;
    };
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
  company?: CompanyDTO;
  setSecurityOverallScore: (score: number | null) => void;
}

export const ApplicationConfigurationView = ({ application, sourceRoute, company, setSecurityOverallScore }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: agent } = useCurrentAgent();

  const deepScanId = application.deepScanId || '';
  const { data: applicationData } = useApplicationSecurityScan(application.id, 'latest');
  const { data } = useDeepScan(deepScanId, {
    enabled: Boolean(deepScanId),
    retry: 1,
  });

  useEffect(() => {
    if (applicationData) {
      setSecurityOverallScore(((applicationData.securityScore || 0) * 100) / 5);
    }
  }, [applicationData, setSecurityOverallScore]);

  const viewOptions: Option<ValueOf<Omit<SourceRoute['setup'], 'optional'>>>[] = React.useMemo(() => {
    const options = [];

    options.push({
      label: `1. Deploy Telivy`,
      value: createSecurityRoute(sourceRoute.setup.DEPLOY, application.id),
    });

    options.push({
      label: `2. Connect Cloud`,
      value: createSecurityRoute(sourceRoute.setup.CLOUD, application.id),
    });

    options.push({
      label: `3. Optional Steps`,
      value: createSecurityRoute(sourceRoute.setup.optional.ROOT, application.id),
    });

    return options;
  }, [sourceRoute, application]);

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Setup - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<Omit<SourceRoute['setup'], 'optional'>>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.setup.DEPLOY}
          element={data && <InstallAgentsSection deepScan={data} application={application} company={company} />}
        />

        <Route
          path={sourceRoute.setup.CLOUD}
          element={data && <ConfigureSoftwares application={application} deepScan={data} />}
        />

        <Route
          path={sourceRoute.setup.optional.PARENT}
          element={
            <ApplicationSetupOptionalView
              application={application}
              deepScan={data}
              sourceRoute={sourceRoute.setup}
              isNodewareEnabled={isNodewareEnabled(agent)}
            />
          }
        />

        <Route index element={<Navigate replace to={sourceRoute.setup.DEPLOY} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
