import { SecurityScanDTO, SecurityScanStatus } from 'dtos/application';
import { SecurityFindingBySlugDTO, SecurityScanType, SecuritySeverity } from 'dtos/security-finding';
import { useSecurityFindingsBySlug } from 'queries/useSecurityFinding';
import { useMemo } from 'react';
import { generateRefetchIntervalMs } from 'telivy-constants';

import { useExternalScan } from './useExternalScan';

interface Options {
  skipInfo?: boolean;
  skipEmpty?: boolean;
}

interface Props {
  securityScan: SecurityScanDTO;
  options?: Options;
  forceShouldPullFindings?: boolean;
}

export const useExternalScanFindings = ({ securityScan, options, forceShouldPullFindings }: Props) => {
  const {
    isLoading,
    shouldPullFindings,
    allSubdomains,
    allSubdomainsIPAddresses,
    allDomains,
    subDomainIPAddressScans,
    subDomainScans,
    scannedSubdomainsSocialEngineering,
    scannedSubdomainsEndpointSecurity,
    scannedSubdomainsIpReputation,
    scannedSubdomainsNetwork,
    scannedSubdomainsApplicationSecurity,
    scannedSubdomainsDnsHealth,
    scannedSubdomainsPatchingCadence,
  } = useExternalScan({
    securityScan,
  });

  const shouldPull = forceShouldPullFindings || shouldPullFindings;
  const { data: findings } = useSecurityFindingsBySlug(
    {
      scanId: securityScan.id,
    },
    {
      refetchInterval: shouldPull ? generateRefetchIntervalMs() : false,
    },
  );

  const isLoadingScanEmail = securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.emailScannedAt;
  const isErrorScanEmail = securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.emailScannedAt;

  const isLoadingPatchingCadence =
    securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.patchingCadenceScannedAt;
  const isErrorPatchingCadence =
    securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.patchingCadenceScannedAt;

  const isLoadingScanDns = securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.dnsScannedAt;
  const isErrorScanDns = securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.dnsScannedAt;

  const isLoadingScanNetwork = securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.networkScannedAt;
  const isErrorScanNetwork = securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.networkScannedAt;

  const isLoadingScanIpReputation =
    securityScan.status != SecurityScanStatus.COMPLETED && !securityScan.ipReputationScannedAt;
  const isErrorScanIpReputation =
    securityScan.status == SecurityScanStatus.COMPLETED && !securityScan.ipReputationScannedAt;

  const findingsByType = useMemo(
    () =>
      (findings || []).reduce<Record<SecurityScanType, SecurityFindingBySlugDTO[]>>(
        (acc, finding) => {
          // Skip info
          if (options?.skipInfo && finding?.severity === SecuritySeverity.INFO) {
            return acc;
          }

          // Skip empty
          if (options?.skipEmpty && finding?.count === 0) {
            return acc;
          }

          if (!finding.scanType || !acc[finding.scanType]) {
            return acc;
          }

          acc[finding.scanType].push(finding);

          return acc;
        },
        {
          [SecurityScanType.SOCIAL_ENGINEERING]: [],
          [SecurityScanType.HACKER_CHATTER]: [],
          [SecurityScanType.NETWORK_SECURITY]: [],
          [SecurityScanType.ENDPOINT_SECURITY]: [],
          [SecurityScanType.APPLICATION_SECURITY]: [],
          [SecurityScanType.DNS_HEALTH]: [],
          [SecurityScanType.IP_REPUTATION]: [],
          [SecurityScanType.PATCHING_CADENCE]: [],
          [SecurityScanType.INSURABILITY]: [],
        },
      ),
    [findings, options?.skipEmpty, options?.skipInfo],
  );

  return {
    findings,
    findingsByType,
    isErrorPatchingCadence,
    isErrorScanDns,
    isErrorScanEmail,
    isErrorScanIpReputation,
    isErrorScanNetwork,
    isLoadingPatchingCadence,
    isLoadingScanDns,
    isLoadingScanEmail,
    isLoadingScanIpReputation,
    isLoadingScanNetwork,
    isLoading,

    scannedSubdomainsSocialEngineering,
    scannedSubdomainsEndpointSecurity,
    scannedSubdomainsIpReputation,
    scannedSubdomainsNetwork,
    scannedSubdomainsApplicationSecurity,
    scannedSubdomainsDnsHealth,
    scannedSubdomainsPatchingCadence,

    // external scan info
    allSubdomains,
    allSubdomainsIPAddresses,
    allDomains,
    subDomainIPAddressScans,
    subDomainScans,
  };
};
