import { AxiosResponse } from 'axios';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import {
  CreateDeclinationDocumentDTO,
  DeclinationDocumentDTO,
  DeclinationDocumentType,
  DeclinationDTO,
  UpdateManualDeclinationDTO,
} from 'dtos/declinations';
import fileDownload from 'js-file-download';

import RequestService from './Request';

class _DeclinationService {
  async createDeclinationDocument(declinationId: string, data: CreateDeclinationDocumentDTO) {
    return await RequestService.client
      .post<DeclinationDocumentDTO>(`/declinations/${declinationId}/documents`, data)
      .then((response) => response.data);
  }

  async updateDeclination(declinationId: string, data: UpdateManualDeclinationDTO): Promise<DeclinationDTO> {
    return await RequestService.client
      .put<UpdateManualDeclinationDTO, AxiosResponse<DeclinationDTO>>(`/declinations/${declinationId}`, data)
      .then((response) => response.data);
  }

  async deleteDeclinationDocument(declinationId: string, documentId: string) {
    return await RequestService.client
      .delete<void>(`/declinations/${declinationId}/documents/${documentId}`)
      .then((response) => response.data);
  }

  async deleteDeclination(declinationId: string) {
    return await RequestService.client.delete<void>(`/declinations/${declinationId}`).then((response) => response.data);
  }

  async uploadDeclinationDocument(declinationId: string, type: DeclinationDocumentType, file: File) {
    const data = await RequestService.client
      .post<GeneratedUploadUrlResponseDTO>(`/declinations/${declinationId}/upload-document`, { fileType: type })
      .then((response) => response.data);

    await RequestService.uploadFile(file, data);

    return data;
  }

  async downloadDeclinationDocument(declinationId: string, declinationDocument: DeclinationDocumentDTO) {
    const signedDownloadUrl = await RequestService.client
      .get<string>(`/declinations/${declinationId}/documents/${declinationDocument.id}`)
      .then((response) => response.data);

    const response = await RequestService.client.get<Blob>(signedDownloadUrl, {
      responseType: 'blob',
    });

    fileDownload(response.data, declinationDocument.fileName);
  }

  async getDeclinationDocuments(applicationId: string, declinationId: string) {
    return await RequestService.client
      .get<DeclinationDocumentDTO[]>(`/declinations/${declinationId}/documents`)
      .then((response) => response.data);
  }
}

const DeclinationService = new _DeclinationService();

export { DeclinationService };
