import { useCurrentAgent } from '../queries/useAgent';

export const useExternalScanDisabled = () => {
  const { data } = useCurrentAgent();
  // console.log('🦑', data?.agency?.disableExternalScan);

  return data?.agency?.disableExternalScan || !data?.agency?.enableLeadMagnet;
};

export const useRiskAssessmentDisabled = () => {
  const { data } = useCurrentAgent();
  // console.log('🦑', data?.agency?.disableRiskAssessment);

  return data?.agency?.disableRiskAssessment;
};
