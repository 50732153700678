import { SecurityScanDTO } from 'dtos/application';
import { SecurityFindingBySlugDTO, SecurityScanType } from 'dtos/security-finding';
import { useSecurityFindingsBySlug } from 'queries/useSecurityFinding';
import { useMemo } from 'react';
import { generateRefetchIntervalMs } from 'telivy-constants';

import { useExternalScan } from './useExternalScan';

interface Props {
  securityScan: SecurityScanDTO;
}

export const useExternalScanPreviousFindings = ({ securityScan }: Props) => {
  const { isLoading, shouldPullFindings } = useExternalScan({
    securityScan,
  });

  const { data: previousFinding } = useSecurityFindingsBySlug(
    {
      scanId: securityScan.previousScan?.id || '',
    },
    {
      enabled: !!securityScan.previousScan,
      refetchInterval: shouldPullFindings ? generateRefetchIntervalMs() : false,
    },
  );

  const previousFindingsByType = useMemo(
    () =>
      (previousFinding || []).reduce<Record<SecurityScanType, SecurityFindingBySlugDTO[]>>(
        (acc, finding) => {
          if (!finding.scanType || !acc[finding.scanType]) {
            return acc;
          }

          acc[finding.scanType].push(finding);

          return acc;
        },
        {
          [SecurityScanType.SOCIAL_ENGINEERING]: [],
          [SecurityScanType.HACKER_CHATTER]: [],
          [SecurityScanType.NETWORK_SECURITY]: [],
          [SecurityScanType.ENDPOINT_SECURITY]: [],
          [SecurityScanType.APPLICATION_SECURITY]: [],
          [SecurityScanType.DNS_HEALTH]: [],
          [SecurityScanType.IP_REPUTATION]: [],
          [SecurityScanType.PATCHING_CADENCE]: [],
          [SecurityScanType.INSURABILITY]: [],
        },
      ),
    [previousFinding],
  );

  return {
    previousFinding,
    previousFindingsByType,
    isLoading,
  };
};
