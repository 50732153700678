import { AxiosError } from 'axios';
import { GeneratedUploadUrlResponseDTO, UpdateDocumentDTO } from 'dtos/_common';
import {
  CreatePolicyDocumentDTO,
  CreatePolicyDTO,
  PolicyDocumentDTO,
  PolicyDocumentType,
  PolicyDTO,
  UpdatePolicyDTO,
} from 'dtos/policy';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { PolicyService } from 'services/Policy';

export const useUploadPolicyDocument = (
  policyId: string,
  options?: UseMutationOptions<GeneratedUploadUrlResponseDTO, AxiosError, { type: PolicyDocumentType; file: File }>,
) =>
  useMutation(
    'policy-document-upload',
    (data: { type: PolicyDocumentType; file: File }) =>
      PolicyService.uploadPolicyDocument(policyId, data.type, data.file),
    options,
  );

export const usePolicyDocuments = (policyId: string, options?: UseQueryOptions<PolicyDocumentDTO[]>) =>
  useQuery<PolicyDocumentDTO[]>(
    ['policy-documents', policyId],
    () => PolicyService.getPolicyDocuments(policyId),
    options,
  );

export const usePolicyDocumentsToSign = (policyId: string, options?: UseQueryOptions<PolicyDocumentDTO[]>) =>
  useQuery<PolicyDocumentDTO[]>(
    ['policy-documents-to-sign', policyId],
    () => PolicyService.getPolicyDocumentsToSign(policyId),
    options,
  );

export const useDownloadPolicyDocument = (
  policyId: string,
  options?: UseMutationOptions<void, AxiosError, PolicyDocumentDTO>,
) =>
  useMutation(
    'policy-document-download',
    (document: PolicyDocumentDTO) => PolicyService.downloadPolicyDocument(policyId, document),
    options,
  );

export const useDownloadCombinedSignedPolicyDocuments = (
  policyId: string,
  options?: UseMutationOptions<void, AxiosError, PolicyDTO>,
) =>
  useMutation(
    'signed-policy-documents',
    (policy: PolicyDTO) => PolicyService.downloadCombinedSignedPolicyDocuments(policyId, policy),
    options,
  );

export const useCreatePolicyDocument = (
  policyId: string,
  options?: UseMutationOptions<PolicyDocumentDTO, AxiosError, CreatePolicyDocumentDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'create-policy-document',
    (data: CreatePolicyDocumentDTO) => PolicyService.createPolicyDocument(policyId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['policy-documents', policyId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const usePolicyApiBind = (policyId: string, options?: UseMutationOptions<void, AxiosError>) =>
  useMutation('api-bind-policy', () => PolicyService.apiBindQuote(policyId), {
    ...options,
  });

export const useUpdatePolicyDocument = (
  policyId: string,
  options?: UseMutationOptions<PolicyDocumentDTO, AxiosError, UpdateDocumentDTO & { documentId: string }>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'update-policy-document',
    ({ documentId, ...rest }: UpdateDocumentDTO & { documentId: string }) =>
      PolicyService.updatePolicyDocument(policyId, documentId, rest),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['policy-documents', policyId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useDeletePolicyDocument = (policyId: string, options?: UseMutationOptions<void, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation(
    'delete-policy-document',
    (documentId: string) => PolicyService.deletePolicyDocument(policyId, documentId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.refetchQueries(['policy-documents', policyId]);

        if (options && options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    },
  );
};

export const useCreatePolicy = (options?: UseMutationOptions<PolicyDTO, AxiosError, CreatePolicyDTO>) => {
  const queryClient = useQueryClient();

  return useMutation('create-policy', (data: CreatePolicyDTO) => PolicyService.createPolicy(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application', data.applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useCancelPolicy = (applicationId: string, options?: UseMutationOptions<PolicyDTO, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation('cancel-policy', (id: string) => PolicyService.cancelPolicy(id), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application-policies', applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useUpdatePolicy = (
  applicationId: string,
  policyId: string,
  options?: UseMutationOptions<PolicyDTO, AxiosError, UpdatePolicyDTO>,
) => {
  const queryClient = useQueryClient();

  return useMutation('update-policy', (data: UpdatePolicyDTO) => PolicyService.updatePolicy(policyId, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries(['application-policies', applicationId]);
      await queryClient.invalidateQueries(['application', applicationId]);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useSignPolicy = (policyId: string, options?: UseMutationOptions<void, AxiosError>) =>
  useMutation('sign-policy-document', () => PolicyService.getSigningUrl(policyId), options);

export const useCheckPolicyEnvelopeStatus = (options?: UseMutationOptions<PolicyDTO, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation((policyId: string) => PolicyService.checkPolicyEnvelopeStatus(policyId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries('active-application-id');
      await setTimeout(() => queryClient.refetchQueries('active-application-id'), 2000);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};

export const useBillingPolicy = (policyId: string, options?: UseMutationOptions<void, AxiosError>) =>
  useMutation('billing-policy-document', () => PolicyService.getBillingUrl(policyId), options);

export const useCheckBillingStatus = (options?: UseMutationOptions<PolicyDTO, AxiosError, string>) => {
  const queryClient = useQueryClient();

  return useMutation((policyId: string) => PolicyService.checkBillingStatus(policyId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries('active-application-id');
      await setTimeout(() => queryClient.refetchQueries('active-application-id'), 2000);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
  });
};
