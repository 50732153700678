import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import { useToasters } from 'hooks/useToasters';
import {
  useDeepScanGoogleWsSecurityData,
  useDeepScanM365SecurityData,
  useDeepScanRefetchGws,
  useDeepScanRefetchM365,
} from 'queries/useDeepScan';
import React, { useMemo } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { TargetStatus } from '../../assets/targets/TargetStatus';

const useStyles = makeStyles((theme) => ({
  root: {},

  infoBox: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    background: 'rgba(218, 216, 216, 0.1)',
    border: '1px solid #D0D0D0',
    borderRadius: theme.spacing(1),
  },

  statusBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'inline-flex',
    width: 'auto',
  },
  statusContent: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    gap: theme.spacing(0.5),
    fontWeight: 400,
    color: COLORS.BLACK,
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  actionButton: {},
}));

interface Props {
  deepScan: DeepScanDTO | undefined;
  application: ApplicationWithAgentDTO;
}

interface DeepScanIntegrationDTO {
  id: string;
  name: string;
  status: string;
  updatedAt?: Date;
  actions: React.ReactNode | null;
}

export const Cloud = ({ deepScan, application }: Props) => {
  const classes = useStyles();
  const { showToaster, toasterErrorHandler } = useToasters();

  const { data: updatedAt } = useDeepScanM365SecurityData<Date>(deepScan?.id || '', 'updatedAt', {
    enabled: Boolean(deepScan?.id || ''),
  });

  const { data: updatedAtGws } = useDeepScanGoogleWsSecurityData<Date>(deepScan?.id || '', 'updatedAt', {
    enabled: Boolean(deepScan?.id || ''),
  });

  const { mutate, isLoading } = useDeepScanRefetchM365(deepScan?.id || '', {
    onSuccess: () => {
      showToaster('Refetch Microsoft 365 scheduled successfully');
    },
    onError: (error) => {
      toasterErrorHandler(error, 'Failed to schedule refetch Microsoft 365');
    },
  });

  const { mutate: mutateGws, isLoading: isLoadingGws } = useDeepScanRefetchGws(deepScan?.id || '', {
    onSuccess: () => {
      showToaster('Refetch Google Workspace scheduled successfully');
    },
    onError: (error) => {
      toasterErrorHandler(error, 'Failed to schedule refetch Google Workspace');
    },
  });

  const columns = useMemo((): Column<DeepScanIntegrationDTO, keyof DeepScanIntegrationDTO>[] => {
    const columns: Column<DeepScanIntegrationDTO, keyof DeepScanIntegrationDTO>[] = [
      {
        title: 'Name',
        // sortKey: 'ipAddress',
        render: (row) => row.name,
      },
      {
        title: 'Status',
        // sortKey: 'ipAddress',
        render: (row) => (
          <TargetStatus status={row.status == 'Connected' ? 'install-success' : 'install-error'}>
            {row.status}
          </TargetStatus>
        ),
      },
      {
        title: 'Data Fetched At',
        render: (row) => row.updatedAt && dayjs(row.updatedAt).format('M/D/YYYY h:mm A'),
      },
      {
        title: 'Actions',
        render: (row) => row.actions,
      },
    ];

    return columns;
  }, []);

  const sortedIntegrations = useMemo(() => {
    const softwares = [
      {
        id: 'm365',
        name: 'Microsoft 365',
        status: deepScan?.m365Connected ? 'Connected' : 'Not Connected',
        updatedAt,
        actions: (
          <span>
            {deepScan?.m365Connected && (
              <Button
                variant='outlined'
                color='primary'
                className={classes.actionButton}
                onClick={() => mutate()}
                startIcon={<RefreshRoundedIcon />}
                disabled={isLoading}
              >
                Refetch Microsoft 365 Data
              </Button>
            )}
          </span>
        ),
      },
    ];

    if (application.agent.agency?.enableGoogleWorkspace || deepScan?.gwsConnected) {
      softwares.push({
        id: 'gws',
        name: 'Google Workspace',
        status: deepScan?.gwsConnected ? 'Connected' : 'Not Connected',
        updatedAt: updatedAtGws,
        actions: (
          <span>
            {deepScan?.gwsConnected && (
              <Button
                variant='outlined'
                color='primary'
                className={classes.actionButton}
                onClick={() => mutateGws()}
                startIcon={<RefreshRoundedIcon />}
                disabled={isLoadingGws}
              >
                Refetch Google Workspace Data
              </Button>
            )}
          </span>
        ),
      });
    }

    return softwares;
  }, [deepScan, isLoading, mutate, classes, updatedAt, isLoadingGws, mutateGws, updatedAtGws, application]);

  return (
    <Table<DeepScanIntegrationDTO, keyof DeepScanIntegrationDTO>
      columns={columns}
      rowContentCentered
      rowKey={(row) => row.id}
      data={sortedIntegrations}
      // sorter={sorter}
      // onChange={(pagination, sorter) => {
      //   setSorter(sorter);
      // }}
    />
  );
};
