import './index.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Auth0ProviderWithHistory } from 'components/Auth0ProviderWithHistory/Auth0ProviderWithHistory';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { GOOGLE_ANALYTICS_TAG_ID, SENTRY_DSN, SENTRY_ENVIRONMENT } from 'telivy-constants';

import App from './App';

ReactGA.initialize(GOOGLE_ANALYTICS_TAG_ID);

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    // 20% of the transactions will get recorded and sent
    tracesSampleRate: 0.2,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary showDialog>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
