import { makeStyles } from '@material-ui/core';
import { Modal } from 'components/Modal';
import { difference } from 'lodash';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  subdomainProgress: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: COLORS.GREY_1,
    marginLeft: 'auto',
    height: 'fit-content',
  },
  subdomainProgressLabel: {
    color: COLORS.GREY_2,
    textDecoration: 'underline',
  },
  modalInnerContent: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(50),
    },
  },
}));

interface Props {
  scannedSubdomains?: string[];
  allSubdomains: string[];
}

export const ScannedSubdomainsModal = ({ scannedSubdomains, allSubdomains }: Props) => {
  const classes = useStyles();

  return (
    <span
      role='button'
      tabIndex={0}
      className={classes.subdomainProgress}
      data-cy='security-subdomain-progress'
      onClick={(e) => e.stopPropagation()}
    >
      <Modal
        openButton={<span className={classes.subdomainProgressLabel}>Scanning targets:</span>}
        title={`Scanning targets: ${scannedSubdomains?.length || 0} of ${allSubdomains?.length || 0}`}
        padding={4}
        autoWidth
      >
        <div className={classes.modalInnerContent}>
          {scannedSubdomains?.map((subdomain) => (
            <p key={subdomain} style={{ margin: 0, fontWeight: 500 }}>
              {subdomain}
            </p>
          ))}
          {difference(allSubdomains, scannedSubdomains || [])?.map((subdomain) => (
            <p style={{ margin: 0, color: COLORS.GREY_4 }} key={subdomain}>
              {subdomain}
            </p>
          ))}
        </div>
      </Modal>
      {` ${scannedSubdomains?.length || 0} of ${allSubdomains?.length || 0}`}
    </span>
  );
};
