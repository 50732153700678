import { makeStyles } from '@material-ui/core/styles';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import moment from 'moment';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
  },
  statusBox: {
    width: 'auto',
  },
  statusContent: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    gap: theme.spacing(0.5),
    fontWeight: 400,
    color: COLORS.BLACK,
    alignItems: 'center',
  },
}));

interface Props {
  agent?: AgentDTO;
  className?: string;
}

export const TrialStatus = ({ agent, className }: Props) => {
  const classes = useStyles();

  const enableFeatures =
    agent?.agency?.enableCharts || agent?.agency?.enableLeadMagnet || agent?.agency?.enableRiskAssessmentsPlus;
  if (!enableFeatures) return null;

  const activatedAt = agent?.agency?.activatedAt;
  if (activatedAt) return null;

  const trialStartedAt = agent?.agency?.trialStartedAt;
  if (!trialStartedAt) return null;

  const trialDays = (agent?.agency?.trialDays || 100) - 1;
  const trialDone = moment().diff(moment(trialStartedAt), 'days');

  let message = '';
  if (trialDays - trialDone > 7) {
    message = `Trial set to expire on ${moment(trialStartedAt).add(trialDays, 'days').format('MMM Do YYYY')}`;
  } else if (trialDays - trialDone > 1) {
    message = `Trial set to expire in ${trialDays - trialDone} days`;
  } else if (trialDays - trialDone === 1) {
    message = `Trial set to expire in 1 day`;
  } else if (trialDays - trialDone === 0) {
    message = `Trial set to expire today`;
  } else if (trialDays - trialDone < 0) {
    message = `Trial expired`;
  }

  return (
    <div className={cx(classes.root, className)}>
      <div>
        <SecurityCard.Badge variant='warning' icon={<ScheduleRoundedIcon />} className={classes.statusBox} bordered>
          <div className={classes.statusContent}>{message}</div>
        </SecurityCard.Badge>
      </div>
    </div>
  );
};
