import { Checkbox } from '@material-ui/core';
import { CHART_OPTION } from 'components/charts/HistoryGrades';
import { InternalScanType as SecurityScanType } from 'dtos/security-finding';
import React from 'react';
import { LegendProps } from 'recharts';

interface CustomLegendProps extends LegendProps {
  toggleChart: (chart: SecurityScanType) => void;
  toggleAll: () => void;
  toggleState: boolean;
}

export const CustomGraphLegendContent = ({ payload, toggleChart, toggleAll, toggleState }: CustomLegendProps) => {
  // const securityOverallScore = payload?.find((item) => item?.value === SecurityScanType.OVERALL_SCORE);
  const items = payload || []; //?.filter((item) => item?.value !== SecurityScanType.OVERALL_SCORE);

  return (
    <div>
      {/*<div key={securityOverallScore?.value} style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <svg
          width={40}
          height={20}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: 8,
          }}
        >
          <line
            strokeWidth={(securityOverallScore as any)?.payload?.strokeWidth || 2}
            fill='none'
            stroke={securityOverallScore?.color}
            strokeDasharray={securityOverallScore?.payload?.strokeDasharray}
            x1='0'
            y1='12'
            x2='40'
            y2='12'
          />
        </svg>
        <span>{CHART_OPTION[SecurityScanType.OVERALL_SCORE]}</span>
      </div>*/}
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 24 }}>
        <span style={{ fontSize: 14, fontWeight: 700 }}>ALL FACTORS</span>{' '}
        <span
          style={{ fontSize: 13, fontWeight: 400, cursor: 'pointer' }}
          onClick={toggleAll}
          role='button'
          tabIndex={0}
        >
          {toggleState ? `Deselect All` : `Select All`}
        </span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: 16 }}>
        {items?.map((entry: any) => (
          <div key={entry.value} style={{ display: 'flex', alignItems: 'center', minWidth: 350 }}>
            <svg
              width={40}
              height={20}
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginRight: 8,
              }}
            >
              <line
                strokeWidth={entry.payload.strokeWidth}
                fill='none'
                stroke={entry.color}
                strokeDasharray={entry.payload.strokeDasharray}
                x1='0'
                y1='12'
                x2='40'
                y2='12'
              />
            </svg>
            <span>{CHART_OPTION[entry.value as keyof typeof CHART_OPTION]}</span>
            <Checkbox
              color='primary'
              checked={!entry.inactive}
              onChange={() => toggleChart(entry.value)}
              style={{ marginLeft: 'auto', padding: 0, fontSize: 12 }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
