import { ADMIN_ACCESS_TOKEN_KEY, AUTH0_ACCESS_TOKEN_KEY } from 'telivy-constants';

export interface IAuth0AuthorizationData {
  accessToken: string;
}

export const isAuthenticatedInStorage = (): boolean => {
  const accessToken = localStorage.getItem(AUTH0_ACCESS_TOKEN_KEY);
  const adminToken = localStorage.getItem(ADMIN_ACCESS_TOKEN_KEY);

  return Boolean(accessToken || adminToken);
};

export const isAuthenticatedUsingAdmin = (): boolean => {
  const adminToken = localStorage.getItem(ADMIN_ACCESS_TOKEN_KEY);

  return Boolean(adminToken);
};

export const setAuthTokens = (data: IAuth0AuthorizationData) => {
  localStorage.setItem(AUTH0_ACCESS_TOKEN_KEY, data.accessToken);
};

export const setAdminAuthToken = (secret: string, sub: string) => {
  localStorage.setItem(ADMIN_ACCESS_TOKEN_KEY, `${secret} ${sub}`);
};

export const clearAuthTokens = (clearAdmin?: boolean) => {
  localStorage.removeItem(AUTH0_ACCESS_TOKEN_KEY);

  if (clearAdmin) {
    localStorage.removeItem(ADMIN_ACCESS_TOKEN_KEY);
  }
};

export const loadAuthTokens = () => {
  const accessToken = localStorage.getItem(AUTH0_ACCESS_TOKEN_KEY);
  const adminToken = localStorage.getItem(ADMIN_ACCESS_TOKEN_KEY);

  return {
    accessToken,
    adminToken,
  };
};
