import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { NmapVulnerabilityData } from 'dtos/deep-scan';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  title: {
    ...TYPOGRAPHY.TITLE_3,
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },

  paragraph: {
    maxWidth: 1000,
  },
}));

interface Props {
  data?: NmapVulnerabilityData;
  isOpen: boolean;
  handleClose: () => void;
}

export const NmapVulnerabilityDetailsModal: React.FC<Props> = ({ data, isOpen, handleClose }) => {
  const classes = useStyles();

  const renderParagraph = (label: string, text?: any | null) => {
    if (!text) return null;

    return (
      <p className={classes.paragraph}>
        <b>{label}:</b>
        <br />
        {text}
      </p>
    );
  };

  return (
    <Dialog fullWidth={true} open={isOpen} maxWidth='lg' onClose={handleClose}>
      <DialogContent>
        <h3 className={classes.title}>
          {data?.id ? `[${data.id}] ` : ''}
          {data?.title}
        </h3>
        {renderParagraph('Description', data?.description)}
        {/*renderParagraph('Recommendation', data?.recommendation)}
        {renderParagraph('Solution', data?.solution)*/}
        {renderParagraph(
          'Source',
          data?.score && data?.foundIn?.product && data?.foundIn?.version
            ? `${data?.foundIn?.product} ${data?.foundIn?.version}`
            : '',
        )}
        {renderParagraph('Port', data?.foundIn?.port)}
        {/*renderParagraph('Scan type', data?.scan_type)*/}
        {renderParagraph('Score', data?.score)}
        {/*renderParagraph('Exploitable', data?.exploitable ? 'Yes' : 'No')}
        {renderParagraph('Test results', data?.test_results)*/}
        {renderParagraph(
          'URL',
          data?.score ? (
            <a href={`https://nvd.nist.gov/vuln/detail/${data?.id}`} target='_blank' rel='noreferrer'>
              {`https://nvd.nist.gov/vuln/detail/${data?.id}`}
            </a>
          ) : (
            ''
          ),
        )}
        {/*renderParagraph('Protocol', data?.protocol)}
        {renderParagraph('Annotations', data?.annotations)}
        {renderParagraph('Processes', data?.processes?.join(', '))*/}
        {renderParagraph('CVE ID', data?.score ? data?.id : '')}
      </DialogContent>
    </Dialog>
  );
};
