import React, { useMemo } from 'react';
import {
  Bar,
  BarChart as _BarChart,
  BarProps,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import { CustomGraphTooltipContent } from 'templates/SecurityAssessments/components/CustomGraphTooltipContent/assets';

import { formatMillionsValue } from '../Money';
import { ChartContainer, Props as ChartContainerProps } from './ChartContainer';

export enum Risk {
  NA = 'na',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface RiskBarDataPoint {
  name: string;
  low?: number;
  na?: number;
  medium?: number;
  high?: number;
  score?: number;
  distribution?: Array<{
    name: string;
    value: number;
    previous: number;
    opposite?: boolean;
  }>;
}

export interface Props extends ChartContainerProps {
  data: RiskBarDataPoint[];
  separated?: boolean;
  dollars?: boolean;
  skipValues?: Array<Risk>;
  yAxisOverrides?: {
    left?: Partial<YAxisProps>;
    right?: Partial<YAxisProps>;
  };
  yAxisId?: {
    low?: 'left' | 'right';
    na?: 'left' | 'right';
    medium?: 'left' | 'right';
    high?: 'left' | 'right';
    score?: 'left' | 'right';
  };
  showTooltip?: boolean;
}

// Keep outside of component to avoid re-rendering
const mockScore = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
const mockNa = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
const mockLow = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
const mockMedium = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];
const mockHigh = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];

export const RiskBar = (props: Props) => {
  const hasAnyRightAxis = Object.values(props.yAxisId || {}).includes('right');
  const commonBarProps: Omit<BarProps, 'dataKey' | 'ref'> = {
    radius: 4,
    strokeWidth: 1,
    stackId: props.separated ? undefined : 'a',
  };

  const commonYAxisProps: YAxisProps = {
    fontSize: 10,
    allowDecimals: false,
    tickFormatter: (tick: number) => {
      const val = tick / 1000;
      let formatted = tick.toLocaleString();

      if (tick >= 1000) {
        formatted = `${parseFloat(val.toFixed(1))}k`;
      }

      if (tick >= 1_000_000) {
        formatted = formatMillionsValue(tick);
      }

      return props.dollars ? `$${formatted}` : formatted;
    },
  };

  const leftYAxisProps: YAxisProps = {
    ...(hasAnyRightAxis ? { yAxisId: 'left', orientation: 'left' } : {}),
    ...commonYAxisProps,
    ...props.yAxisOverrides?.left,
  };

  const rightYAxisProps: YAxisProps = {
    ...(hasAnyRightAxis ? { yAxisId: 'right', orientation: 'right' } : {}),
    ...commonYAxisProps,
    ...props.yAxisOverrides?.right,
  };

  const parsedData = useMemo(() => {
    if (props.locked) {
      // Randomize the data
      return props.data.map((d, idx) => ({
        ...d,
        score: idx % 2 === 0 ? mockScore[idx % 3] : mockScore[(idx + 1) % 3],
        low: idx % 2 === 0 ? mockLow[idx % 3] : mockLow[(idx + 1) % 3],
        medium: idx % 2 === 0 ? mockMedium[idx % 3] : mockMedium[(idx + 1) % 3],
        high: idx % 2 === 0 ? mockHigh[idx % 3] : mockHigh[(idx + 1) % 3],
        na: idx % 2 === 0 ? mockNa[idx % 3] : mockNa[(idx + 1) % 3],
      }));
    } else {
      return props.data;
    }
  }, [props.data, props.locked]);

  return (
    <ChartContainer {...props}>
      <ResponsiveContainer>
        <_BarChart data={parsedData} margin={{ top: 20, left: -20, bottom: 20 }} barSize={10}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' fontSize={10} />

          <YAxis {...leftYAxisProps} />
          {hasAnyRightAxis && <YAxis {...rightYAxisProps} />}

          {props.showTooltip && (
            <Tooltip
              contentStyle={{ borderRadius: 24, border: '1px solid #EDEDED', padding: '1px 2px' }}
              content={<CustomGraphTooltipContent />}
              filterNull={false}
            />
          )}

          <Bar
            isAnimationActive={false}
            dataKey='score'
            fill='rgb(243, 176, 174)'
            stroke='#ED6660'
            yAxisId={props.yAxisId?.score || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonBarProps}
          />

          <Bar
            isAnimationActive={false}
            dataKey='low'
            fill='rgb(164, 213, 195)'
            stroke='#4EAE8B'
            yAxisId={props.yAxisId?.low || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonBarProps}
          />
          <Bar
            isAnimationActive={false}
            dataKey='medium'
            fill='rgb(250, 230, 160)'
            stroke='#FBD144'
            yAxisId={props.yAxisId?.medium || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonBarProps}
          />
          <Bar
            isAnimationActive={false}
            dataKey='high'
            fill='rgb(243, 176, 174)'
            stroke='#ED6660'
            yAxisId={props.yAxisId?.high || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonBarProps}
          />
          <Bar
            isAnimationActive={false}
            dataKey='na'
            fill='rgb(157, 165, 172)'
            stroke='#41505D'
            yAxisId={props.yAxisId?.na || (hasAnyRightAxis ? 'left' : undefined)}
            {...commonBarProps}
          />
        </_BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};
