import { variantColorsMap } from 'components/Badge';
import { CHART_OPTION } from 'components/charts/HistoryGrades';
// import { InternalScanType as SecurityScanType } from 'dtos/security-finding';
import isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { TooltipProps } from 'recharts';
// import { TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';
import { getGradeBadgeVariant } from 'templates/SecurityAssessments/components/ExecutiveSummaryItem';

// import { OverallScoreBadge } from '../OverallScoreBadge';

interface CustomTooltipProps extends TooltipProps<number, string> {}

export const CustomGraphTooltipContent = (props: CustomTooltipProps) => {
  const { label, payload } = props;
  const hasLabel = !isNil(label);
  const finalLabel = hasLabel ? label : '';
  // const securityOverallScore = payload?.find((item) => item?.dataKey === 'securityOverallScore');
  // const items = payload || []; //)?.filter((item) => item?.dataKey !== 'securityOverallScore');

  const content = useMemo(() => {
    const items = payload || [];
    if (items && items.length > 0) {
      return (
        <ul className='recharts-tooltip-item-list' style={{ padding: 0, margin: 0 }}>
          {items
            .sort((a, b) => (b?.value || 0) - (a?.value || 0))
            .map((item: any) => {
              return (
                <li
                  className='recharts-tooltip-item'
                  key={`tooltip-item-${item.name}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: '#000',
                  }}
                >
                  <svg
                    width={40}
                    height={20}
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      marginRight: 8,
                    }}
                  >
                    <line
                      strokeWidth={item.strokeWidth}
                      fill='none'
                      stroke={item.color}
                      strokeDasharray={item.strokeDasharray}
                      x1='0'
                      y1='12'
                      x2='40'
                      y2='12'
                    />
                  </svg>
                  <span>{CHART_OPTION[item.name as keyof typeof CHART_OPTION]}</span>
                  <span
                    style={{
                      marginLeft: 'auto',
                      paddingLeft: 64,
                      width: 32,
                      display: 'flex',
                      justifyContent: 'center',
                      color:
                        variantColorsMap[
                          getGradeBadgeVariant(item.value >= 25 ? parseScoreToGrade(item.value) : undefined)
                        ].text,
                    }}
                  >
                    {parseScoreToGrade(item.value) || 'N/A'}
                  </span>
                </li>
              );
            })}
        </ul>
      );
    }

    return null;
  }, [payload]);

  return (
    <div
      className='recharts-default-tooltip'
      style={{
        margin: 0,
        backgroundColor: '#fff',
        whiteSpace: 'nowrap',
        borderRadius: 24,
        border: '1px solid #EDEDED',
        padding: '16px 20px',
      }}
    >
      <p className='recharts-tooltip-label' style={{ margin: 0, fontSize: 16, fontWeight: 700 }}>
        {React.isValidElement(finalLabel) ? finalLabel : `${finalLabel}`}
      </p>
      {content}
    </div>
  );
};
