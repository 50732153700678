import { makeStyles } from '@material-ui/core';
import { formatMillionsValue, formatNumberWithCommas } from 'components/Money';
import { Column, Table } from 'components/Table';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& table': {
      marginBottom: theme.spacing(5),
    },
  },

  // reusable
  pageBreakBefore: {
    pageBreakBefore: 'always',
    breakBefore: 'page',
  },

  avoidPageBreak: {
    pageBreakInside: 'avoid',
    display: 'block',
    padding: `${theme.spacing(1)}px 0`,
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  title?: string;
}

export const AssessmentData = ({ application, title }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.avoidPageBreak}>
        <h2>{title || 'Assessment:'}</h2>
        <h3>Describe the Business:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={describeBusinessData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Operations:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={operationsData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Data Assets:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={dataAssetsData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Security Organization:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={securityOrganizationData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Security:</h3>
        <h4>Network Security:</h4>
        <Table<AssesmentRecord>
          columns={columns}
          data={networkSecurityData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h4>Endpoint Security:</h4>
        <Table<AssesmentRecord>
          columns={columns}
          data={endpointSecurityData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Vulnerability Management:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={vulnerabilityManagementData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Secure Software Development Lifecycle (SDLC):</h3>
        <Table<AssesmentRecord> columns={columns} data={sdlcData(application)} rowKey={(el) => el.field} semiBordered />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Recovery:</h3>
        <h4>Business Continuity:</h4>
        <Table<AssesmentRecord>
          columns={columns}
          data={businessContinuityData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h4>Disaster Recovery:</h4>
        <Table<AssesmentRecord>
          columns={columns}
          data={disasterRecoveryData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Access:</h3>
        <h4>Access Control:</h4>
        <Table<AssesmentRecord>
          columns={columns}
          data={accessControlData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      <div className={classes.avoidPageBreak}>
        <h4>Multi-Factor Authentication:</h4>
        <Table<AssesmentRecord> columns={columns} data={mfaData(application)} rowKey={(el) => el.field} semiBordered />
      </div>
      <div className={classes.avoidPageBreak}>
        <h3>Encryption:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={encryptionData(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
      {/*<div className={classes.avoidPageBreak}>*/}
      {/*  <h3>Loss Information:</h3>*/}
      {/*  <Table<AssesmentRecord>*/}
      {/*    columns={columns}*/}
      {/*    data={lossInformationData(application)}*/}
      {/*    rowKey={(el) => el.field}*/}
      {/*    semiBordered*/}
      {/*  />*/}
      {/*</div>*/}
      <div className={classes.avoidPageBreak}>
        <h3>Insurance Posture:</h3>
        <Table<AssesmentRecord>
          columns={columns}
          data={insurancePosture(application)}
          rowKey={(el) => el.field}
          semiBordered
        />
      </div>
    </div>
  );
};

interface AssesmentRecord {
  field: string;
  input?: string;
}

const columns: Column<AssesmentRecord, keyof AssesmentRecord>[] = [
  {
    title: 'Field',
    width: '40%',
    render: (record) => record?.field || '-',
  },
  {
    title: 'Input',
    width: '60%',
    render: (record) => record?.input || '-',
  },
];

const describeBusinessData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    { field: 'Organization Name', input: assessment?.organization_name },
    { field: 'Organization Website', input: assessment?.domain_prim },
    { field: 'Street', input: assessment?.organization_address?.street },
    { field: 'Unit', input: assessment?.organization_address?.unit },
    { field: 'City', input: assessment?.organization_address?.city },
    { field: 'State', input: assessment?.organization_address?.state },
    { field: 'Zip Code', input: assessment?.organization_address?.zip_code },
  ];
};

const operationsData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    { field: 'Year Established', input: assessment?.year_established },
    { field: 'Employee Count', input: assessment?.employee_count },
    {
      field: 'Annual Revenue (Sales)',
      input:
        assessment?.annual_revenue < 1000000
          ? formatNumberWithCommas(assessment?.annual_revenue)
          : formatMillionsValue(assessment?.annual_revenue),
    },
    { field: 'Organization Type', input: assessment?.organization_type },
    { field: 'Primary Operations', input: assessment?.primary_operations },
    { field: 'Primary Operations Long', input: assessment?.primary_operations_long },
  ];
};

const dataAssetsData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    { field: 'Estimated Personal Records', input: assessment?.personal_information_exposure },
    { field: 'Payment Card Transactions', input: assessment?.payment_information_exposure },
    { field: 'PCI-DSS Compliance', input: assessment?.pci_dss_compliance },
    { field: 'Healthcare Information Transactions', input: assessment?.healthcare_information_transactions },
    { field: 'HIPAA Compliance', input: assessment?.hipaa_compliance },
  ];
};

const securityOrganizationData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    { field: 'Patching Critical Software Procedures', input: castToYesOrNo(assessment?.uw_patch) },
    { field: 'Business Continuity & Disaster Recovery Plans', input: castToYesOrNo(assessment?.uw_bizint) },
    { field: 'Media Content Review', input: castToYesOrNo(assessment?.uw_media) },
    { field: 'Vendor Security Controls', input: castToYesOrNo(assessment?.uw_vendor) },
    { field: 'Employee Training', input: castToYesOrNo(assessment?.uw_training) },
    { field: 'Employee Training on Funds Transfer Protocol', input: castToYesOrNo(assessment?.uw_transfers) },
    { field: 'Funds Transfer Controls', input: castToYesOrNo(assessment?.uw_transfers) },
  ];
};

const networkSecurityData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Network Firewalls or Security Group Settings (Cloud Environments)',
      input: castToYesOrNo(assessment?.network_security?.network_firewalls_security_group_settings),
    },
    {
      field: 'Intrusion Detection Systems (or Prevention Systems)',
      input: castToYesOrNo(assessment?.network_security?.intrusion_detection_systems),
    },
    {
      field: 'Security Information & Event Monitoring (SIEM)',
      input: castToYesOrNo(assessment?.network_security?.security_information_event_monitoring),
    },
  ];
};

const endpointSecurityData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Laptops/Computers',
      input: castToYesOrNo(assessment?.endpoint_security?.antivirus_edr?.laptops_computers),
    },
    { field: 'Servers', input: castToYesOrNo(assessment?.endpoint_security?.antivirus_edr?.servers) },
    { field: 'Networks', input: castToYesOrNo(assessment?.endpoint_security?.antivirus_edr?.networks) },
    { field: 'Mobile Devices', input: castToYesOrNo(assessment?.endpoint_security?.antivirus_edr?.mobile_devices) },
  ];
};

const vulnerabilityManagementData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Does your organization conduct regular or periodic penetration testing by a third party?',
      input: castToYesOrNo(assessment?.vulnerability_management?.periodic_penetration_testing_3rd_party),
    },
    {
      field: 'Network of Cloud Infrastructure Penetration Test',
      input: castToYesOrNo(assessment?.vulnerability_management?.pen_testing?.network_cloud_infra_pen_testing),
    },
    {
      field: 'Web Application Penetration Test',
      input: castToYesOrNo(assessment?.vulnerability_management?.pen_testing?.web_app_pen_testing),
    },
    {
      field: 'Mobile Application Penetration Test',
      input: castToYesOrNo(assessment?.vulnerability_management?.pen_testing?.mobile_app_pen_testing),
    },
    {
      field: 'API Penetration Test',
      input: castToYesOrNo(assessment?.vulnerability_management?.pen_testing?.api_pen_testing),
    },
  ];
};

const sdlcData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Does your organization conduct any of the following on a regular or periodic cadence?',
      input: [
        assessment?.secure_software_development_lifecycle?.periodic_cadence?.code_reviews ? 'Code Reviews' : undefined,
        assessment?.secure_software_development_lifecycle?.periodic_cadence?.static_code_analysis
          ? 'Static Code Analysis'
          : undefined,
        assessment?.secure_software_development_lifecycle?.periodic_cadence?.dynamic_code_analysis
          ? 'Dynamic Code Analysis'
          : undefined,
      ]
        .filter(Boolean)
        .join(', '),
    },
  ];
};

const businessContinuityData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Backups include all sensitive or otherwise critical data and technologies',
      input: castToYesOrNo(assessment?.business_continuity?.backups_critial_data),
    },
    {
      field: 'Frequency of backup is at least weekly',
      input: castToYesOrNo(assessment?.business_continuity?.weekly_backup),
    },
    { field: 'Backups are', input: assessment?.business_continuity?.backup_type },
    {
      field: 'Backups are encrypted',
      input: castToYesOrNo(assessment?.business_continuity?.backup_encrypted),
    },
  ];
};

const disasterRecoveryData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Are you able to recover business critical data and systems in less than 10 days?',
      input: castToYesOrNo(assessment?.disaster_recovery?.able_to_recover_data),
    },
    {
      field: 'Do you perform quarterly table top exercises for DR preparedness?',
      input: castToYesOrNo(assessment?.disaster_recovery?.quarterly_table_top_exercises),
    },
  ];
};

const accessControlData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Do you perform access control reviews for your production system?',
      input: castToYesOrNo(assessment?.access_control?.access_control_reviews),
    },
    {
      field: 'Do you document access control reviews?',
      input: castToYesOrNo(assessment?.access_control?.document_access_control_reviews),
    },
  ];
};

const mfaData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Is MFA required for all internal, remote or remote admin access provided to any parties',
      input: [
        assessment?.multi_factor_authentication?.mfa_required_users?.employees ? 'Employees' : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users?.contractors ? 'Contractors' : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users?.third_party
          ? '3rd party service providers'
          : undefined,
      ]
        .filter(Boolean)
        .join(', '),
    },
    {
      field: 'Is MFA a requirement for accessing the following',
      input: [
        assessment?.multi_factor_authentication?.mfa_required_users_for_access
          ?.email_through_website_or_cloud_based_service
          ? 'Email through a website or cloud based service'
          : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users_for_access?.all_remote_access_to_the_network
          ? 'All Remote Access to the network'
          : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users_for_access
          ?.for_internal_remote_admin_access_to_directory_services
          ? 'For internal & remote admin access to directory services'
          : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users_for_access?.network_backup_environments
          ? 'Network backup environments'
          : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users_for_access?.network_infrastructure
          ? 'Network infrastructure'
          : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users_for_access?.organization_endpoints
          ? "Organization's endpoints"
          : undefined,
        assessment?.multi_factor_authentication?.mfa_required_users_for_access?.organization_servers
          ? "Organization's servers"
          : undefined,
      ]
        .filter(Boolean)
        .join(', '),
    },
  ];
};

const encryptionData = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Do you encrypt all Personally Identifiable Information, Payment Information, Healthcare Information?',
      input: castToYesOrNo(assessment?.encryption?.encrypt_all_personal_identifiable_information),
    },
    {
      field: 'Do you encrypt the above information at rest and during transit from the following?',
      input: [
        assessment?.encryption?.encrypt_payment_information?.computing_devices
          ? 'Any computing devices (laptop/desktop)'
          : undefined,
        assessment?.encryption?.encrypt_payment_information?.email ? 'Email' : undefined,
        assessment?.encryption?.encrypt_payment_information?.smart_phones ? 'Smartphones' : undefined,
        assessment?.encryption?.encrypt_payment_information?.other_portable_media_devices
          ? 'Other portable media devices (tablets etc.)'
          : undefined,
      ]
        .filter(Boolean)
        .join(', '),
    },
  ];
};

const insurancePosture = (application: ApplicationWithAgentDTO): AssesmentRecord[] => {
  const assessment = application?.applicationResponse;

  return [
    {
      field: 'Organization has controlled subsidiaries',
      input: castToYesOrNo(assessment?.ops_unique?.controlled_subsidiaries),
    },
    {
      field: 'Revenue for current year is anticipated to be different that last years',
      input: castToYesOrNo(assessment?.ops_unique?.different_revenue),
    },
    {
      field:
        'You have an active technology errors & omissions or management professional liability policy concurrent with this insurance policy',
      input: castToYesOrNo(assessment?.ops_unique?.has_tech_eo_or_mpl),
    },
    { field: 'Organization operates as a Franchise', input: castToYesOrNo(assessment?.ops_unique?.ops_franchise) },
    {
      field: 'Operations include Cannabis, Gambling or Adult Content',
      input: castToYesOrNo(assessment?.ops_unique?.ops_restrict),
    },
    {
      field: 'Your Organization currently buys Cyber Insurance',
      input: castToYesOrNo(assessment?.ops_unique?.prevailing_cyber_insurance),
    },
  ];
};

export const castToBoolean = (value: any, forceBoolean: boolean) => {
  if (value === null || value === undefined) {
    return forceBoolean ? false : undefined;
  }

  if ([true, 1, '1', 'true', 'on', 'yes', 'enabled', 'True', 'On', 'Yes', 'Enabled'].includes(value)) {
    return true;
  }

  if ([false, 0, '0', 'false', 'off', 'no', 'disabled', 'False', 'Off', 'No', 'Disabled'].includes(value)) {
    return false;
  }

  return forceBoolean ? false : undefined;
};

export const castToYesOrNo = (value: any) => (castToBoolean(value, true) ? 'Yes' : 'No');
