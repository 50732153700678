import { makeStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Modal } from 'components/Modal';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
// import { ApplicationWithAgentDTO } from 'dtos/application';
import { InstalledAppsStats } from 'dtos/deep-scan';
import sortBy from 'lodash/sortBy';
// import { useDeepScanParsedReports } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { COLORS } from 'telivy-theme';
// import { ScoreBox, ScoreRanking } from 'templates/SecurityAssessments/components/ScoreBox';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    width: '100%',
  },
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },
}));

interface Props {
  installedApps?: InstalledAppsStats[] | null;
}

const PER_PAGE = 500;
export const Device = ({ installedApps }: Props) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sorter, setSorter] = useState<Sorter<string> | undefined>({
    key: 'data.nmapScanStats.epssMax',
    order: SortOrder.DESC,
  });

  const columns = useMemo((): Column<InstalledAppsStats, string>[] => {
    return [
      {
        title: 'Name',
        sortKey: 'data.name',
        render: (row) => row.data.name,
      },
      {
        title: 'Version',
        sortKey: 'data.version',
        render: (row) => row.data.version,
      },
      {
        title: 'Assets',
        sortKey: 'assets',
        render: (row) => row.assets,
      },
      {
        title: (
          <span>
            Max EPSS
            <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='EPSS'>
              <p>
                The EPSS (Exploit Prediction Scoring System) score represents the probability of exploitation in the
                wild during the next 30 days in the range from 0 to 1. For example, a CVE with EPSS of 0.96 has 96%
                chance to be exploited in the following 30 days.
              </p>
            </Modal>
          </span>
        ),
        sortKey: 'data.nmapScanStats.epssMax',
        render: (row) => row.data.nmapScanStats?.epssMax && `${(row.data.nmapScanStats?.epssMax * 100).toFixed(2)}%`,
      },
      {
        title: 'High Severity CVEs',
        sortKey: 'data.nmapScanStats.cveCounts.high',
        render: (row) => row.data.nmapScanStats?.cveCounts?.high,
      },
      {
        title: 'Medium Severity CVEs',
        sortKey: 'data.nmapScanStats.cveCounts.medium',
        render: (row) => row.data.nmapScanStats?.cveCounts?.medium,
      },
      {
        title: 'Low Severity CVEs',
        sortKey: 'data.nmapScanStats.cveCounts.low',
        render: (row) => row.data.nmapScanStats?.cveCounts?.low,
      },
      // {
      //   title: 'Informational',
      //   sortKey: 'data.nmapScanStats.cveCounts.info',
      //   render: (row) => row.data.nmapScanStats?.cveCounts?.info,
      // },
    ];
  }, [classes]);

  const sortedData = useMemo(() => {
    if (sorter) {
      const data = sortBy(installedApps, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(installedApps, (d) => d.data.nmapScanStats?.epssMax || 0).reverse();
  }, [installedApps, sorter]);

  return (
    <Table<InstalledAppsStats, string>
      columns={columns}
      sorter={sorter}
      rowContentCentered
      onChange={(newPage, sorting) => {
        if (newPage?.page !== undefined) {
          setPage(newPage.page);
        }

        setSorter(sorting);
      }}
      className={classes.table}
      rowKey={(row) => row.data.id}
      data={sortedData.slice(page * PER_PAGE, (page + 1) * PER_PAGE)}
      loading={!installedApps}
      pagination={!installedApps ? undefined : { page, perPage: PER_PAGE, total: installedApps?.length || 0 }}
    />
  );
};
