import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { ApplicationWithAgentDTO } from 'dtos/application';
import React from 'react';
import { Helmet } from 'react-helmet';
import { isSecurityApplication } from 'telivy-selectors';
import { SecurityHistory } from 'templates/SecurityAssessments/views/SecurityHistory';

import { ApplicationViewContainer } from '../ApplicationViewContainer';

interface Props {
  application: ApplicationWithAgentDTO;
}

export const ApplicationHistoryView = ({ application }: Props) => {
  if (!isSecurityApplication(application) && !application.agent.securityEnabled) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Security History</title>
      </Helmet>

      <SecurityHistory applicationId={application.id} />
    </ApplicationViewContainer>
  );
};
