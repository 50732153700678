import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import { ToasterVariant, useToasters } from 'hooks/useToasters';
import { ReactComponent as AddIcon } from 'images/add.svg';
import { ReactComponent as UploadIcon } from 'images/upload-cloud.svg';
import React, { useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles<Theme, { smaller?: boolean }>((theme) => ({
  box: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: COLORS.GREY_3,
    border: `2px dashed ${COLORS.GREY_4}`,
    padding: ({ smaller }) => theme.spacing(smaller ? 1 : 3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    cursor: 'pointer',
  },
  focused: {
    borderColor: COLORS.BLUE_1,
    color: COLORS.BLUE_1,
    backgroundColor: COLORS.BLUE_3,

    '& $button': {
      color: COLORS.BLUE_1,
    },
    '& $icon path': {
      fill: COLORS.BLUE_1,
    },
  },
  reject: {
    borderColor: COLORS.RED_1,
    color: COLORS.RED_1,
    backgroundColor: COLORS.RED_3,

    '& $button': {
      borderColor: COLORS.RED_1,
      color: COLORS.RED_1,
    },
    '& $icon path': {
      fill: COLORS.RED_1,
    },
  },

  button: {
    color: COLORS.GREY_2,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    backgroundColor: COLORS.WHITE,
    alignItems: 'center',
    border: `1px solid ${COLORS.GREY_5}`,
    borderRadius: theme.spacing(),
  },
  icon: {
    marginRight: theme.spacing(1),
    '& path': {
      fill: COLORS.GREY_2,
    },
  },

  text: {
    margin: 0,
    marginTop: theme.spacing(1),
  },
}));

interface Props extends DropzoneOptions {
  addAnother?: boolean;
  buttonLabel?: string;
}

const ERROR_MESSAGE = 'Document is not the right format. Use a .doc or .pdf';
export const FilesDnD = ({ addAnother, buttonLabel, ...restProps }: Props) => {
  const classes = useStyles({ smaller: addAnother });
  const { showToaster } = useToasters();

  const rejectedFilesHandler = useCallback(() => {
    showToaster({ message: ERROR_MESSAGE, variant: ToasterVariant.ERROR });
  }, [showToaster]);

  const { getRootProps, getInputProps, isFocused, isDragReject, isDragActive, isFileDialogActive } = useDropzone({
    accept: 'application/pdf',
    ...restProps,
    onDropRejected: restProps.onDropRejected || rejectedFilesHandler,
  });

  const Icon = addAnother ? AddIcon : UploadIcon;
  const containerClasses = cx(classes.box, {
    [classes.focused]: isFocused || isDragActive || isFileDialogActive,
    [classes.reject]: isDragReject,
  });

  const message = isDragReject ? ERROR_MESSAGE : 'or drag & drop';
  return (
    <div {...getRootProps({ className: containerClasses })}>
      <div className={classes.button}>
        <Icon className={classes.icon} /> {addAnother ? 'Add Another' : buttonLabel || 'Upload'}
      </div>
      <input {...getInputProps()} />
      {(!addAnother || isDragReject) && <p className={classes.text}>{message}</p>}
    </div>
  );
};
