import { makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
// import { LoadingContainer } from 'components/LoadingContainer';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO } from 'dtos/application';
import { ApplicationsRiskReport, DeepScanDTO, InstalledAppsStats } from 'dtos/deep-scan';
// import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
// import { useChartsEnabled } from 'hooks/useChartsEnabled';
// import { uniq } from 'lodash';
// import uniqBy from 'lodash/uniqBy';
// import { useCurrentAgent } from 'queries/useAgent';
// import { useApplicationSecurityScan } from 'queries/useApplications';
// import { useDeepScan, useDeepScanNetworkHosts, useDeepScanTargets } from 'queries/useDeepScan';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';
import { isScanApplication } from 'telivy-selectors';

import { ApplicationViewContainer } from '../../ApplicationViewContainer';
import { Browser } from './Browser';
import { Cloud } from './Cloud';
import { Device } from './Device';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  PARENT: string;
  ROOT: string;
  CLOUD: string;
  BROWSER: string;
  DEVICE: string;
};

interface Props {
  application: ApplicationWithAgentDTO;
  deepScan: DeepScanDTO | undefined;
  sourceRoute: SourceRoute;
  applicationRiskReports?: ApplicationsRiskReport[] | null;
  installedApps?: InstalledAppsStats[] | null;
}

export const ApplicationsView = ({
  application,
  deepScan,
  sourceRoute,
  applicationRiskReports,
  installedApps,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const viewOptions: Option<ValueOf<SourceRoute>>[] = React.useMemo(() => {
    const options = [];

    if (deepScan) {
      options.push({
        label: `Cloud (${(deepScan.m365Connected ? 1 : 0) + (deepScan.gwsConnected ? 1 : 0)})`,
        value: createSecurityRoute(sourceRoute.CLOUD, application.id),
      });

      options.push({
        label: `Browser (${applicationRiskReports?.length || 0})`,
        value: createSecurityRoute(sourceRoute.BROWSER, application.id),
      });

      options.push({
        label: `Device (${installedApps?.length || 0})`,
        value: createSecurityRoute(sourceRoute.DEVICE, application.id),
      });
    }

    // if (isAgentAnAdmin(agent)) {
    // options.push({
    // label: `Devices (${0})`,
    // value: createSecurityRoute(sourceRoute.softwares.BROWSER, application.id),
    // });
    // }

    return options;
  }, [sourceRoute, application, deepScan, applicationRiskReports, installedApps]);

  if (!isScanApplication(application)) {
    return (
      <ApplicationViewContainer>
        <EmptyState
          title='Not enabled'
          text={
            <>
              Please reach out to <a href='mailto:brokerage@telivy.com'>brokerage@telivy.com</a> for access.
            </>
          }
          icon={<LockOutlinedIcon />}
        />
      </ApplicationViewContainer>
    );
  }

  // if (isLoading) {
  //   return <LoadingContainer />;
  // }

  return (
    <div className={classes.root}>
      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<SourceRoute>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route path={sourceRoute.CLOUD} element={<Cloud deepScan={deepScan} application={application} />} />

        <Route path={sourceRoute.BROWSER} element={<Browser applicationRiskReports={applicationRiskReports} />} />

        <Route path={sourceRoute.DEVICE} element={<Device installedApps={installedApps} />} />

        <Route index element={<Navigate replace to={sourceRoute.CLOUD} />} />
      </Routes>
    </div>
  );
};
