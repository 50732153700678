import { LabelElement, OwnPropsOfRenderer } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles(() => ({
  subtitle: {
    ...TYPOGRAPHY.LARGE,
    fontWeight: 400,
  },
}));

export const _Subtitle = ({ uischema, visible }: OwnPropsOfRenderer) => {
  const classes = useStyles();
  const labelElement: LabelElement = uischema as LabelElement;

  return (
    <Hidden xsUp={!visible}>
      <Typography variant='subtitle1' className={classes.subtitle}>
        {labelElement.text !== undefined && labelElement.text !== null && labelElement.text}
      </Typography>
    </Hidden>
  );
};

export const Subtitle = withJsonFormsLayoutProps(_Subtitle);
