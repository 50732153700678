import { makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as NotFoundHeader } from 'images/not-found.svg';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  fullScreen?: boolean;
  message?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ fullScreen }) => ({
    backgroundColor: COLORS.WHITE,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),

    ...(fullScreen && {
      height: '100vh',
      width: '100vw',
    }),
  }),

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
  },

  message: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: COLORS.GREY_3,
    maxWidth: theme.spacing(33),
    textAlign: 'center',
    margin: `0 ${theme.spacing(3)}px ${theme.spacing(5)}px`,
  },

  email: {
    color: COLORS.BLUE_1,
  },

  headerImage: {
    width: theme.spacing(28),
  },
}));

interface Props extends StyleProps {}

export const NotFoundContainer = ({ fullScreen, message }: Props) => {
  const classes = useStyles({ fullScreen });

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Whoops...</h1>
      <p className={classes.message}>
        {message || 'We seem to have encountered an error. An agent will reach out to you soon!'}
      </p>
      <div>
        <NotFoundHeader className={classes.headerImage} />
      </div>
    </div>
  );
};
