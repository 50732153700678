import axios, { AxiosError } from 'axios';
import { CustomSnackbar, Props as SnackbarProps, SnackbarVariant } from 'components/CustomSnackbar';
import { camelCase, isString, startCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import React from 'react';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

interface AsyncActionOptions {
  successMessage: string;
  errorMessage?: string;
  infoMessage: string;
}

export const parseHttpError = (error: AxiosError | Error, defaultMessage?: string): string => {
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.data.message === 'Validation error') {
      const { errors }: { errors: { [key: string]: string[] } } = error.response.data;
      if (errors) {
        return Object.keys(errors)
          .map((key) => {
            const message = errors[key][0];

            return startCase(camelCase(key)) + ': ' + message;
          })
          .join(', ');
      }

      return 'Validation error';
    }

    if (error.response?.data.message) {
      return error.response.data.message;
    } else if (error.request) {
      return 'Network Error';
    }
  }

  return defaultMessage || error.message;
};

type ShowTosterData = Omit<SnackbarProps, 'id'> & { id?: string | number };

export const useToasters = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showToaster = useCallback(
    (data: ShowTosterData | string) => {
      if (isString(data)) {
        return enqueueSnackbar(data);
      } else {
        return enqueueSnackbar(data.message, {
          content: (key) => <CustomSnackbar {...data} id={data.id || key} />,
        });
      }
    },
    [enqueueSnackbar],
  );

  const toasterErrorHandler = useCallback(
    (error: AxiosError | unknown, defaultMessage?: string) => {
      if (axios.isAxiosError(error)) {
        showToaster({ message: parseHttpError(error, defaultMessage), variant: SnackbarVariant.ERROR });
      } else {
        showToaster({ message: defaultMessage || DEFAULT_ERROR_MESSAGE, variant: SnackbarVariant.ERROR });
      }
    },
    [showToaster],
  );

  const handleAsyncAction = useCallback(
    async <T,>(action: () => Promise<T>, options: AsyncActionOptions) => {
      const infoToaster = showToaster({ message: options.infoMessage, variant: SnackbarVariant.INFO });
      try {
        await action();

        showToaster(options.successMessage);
      } catch (e) {
        toasterErrorHandler(e, options.errorMessage);
      }

      closeSnackbar(infoToaster);
    },
    [showToaster, closeSnackbar, toasterErrorHandler],
  );

  return {
    showToaster,
    toasterErrorHandler,
    hideToaster: closeSnackbar,
    handleAsyncAction,
  };
};

export { SnackbarVariant as ToasterVariant };
