import { withJsonFormsControlProps } from '@jsonforms/react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomControlProps } from 'components/JsonForms/customProps';
import { JsonFormsErrors } from 'components/JsonForms/JsonFormsErrors';
import { JsonFormsLabelDescription } from 'components/JsonForms/JsonFormsLabelDescription';
import { kebabCase } from 'lodash';
import isString from 'lodash/isString';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
}));

export const _Input = (props: CustomControlProps) => {
  const classes = useStyles();
  const disabled = !props.enabled;

  if (!props.visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <TextField
        error={!!props.errors}
        multiline={props.uischema.options?.multi}
        disabled={disabled}
        required={props.required}
        data-cy={`form-${kebabCase(isString(props.label) ? props.label : props.label.default)}-input`}
        label={
          <JsonFormsLabelDescription
            label={isString(props.label) ? props.label : props.label.default}
            description={props.schema.description}
          />
        }
        inputProps={{
          autoComplete: 'new-password',
        }}
        placeholder={props.uischema.placeholder}
        InputLabelProps={{ shrink: false }}
        value={props?.data || ''}
        onChange={(e) => {
          props.handleChange(props.path, e?.target?.value);
        }}
        onBlur={(e) => {
          props.handleChange(props.path, e.target.value.trim().length > 0 ? e.target.value.trim() : undefined);
        }}
        className={classes.input}
      />

      <JsonFormsErrors errors={props.errors} />
    </div>
  );
};

export const Input = withJsonFormsControlProps(_Input);
