import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveFromQueueRoundedIcon from '@material-ui/icons/RemoveFromQueueRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { useToasters } from 'hooks/useToasters';
import { useMarkApplicationUninstallAll } from 'queries/useApplications';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  input: {},
}));

interface Props {
  applicationId: string;
  className?: string;
  isUninstallAll?: boolean;
}

export const UninstallAllButton: React.FC<Props> = ({ applicationId, className, isUninstallAll }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useMarkApplicationUninstallAll(applicationId, {
    onSuccess: () => {
      showToaster(
        `Scheduled uninstall on all online devices successfully. Please manually run uninstall command on offline devices.`,
      );
      setIsModalOpen(false);
    },
    onError: (e) => {
      toasterErrorHandler(e);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const MODAL_TITLE = !isUninstallAll ? 'Confirm Uninstall All' : 'Uninstall In Progress';
  const MODAL_TEXT = !isUninstallAll ? (
    <div>
      Uninstall will be scheduled on all online devices. You will have to manually run uninstall command on offline
      devices.
      <br />
      <br />
      <FormControl fullWidth>
        <CustomTextFieldShrink
          disabled={isLoading}
          placeholder='Enter the reason for triggering uninstall'
          value={reason}
          onChange={(event) => setReason(event.currentTarget.value)}
          className={classes.input}
          label='Enter Reason *'
        />
      </FormControl>
      <br />
      <br />
      Do you want to proceed?
    </div>
  ) : (
    'Uninstall is currently ongoing on online devices.'
  );

  return (
    <>
      <Tooltip title='Uninstall All'>
        <Button
          className={className}
          onClick={handleModalOpen}
          variant={isUninstallAll ? 'text' : 'outlined'}
          color='secondary'
          disabled={isUninstallAll}
          startIcon={<RemoveFromQueueRoundedIcon />}
        >
          {isUninstallAll ? 'Uninstall All In Progress' : 'Uninstall All'}
        </Button>
      </Tooltip>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className={classes.title}>
            {MODAL_TITLE}
          </Typography>
          <Typography variant='body1'>{MODAL_TEXT}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>{isUninstallAll ? 'Close' : 'Cancel'}</Button>
          {!isUninstallAll && (
            <Button
              color='secondary'
              variant='contained'
              disabled={isLoading || !reason}
              onClick={() => mutate(reason)}
              startIcon={<RemoveFromQueueRoundedIcon />}
            >
              {isLoading ? (isUninstallAll ? 'Saving...' : 'Submitting...') : 'Confirm'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
