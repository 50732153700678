import { UISchemaElement } from '@jsonforms/core';
import { Button, makeStyles } from '@material-ui/core';
import { JsonForms } from 'components/JsonForms';
import { RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO, UiSectionSchema } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { intersection, isNumber, uniq } from 'lodash';
import { useEditApplication, useFinancialRiskCalculatorApplicationVersion } from 'queries/useApplications';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  select: {
    marginBottom: theme.spacing(),
  },
  buttonWrapper: {
    display: 'flex',
    gap: theme.spacing(),
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  application?: ApplicationWithAgentDTO;
  setIsSavingFinancialCalculator?: (isSaving: boolean) => void;
}

export const FinancialRiskCalculator = ({ application, setIsSavingFinancialCalculator }: Props) => {
  const classes = useStyles();
  const { showToaster } = useToasters();
  const { data: applicationVersion } = useFinancialRiskCalculatorApplicationVersion();

  // const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [applicationEditedData, setApplicationEditedData] = useState<any>({});
  const [applicationInitialData, setApplicationInitialData] = useState<any>({});
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [showErrors, setShowErrors] = useState<boolean>(false);
  // const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
  const [errorPaths, setErrorPaths] = useState<string[]>([]);

  useEffect(() => {
    // We want to invoke this code only once on initial load
    if (application?.applicationResponse) {
      setApplicationEditedData(application?.applicationResponse);
      setSelectedSection(applicationVersion?.uiSchema?.sections?.[0]?.pageSlug || '');
    }
  }, [application, applicationVersion?.uiSchema?.sections]);

  useEffect(() => {
    if (application?.applicationResponse) {
      // set initial data to be able to reset the form
      setApplicationInitialData(application?.applicationResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentPageIdx = useMemo(
    () => (applicationVersion?.uiSchema?.sections || [])?.findIndex((el) => el.pageSlug === selectedSection),
    [applicationVersion?.uiSchema?.sections, selectedSection],
  );

  const currentPageData = currentPageIdx != null && applicationVersion?.uiSchema?.sections?.[currentPageIdx];

  const { mutate, isLoading: isSaving } = useEditApplication(application?.id || '', {
    onSuccess: (data) => {
      showToaster('Calculated Financial Risk');
      setApplicationEditedData(data.applicationResponse);
      if (!isLastPage) {
        goToNextPage();
      }
    },
  });

  useEffect(() => {
    if (setIsSavingFinancialCalculator) {
      if (isSaving) {
        setIsSavingFinancialCalculator(isSaving);
      } else {
        setTimeout(() => setIsSavingFinancialCalculator(isSaving), 1000);
      }
    }
  }, [isSaving, setIsSavingFinancialCalculator]);

  const handleUpdateData = useCallback(
    (updatedData: any) => {
      setApplicationEditedData(updatedData);
    },
    [setApplicationEditedData],
  );

  const hasErrors = useMemo(() => {
    if (currentPageData && isNumber(currentPageIdx)) {
      const { propertiesPaths } = currentPageData.uiSchema;
      const parsedErrorsPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
      const errors = intersection(propertiesPaths, parsedErrorsPaths);
      return errors.length !== 0;
    }
  }, [currentPageData, currentPageIdx, errorPaths]);

  const handleSave = useCallback(() => {
    mutate({
      applicationResponse: applicationEditedData,
    });
  }, [applicationEditedData, mutate]);

  const numberOfSections = applicationVersion?.uiSchema?.sections?.length;

  const isLastPage = useMemo(
    () => (numberOfSections ? currentPageIdx === numberOfSections - 1 : false),
    [numberOfSections, currentPageIdx],
  );

  const goToPage = useCallback((pageSlug: string) => {
    setSelectedSection(pageSlug);
  }, []);

  // const redirectToFirstSectionWithErrors = useCallback(() => {
  //   setShowAllErrors(true);
  //   setShowErrors(true);

  //   const allPages = applicationVersion?.uiSchema?.sections;
  //   // all pages before the current one reduced into array of objects
  //   const allPagesReduced = (allPages || [])?.map((page) => ({
  //     // paths is an array with paths to field on current page
  //     paths: page.uiSchema.propertiesPaths,
  //     pageSlug: page.pageSlug,
  //   }));
  //   // all errors in the whole form
  //   const parsedPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
  //   const firstSectionWithErrors = allPagesReduced?.find((page) => {
  //     return intersection(page?.paths, parsedPaths).length !== 0;
  //   });

  //   if (firstSectionWithErrors) {
  //     goToPage(firstSectionWithErrors?.pageSlug);
  //   }
  // }, [applicationVersion?.uiSchema?.sections, errorPaths, goToPage]);

  const goToNextPage = useCallback(() => {
    if (isNumber(currentPageIdx)) {
      const nextPageIdx = currentPageIdx + 1;
      const nextPage = applicationVersion?.uiSchema?.sections?.[nextPageIdx];

      if (nextPage) {
        goToPage(nextPage?.pageSlug);
      }
    }
  }, [currentPageIdx, applicationVersion?.uiSchema?.sections, goToPage]);

  // const handleNext = useCallback(() => {
  //   if (hasErrors) {
  //     setShowErrors(true);
  //   } else {
  //     if (!isFormValid && isLastPage) {
  //       // if there are any errors on the previous pages, then redirect to the first page with errors
  //       redirectToFirstSectionWithErrors();
  //     } else {
  //       !showAllErrors && setShowErrors(false);

  //       if (isLastPage) {
  //         handleSave();
  //       } else {
  //         goToNextPage();
  //       }
  //     }
  //   }
  // }, [hasErrors, isFormValid, isLastPage, redirectToFirstSectionWithErrors, showAllErrors, handleSave, goToNextPage]);

  const handleNext = useCallback(() => {
    if (hasErrors) {
      setShowErrors(true);
    } else {
      // if (applicationEditedData !== applicationInitialData) {
      handleSave();
      // }
    }
  }, [hasErrors, handleSave]);

  const handleReset = useCallback(() => {
    // reset the form
    setApplicationEditedData(applicationInitialData);
    goToPage(applicationVersion?.uiSchema?.sections?.[0]?.pageSlug || '');
  }, [applicationInitialData, applicationVersion?.uiSchema?.sections, goToPage]);

  const OPTIONS = useMemo(
    () =>
      (applicationVersion?.uiSchema?.sections || [])?.map((section, idx) => ({
        label: idx + 1 + '. ' + section?.label,
        value: section?.pageSlug,
      })),
    [applicationVersion?.uiSchema?.sections],
  );

  return (
    <div>
      <RadioSelect<ValueOf<UiSectionSchema>>
        options={OPTIONS}
        className={classes.select}
        selectedValue={selectedSection}
        onOptionSelect={(opt) => goToPage(opt?.value as string)}
      />
      {currentPageData ? (
        <JsonForms
          schema={applicationVersion?.dataSchema}
          uischema={currentPageData?.uiSchema as UISchemaElement}
          data={applicationEditedData}
          onChange={({ data, errors }) => {
            handleUpdateData(data);
            // setIsFormValid(errors ? errors.length === 0 : false);
            setErrorPaths(uniq((errors || []).map((e) => e.dataPath)));
          }}
          validationMode={showErrors ? 'ValidateAndShow' : 'ValidateAndHide'}
          applicationSource='agent'
        />
      ) : null}

      <div className={classes.buttonWrapper}>
        <Button
          // disabled={application?.applicationResponse === applicationEditedData ? true : isLastPage ? isSaving : false}
          disabled={application?.applicationResponse === applicationEditedData || isSaving}
          onClick={handleNext}
          variant='contained'
          color='primary'
        >
          {/* {isLastPage ? (isSaving ? 'Saving...' : 'Recalculate') : 'Next'} */}
          {isSaving ? 'Saving...' : 'Recalculate'}
        </Button>
        {/* {isLastPage ? ( */}
        <Button
          disabled={
            applicationEditedData === applicationInitialData ||
            application?.applicationResponse === applicationEditedData ||
            isSaving
          }
          onClick={handleReset}
          variant='outlined'
          color='primary'
        >
          Reset input
        </Button>
        {/* ) : null} */}
      </div>
    </div>
  );
};
