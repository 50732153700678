import { AxiosError } from 'axios';
import { CreateApplicationPublicDTO, VerifyCaptchaDTO } from 'dtos/public';
import { useMutation } from 'react-query';
import { UseMutationOptions } from 'react-query/types/react/types';
import { PublicService } from 'services/Public';

export const useCreatePublicApplications = (
  options?: UseMutationOptions<string, AxiosError, CreateApplicationPublicDTO>,
) => {
  return useMutation(
    'createPublicApplication',
    (data: CreateApplicationPublicDTO) => PublicService.createPublicApplications(data),
    options,
  );
};

export const useVerifyCaptcha = (options?: UseMutationOptions<{ success: boolean }, AxiosError, VerifyCaptchaDTO>) => {
  return useMutation('verifyCaptcha', (data: VerifyCaptchaDTO) => PublicService.verifyCaptcha(data), options);
};
