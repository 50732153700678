import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { DeclinationDocumentDTO, DeclinationDocumentType } from 'dtos/declinations';
import { useToasters } from 'hooks/useToasters';
import { useCreateDeclinationDocument, useUploadDeclinationDocument } from 'queries/useDeclinations';
import React, { useCallback, useRef, useState } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { DeleteDocumentButton } from './DeleteDocumnetButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 45,
    border: `1px solid ${COLORS.GREY_4}`,
    display: 'flex',
    '& + $root': {
      marginTop: theme.spacing(2),
    },
  },
  select: {
    width: 180,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${COLORS.GREY_4}`,
  },
  label: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    height: '100%',
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  error: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
    color: COLORS.RED_1,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    paddingMargin: theme.spacing(2),
    border: 0,
    background: 0,
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  quoteId: string;
  document?: DeclinationDocumentDTO;
}

const DEFAULT_DOCUMENT_TYPE = DeclinationDocumentType.SECURITY_REPORT;

export const DocumentRow = ({ quoteId, document }: Props) => {
  const [selectedType, setSelectedType] = useState(DEFAULT_DOCUMENT_TYPE);
  const fileInput = useRef<null | HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const { showToaster } = useToasters();
  const classes = useStyles();

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const list = e.target.files;

      if (list) {
        setFile(list[0]);
      }
    },
    [setFile],
  );

  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadDeclinationDocument(quoteId);
  const { mutate: createQuoteDocument, isLoading: isCreatingQuote } = useCreateDeclinationDocument(quoteId);

  const handleUploadClick = useCallback(async () => {
    if (file) {
      try {
        const data = await uploadFile({
          type: selectedType,
          file: file,
        });

        await createQuoteDocument({
          documentPath: data.path,
          fileName: file.name,
          documentType: selectedType,
        });

        showToaster(`Successfully uploaded a ${DOCUMENT_TYPE_HUMANIZED_NAME[selectedType]}`);
        setFile(null);
        setSelectedType(DEFAULT_DOCUMENT_TYPE);

        if (fileInput.current) {
          fileInput.current.value = '';
        }
      } catch (e) {
        showToaster(`Failed to upload a ${DOCUMENT_TYPE_HUMANIZED_NAME[selectedType]}`);
      }
    }
  }, [uploadFile, createQuoteDocument, file, selectedType, showToaster, fileInput]);

  return (
    <div className={classes.root}>
      <Select
        className={classes.select}
        disabled={!!document}
        value={document?.documentType || selectedType}
        onChange={(el) => {
          setSelectedType(el.target.value as DeclinationDocumentType);
        }}
      >
        {Object.values(DeclinationDocumentType).map((type) => (
          <MenuItem key={type} value={type}>
            {DOCUMENT_TYPE_HUMANIZED_NAME[type]}
          </MenuItem>
        ))}
      </Select>
      {document ? (
        <div className={classes.label}>{document.fileName}</div>
      ) : (
        <>
          <input style={{ display: 'none' }} type='file' ref={fileInput} onChange={handleInputChange} />
          <Button
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={() => {
              if (fileInput.current) {
                fileInput.current.click();
              }
            }}
          >
            {file ? file.name : 'Add Document'}
          </Button>
        </>
      )}
      <div className={classes.actions}>
        {!document && (
          <Button
            disabled={!file || isCreatingQuote || isUploading}
            className={classes.button}
            onClick={handleUploadClick}
          >
            {isCreatingQuote || isUploading ? 'Uploading & creating...' : 'Upload and create document'}
          </Button>
        )}
        {document && <DeleteDocumentButton quoteId={quoteId} document={document} className={classes.button} />}
      </div>
    </div>
  );
};
