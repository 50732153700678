import { makeStyles } from '@material-ui/core/styles';
import { DetailsList, Props as ListProps } from 'components/DetailsList';
import { EditableDocumentRow } from 'components/EditableDocumentRow';
import { FilesDnD } from 'components/FilesDnD';
import { LinkButton } from 'components/LinkButton';
import { PolicyDocumentDTO, PolicyDocumentType, PolicyDTO } from 'dtos/policy';
import { useManageDocuments } from 'hooks/useManageDocuments';
import { useToasters } from 'hooks/useToasters';
import { useCurrentAgent } from 'queries/useAgent';
import {
  useCreatePolicyDocument,
  useDeletePolicyDocument,
  useDownloadCombinedSignedPolicyDocuments,
  useDownloadPolicyDocument,
  usePolicyDocuments,
  useUpdatePolicyDocument,
  useUploadPolicyDocument,
} from 'queries/usePolicy';
import React, { useCallback, useMemo } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';
import { isAgentAnAdmin } from 'telivy-selectors';
import { TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  dnd: {
    marginTop: theme.spacing(1),
  },
  title: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  select: {
    width: 300,
  },
}));

interface Props {
  policy: PolicyDTO;
}

const DEFAULT_TYPE = PolicyDocumentType.CONFIRMATION_OF_INSURANCE;

export const Documents = ({ policy }: Props) => {
  const classes = useStyles();
  const { handleAsyncAction } = useToasters();
  const { data: agentData } = useCurrentAgent();
  const { data } = usePolicyDocuments(policy.id);
  const { mutate } = useCreatePolicyDocument(policy.id);
  const { mutateAsync: downloadDocument } = useDownloadPolicyDocument(policy.id);
  const { mutateAsync: downloadCombinedDocument } = useDownloadCombinedSignedPolicyDocuments(policy.id);
  const { mutateAsync: uploadFile } = useUploadPolicyDocument(policy.id);
  const { mutateAsync: deleteDocument, isLoading: isDeleting } = useDeletePolicyDocument(policy.id);
  const { mutateAsync: updateDocument, isLoading: isUpdating } = useUpdatePolicyDocument(policy.id);
  const { handleUpload } = useManageDocuments({
    defaultDocumentType: DEFAULT_TYPE,
    fileUploadFn: uploadFile,
    createDocumentFn: mutate,
  });

  const isAdmin = isAgentAnAdmin(agentData);

  const handleDownloadSignedPolicyDocument = useCallback(
    (policy: PolicyDTO) => () => {
      handleAsyncAction(() => downloadCombinedDocument(policy), {
        infoMessage: `Downloading Signed Documents`,
        successMessage: 'Document downloaded',
      });
    },
    [downloadCombinedDocument, handleAsyncAction],
  );

  const handleDeleteDocument = useCallback(
    (document: PolicyDocumentDTO) => () => {
      handleAsyncAction(() => deleteDocument(document.id), {
        infoMessage: `Deleting ${document.documentType}...`,
        successMessage: 'Document deleted',
      });
    },
    [deleteDocument, handleAsyncAction],
  );

  const handleUpdateDocumentType = useCallback(
    (document) => (type: PolicyDocumentType) => {
      handleAsyncAction(() => updateDocument({ documentId: document.id, documentType: type }), {
        infoMessage: `Updating ${document.documentType}...`,
        successMessage: 'Document updated',
      });
    },
    [updateDocument, handleAsyncAction],
  );

  const handleDownloadDocument = useCallback(
    (document) => () => {
      handleAsyncAction(() => downloadDocument(document), {
        infoMessage: `Downloading ${document.documentType}...`,
        successMessage: 'Document downloaded',
      });
    },
    [downloadDocument, handleAsyncAction],
  );

  const rows = useMemo((): ListProps['rows'] => {
    if (!data) {
      return [];
    }
    const rowsData = [];

    if (policy?.signedDocumentsPath) {
      rowsData.unshift({
        value: (
          <LinkButton onClick={handleDownloadSignedPolicyDocument(policy)}>Signed Policy Documents.pdf</LinkButton>
        ),
        title: 'Signed Documents',
      });
    }

    if (!isAdmin) {
      data.forEach((el) =>
        rowsData.push({
          value: <LinkButton onClick={handleDownloadDocument(el)}>{el.fileName}</LinkButton>,
          title: DOCUMENT_TYPE_HUMANIZED_NAME[el.documentType],
        }),
      );
    }

    return rowsData;
  }, [policy, handleDownloadSignedPolicyDocument, data, handleDownloadDocument, isAdmin]);

  if (!isAdmin && !rows.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      {rows?.length !== 0 && (
        <>
          <h3 className={classes.title}>Documents</h3>
          {rows.length ? <DetailsList rows={rows} className={classes.list} /> : null}
        </>
      )}
      {isAdmin && (
        <>
          <h3 className={classes.title}>Upload Documents</h3>
          <div className={classes.list}>
            {data?.map((doc) => (
              <EditableDocumentRow
                selectClassName={classes.select}
                types={Object.values(PolicyDocumentType).filter(
                  (documentType) => isAdmin || documentType != PolicyDocumentType.CARRIER_INVOICE,
                )}
                key={doc.id}
                onRequestDownload={handleDownloadDocument(doc)}
                onSelectType={handleUpdateDocumentType(doc)}
                document={doc}
                isDeleting={isDeleting}
                isUpdating={isUpdating}
                onDelete={handleDeleteDocument(doc)}
              />
            ))}
          </div>
          <FilesDnD addAnother={data && data?.length > 0} onDrop={handleUpload} />
        </>
      )}
    </div>
  );
};
