import { EmptyState } from 'components/EmptyState';
import { Money } from 'components/Money';
import { CarrierColumn } from 'components/QuotesTable/CarrierColumn';
import { Column, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationDTO } from 'dtos/application';
import { DeclinationDTO } from 'dtos/declinations';
import { ReactComponent as QuoteIcon } from 'images/quote.svg';
import { useApplicationDeclinations } from 'queries/useApplications';
import React from 'react';
import { DeleteDeclinationButton } from 'views/agent/views/application-details/views/quotes/DeleteDeclinationButton';

interface Props {
  application: ApplicationDTO;
  onDeclinationSelect: (item: DeclinationDTO) => void;
  selectedRowsKeys: string[];
  selectedRowClass: string;
}

const columns: Array<Column<DeclinationDTO, keyof DeclinationDTO>> = [
  {
    title: 'Carrier',
    width: '15%',
    render: (item) => <CarrierColumn carrier={item.carrier} />,
  },
  {
    title: 'Reason',
    width: '20%',
    render: (item) => item.reason,
  },
  {
    title: 'Requested Retention',
    width: '20%',
    render: (item) => <Money value={item.requestedRetention} />,
  },
  {
    title: 'Requested Aggregate Limit',
    width: '20%',
    render: (item) => <Money value={item.requestedAggregateLimit} />,
  },
  {
    title: 'Requested Effective Date',
    width: '20%',
    render: (item) => dayjs(item.requestedEffectiveDate).format('M/D/YYYY'),
  },
  {
    title: 'Actions',
    width: '5%',
    render: (item) => {
      return (
        <>
          <DeleteDeclinationButton declination={item} />
        </>
      );
    },
  },
];

export const DeclinationsTable = ({ application, onDeclinationSelect, selectedRowClass, selectedRowsKeys }: Props) => {
  const { data, isLoading } = useApplicationDeclinations(application.id);

  if (!isLoading && data && data.length === 0) {
    return <EmptyState title='No Declined Quotes' icon={<QuoteIcon />} />;
  }

  return (
    <Table<DeclinationDTO>
      hideHeadersWhenEmpty
      columns={columns}
      loading={isLoading}
      data={data}
      selectedRowsKeys={selectedRowsKeys}
      selectedRowClass={selectedRowClass}
      onRowClick={(item) => onDeclinationSelect(item)}
      rowKey={(el) => el.id}
    />
  );
};
