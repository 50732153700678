import { makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import React, { ReactElement } from 'react';

interface StylesProps {
  inline?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(() => ({
  '@keyframes placeHolderShimmer': {
    '0%': {
      backgroundPosition: '-468px 0',
    },
    '100%': {
      backgroundPosition: '468px 0',
    },
  },

  root: ({ inline }) => ({
    ...(inline && { display: 'inline-block' }),
  }),

  placeholder: {
    animationDuration: '1.25s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeHolderShimmer',
    animationTimingFunction: 'linear',
    backgroundColor: 'darkgray',
    background: 'linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%)',
    backgroundSize: '800px 104px',
    position: 'relative',
    color: 'transparent !important',
    zIndex: 2,
    userSelect: 'none',
    pointerEvents: 'none',

    '& > *': {
      opacity: 0,
      visiblity: 'hidden',
    },
  },
}));

interface Props extends StylesProps {
  children: ReactElement;
  isActive: boolean;
  className?: string;
}

export const Placeholder = ({ children, isActive, className, inline }: Props) => {
  const classes = useStyles({ inline });

  return <div className={cx(classes.root, className, isActive && classes.placeholder)}>{children}</div>;
};
