import { makeStyles } from '@material-ui/core';
import { InsurabilityScoreComponents } from 'dtos/application';
import React from 'react';

// const columns: Column<InsurabilityScoreComponents, keyof InsurabilityScoreComponents>[] = [
//   {
//     title: 'Carrier',
//     width: '70%',
//     render: (row) => row?.carrier || '-',
//   },
//   {
//     title: 'Insurability score per carrier',
//     width: '30%',
//     render: (row) => (
//       <span style={{ color: COLORS.BLUE_1, fontWeight: 700 }}>{Math.round((row?.score || 0) * 100)}%</span>
//     ),
//   },
// ];

interface Props {
  components?: InsurabilityScoreComponents[];
}

export const useStyles = makeStyles((theme) => ({
  twoColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
}));

export const InsurabilityScoreComponentsTable = ({ components }: Props) => {
  const classes = useStyles();

  // return (
  //   <div style={{ maxHeight: '50vh' }}>
  //     <Table<InsurabilityScoreComponents>
  //       columns={columns}
  //       data={components}
  //       rowKey={(el, idx) => el?.carrier || idx.toString()}
  //       stickyHeader
  //     />
  //   </div>
  // );

  return (
    <div>
      <span style={{ fontWeight: 600, marginTop: 16 }}>List of carriers (addressable market):</span>
      <div className={classes.twoColumn} style={{ marginTop: 16 }}>
        {components?.map((component) => (
          <span key={component.carrier}>{component.carrier}</span>
        ))}
      </div>
    </div>
  );
};
