import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import cx from 'classnames';
import { ApplicationStatus } from 'dtos/application';
import startCase from 'lodash/startCase';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  status: ApplicationStatus;
  fullWidth?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
    width: (props) => (props.fullWidth ? '100%' : 'fit-content'),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: `2px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2)}px`,
    border: `1px solid ${COLORS.WHITE}`,
    color: (props) => {
      switch (props.status) {
        case ApplicationStatus.IN_PROGRESS:
        case ApplicationStatus.NOT_STARTED:
        case ApplicationStatus.CONFIGURATION_PENDING:
        case ApplicationStatus.CONFIGURATION_DONE:
        case ApplicationStatus.DATA_PENDING:
        case ApplicationStatus.IN_REVIEW:
        case ApplicationStatus.REPORT_REQUESTED:
        case ApplicationStatus.RESCAN_ALL:
          return COLORS.GREY_1;
        case ApplicationStatus.QUOTES_DO_NOT_EXIST:
        case ApplicationStatus.ARCHIVED:
        case ApplicationStatus.UNINSTALL_ALL:
          return COLORS.RED_1;
        case ApplicationStatus.SUBMITTED:
        case ApplicationStatus.REPORT_COMPLETED:
          return COLORS.GREEN_1;
        case ApplicationStatus.BOUND:
          return COLORS.WHITE;
        case ApplicationStatus.QUOTED:
        case ApplicationStatus.BIND_REQUESTED:
          return COLORS.BLUE_1;
      }
    },
    background: (props) => {
      switch (props.status) {
        case ApplicationStatus.IN_PROGRESS:
        case ApplicationStatus.IN_REVIEW:
        case ApplicationStatus.REPORT_REQUESTED:
        case ApplicationStatus.RESCAN_ALL:
          return 'rgba(252, 245, 218, 0.8)';
        case ApplicationStatus.NOT_STARTED:
        case ApplicationStatus.CONFIGURATION_PENDING:
        case ApplicationStatus.CONFIGURATION_DONE:
        case ApplicationStatus.DATA_PENDING:
          return COLORS.GREY_5;
        case ApplicationStatus.SUBMITTED:
        case ApplicationStatus.REPORT_COMPLETED:
          return COLORS.GREEN_3;
        case ApplicationStatus.QUOTES_DO_NOT_EXIST:
        case ApplicationStatus.ARCHIVED:
        case ApplicationStatus.UNINSTALL_ALL:
          return COLORS.PEACH_3;
        case ApplicationStatus.BOUND:
          return COLORS.BLUE_1;
        case ApplicationStatus.QUOTED:
        case ApplicationStatus.BIND_REQUESTED:
          return COLORS.BLUE_3;
      }
    },
    borderColor: (props) => {
      switch (props.status) {
        case ApplicationStatus.QUOTES_DO_NOT_EXIST:
        case ApplicationStatus.ARCHIVED:
        case ApplicationStatus.UNINSTALL_ALL:
          return COLORS.PEACH_1;
        case ApplicationStatus.BOUND:
          return COLORS.BLUE_1;
        case ApplicationStatus.BIND_REQUESTED:
        case ApplicationStatus.QUOTED:
          return COLORS.BLUE_2;
        case ApplicationStatus.SUBMITTED:
        case ApplicationStatus.REPORT_COMPLETED:
          return COLORS.GREEN_2;
        case ApplicationStatus.IN_PROGRESS:
        case ApplicationStatus.IN_REVIEW:
        case ApplicationStatus.REPORT_REQUESTED:
        case ApplicationStatus.RESCAN_ALL:
          return COLORS.YELLOW_2;
        case ApplicationStatus.NOT_STARTED:
        case ApplicationStatus.CONFIGURATION_PENDING:
        case ApplicationStatus.CONFIGURATION_DONE:
        case ApplicationStatus.DATA_PENDING:
          return COLORS.GREY_5;
      }
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}));

interface Props extends StyleProps {
  className?: string;
  onClick?: () => void;
  buttonRef?: React.RefObject<HTMLButtonElement> | null;
  isAdmin?: boolean;
}

export function ApplicationStatusLabel({ status, className, onClick, buttonRef, fullWidth, isAdmin }: Props) {
  const classes = useStyles({ status, fullWidth });
  const rootClasses = cx(classes.root, className, onClick && classes.clickable);

  const getStatusForUser = (status: ApplicationStatus, isAdmin?: boolean) => {
    if (isAdmin) return status;

    switch (status) {
      case ApplicationStatus.CONFIGURATION_PENDING:
      case ApplicationStatus.CONFIGURATION_DONE:
      case ApplicationStatus.DATA_PENDING:
        return ApplicationStatus.NOT_STARTED;
      default:
        return status;
    }
  };

  return (
    <span className={rootClasses} onClick={onClick} role='button' tabIndex={0} ref={buttonRef}>
      {startCase(getStatusForUser(status, isAdmin))} {onClick && <ArrowDropDownRoundedIcon fontSize='small' />}
    </span>
  );
}
